import { Component, ViewContainerRef } from '@angular/core';

import { SharedService } from 'app/shared/shared.service';
import { ConfirmationDeactivateService } from 'app/shared/ConfirmationDeactivateModule/confirmationdeactivate.service';

import { Language, EntityDto } from 'app/shared/model';
import { Router } from '@angular/router';

@Component({
  templateUrl: './layout-mobile.component.html',
  styleUrls: ['./layout-mobile.component.scss']
})
export class LayoutMobileComponent  {
  public drawerOpen: boolean;


  constructor(public sharedService: SharedService,
    private router: Router, private vRef: ViewContainerRef,
    private confirmationDeactivateService: ConfirmationDeactivateService) {
      this.drawerOpen = false;

      this.confirmationDeactivateService.setRootViewContainerRef(this.vRef);
  }

  toggleDrawer() {
    this.drawerOpen = !this.drawerOpen;
  }

  openDrawer() {
    this.drawerOpen = true;
  }

  closeDrawer() {
    this.drawerOpen = false;
  }

  linkClicked(route: string) {
    this.router.navigateByUrl(route);
    this.closeDrawer();
  }

  languageChanged(language: Language) {
    this.sharedService.setCurrentLanguage(language);
  }

  entityChanged(entity: EntityDto) {
    this.sharedService.setCurrentEntity(entity.Id);
  }
}
