import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StickyHeaderComponent } from './sticky-header.component';

@NgModule({
    imports: [CommonModule],
    exports: [StickyHeaderComponent],
    declarations: [StickyHeaderComponent],
    providers: []
})
export class StickyHeaderModule { }
