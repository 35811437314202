import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

import { PphraseDto, HphraseDetailsDto } from '../../manage-articles.model';
import { PphraseKind } from '../../../manage-pphrases/manage-pphrases.model';
import { CustomPphraseDto } from '../../../../risk-analysis/risk-analysis.model';
import { ArticleHphraseDto } from 'app/requests/new-request/new-request.model';

@Component({
  selector: 'app-manage-article-h-p-phrases',
  templateUrl: './manage-article-h-p-phrases.component.html'
})
export class ManageArticleHpPhrasesComponent implements OnInit, OnChanges {
  @Input() hPhrases: HphraseDetailsDto[];
  @Input() articleHphrases: ArticleHphraseDto[];
  @Input() multiselectData: PphraseDto[];
  @Input() kinds: PphraseKind[];
  @Input() additionalPphrases: CustomPphraseDto[];

  @Output() pPhrasesUpdated = new EventEmitter();
  @Output() hPhrasesUpdated = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.additionalPphrases) {
      this.additionalPphrases = changes.additionalPphrases.currentValue;
    } else if (changes.articleHphrases) {
      this.articleHphrases = changes.articleHphrases.currentValue;
    }
  }

  updatePphrases(pPhrases: CustomPphraseDto[]) {
    this.additionalPphrases = pPhrases;
    this.pPhrasesUpdated.emit(pPhrases);
  }

  updateHphrases(hPhrases: ArticleHphraseDto[]) {
    this.articleHphrases = hPhrases;
    this.hPhrasesUpdated.emit(hPhrases);
  }
}
