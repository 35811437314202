import { Component, OnInit } from '@angular/core';

import { finalize } from 'rxjs/operators';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { GridStateHelper } from '../../../data-management/shared/grid-state';

import { SdsReportDto } from '../../reports.model';
import { ReportsService } from '../../reports.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';
import { FileManagerService } from '../../../shared/file-manager/file-manager.service';

@Component({
  selector: 'app-sds-report',
  templateUrl: './sds-report.component.html',
  styleUrls: ['./sds-report.component.scss']
})
export class SdsReportComponent implements OnInit {

  items: SdsReportDto[] = [];
  gridData: GridDataResult;
  state: State;

  constructor(
    private gridState: GridStateHelper,
    private reportService: ReportsService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService,
    private fileManager: FileManagerService
  ) {
    this.state = this.gridState.getBasicState();
  }

  ngOnInit() {
    this.spinner.show('spinner');
    this.reportService.getArticlesforSdsReport()
    .pipe(
      finalize(() => this.spinner.hide('spinner'))
    )
    .subscribe(
      (items) => {
        this.items = items.map( i => {
          i.SdsDate = new Date(i.SdsDate);
          return i;
        });
        this.gridData = process(this.items, this.state);
      },
      () => this.notificationService.showDefaultMsgError()
    );
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  downloadReport() {
    this.spinner.show('spinner');
    this.reportService
      .downloadSdsReport()
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe(
        (data: Blob) => {
          data['name'] = `SDS_${Date.now()}.xlsx`;
          this.fileManager.downloadFile(data);
        },
        () => {
          this.notificationService.showMsgError(
            'error.cannot_download_sds_report',
            'There was an error on downloading SDS report.'
          );
        }
      );
  }
}
