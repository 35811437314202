import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
    UserCompleteDto, SevesoNominativeDto, StateOfMatterDto, EntityDto, ContainerTypeDto, LanguageCode, SiteGridDto, LocationGridDto, SiteDto
} from 'app/shared/model';
import {
    ArticleBasicDataDto, EditArticleDetailsDto, EditArticleDto,
    CharacteristicsDto, CharacteristicsAndContainersDto, ContainerDto, UpdatePphrasesDto, UpdateHphrasesDto
} from './manage-articles/manage-articles.model';
import { GhsGridDto } from './manage-ghs/manage-ghs.model';
import {
    DangerClassDto, SupplierDto, HphraseDto, CategoryDto, ClassDto, TypeDto, SignalWordDto
} from 'app/catalogue-view/shared/catalogue-view.model';
import { PphraseType, PphraseKind, PphraseGridDto } from './manage-pphrases/manage-pphrases.model';
import { TechnicalMeasureEditDto } from './manage-technical-measures/manage-technical-measures.model';

import { DataService } from '../data.service';
import { RiskTypeDto } from 'app/risk-analysis/risk-analysis.model';

@Injectable({
    providedIn: 'root'
})
export class DataManagementService {

    constructor(private dataService: DataService) { }

    getDangerClasses(): Observable<any> {
        return this.dataService.get('DangerClass/GetDangerClasses', {});
    }

    getSuppliers(): Observable<any> {
        return this.dataService.get('Supplier/GetSuppliers', {});
    }

    updateSupplier(supplierDto: SupplierDto): Observable<SupplierDto> {
        return this.dataService.patch('Supplier/UpdateSupplier', supplierDto);
    }

    addSupplier(supplierDto: SupplierDto): Observable<SupplierDto> {
        return this.dataService.post('Supplier/AddSupplier', supplierDto);
    }

    addNewDangerClass(dangerClassDto: DangerClassDto): Observable<DangerClassDto> {
        return this.dataService.post('DangerClass/SaveDangerClass', dangerClassDto);
    }

    updateExistingDangerClass(dangerClassDto: DangerClassDto): Observable<DangerClassDto> {
        return this.dataService.patch('DangerClass/UpdateDangerClass', dangerClassDto);
    }

    getUsers(): Observable<UserCompleteDto[]> {
        return this.dataService.get('User/GetUsers', {});
    }

    addNewUser(userCompleteDto: UserCompleteDto): Observable<UserCompleteDto> {
        return this.dataService.post('User/SaveCompleteUser', userCompleteDto);
    }

    updateExistingUser(userCompleteDto: UserCompleteDto): Observable<UserCompleteDto> {
        return this.dataService.put('User/UpdateExistingUser', userCompleteDto);
    }

    deleteUser(id: string): Observable<any> {
        const url = `User/DeleteUser/${id}`;
        return this.dataService.put(url, {});
    }

    addNewSevesoNominative(sevesoNominativeDto: SevesoNominativeDto): Observable<SevesoNominativeDto> {
        return this.dataService.post('SevesoNominative/CreateSevesoNominative', sevesoNominativeDto);
    }

    updateExistingSevesoNominative(sevesoNominativeDto: SevesoNominativeDto): Observable<SevesoNominativeDto> {
        return this.dataService.patch('SevesoNominative/UpdateSevesoNominative', sevesoNominativeDto);
    }

    getArticlesBasicData(entityId: string): Observable<ArticleBasicDataDto[]> {
        return this.dataService.get('Article/GetArticlesBasicData', {entityId});
    }

    getEntities(): Observable<EntityDto[]> {
        return this.dataService.get('Entity/GetEntities', {});
    }

    updateEntity(entityDto: EntityDto): Observable<EntityDto> {
        return this.dataService.patch('Entity/UpdateEntity', entityDto);
    }

    addEntity(entityDto: EntityDto): Observable<EntityDto> {
        return this.dataService.post('Entity/AddEntity', entityDto);
    }

    updateContainerType(containerTypeDto: ContainerTypeDto): Observable<ContainerTypeDto> {
        return this.dataService.patch('ContainerType/UpdateContainerType', containerTypeDto);
    }

    addContainerType(containerTypeDto: ContainerTypeDto): Observable<ContainerTypeDto> {
        return this.dataService.post('ContainerType/AddContainerType', containerTypeDto);
    }

    getGridSites(): Observable<SiteGridDto[]> {
        return this.dataService.get('Sites/GetGridSites', {});
    }

    updateSite(site: SiteGridDto): Observable<SiteGridDto> {
        return this.dataService.patch('Site/UpdateSite', site);
    }

    addSite(site: SiteGridDto): Observable<SiteGridDto> {
        return this.dataService.post('Site/AddSite', site);
    }

    getCompleteProducers(): Observable<any> {
        return this.dataService.get('Producer/GetCompleteProducers', {});
    }

    getSevesoNominatives(): Observable<SevesoNominativeDto[]> {
        return this.dataService.get('SevesoNominative/GetSevesoNominatives', {});
    }

    getStatesOfMatter(): Observable<StateOfMatterDto[]> {
        return this.dataService.get('StatesOfMatter/GetStatesOfMatter', {});
    }

    getProducers(): Observable<any> {
        return this.dataService.get('Producer/GetProducersBaseInfo', {});
    }

    getFamilies(): Observable<any> {
        return this.dataService.get('Article/GetFamilies', {});
    }

    updateArticleDetails(formData: FormData): Observable<EditArticleDetailsDto> {
        return this.dataService.postMultiPartData('Article/UpdateArticleDetails', formData);
    }

    getAllGhs(): Observable<GhsGridDto[]> {
        return this.dataService.get('Ghs/GetAllGhs', {});
    }

    updateGhs(ghs: GhsGridDto): Observable<GhsGridDto[]> {
        return this.dataService.patch('Ghs/UpdateGhs', ghs);
    }

    getAllPphrases(): Observable<PphraseGridDto[]> {
        return this.dataService.get('Pphrase/GetAllPphrases', {});
    }

    getAllHphrases(): Observable<HphraseDto[]> {
        return this.dataService.get('Hphrase/GetAllHphrases', {});
    }

    getAllRiskTypes(): Observable<RiskTypeDto[]> {
        return this.dataService.get('Hphrase/GetAllRiskTypes', {});
    }

    getPphraseTypes(): Observable<PphraseType[]> {
        return this.dataService.get('Pphrase/GetAllPphraseTypes', {});
    }

    getPphraseKinds(): Observable<PphraseKind[]> {
        return this.dataService.get('Pphrase/GetAllPphraseKinds', {});
    }

    addPphrase(pPhraseGridDto: PphraseGridDto): Observable<PphraseGridDto> {
        return this.dataService.post('Pphrase/AddPphrase', pPhraseGridDto);
    }

    updatePphrase(pPhraseGridDto: PphraseGridDto): Observable<PphraseGridDto> {
        return this.dataService.patch('Pphrase/UpdatePphrase', pPhraseGridDto);
    }

    addHphrase(hPhrase: HphraseDto): Observable<HphraseDto> {
        return this.dataService.post('Hphrase/AddHphrase', hPhrase);
    }

    updateHphrase(hPhrase: HphraseDto): Observable<HphraseDto> {
        return this.dataService.patch('Hphrase/UpdateHphrase', hPhrase);
    }

    updateLocation(site: LocationGridDto): Observable<LocationGridDto> {
        return this.dataService.patch('Location/UpdateLocation', site);
    }

    addLocation(site: LocationGridDto): Observable<LocationGridDto> {
        return this.dataService.post('Location/AddLocation', site);
    }

    getGridLocations(): Observable<LocationGridDto[]> {
        return this.dataService.get('Location/GetGridLocations', {});
    }

    getSites(): Observable<SiteDto[]> {
        return this.dataService.get('Site/GetSites', {});
    }

    getArticle(articleId: number, languageCode: LanguageCode): Observable<EditArticleDto> {
        return this.dataService.get('Article/GetArticle', { articleId, languageCode: languageCode });
    }

    putCharacteristic(characteristic: CharacteristicsDto): Observable<CharacteristicsDto> {
        return this.dataService.put('Article/PutCharacteristic', characteristic);
    }

    putContainer(container: ContainerDto): Observable<ContainerDto> {
        return this.dataService.put('Article/PutContainer', container);
    }

    getCharacteristicsAndContainersByArticle(articleId: number): Observable<CharacteristicsAndContainersDto[]> {
        return this.dataService.get('Article/GetCharacteristicsAndContainersByArticle', { articleId });
    }

    getTechnicalMeasures(language: LanguageCode) {
        return this.dataService.get('TechnicalMeasure/GetTechnicalMeasures', { language: language });
    }

    updateTechnicalMeasure(measure: TechnicalMeasureEditDto) {
        return this.dataService.patch('TechnicalMeasure/UpdateTechnicalMeasure', measure);
    }

    deleteCharacteristic(characteristicId: number): Observable<any> {
        return this.dataService.delete('Article/DeleteCharacteristic/' + characteristicId, null);
    }

    areApprovalsAssignedToCharacteristics(characteristicId: number): Observable<boolean> {
        return this.dataService.get('Article/AreApprovalsAssignedToCharacteristics/' + characteristicId, null);
    }

    deleteContainer(containerId: number): Observable<any> {
        return this.dataService.delete('Article/DeleteContainer/' + containerId, null);
    }

    areApprovalsAssignedToContainer(containerId: number): Observable<boolean> {
        return this.dataService.get('Article/AreApprovalsAssignedToContainer/' + containerId, null);
    }

    updatePphrases(updatePphrasesDto: UpdatePphrasesDto): Observable<any> {
        return this.dataService.patch('Article/UpdatePphrases', updatePphrasesDto);
    }

    updateHphrases(updateHphrasesDto: UpdateHphrasesDto): Observable<any> {
        return this.dataService.patch('Article/UpdateHphrases', updateHphrasesDto);
    }

    getCategories(): Observable<CategoryDto[]> {
        return this.dataService.get('DangerClass/GetCategories', {});
    }

    addNewArticle(formData: FormData): Observable<EditArticleDto> {
        return this.dataService.postMultiPartData('Article/AddNewArticle', formData);
    }

    getDangerClassClasses(): Observable<ClassDto[]> {
        return this.dataService.get('DangerClass/GetClasses', {});
    }

    getDangerClassTypes(): Observable<TypeDto[]> {
        return this.dataService.get('DangerClass/GetTypes', {});
    }

    getDangerClassSignalWords(): Observable<SignalWordDto[]> {
        return this.dataService.get('DangerClass/GetSignalWords', {});
    }
}
