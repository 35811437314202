import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ApprovalDetailsDto, ApprovalEditDetailsDto, ApprovalActionEnum } from '../../shared/catalogue-view.model';
import { FileManagerService } from '../../../shared/file-manager/file-manager.service';
import { FileToDownload, FileTypeEnum, DownloadedFile } from '../../../shared/file-manager/file-manager.model';
import { SharedService } from 'app/shared/shared.service';
import { UserRole } from 'app/shared/model';
import { ArticleCommunicationService } from 'app/catalogue-view/shared/article-communication.service';
import { UserDataSheetDto } from '../../../printing-module/print.model';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { PrintingModuleService } from '../../../printing-module/printing-module.service';
import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';

@Component({
  selector: 'app-article-details-sidebar',
  templateUrl: './article-details-sidebar.component.html',
  styleUrls: ['./article-details-sidebar.component.scss']
})
export class ArticleDetailsSidebarComponent implements OnInit {
  @Input() approval: ApprovalDetailsDto;
  @Output() downloadSdsFile = new EventEmitter();
  @Output() deleteApprovalOpened = new EventEmitter();

  public isAdmin: boolean;
  public printingLabelsModalOpen = false;
  private fetchFilesubscription$: Subscription;
  private file: File | Blob;

  private derogationFile: Blob | File;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private printingService: PrintingModuleService,
    private fileManagerService: FileManagerService,
    private sharedservice: SharedService,
    private communicationService: ArticleCommunicationService,
    private notificationService: TranslatedNotificationService) {
    communicationService.editApprovalData$.subscribe(
      approvalData => {
        this.updateApprovalData(approvalData);
      });
  }

  ngOnInit() {
    const filepath = this.approval?.Warning?.DerogationFilePath;
    this.isAdmin = this.sharedservice.userRole == UserRole.Admin;

    if (filepath) {
      this.prepareDownloadFile(filepath);
    }
  }

  public showSdsFile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.downloadSdsFile.emit();
    } else {
      this.router.navigate(['../' + this.approval.Id + '/sds'], { relativeTo: this.route });
    }
  }

  openModal() {
    this.redirect(ApprovalActionEnum.EDIT);
    this.communicationService.toggleEditAprovalModal(true);
  }

  goToRiskAnalysis() {
    this.router.navigate(['/risk-analysis', this.approval.Request.Id]);
  }

  copyApproval() {
    this.redirect(ApprovalActionEnum.COPY);
    this.communicationService.toggleCopyApprovalModal(true);
  }

  removeApproval() {
    this.deleteApprovalOpened.emit();
  }

  updateApprovalData(approvalData: ApprovalEditDetailsDto) {
    if (this.fetchFilesubscription$) {
      this.fetchFilesubscription$.unsubscribe();
    }

    if (approvalData.DerogationFilePath) {
      this.prepareDownloadFile(approvalData.DerogationFilePath);
    } else {
      this.derogationFile = null;
    }
  }

  prepareDownloadFile(fileName: string) {
    const file = <FileToDownload>{
      FileName: fileName,
      FileType: FileTypeEnum.DerogationFilePath
    };

    this.fetchFilesubscription$ = this.fileManagerService.fetchFile(file).subscribe(
      (data: DownloadedFile) => {
        if (data) {
          this.derogationFile = !!data && data.File;
        }
      }
    );
  }

  openPrintingLabelsModal() {
    this.printingLabelsModalOpen = true;
  }

  closeLabelsDialog() {
    this.printingLabelsModalOpen = false;
  }

  printUserDataSheet() {
    const dto = <UserDataSheetDto>{
      ApprovalId: this.approval.Id,
      LanguageCode: this.sharedservice.currentLanguage.LanguageCode
    };

    this.spinnerService.show('spinner');
    this.printingService.downloadUserDataSheet(dto)
      .pipe(
        finalize(() => this.spinnerService.hide('spinner'))
      )
      .subscribe(
        (data: Blob) => {
          data['name'] = `UserDataSheet_${this.approval.Article.Name}.pdf`;
          this.fileManagerService.downloadFile(data);
        },
        () => {
          this.notificationService.showMsgError(
            'error.cannot_generate_user_data_sheet',
            'There was an error while generating User Data Sheet for the article. Please try again.'
          );
        }
      );
  }

  sapLinkExists(link: string) {
    return !!link && link.trim().length > 0;
  }

  openSapLink(link: string) {
    if (this.sapLinkExists(link)) {
      if (!link.match(/^http?:\/\//i) || !link.match(/^https?:\/\//i)) {
        link = 'http://' + link;
      }
      window.open(link, '_blank');
    }
  }

  public getSdsFile() {
    const sdsPath = this.approval.Article.SdsPath;
    const sdsToDownload = <FileToDownload>{
      FileName: sdsPath,
      FileType: FileTypeEnum.SdsFilePath
    };
    this.fileManagerService.fetchFile(sdsToDownload)
      .subscribe(
        (data: DownloadedFile) => {
          if (data) {
            this.file = data.File;
          }
          this.fileManagerService.downloadFile(this.file, this.approval?.Article?.SdsDisplayName);
        },
        () => {
          this.notificationService.showMsgError(
            'error.cannot_load_sds_file',
            'Cannot find the SDS file for the article. Please upload it again.'
        );
        }
      );
}

  private redirect(actionType: ApprovalActionEnum) {
    const sdsUrl = '/catalogue/' + this.approval.Id + '/sds';
    if (this.router.url === sdsUrl) {
      this.router.navigate(['../' + this.approval.Id], { relativeTo: this.route, queryParams: { actionType: actionType } });
    }
  }
}
