import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-topbar',
    templateUrl: 'topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})

export class TopbarComponent {
    @Input() userName: string;
}
