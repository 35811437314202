import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { CharacteristicsDto } from '../../../manage-articles.model';

@Component({
  selector: 'app-manage-characteristics',
  templateUrl: './manage-characteristics.component.html',
  styleUrls: ['./manage-characteristics.component.scss']
})
export class ManageCharacteristicsComponent implements OnInit, OnChanges {
  @Input() characteristics: CharacteristicsDto[];

  @Output() characteristicPut = new EventEmitter();
  @Output() characteristicDeleted = new EventEmitter();

  initializated: boolean;
  subdivisions: string[];
  addCharacteristicForm: FormGroup;
  editCharacteristicForm: FormGroup;
  editedCharacteristicIndex: number;

  private temporalId: number;

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    if (this.characteristics) {
      this.subdivisions = this.characteristics.map(characteristic => {
        return characteristic.Subdivision;
      });
    }

    this.assignIds();
    this.addCharacteristicForm = this.createForm();
    this.editCharacteristicForm = this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.characteristics = changes.characteristics.currentValue;
  }

  getDensity(characteristic: CharacteristicsDto) {
    return characteristic.Density ? characteristic.Density : '-';
  }

  discardEditForm() {
    this.editedCharacteristicIndex = -1;
    this.clearForm(this.editCharacteristicForm);
  }

  clearForm(form: FormGroup) {
    form.reset();
    form.markAsPristine();
  }

  submitForm(form: FormGroup, id: number) {
    const isEdit = !!id;
    const characteristic = this.collectData(form);

    if (isEdit) {
      characteristic.Id = id;
      characteristic.TempId = id;
    }

    if (form.valid && this.isNewCharacteristic(characteristic)) {
      this.characteristicPut.emit(characteristic);
      isEdit ? this.discardEditForm() : this.clearForm(form);
      this.temporalId++;
    }
  }

  editCharacteristic(id: number, index: number) {
    this.fillEditForm(id);
    this.editedCharacteristicIndex = index;
  }

  deleteCharacteristic(id: number) {
    this.characteristicDeleted.emit(id);
  }

  private fillEditForm(id: number) {
    const characteristicToEdit = this.characteristics.find(x => x.TempId === id);

    if (characteristicToEdit) {
      this.editCharacteristicForm.patchValue(characteristicToEdit);
    }
  }

  private assignIds() {
    this.temporalId = 1;
    if (this.characteristics) {
      const tempIds = this.characteristics.map(p => p.TempId = p.Id);
      this.temporalId = Math.max(...tempIds, 1);
    }
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      Id: [''],
      Subdivision: [''],
      Density: [null]
    });
  }

  private isNewCharacteristic(characteristic: CharacteristicsDto): boolean {
    if (this.characteristics) {
      return !this.characteristics
        .filter(x => x.Subdivision === characteristic.Subdivision)
        .find(x => x.Density === characteristic.Density);
    }

    return true;
  }

  private collectData(form: FormGroup): CharacteristicsDto {
    return <CharacteristicsDto>{
      Subdivision: form.get('Subdivision').value ? form.get('Subdivision').value : null,
      Density: form.get('Density').value ? form.get('Density').value : null,
      TempId: this.temporalId
    };
  }
}
