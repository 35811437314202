import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

/**
 * Directive searches for <input> element within the given element i.e. <kendo-combobox>
 * after view initialization and adds autocomplete="new-password" attribute to prevent
 * browsers from giving hint on stored passwords, addresses, email addresses, phones etc.
 */
@Directive({
  selector: '[appDisableAutocomplete]'
})
export class DisableAutocompleteDirective implements AfterViewInit {

  constructor(private element: ElementRef, private render: Renderer2) {}

  ngAfterViewInit() {
    const inputElement = this.element.nativeElement.querySelector('input');

    if (inputElement) {
      this.render.setAttribute(inputElement, 'autocomplete', 'new-password');
    }
  }
}

