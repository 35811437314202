import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { forkJoin, EMPTY } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { HphraseDto } from './../../../catalogue-view/shared/catalogue-view.model';
import { ArticleDetailsDto, EditRequestedArticleDto, ArticleHphraseDto } from './../../new-request/new-request.model';
import {
    SevesoNominativeDto, FlashPointSignDto, StateOfMatter, FlashPointSign, LimitValueUnit, LimitValueUnitDto
} from './../../../shared/model';
import { PphraseDto, ArticleDto } from 'app/catalogue-view/shared/catalogue-view.model';
import { RequestDetailsDto } from '../request-view.model';

import { DataManagementService } from './../../../data-management/data-management.service';
import { RiskAnalysisService } from './../../../risk-analysis/risk-analysis.service';
import { RequestService } from './../../request.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { SharedService } from '../../../shared/shared.service';
import { PphraseKind } from 'app/data-management/manage-pphrases/manage-pphrases.model';
import { CustomPphraseDto } from 'app/risk-analysis/risk-analysis.model';

@Component({
    selector: 'app-edit-article-modal',
    templateUrl: './edit-article-modal.component.html',
    styleUrls: ['./edit-article-modal.component.scss']
})
export class EditArticleModalComponent implements OnInit {
    @Input() editArticleOpen: boolean;
    @Input() requestData: RequestDetailsDto;
    @Input() requestId: number;
    @Input() article: ArticleDto;
    @Input() isArticleNew: boolean;

    @Output() editArticleModalClose = new EventEmitter();
    @Output() updatedArticle = new EventEmitter();
    @Output() changeName = new EventEmitter();
    @Output() formStateChange: EventEmitter<boolean> = new EventEmitter();

    articleForm: FormGroup;
    allNominatives: SevesoNominativeDto[];
    articleDetails: ArticleDetailsDto;
    flashPointSigns: FlashPointSignDto[];
    flashPointSignEnum = FlashPointSign;
    selectedNominative: SevesoNominativeDto;
    selectedSign: FlashPointSignDto;
    selectedState: StateOfMatter;
    multiselectData: PphraseDto[];
    selectedAdditionalPphrases: PphraseDto[] = [];
    selectedAdditionalPphrasesDescription: string;
    additionalPphrases: CustomPphraseDto[] = [];
    allHphrases: HphraseDto[];
    hPhrasesForSelect: HphraseDto[];
    limitValueUnits: LimitValueUnitDto[];
    limitValueUnitEnum = LimitValueUnit;
    kinds: PphraseKind[];

    constructor(
        private notificationService: TranslatedNotificationService,
        private formBuilder: FormBuilder,
        private spinner: SpinnerService,
        private requestService: RequestService,
        private riskAnalysisService: RiskAnalysisService,
        private sharedService: SharedService,
        private dataManagementService: DataManagementService
    ) {
    }

    ngOnInit() {
        this.spinner.show('spinner');
        forkJoin([
            this.requestService.getSevesoNominatives(),
            this.riskAnalysisService.getRequest(this.requestId, this.sharedService.currentLanguage.LanguageCode, this.sharedService.entityId),
            this.dataManagementService.getAllHphrases(),
            this.requestService.getArticleDetails(this.article.Id, this.sharedService.currentLanguage.LanguageCode),
            this.dataManagementService.getPphraseKinds()])
            .pipe(
                finalize(() => this.spinner.hide('spinner')))
            .subscribe(
                ([seveso, analysis, hPhrases, details, kinds]) => {
                    this.allNominatives = seveso;
                    this.articleDetails = details;
                    this.additionalPphrases = this.articleDetails.Pphrases;
                    this.kinds = kinds;
                    this.mapFlashPointSign();
                    this.mapFinalValueUnit();
                    this.multiselectData = analysis.AvailablePphrases;
                    this.allHphrases = hPhrases;
                    this.setLimitValueValidators();
                    this.setFlashpointValidators();
                    this.fillForm();
                },
                () => this.notificationService.showDefaultMsgError()
            );

        this.createForm();
        this.articleForm.valueChanges.subscribe(() => {
            this.formStateChange.emit(this.articleForm.dirty);
        });
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView({ behavior: 'smooth' });
    }

    editArticleClose() {
        this.editArticleModalClose.emit();
    }

    discard() {
        this.articleForm.reset();
        this.formStateChange.emit(false);
        this.editArticleClose();
    }

    manageItems() {
        this.selectedAdditionalPphrasesDescription = this.selectedAdditionalPphrases.map(p => p.Description).join('; ');
    }

    isItemSelected(code: string): boolean {
        return this.selectedAdditionalPphrases.some(item => item.Code === code);
    }

    removePphrase(code: string) {
        this.additionalPphrases = [...this.additionalPphrases.filter(p => p.Code !== code)];
    }

    removeExistingPphrase(code: string) {
        this.articleDetails.Pphrases = [...this.articleDetails.Pphrases.filter(p => p.Code !== code)];
    }

    removeHphrase(hPhrases: ArticleHphraseDto[]) {
        this.articleDetails.Hphrases = hPhrases;
        this.hPhrasesForSelect = this.getPhrasesForSelect();
    }

    get pPhrasesNotEmpty(): boolean {
        return this.articleDetails.Pphrases && this.articleDetails.Pphrases.length > 0 ||
            this.additionalPphrases && this.additionalPphrases.length > 0;
    }

    get hPhrasesNotEmpty(): boolean {
        return this.articleDetails.Hphrases && this.articleDetails.Hphrases.length > 0;
    }

    updatePphrasesList(event) {
        this.additionalPphrases = event;
    }

    addHphrases(hPhrases: ArticleHphraseDto[]) {
        if (hPhrases) {
            this.articleDetails.Hphrases = this.articleDetails.Hphrases.concat(hPhrases);
            this.hPhrasesForSelect = this.getPhrasesForSelect();
        }
    }

    submitForm() {
        this.articleForm.markAllAsTouched();
        this.scrollToRequired();
        this.spinner.show('spinner');
        const data = this.collectData();

        if (this.articleForm.valid) {
            this.requestService.isRequestFinalStatusSet(this.requestId)
                .pipe(
                    finalize(() => this.spinner.hide('spinner'))
                ).switchMap((isRequestFinalStatusSet) => {
                    if (isRequestFinalStatusSet) {
                        this.notificationService.showMsgInfo(
                            'info.request_final_status_set',
                            'Sorry, it is not possible to modify the request as it has been approved already.');

                        return EMPTY;
                    } else {
                        return this.requestService.updateArticle(data);
                    }
                }).subscribe(() => {
                    this.updatedArticle.emit(data);
                    this.formStateChange.emit(false);
                    this.articleForm.markAsPristine();
                    this.changeName.emit(this.articleForm.get('Name').value);
                    this.editArticleClose();
                });
        } else {
            this.notificationService.showMsgError('error.invalid_form', 'Please fill all the required fields.');
            this.spinner.hide('spinner');
        }
    }

    private scrollToRequired() {
        if (!this.articleForm.get('Name').value) {
            this.scroll(document.getElementById('topRow'));
        } else if (!this.articleForm.get('FlashPointSign').invalid || !this.articleForm.get('FlashPointValue').invalid) {
            this.scroll(document.getElementById('sevesoRow'));
        } else if (!this.selectedState) {
            this.scroll(document.getElementById('flashPointRow'));
        } else if (this.articleForm.get('LimitValue').invalid || this.articleForm.get('LimitValueUnit').invalid) {
            this.scroll(document.getElementById('physicalConditionRow'));
        }
    }

    private createForm() {
        this.articleForm = this.formBuilder.group({
            Name: ['', Validators.required],
            Synonyms: [''],
            Cas: [''],
            FlashPointSign: [null],
            FlashPointValue: [''],
            LimitValue: [null, this.sharedService.validateNumberGreaterThanZero],
            LimitValueUnit: [null],
            SdsNumber: [''],
            ReachNumber: [''],
            Biocide: ['']
        });
    }

    private setLimitValueValidators() {
        const limitValue = this.articleForm.get('LimitValue');
        const limitValueUnit = this.articleForm.get('LimitValueUnit');

        this.sharedService.setConditionalValidation(limitValue, limitValueUnit);
        this.sharedService.setPositiveNumberValidation(limitValue, limitValueUnit);
    }

    private setFlashpointValidators() {
        const flashPointSign = this.articleForm.get('FlashPointSign');
        const flashPointValue = this.articleForm.get('FlashPointValue');

        this.sharedService.setConditionalValidation(flashPointSign, flashPointValue);
        this.sharedService.setConditionalValidation(flashPointValue, flashPointSign);
    }

    private mapFlashPointSign() {
        this.flashPointSigns = this.sharedService.mapFlashPointSign();
    }

    private mapFinalValueUnit() {
        this.limitValueUnits = this.sharedService.mapFinalValueUnit();
    }

    private fillForm() {
        this.articleForm.patchValue({
            Name: this.article.Name,
            Synonyms: this.articleDetails.Synonyms.join(', '),
            Cas: this.articleDetails.Cas,
            FlashPointSign: this.mapSelectedSign(),
            FlashPointValue: this.articleDetails.FlashPointValue,
            LimitValue: this.articleDetails.LimitValue,
            LimitValueUnit: this.mapSelectedLimitValueUnit(),
            SdsNumber: this.articleDetails.SdsNumber,
            ReachNumber: this.articleDetails.ReachNumber,
            Biocide: this.articleDetails.IsBiocide.toString()
        });
        this.selectedNominative = this.findSevesoNominative();
        this.hPhrasesForSelect = this.getPhrasesForSelect();
    }

    private findSevesoNominative(): SevesoNominativeDto {
        return this.allNominatives.find(
            x => x.Id === this.articleDetails.SevesoNominativeId
        );
    }

    private mapSelectedSign() {
        if (this.articleDetails.FlashPointSign !== null) {
            return <FlashPointSignDto>{
                EnumValue: this.articleDetails.FlashPointSign,
                Name: this.findFlashPointName()
            };
        }

        return null;
    }

    private findFlashPointName(): string {
        const flashPointName = this.flashPointSigns.find(
            x => x.EnumValue === this.articleDetails.FlashPointSign
        );
        if (flashPointName && flashPointName.Name) {
            return flashPointName.Name;
        } else {
            return '';
        }
    }

    private mapSelectedLimitValueUnit() {
        if (this.articleDetails.LimitValueUnit === null) {
            return undefined;
        } else {
            return <LimitValueUnitDto>{
                EnumValue: this.articleDetails.LimitValueUnit,
                Name: this.findLimitValueUnitName()
            };
        }
    }

    private findLimitValueUnitName(): string {
        const limitValueUnit = this.limitValueUnits.find(x => x.EnumValue === this.articleDetails.LimitValueUnit);

        return (limitValueUnit && limitValueUnit.Name) ? limitValueUnit.Name : '';
    }

    private getPhrasesForSelect(): HphraseDto[] {
        const artilceHPhrasesId = this.articleDetails.Hphrases.map(x => x.Id);

        return this.allHphrases.filter(x => artilceHPhrasesId.find(id => id === x.Id) == null);
    }

    private getLimitValueUnit() {
        let limitValueUnit: LimitValueUnitDto;
        limitValueUnit = this.articleForm.get('LimitValueUnit').value;

        return !!limitValueUnit && !!limitValueUnit.EnumValue ? limitValueUnit.EnumValue : null;
    }

    private collectData(): EditRequestedArticleDto {
        const flashPointSign = this.articleForm.get('FlashPointSign').value;

        return <EditRequestedArticleDto>{
            ArticleId: this.article.Id ? this.article.Id : 0,
            Name: this.articleForm.get('Name').value,
            Hphrases: this.articleDetails.Hphrases,
            Pphrases: this.additionalPphrases,
            Cas: this.articleForm.get('Cas').value ? this.articleForm.get('Cas').value : '',
            LimitValue: this.articleForm.get('LimitValue').value,
            LimitValueUnit: this.getLimitValueUnit(),
            FlashPointSign: flashPointSign ? flashPointSign.EnumValue : null,
            FlashPointValue: this.articleForm.get('FlashPointValue').value,
            SevesoNominativeId: this.selectedNominative ? this.selectedNominative.Id : null,
            SdsNumber: this.articleForm.get('SdsNumber').value,
            ReachNumber: this.articleForm.get('ReachNumber').value,
            IsBiocide: this.articleForm.get('Biocide').value,
            Synonyms: this.articleForm.get('Synonyms').value.toString().split(';')
        };
    }
}
