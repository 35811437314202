import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { SpinnerModule } from './spinner/spinner.module';
import { TranslationModule } from './translation/translation.module';
import { FileManagerModule } from './file-manager/file-manager.module';

import { DerogationPipe } from './derogation-pipe/derogation-pipe';

import { PphraseAttachingComponent } from './p-phrase-attaching-component/p-phrase-attaching.component';
import { HphraseAttachingComponent } from './h-phrase-attaching/h-phrase-attaching.component';
import { HphraseTableComponent } from './h-phrase-table/h-phrase-table.component';
import { DisableAutocompleteModule } from './disable-autocomplete/disable-autocomplete.module';

@NgModule({
  declarations: [DerogationPipe, PphraseAttachingComponent, HphraseAttachingComponent, HphraseTableComponent],
  imports: [
    CommonModule,
    TranslationModule,
    DropDownsModule,
    FormsModule,
    ReactiveFormsModule,
    DisableAutocompleteModule
  ],
  exports: [
    SpinnerModule,
    TranslationModule,
    FileManagerModule,
    DerogationPipe,
    PphraseAttachingComponent,
    HphraseAttachingComponent,
    HphraseTableComponent
  ]
})
export class SharedModule { }
