import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../../data.service';
import { UserEntityDto } from '../../shared/model';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private dataService: DataService) { }

  getEntities(): Observable<any> {
    return this.dataService.get('Entity/GetEntities ', {});
  }

  saveUserEntity(userEntityDto: UserEntityDto): Observable<any> {
    return this.dataService.post('User/SaveUserEntity', userEntityDto);
  }

}
