import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserRole } from './shared/model';
import { CanActivateUserRole } from './canActivateUserRole.guard';
import { environment } from '../environments/environment';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { EntitySelectViewComponent } from './entity-select-view/entity-select-view.component';
import { EntityIsAssignedToUserGuard } from './entity-is-assigned-to-user.guard';
import { ArticleDetailsViewComponent } from './catalogue-view/desktop/article-details-view/article-details-view.component';
import { NewRequestComponent } from './requests/new-request/new-request.component';
import { RequestsViewComponent } from './requests/requests-view/requests-view.component';
import { RequestViewComponent } from './requests/request-view/request-view.component';
import { CatalogueViewMobileComponent, CatalogueViewComponent } from './catalogue-view/catalogue-view.module';
import { LayoutMobileComponent, LayoutComponent } from './navigation/navigation.module';
import { RiskAnalysisComponent } from './risk-analysis/risk-analysis.component';
import { SdsFileComponent } from './catalogue-view/article-details-view/sds-file/sds-file.component';
import { ArticleDetailsComponent } from './catalogue-view/article-details-view/article-details/article-details.component';
import { ManageUsersComponent } from './data-management/manage-users/manage-users.component';
import { ManageDangerClassComponent } from './data-management/manage-danger-classes/manage-danger-class.component';
import { EmailTemplatesComponent } from './email-templates/email-templates.component';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { RequestCreatedComponent } from './requests/request-created/request-created.component';
import { ManageSevesoNominativesComponent } from './data-management/manage-seveso-nominatives/manage-seveso-nominatives.component';
import { ManageArticlesComponent } from './data-management/manage-articles/manage-articles.component';
import { ManageEntitiesComponent } from './data-management/manage-entities/manage-entities.component';
import { ManageSuppliersComponent } from './data-management/manage-suppliers/manage-suppliers.component';
import { ManageProducersComponent } from './data-management/manage-producers/manage-producers.component';
import { ManageContainerTypesComponent } from './data-management/manage-container-types/manage-container-types.component';
import { ManageSitesComponent } from './data-management/manage-sites/manage-sites.component';
import { ManageGhsComponent } from './data-management/manage-ghs/manage-ghs.component';
import { ManageLocationsComponent } from './data-management/manage-locations/manage-locations.component';
import { ReportsHubComponent } from './reports/reports-hub/reports-hub.component';
import { ManagePphrasesComponent } from './data-management/manage-pphrases/manage-pphrases.component';
import { ManageArticleEditComponent } from './data-management/manage-articles/manage-article-edit/manage-article-edit.component';
import { ManageTechnicalMeasuresComponent } from './data-management/manage-technical-measures/manage-technical-measures.component';
import { UnauthorizedComponent } from './page-not-found/unauthorized/unauthorized.component';
import { ManageHphrasesComponent } from './data-management/manage-hphrases/manage-hphrases.component';
import { SdsReportComponent } from './reports/reports-hub/sds-report/sds-report.component';
import { EsdsReportComponent } from './reports/reports-hub/esds-report/esds-report.component';
import { RiskAnalysisReportComponent } from './reports/reports-hub/risk-analysis-report/risk-analysis-report.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';

const appRoutes: Routes = [
    { 
        path: 'oauth2redirect', 
        component: OktaCallbackComponent 
    },
    {
        path: 'entity-selection',
        component: EntitySelectViewComponent,
        canActivate: [OktaAuthGuard]
    },
    {
        path: '',
        redirectTo: '/catalogue',
        pathMatch: 'full'
    },
    {
        path: '',
        component: window.innerWidth <= environment.maxMobileDeviceWidth ? LayoutMobileComponent : LayoutComponent,
        canActivate: [EntityIsAssignedToUserGuard, OktaAuthGuard],
        children: [
            {
                path: 'catalogue',
                component: window.innerWidth <= environment.maxMobileDeviceWidth ? CatalogueViewMobileComponent : CatalogueViewComponent
            },
            {
                path: 'email-templates',
                component: EmailTemplatesComponent,
                canActivate: [CanActivateUserRole],
                data: { role: UserRole.Admin }
            },
            {
                path: 'new-request',
                component: NewRequestComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'request-created/:RequestId',
                component: RequestCreatedComponent
            },
            {
                path: 'requests',
                component: RequestsViewComponent,
                canActivate: [CanActivateUserRole],
            },
            {
                path: 'management',
                canActivate: [CanActivateUserRole],
                data: { role: UserRole.Admin },
                children: [
                    {
                        path: 'users',
                        component: ManageUsersComponent
                    },
                    {
                        path: 'seveso-nominatives',
                        component: ManageSevesoNominativesComponent
                    },
                    {
                        path: 'articles',
                        component: ManageArticlesComponent
                    },
                    {
                        path: 'articles/addNew',
                        component: ManageArticleEditComponent,
                        canDeactivate: [CanDeactivateGuard]
                    },
                    {
                        path: 'articles/:articleId',
                        component: ManageArticleEditComponent,
                        canDeactivate: [CanDeactivateGuard]
                    },
                    {
                        path: 'dangerClasses',
                        component: ManageDangerClassComponent
                    },
                    {
                        path: 'suppliers',
                        component: ManageSuppliersComponent
                    },
                    {
                        path: 'entities',
                        component: ManageEntitiesComponent
                    },
                    {
                        path: 'producers',
                        component: ManageProducersComponent
                    },
                    {
                        path: 'sites',
                        component: ManageSitesComponent
                    },
                    {
                        path: 'locations',
                        component: ManageLocationsComponent
                    },
                    {
                        path: 'container-types',
                        component: ManageContainerTypesComponent
                    },
                    {
                        path: 'ghs',
                        component: ManageGhsComponent
                    },
                    {
                        path: 'p-phrases',
                        component: ManagePphrasesComponent
                    },
                    {
                        path: 'h-phrases',
                        component: ManageHphrasesComponent
                    },
                    {
                        path: 'technical-measures',
                        component: ManageTechnicalMeasuresComponent
                    }
                ]
            },
            {
                path: 'reports',
                component: ReportsHubComponent,
                canActivate: [CanActivateUserRole],
                data: { role: UserRole.Specialist }
            },
            {
                path: 'reports/sds-report',
                canActivate: [CanActivateUserRole],
                data: { role: UserRole.Specialist },
                component: SdsReportComponent
            },
            {
                path: 'reports/esds-report',
                canActivate: [CanActivateUserRole],
                data: { role: UserRole.Specialist },
                component: EsdsReportComponent
            },
            {
                path: 'reports/risk-analysis-report',
                canActivate: [CanActivateUserRole],
                data: { role: UserRole.Specialist },
                component: RiskAnalysisReportComponent
            },
            {
                path: 'catalogue/:approvalId',
                canActivate: [EntityIsAssignedToUserGuard],
                component: ArticleDetailsViewComponent,
                children: [
                    {
                        path: '',
                        canActivate: [EntityIsAssignedToUserGuard],
                        component: ArticleDetailsComponent
                    },
                    {
                        path: 'sds',
                        canActivate: [EntityIsAssignedToUserGuard],
                        component: SdsFileComponent
                    }
                ]
            },
            {
                path: 'request-details/:RequestId',
                component: RequestViewComponent,
                canDeactivate: [CanDeactivateGuard],
                canActivate: [CanActivateUserRole],
            },
            {
                path: 'risk-analysis/:requestId',
                component: RiskAnalysisComponent,
                canDeactivate: [CanDeactivateGuard],
                canActivate: [CanActivateUserRole],
                data: { role: UserRole.Specialist }
            },
            {
                path: 'unauthorized',
                component: UnauthorizedComponent
            },
        ]
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        data: {
            requiredrole: UserRole.User,
            template: 'navigation.page-not-found',
            name: 'Page not found.'
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            { enableTracing: false } // <-- debugging purposes only
        )
    ],
    exports: [
        RouterModule
    ],
    providers: [CanActivateUserRole, CanDeactivateGuard, EntityIsAssignedToUserGuard]
})
export class AppRoutingModule { }
