import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'confirmation-deactivate',
    templateUrl: './confirmationdeactivate.component.html',
    styleUrls: ['../styles/confirm-dialog.scss', '../styles/translation.scss']
})
export class ConfirmationDeactivateComponent implements OnInit {

    public optionClicked = new Subject<boolean>();
    hostElement: any;

    constructor() { }

    ngOnInit() { }

    confirmClick(data) {
        if (data) {
            this.optionClicked.next(true);
            this.optionClicked.complete();
        }
        this.optionClicked.next(false);
        this.optionClicked.complete();
    }
}
