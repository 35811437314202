import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

import { SharedService } from './shared/shared.service';

import { UserRole } from './shared/model';
import { map } from 'rxjs/operators';

@Injectable()
export class CanActivateUserRole implements CanActivate {
  constructor(private sharedService: SharedService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.sharedService.getPermissionsLevel().pipe(
      map((userRole: UserRole) => {
        const requiredrole = route.data['role'] as UserRole || UserRole.User;
        if (userRole >= requiredrole) {
          return true;
        } else {
          this.router.navigateByUrl('unauthorized');
          return false;
        }
      }));
  }
}
