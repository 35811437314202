import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { SiteDto, StateOfMatter } from './../../../../shared/model';
import { LocationDto } from 'app/shared/model';
import { ApprovalDetailsDto } from './../../../shared/catalogue-view.model';
import { SupplierDto, ApprovalEditDetailsDto } from 'app/catalogue-view/shared/catalogue-view.model';

import { RequestService } from './../../../../requests/request.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { ArticleCommunicationService } from 'app/catalogue-view/shared/article-communication.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { CatalogueViewService } from 'app/catalogue-view/shared/catalogue-view.service';
import { SharedService } from 'app/shared/shared.service';
import { Configuration } from 'app/shared-files/configuration';

@Component({
  selector: 'app-approval-edit-modal',
  templateUrl: './approval-edit-modal.component.html',
  styleUrls: ['./approval-edit-modal.component.scss']
})
export class ApprovalEditModalComponent implements OnInit {
  @Input() isApprovalCopied: boolean;

  approvalForm: FormGroup;
  approvalDetails: ApprovalEditDetailsDto;
  approvalData: ApprovalEditDetailsDto;
  approvalId: string;
  derogationFile: File;
  initialized = false;
  filteredLocations: Array<LocationDto>;
  locations: Array<LocationDto>;
  suppliers: Array<SupplierDto>;
  sites: Array<SiteDto>;
  approvalSite: string;
  newApproval: ApprovalEditDetailsDto;
  newApprovalDetails: ApprovalDetailsDto;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private catalogueService: CatalogueViewService,
    private communicationService: ArticleCommunicationService,
    private spinner: SpinnerService,
    private notificationService: TranslatedNotificationService,
    private requestService: RequestService,
    private sharedService: SharedService,
    private configuration: Configuration
  ) {
    this.approvalId = this.route.snapshot.paramMap.get('approvalId');

    if (!this.approvalId || this.approvalId === '0') {
      this.router.navigate(['']);
    }
  }

  ngOnInit(): void {
    this.spinner.show('spinner');
    const approvalId = this.route.snapshot.paramMap.get('approvalId');

    this.fetchData(approvalId);
  }

  fetchData(approvalId: string) {
    this.catalogueService
      .getEditApprovalDetails(approvalId)
      .pipe(
        switchMap(data => {
          this.approvalDetails = data;
          return forkJoin([
            this.requestService.getLocations(this.sharedService.entityId),
            this.catalogueService.getSuppliers(),
            this.requestService.getSites(this.sharedService.entityId)
          ]);
        }),
        finalize(() => this.spinner.hide('spinner'))
      )
      .subscribe(
        ([locations, suppliers, sites]) => {
          this.locations = locations;
          this.suppliers = suppliers;
          this.sites = sites;
        },
        () => {
          this.communicationService.toggleEditAprovalModal(false);
          this.notificationService.showDefaultMsgError();
        },
        () => {
          this.createForm();
          this.filterLocations(
            this.sites.find(x => x.Id === this.approvalDetails.Location.SiteId)
          );
          this.fillApprovalData();
          this.initialized = true;
        }
      );
  }

  saveForm() {
    if (!this.isApprovalCopied || this.isLocationChanged()) {
      if (this.approvalForm.valid) {
        this.spinner.show('spinner');
        this.approvalData = this.prepareApprovalEditDto();
        const formData = this.prepareFormData();
        const requestedMethod = this.getRequestedMethod(formData);

        requestedMethod
          .pipe(
            finalize(() => this.spinner.hide('spinner')))
          .switchMap((approval) => {
            if (approval) {
              this.newApproval = approval;
              return this.catalogueService.getApprovalById(this.newApproval.Id);
            } else {
              return of(null);
            }
          })
          .subscribe(approval => {
            if (approval) {
              this.newApprovalDetails = approval;
              this.router.navigate(['/risk-analysis', this.newApprovalDetails.Request.Id],
                { queryParams: { copiedApprovalId: this.approvalId } });
            }
            this.communicationService.approvalEdited(this.approvalData);
          },
            () =>
              this.notificationService.showMsgError(
                'error.invalid_form',
                'Please fill all the required fields.'
              )
          );
        this.closeModal();
      } else {
        this.notificationService.showMsgError(
          'error.invalid_form',
          'Please fill all the required fields.'
        );
      }
    } else {
      this.notificationService.showMsgError(
        'error.same_location',
        'In order to copy an approval please change site or location.'
      );
    }
  }

  closeModal() {
    this.communicationService.toggleEditAprovalModal(false);
    this.communicationService.toggleCopyApprovalModal(false);
  }

  derogationFileSelected(file: File) {
    this.derogationFile = file;
    this.approvalForm.get('DerogationFile').setValue(file ? file.name : null);
  }

  shouldDisableSites(): boolean {
    return this.isApprovalCopied;
  }

  filterLocations(selectedSite: SiteDto) {
    if (this.approvalForm.get('Location').value && selectedSite && this.approvalForm.get('Site').value !== selectedSite.Id) {
      this.approvalForm.get('Location').patchValue(null);
      this.approvalForm.get('Location').markAsTouched();
    }
    if (selectedSite?.Id) {
      this.filteredLocations = this.locations.filter(l => l.SiteId === selectedSite.Id);
    } else {
      this.filteredLocations = [...this.locations];
    }
  }

  private fillApprovalData() {
    this.approvalForm.get('Site').patchValue(this.sites.find(l => l.Id === this.approvalDetails.Location.SiteId));
    this.approvalForm.get('Location').patchValue(this.locations.find(l => l.Id === this.approvalDetails.Location.Id));
    this.approvalForm.get('Quantity').patchValue(this.approvalDetails.Quantity);
    this.approvalForm.get('Supplier').patchValue(this.suppliers.find(s => s.Id === this.approvalDetails.Supplier.Id));
    this.approvalForm.get('PurposeOfUse').patchValue(this.approvalDetails.PurposeOfUse);
    this.approvalForm.get('SapNumber').patchValue(this.approvalDetails.SapNumber ? this.approvalDetails.SapNumber : '');
    this.approvalForm.get('SapLink').patchValue(this.approvalDetails.SapLink ? this.approvalDetails.SapLink : '');
    this.approvalForm.get('Function').patchValue(this.approvalDetails.Function
      ? this.approvalDetails.Function === 1 ? 'storage' : 'process' : null);
    if (this.approvalDetails.StateOfMatter.EnumValue === StateOfMatter.Gas) {
      if (this.approvalDetails.IsReplaceable !== null) {
        this.approvalForm.get('IsReplaceable').patchValue(this.approvalDetails.IsReplaceable.toString());
      }
    } else {
      this.approvalForm.get('IsReplaceable').disable();
    }
    const curretntSite = this.sites.find(x => x.Id === this.approvalDetails.Location.SiteId);
    if (curretntSite) {
      this.approvalSite = this.sites.find(x => x.Id === this.approvalDetails.Location.SiteId).Name;
    }
  }

  private createForm() {
    this.approvalForm = this.formBuilder.group({
      Site: [null, Validators.required],
      Location: [null, Validators.required],
      Quantity: ['', Validators.required],
      Supplier: [null, Validators.required],
      IsReplaceable: ['', Validators.required],
      PurposeOfUse: ['', Validators.required],
      Function: ['', Validators.required],
      DerogationFile: [this.approvalDetails.DerogationFileDisplayName],
      SapNumber: [''],
      SapLink: ['']
    });
  }

  private prepareApprovalEditDto() {
    const approvalDto = {} as ApprovalEditDetailsDto;

    approvalDto.Id = this.approvalId;
    approvalDto.Location = this.approvalForm.get('Location').value;
    approvalDto.Quantity = this.approvalForm.get('Quantity').value;
    approvalDto.Supplier = this.approvalForm.get('Supplier').value;
    approvalDto.PurposeOfUse = this.approvalForm.get('PurposeOfUse').value;
    approvalDto.SapNumber = this.approvalForm.get('SapNumber').value;
    approvalDto.SapLink = this.approvalForm.get('SapLink').value;
    approvalDto.ApproverEmail = this.sharedService.loggedInUser.Email;

    if (this.approvalForm.get('IsReplaceable').enabled) {
      approvalDto.IsReplaceable = this.approvalForm.get('IsReplaceable').value;
    }

    if (this.approvalForm.get('Function').value) {
      approvalDto.Function = this.approvalForm.get('Function').value === 'storage' ? 1 : 2;
    }

    return approvalDto;
  }

  private prepareFormData() {
    const formData = new FormData();

    if (this.derogationFile) {
      formData.append('derogationFile', this.derogationFile, this.derogationFile.name);
      this.approvalData.DerogationFileDisplayName = this.derogationFile.name;
    }

    formData.append('approvalEditDetailsDto', JSON.stringify(this.approvalData));

    return formData;
  }

  private getRequestedMethod(formData) {
    return this.isApprovalCopied ? this.catalogueService.addApproval(formData) : this.catalogueService.updateApproval(formData);
  }

  private isLocationChanged(): boolean {
    return this.approvalForm.get('Location').value !== this.locations.find(l => l.Id === this.approvalDetails.Location.Id);
  }
}
