import { Injectable } from '@angular/core';

import { State } from '@progress/kendo-data-query';

@Injectable()
export class GridStateHelper {

    public getBasicState(): State {
      return <State> {
        skip: 0,
        take: 10,
        filter: {
          logic: 'and',
          filters: []
        }
      };
    }
}
