import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';

import { HazardStatementsDetails, ApprovalDto, HPhrasesDetailsDto, WarningDto } from '../shared/catalogue-view.model';
import { Unit, GhsEnum } from 'app/shared/model';

import { CatalogueFiltersFormHelper } from '../shared/catalogueFiltersFormHelper';
import { CatalogueViewService } from '../shared/catalogue-view.service';
import { CatalogueDataManager } from '../shared/catalogueDataManager';
import { SpinnerService } from '../../shared/spinner/spinner.service';

@Component({
  selector: 'app-catalogue-view-mobile',
  templateUrl: './catalogue-view.component.mobile.html',
  styleUrls: ['./catalogue-view.component.mobile.scss', '../../shared/styles/top-view.scss'],
  animations: [
    trigger('expandDetailsTrigger', [
      transition(':enter', [
        style({ height: '0' }),
        animate('0.1s ease-out', style({ height: '*' })),
      ]),
      transition(':leave', [
        animate('0.1s ease-out', style({ height: '0' }))
      ])
    ])
  ]
})
export class CatalogueViewMobileComponent {
  filtersForm: FormGroup;
  gridData: Observable<GridDataResult>;

  showFiltersDialog: boolean;
  expandedRows: number[] = [];
  Unit = Unit;
  GhsEnum = GhsEnum;
  warningDialogOpen = false;
  hazardsDialogOpen = false;
  selectedItem: HazardStatementsDetails;

  constructor(
    public dataManager: CatalogueDataManager,
    private spinnerService: SpinnerService,
    private formHelper: CatalogueFiltersFormHelper,
    private catalogueService: CatalogueViewService) {
    this.dataManager.dataIsLoading
      .subscribe((isLoading: boolean) => {
        setTimeout(() => {
          isLoading ? this.spinnerService.show('catalogue-mobile-spinner') : this.spinnerService.hide('catalogue-mobile-spinner');
        }, 0);
      });
    this.dataManager.reloadData();
    this.filtersForm = this.formHelper.filtersForm;
    this.gridData = this.dataManager.gridData;
    this.showFiltersDialog = false;
  }

  isOpened(id: number): boolean {
    return this.expandedRows.indexOf(id) !== -1;
  }

  toggleDetails(rowIndex: number) {
    if (this.expandedRows.indexOf(rowIndex) !== -1) {
      this.expandedRows = this.expandedRows.filter(x => x !== rowIndex);
    } else {
      this.expandedRows = [...this.expandedRows, rowIndex];
    }
  }

  expandDetails(rowIndex: number) {
    this.expandedRows.push(rowIndex);
  }

  collapseDetails(rowIndex: number) {
    const index = this.expandedRows.findIndex(rowId => rowId === rowIndex);

    this.expandedRows.splice(index, 1);
  }

  onSearchSubmit(event) {
    event.preventDefault();
    const searchString: string = event.target['SearchString'].value;
    this.dataManager.search(searchString);
  }

  applyFilters() {
    this.dataManager.updateState({ filter: this.formHelper.filterDescriptor });
    this.filtersDialogClose();
  }

  filtersDialogClose() {
    this.showFiltersDialog = false;
  }

  showFilters() {
    this.showFiltersDialog = true;
  }

  resetFilters() {
    this.formHelper.resetFilters();
    this.applyFilters();
  }

  pageChange(newPage: number) {
    const skip = this.dataManager.state.take * (newPage - 1);
    this.dataManager.updateState({ skip: skip });
  }

  getArticleSubdivision(subdivision: string): string {
    return subdivision && subdivision !== ' ' ? subdivision : '-';
  }

  getArticleSynonyms(synonyms: Array<string>): string {
    return synonyms && synonyms.length > 0 ? synonyms.join(', ') : '-';
  }

  openWarningDialog(item: ApprovalDto) {
    this.setSelectedItem(item);
    this.warningDialogOpen = true;
  }

  openHazardsDialog(item: ApprovalDto) {
    this.setSelectedItem(item);
    this.hazardsDialogOpen = true;
  }

  hazardsDialogClosed() {
    this.hazardsDialogOpen = false;
  }

  warningDialogClosed() {
    this.warningDialogOpen = false;
  }

  showWarningIcon(item: ApprovalDto): boolean {
    return !!item.Hphrases.find(x => x.HasDerogation === true) || this.warningHasDescription(item.Warning);
  }

  showWarningAndHphrases(item: ApprovalDto): boolean {
    return this.showWarningIcon(item) || !!item.GhsCodes.length;
  }

  downloadSdsFile(filepath: string, fileDisplayName?: string) {
    this.dataManager.fetchFile(filepath, fileDisplayName);
  }

  private setSelectedItem(item: ApprovalDto) {
    const selectedApproval = item;

    this.selectedItem = <HazardStatementsDetails>{
      ApprovalId: selectedApproval.Id,
      ArticleName: selectedApproval.Article.Name,
      Warning: selectedApproval.Warning,
      HPhrases: selectedApproval.Hphrases.map(phrase => <HPhrasesDetailsDto>{
        GhsList: this.catalogueService.getUniqueArray(phrase.DangerClass?.GhsList
          ? phrase.DangerClass.GhsList.map(g => g.Code) : []),
        Code: phrase.Code,
        Description: phrase.Description,
        HasDerogation: phrase.HasDerogation,
        SignalWord: phrase.DangerClass?.SignalWord ? phrase.DangerClass.SignalWord.Name : '-',
        Category: phrase.DangerClass?.Category ? phrase.DangerClass.Category.Name : '-',
        Type: phrase.DangerClass?.Type ? phrase.DangerClass.Type.Name : '-',
        Class: phrase.DangerClass?.Class ? phrase.DangerClass.Class.Name : '-'
      })
    };
  }

  private warningHasDescription(item: WarningDto): boolean {
    return item && !!item.Description;
  }
}
