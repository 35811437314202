import { Injectable } from '@angular/core';
import { TranslationSet, LanguageCode, Language, TemplateDto , TranslationCollection } from '../model';
import { SharedService } from '../../shared/shared.service';
import { DataService } from '../../data.service';

import { Observable, of} from 'rxjs';
import { map, share } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class TranslationService {

    public languages: Language[];

    private observableCache: { [key: string]: Observable<TranslationSet> } = {};
    private translationCache: TranslationCollection[] = [];

    constructor(private sharedService: SharedService, 
        private dataService: DataService,
        private cookieService: CookieService) {

        this.sharedService.translations.forEach((value) => {
            const translationCollection = <TranslationCollection>{};
            translationCollection.LanguageCode = value.LanguageCode;
            translationCollection.Collection = <TranslationSet> {
                LanguageCode: value.LanguageCode,
                Values: value.Values
            };

            this.translationCache = this.translationCache.concat(new Array(translationCollection));
        });
    }

    private getLanguageCode() : LanguageCode {
        let langCode = 'en';
    
        let cookieLangCode = this.cookieService.get('language');
        if (cookieLangCode && cookieLangCode !== langCode) {
          langCode = cookieLangCode;
        }

        return LanguageCode[langCode];
    }

    getTranslation(language: LanguageCode): Observable<TranslationSet> {
        // Fetch data
        const collection = this.translationCache.find(col => col.LanguageCode === language);
        // Data available
        if (collection && collection.Collection) {
            return of(collection.Collection);
        } else if (this.observableCache[language]) {
            // Request pending
            return this.observableCache[language];
        } else {
            // New request needed
            this.observableCache[language] = this.fetchTranslations(language);
        }

        return this.observableCache[this.sharedService.currentLanguage.LanguageCode];
    }

    translate(key: string, value: string): Promise<string> {
        return this.getTranslation(this.sharedService.currentLanguage.LanguageCode)
            .pipe(
                map(result => {
            const englishCollection = this.translationCache
                .find(col => col.LanguageCode.toString() === LanguageCode[LanguageCode.en]);

            if (!!englishCollection && !(key in englishCollection.Collection.Values)) {
                this.addTemplate(key, value);
            }

            if (!!englishCollection && this.translationCache) {
                if (this.sharedService.currentLanguage.LanguageCode && LanguageCode[this.sharedService.currentLanguage.LanguageCode.toString()] === LanguageCode.en) {
                    return englishCollection.Collection.Values[key];
                } else {
                    let translationCollection = this.translationCache.find(x => this.findTranslationCollection(x));
                    if(translationCollection) {
                        return translationCollection.Collection.Values[key];
                    } 
                }
            } 
            return null;
        })).toPromise();
    }

    private fetchTranslationSets(language: LanguageCode): Observable<TranslationSet[]> {
        return this.dataService.get('Translations/GetTranslations', { languageCode: language });
    }

    private fetchLanguages() : Observable<Language[]> {
        return this.dataService.get('Languages/GetLanguages', { });
    }

    private fetchTranslations(language: LanguageCode): Observable<TranslationSet> {
        const request = this.dataService.get('Translations/GetTranslations', { languageCode: language });
        return request.pipe(map(rawData => this.mapCachedTranslations(rawData)), share());
    }

    private addTemplate(templateValue: string, englishValue: string) {

        const templateDto = <TemplateDto>{
            TemplateString: templateValue,
            Translations : {
                'EN' : englishValue,
                'DE' : '',
                'FR' : '',
                'ES' : '',
                'IT' : '',
                'NL' : ''
            }
        };

        this.dataService.post('Translations/AddTemplate', templateDto).subscribe();
    }

    private mapCachedTranslations(body: Array<TranslationSet> ) {
        this.observableCache[this.sharedService.currentLanguage.LanguageCode] = null;
        let tempTranslationCache = this.translationCache;

        body.forEach(function(value) {
            const translationCollection = <TranslationCollection>{};
            translationCollection.LanguageCode = value.LanguageCode;
            translationCollection.Collection = <TranslationSet> {
                LanguageCode: value.LanguageCode,
                Values: value.Values
            };

            tempTranslationCache = tempTranslationCache.concat(new Array(translationCollection));
        });
        this.translationCache = tempTranslationCache;
        const foundTranslation = this.translationCache.find(x => this.findTranslationCollection(x));
        return !!foundTranslation ? foundTranslation.Collection : <TranslationSet>{};
    }

    private findTranslationCollection(element: TranslationCollection): boolean {
       return element.LanguageCode === this.sharedService.currentLanguage.LanguageCode;
    }
}
