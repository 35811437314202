import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from './translation.service';

@Pipe({name: 'translate', pure: false})
export class TranslatePipe implements PipeTransform {
    private translation = '';
    private isPending = false;
    private isTranslated = false;

    constructor(private cdRef: ChangeDetectorRef, private translationService: TranslationService) {}

    transform(template: string, fallback: string): any {
        if (!this.isPending && !this.isTranslated) {
            this.isPending = true;
            this.translationService.translate(template, fallback)
                .then((translated: string) => {
                    this.translation = translated || fallback;
                    this.isPending = false;
                    this.isTranslated = true;
                    this.cdRef.markForCheck();
                });
        }
        return this.translation;
    }
}
