import { PphraseDto } from 'app/catalogue-view/shared/catalogue-view.model';

export interface PphraseGridDto extends PphraseDto {
    Kind: PphraseKind;
    Type: PphraseType;
    Pictogram:  Pictogram;
    RiskAnalysis: number;
}

export enum Pictogram {
    Pic0 = 0,
    Pic1 = 1,
    Pic2 = 2,
    Pic3 = 3,
    Pic4 = 4,
    Pic5 = 5,
    Pic6 = 6,
    Pic7 = 7,
    Pic8 = 8,
    Pic9 = 9,
    Pic10 = 10,
    Pic11 = 11,
    Pic12 = 12
}

export interface PictogramDto {
    EnumValue: Pictogram;
    Value: string;
}

export interface PphraseType {
    Id: number;
    PphraseTypeEnum: PphraseTypeEnum;
    Text: string;
    PphraseTypeTranslation: string;
}

export interface PphraseKind {
    Id: number;
    PphraseKindEnum: PphraseKindEnum;
    Text: string;
    PphraseKindTranslation: string;
}

export enum PphraseTypeEnum {
    ControlMeasure = 1,
    Pphrase = 2
}

export enum PphraseKindEnum {
    Waste = 1,
    Other = 2,
    Emergency = 3,
    Storage = 4,
    PPE = 5,
    Manipulation = 6
}
