import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogModule } from '@progress/kendo-angular-dialog';

import { DisclaimerModule } from '../disclaimer/disclaimer.module';
import { TranslationModule } from '../shared/translation/translation.module';
import { StickyHeaderModule } from '../shared/sticky-header/sticky-header.module';

import { TopbarComponent } from './desktop/topbar/topbar.component';
import { EntitySelectDropdownComponent } from './desktop/entity-select-dropdown/entity-select-dropdown.component';
import { LayoutComponent } from './desktop/layout/layout.component';
import { LayoutMobileComponent } from './mobile/layout-mobile/layout-mobile.component';
import { DrawerComponent } from './mobile/drawer/drawer.component';
import { LanguageSelectDropdownComponent } from './desktop/language-select-dropdown/language-select-dropdown.component';
import { NavbarComponent } from './desktop/navbar/navbar.component';
import { NavMenuComponent } from './desktop/navbar/nav-menu/nav-menu.component';
import { NavMenuItemComponent } from './desktop/navbar/nav-menu-item/nav-menu-item.component';
import { SubMenuItemComponent } from './desktop/navbar/sub-menu-item/sub-menu-item.component';
import { SubMenuComponent } from './desktop/navbar/sub-menu/sub-menu.component';
import { HelpComponent } from './help/help.component';
import { HelpService } from './help/help.service';

@NgModule({
  declarations: [
    NavMenuComponent,
    TopbarComponent,
    LayoutComponent,
    LayoutMobileComponent,
    EntitySelectDropdownComponent,
    DrawerComponent,
    LanguageSelectDropdownComponent,
    NavbarComponent,
    NavMenuItemComponent,
    SubMenuItemComponent,
    SubMenuComponent,
    HelpComponent
  ],
  imports: [
    CommonModule,
    DisclaimerModule,
    RouterModule,
    DropDownsModule,
    TranslationModule,
    FormsModule,
    StickyHeaderModule,
    DialogModule
  ],
  exports: [
    LayoutComponent,
    LayoutMobileComponent,
    EntitySelectDropdownComponent
  ],
  providers: [HelpService]
})
class NavigationModule { }

export {
  NavigationModule,
  LayoutComponent,
  LayoutMobileComponent
};
