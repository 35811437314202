import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { FileManagerService } from '../../shared/file-manager/file-manager.service';

import { RequestDetailsDto } from '../request-view/request-view.model';
import { FileToDownload, FileTypeEnum, DownloadedFile } from '../../shared/file-manager/file-manager.model';
import { ApprovalStatusEnum } from '../requests-view/requests-view.model';
import { Unit, StateOfMatterDto } from '../../shared/model';

@Component({
  selector: 'app-request-details-read-only',
  templateUrl: './request-details-read-only.component.html',
  styleUrls: ['./request-details-read-only.component.scss']
})
export class RequestDetailsReadOnlyComponent implements OnDestroy {
  @Input() requestData: RequestDetailsDto;
  @Input() hideCorrectDataButton: boolean;
  @Output() swichToEdit = new EventEmitter();

  Unit = Unit;

  private sdsFile: Blob | File;
  private downloadSds: Subscription;

  get canDispayCorrectDataButton(): boolean {
    return this.requestData
      && !this.hideCorrectDataButton
      && this.requestData.FinalApprovalEnum === ApprovalStatusEnum.NotApprovedYet
      && this.requestData.MedicApprovalEnum !== ApprovalStatusEnum.NotApprovedYet;
  }

  constructor(
    private notificationService: TranslatedNotificationService,
    private fileManagerService: FileManagerService,
  ) {}

  ngOnDestroy() {
    this.unsubscribeSdsDownload();
  }

  get containerData(): string {
    return this.requestData.ContainerTypeName + ' ' + this.requestData.ArticleData.Container.Capacity + ' ' + this.Unit[this.requestData.ArticleData.Container.Unit];
  }

  downloadFile() {
    const sdsFilePath = this.requestData.ArticleData.SdsPath;
    const sdsFile: FileToDownload = {
      FileName: sdsFilePath,
      FileType: FileTypeEnum.SdsFilePath
    };

    if (sdsFilePath) {
      this.unsubscribeSdsDownload();

      this.downloadSds = this.fileManagerService.fetchFile(sdsFile)
        .subscribe((data: DownloadedFile) => {
          if (!this.sdsFile) {
            this.sdsFile = data?.File;
          }

          this.fileManagerService.downloadFile(this.sdsFile, this.requestData?.ArticleData?.SdsDisplayName);
        },
          () => {
            this.notificationService.showMsgError(
              'error.cannot_load_sds_file',
              'Cannot find the SDS file for the article. Please upload it again.'
            );
          });
    }
  }

  getObjectForDescription(element: Object) {
    if (element) {
      return element;
    }
    return '-';
  }

  getStateOfMatterName(data: StateOfMatterDto) {
    return data ? data.Name : '-';
  }

  toggleEditingMode() {
    this.swichToEdit.emit();
  }

  private unsubscribeSdsDownload() {
    if (this.downloadSds) {
      this.downloadSds.unsubscribe();
    }
  }
}
