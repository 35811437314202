import { Injectable } from '@angular/core';
import { DataService } from '../../data.service';
import { Observable } from 'rxjs';
import { QueryDto } from './favourite-queries.model';
import { SharedService } from '../../shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class FavouriteQueriesService {

  constructor(
    private dataService: DataService,
    private sharedService: SharedService
  ) { }

  getQueries(): Observable<QueryDto[]> {
    const userId = this.getUserId();

    return this.dataService.get('FavouriteQueries/GetAllQueries', { userId });
  }

  addQuery(dto: QueryDto): Observable<QueryDto[]> {
    dto.UserId = this.getUserId();

    return this.dataService.post('FavouriteQueries/AddQuery', dto);
  }

  updateQuery(dto: QueryDto): Observable<QueryDto[]> {
    return this.dataService.patch('FavouriteQueries/UpdateQuery', dto);
  }

  deleteQuery(queryId: number, userId: string): Observable<QueryDto[]> {
    return this.dataService.delete('FavouriteQueries/DeleteQuery', { queryId, userId });
  }

  private getUserId(): string {
    const user = this.sharedService.loggedInUser;
    const userId = user ? user.UserId : '';

    return userId;
  }
}

