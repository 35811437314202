import { Component, OnInit } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { GridStateHelper } from '../shared/grid-state';
import { finalize } from 'rxjs/operators';
import { ProducerDto, ContactType } from './../../shared/model';

import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { DataManagementService } from 'app/data-management/data-management.service';

@Component({
  selector: 'app-manage-producers',
  templateUrl: './manage-producers.component.html',
  styleUrls: ['./manage-producers.component.scss']
})
export class ManageProducersComponent implements OnInit {
  gridData: GridDataResult;
  producersForGrid: ProducerDto[];
  state: State;
  selectedProducer: ProducerDto;
  editProducerOpen = false;

  constructor(
    private dataManagementService: DataManagementService,
    private gridState: GridStateHelper,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService
  ) {
    this.state = this.gridState.getBasicState();
  }

  ngOnInit() {
    this.fetchData();
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.producersForGrid, this.state);
  }

  openEditProducerModal() {
    this.editProducerOpen = true;
  }

  editProducerModalClose() {
    this.editProducerOpen = false;
  }

  saveProducerChanges(editedProducer: ProducerDto) {
    if (editedProducer) {
      const producer = this.producersForGrid.find(
        p => p.Id === editedProducer.Id
      );
      if (producer) {
        producer.Name = editedProducer.Name;
        producer.Address = editedProducer.Address;
        producer.Website = editedProducer.Website;
        producer.Contacts = editedProducer.Contacts;
        this.gridData = process(this.producersForGrid, this.state);
      } else {
        this.selectedProducer = null;
        this.producersForGrid.push(editedProducer);
        this.gridData = process(this.producersForGrid, this.state);
      }
    } else {
      this.selectedProducer = { ...this.selectedProducer };
    }
  }

  goToProducerModal(ProducerId: number) {
    this.selectedProducer = this.producersForGrid.find(
      x => x.Id === ProducerId
    );
    this.openEditProducerModal();
  }

  createNewProducer() {
    this.selectedProducer = <ProducerDto>{
      Id: 0,
      Name: ''
    };
    this.openEditProducerModal();
  }

  private fetchData() {
    this.spinner.show('spinner');
    this.dataManagementService.getCompleteProducers()
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe(
        producers => {
          this.producersForGrid = producers;
          this.gridData = process(this.producersForGrid, this.state);
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }

  public getFirstContact(producer: ProducerDto, contactType: string) {
    if (producer) {
      const contact = producer.Contacts.find(
        c => c.ContactType === ContactType[contactType]
      );

      return contact ? contact.Value : '';
    }

    return '';
  }
}
