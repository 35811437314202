import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { Report, ReportDto, ArticleSevesoHazardCategoryDto } from '../reports.model';
import { SiteDto } from 'app/shared/model';

import { DataManagementService } from 'app/data-management/data-management.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { FileManagerService } from '../../shared/file-manager/file-manager.service';
import { ReportsService } from './../reports.service';

@Component({
  selector: 'app-reports-hub',
  templateUrl: './reports-hub.component.html',
  styleUrls: ['./reports-hub.component.scss']
})
export class ReportsHubComponent implements OnInit {
  searchInput: string;
  Report = Report;
  selectedReport: Report;
  sites: SiteDto[];
  form: FormGroup;
  showModal: boolean;
  articlesSevesoHazardCategories: ArticleSevesoHazardCategoryDto[];

  constructor(
    private dataManagementService: DataManagementService,
    private formBuilder: FormBuilder,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService,
    private reportsService: ReportsService,
    private fileManager: FileManagerService,
    private router: Router) { }

  ngOnInit() {
    this.fetchData();
    this.form = this.formBuilder.group({
      Site: [null, Validators.required]
    });
  }

  closeDialog() {
    this.showModal = false;
  }

  openDialog() {
    this.showModal = true;
  }

  openArticlesSevesoHazardCategoriesModal() {
    if (this.isFormValid()) {
      this.reportsService.getArticlesSevesoHazardCategories()
        .pipe(
          finalize(() => this.spinner.hide('spinner'))
        ).subscribe(
          articlesSevesoHazardCategories => {
            this.articlesSevesoHazardCategories = articlesSevesoHazardCategories;
            this.openDialog();
          },
          () => this.notificationService.showDefaultMsgError()
        );
    }
  }

  downloadReport(report: Report) {
    if (this.isFormValid()) {
      this.closeDialog();
      const site = this.form.get('Site').value as SiteDto;
      const reportDto = <ReportDto>{
        Report: report,
        SiteId: site.Id
      };

      if (reportDto.Report === Report.Vlarem) {
        this.spinner.show('reports');
        this.reportsService.downloadVlaremReport(reportDto).pipe(
          finalize(() => this.spinner.hide('reports'))
        )
        .subscribe(
          (data: Blob) => {
            data['name'] = `VLAREM_${site.Name}_${Date.now()}.xlsx`;
            this.fileManager.downloadFile(data);
          },
          () => {
            this.notificationService.showMsgError(
              'error.cannot_download_vlarem_report',
              'There was an error on downloading VLAREM report.'
            );
          }
        );
      } else if (reportDto.Report === Report.Seveso) {
        this.spinner.show('reports');
        this.reportsService.downloadSevesoReport(reportDto).pipe(
          finalize(() => this.spinner.hide('reports'))
        )
        .subscribe(
          (data: Blob) => {
            data['name'] = `SEVESO_${site.Name}_${Date.now()}.xlsx`;
            this.fileManager.downloadFile(data);
          },
          () => {
            this.notificationService.showMsgError(
              'error.cannot_download_seveso_report',
              'There was an error on downloading SEVESO report.'
            );
          }
        );
      }
    }
  }

  goToSdsReport() {
    this.router.navigate(['/reports/sds-report']);
  }

  goToEsdsReport() {
    this.router.navigate(['/reports/esds-report']);
  }

  goToRiskAnalysisReport() {
    this.router.navigate(['/reports/risk-analysis-report']);
  }

  private fetchData() {
    this.spinner.show('spinner');
    this.dataManagementService.getSites()
      .pipe(
        finalize(() => this.spinner.hide('spinner'))
      ).subscribe(
        sites => {
          this.sites = sites;
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }

  private isFormValid(): boolean {
    if (this.form.invalid) {
      this.form.controls.Site.markAsTouched();
      this.notificationService.showMsgError('error.data_required', 'Required data must be filled in');

      return false;
    }

    return true;
  }
}
