import { Component, ViewChild, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import { State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';

import { ApprovalDto, HazardStatementsDetails, HPhrasesDetailsDto, WarningDto } from '../shared/catalogue-view.model';
import { Unit, GhsEnum } from 'app/shared/model';

import { CatalogueDataManager } from '../shared/catalogueDataManager';
import { CatalogueViewService } from '../shared/catalogue-view.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { QueryDto } from '../favourite-queries/favourite-queries.model';

@Component({
  selector: 'app-catalogue-view',
  templateUrl: './catalogue-view.component.html',
  styleUrls: ['./catalogue-view.component.scss', '../../shared/styles/top-view.scss']
})
export class CatalogueViewComponent implements OnInit, OnDestroy {
  @ViewChild('searchString', { static: true }) searchString: ElementRef;
  @ViewChild('searchStringInFile', { static: true }) searchStringInFile: ElementRef;
  @ViewChild('catalogueGrid', { static: true }) private catalogueGrid;

  dataLoading: Subscription;
  dataLoaded: boolean;
  expandedRows: number[] = [];
  Unit = Unit;
  GhsEnum = GhsEnum;

  warningDialogOpen = false;
  hazardsDialogOpen = false;
  selectedItem: HazardStatementsDetails;

  gridData: Observable<GridDataResult>;

  state: State;
  readonly initialState: State;

  constructor(
    private catalogueService: CatalogueViewService,
    private dataManager: CatalogueDataManager,
    private router: Router,
    private spinner: SpinnerService) {
    this.gridData = this.dataManager.gridData;
    this.initialState = { ...this.dataManager.state };
  }

  ngOnInit(): void {
    this.reloadData();

    this.dataLoading = this.dataManager.dataIsLoading
    .subscribe((isLoading: boolean) => {
      setTimeout(() => {
        isLoading ? this.spinner.show('spinner') : this.spinner.hide('spinner');
      }, 0);
    });

  this.dataLoading.add(this.dataManager.dataInitialized.subscribe((initialized: boolean) => {
    if (initialized) {
      this.dataLoaded = initialized;
    }
  }));
  }

  ngOnDestroy(): void {
    this.dataLoading.unsubscribe();
    this.dataLoaded = false;
  }

  public showWarningIcon(item: ApprovalDto): boolean {
    return !!item.Hphrases.find(x => x.HasDerogation === true) || this.warningHasDescription(item.Warning) || item.Warning.ContainsIsocyanates;
  }

  public isOpened(id: number): boolean {
    return this.expandedRows.findIndex(rowId => rowId === id) > -1;
  }

  public toggleExpandedClass = (context: RowClassArgs) => {
    const isExpanded = this.expandedRows.indexOf(context.index) !== -1;

    return {
      expanded: isExpanded
    };
  }

  public dataStateChange(currentState: DataStateChangeEvent | State): void {
    this.dataManager.updateState(currentState);
    this.state = this.dataManager.state;
    this.collapseAllRows();
  }

  public applyGridConfiguration(query: QueryDto) {
    const state = JSON.parse(query.Configuration) as State;

    if (state) {
      this.dataStateChange(state);
    }
  }

  public goToApprovalDetails(id: string) {
    this.router.navigate(['/catalogue', id]);
  }

  public exportToExcel() {
    this.dataManager.exportToExcel();
  }

  public openWarningDialog(item: ApprovalDto) {
    this.setSelectedItem(item);
    this.warningDialogOpen = true;
  }

  public openHazardsDialog(item: ApprovalDto) {
    this.setSelectedItem(item);
    this.hazardsDialogOpen = true;
  }

  public hazardsDialogClosed() {
    this.hazardsDialogOpen = false;
  }

  public warningDialogClosed() {
    this.warningDialogOpen = false;
  }

  onSearchSubmit(event) {
    event.preventDefault();
    const searchString: string = event.target['searchString'].value;
    const searchInFileContentFlag: boolean = event.target['searchStringInFile'].checked;
    this.dataManager.search(searchString,searchInFileContentFlag);
    sessionStorage.setItem('searchString', searchString);
    sessionStorage.setItem('searchInFileContent', String(searchInFileContentFlag));
    this.collapseAllRows();
    this.reloadState();
  }

  detailExpand({ dataItem, index }) {
    this.expandedRows = [...this.expandedRows, index];
    setTimeout(() => {
      const elements = document.getElementsByClassName('k-detail-cell');
      for (let i = 0; i < elements.length; ++i) {
        elements.item(i).setAttribute('colspan', '7');
      }
    }, 0);
  }

  detailCollapse({ dataItem, index }) {
    this.expandedRows = this.expandedRows.filter(x => x !== index);
  }

  get gridWidth(): number {
    return document.getElementById('approvals-grid-wrapper').clientWidth;
  }

  private reloadData() {
    const searchString = sessionStorage.getItem('searchString');
    const searchStringInFileFlag = sessionStorage.getItem('searchInFileContent') && sessionStorage.getItem('searchInFileContent').toLowerCase() === 'true';

    if (searchString) {
      this.fillInSearchBar(searchString);
    }

    if (this.searchStringInFile) 
      {
        this.searchStringInFile.nativeElement.checked = searchStringInFileFlag;
      }
    this.dataManager.search(searchString,searchStringInFileFlag);
    this.reloadState();
  }

  private fillInSearchBar(searchString: string) {
    this.searchString.nativeElement.value = searchString;
  }

  private warningHasDescription(item: WarningDto): boolean {
    return item && !!item.Description;
  }

  private collapseAllRows() {
    const rowsNumber = this.catalogueGrid.data.total;

    for (let i = 0; i < rowsNumber; ++i) {
      this.catalogueGrid.collapseRow(i);
    }
    this.expandedRows = [];
  }

  private reloadState() {
    this.state = {
      ...this.dataManager.currentState,
      skip: 0,
      take: 10
    };
  }

  private setSelectedItem(item: ApprovalDto) {
    const selectedApproval = item;
    this.selectedItem = <HazardStatementsDetails>{
      ApprovalId: selectedApproval.Id,
      ArticleName: selectedApproval.Article.Name,
      Warning: selectedApproval.Warning,
      HPhrases: selectedApproval.Hphrases.map(phrase => <HPhrasesDetailsDto>{
        GhsList: this.catalogueService.getUniqueArray(phrase.DangerClass && phrase.DangerClass.GhsList
          ? phrase.DangerClass.GhsList.map(g => g.Code) : []),
        Code: phrase.Code,
        Description: phrase.Description,
        HasDerogation: phrase.HasDerogation,
        SignalWord: phrase.DangerClass.SignalWord ? phrase.DangerClass.SignalWord.Name : '-',
        Category: phrase.DangerClass.Category ? phrase.DangerClass.Category.Name : '-',
        Type: phrase.DangerClass.Type.Name,
        Class: phrase.DangerClass.Class ? phrase.DangerClass.Class.Name : '-'
      })
    };
  }
}
