import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';

import { Subscription, Observable, EMPTY } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

import { RequestDetailsWithId } from 'app/requests/request-view/request-view.model';
import {
    SelectedRequestData, DataForRequest, ContainerComboBoxValue,
    CharacteristicComboBoxValue, RequestContainerCharacteristics, RequestFormPatchData
} from './edit-request.model';
import { SupplierDto } from 'app/catalogue-view/shared/catalogue-view.model';
import { LocationDto, SiteDto, ArticleDto, ProducerBase, Unit, Family, StateOfMatterDto } from 'app/shared/model';
import { EditRequestedArticleDto, CharacteristicsDto, ContainerDto, ArticleDetailsDto } from 'app/requests/new-request/new-request.model';
import { RequestDetailsDto } from 'app/requests/request-view/request-view.model';
import { FileToDownload, FileTypeEnum, DownloadedFile } from '../../../shared/file-manager/file-manager.model';

import { RequestService } from 'app/requests/request.service';
import { SharedService } from 'app/shared/shared.service';
import { EditRequestService } from './edit-request.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { FileManagerService } from '../../../shared/file-manager/file-manager.service';

import { RequestValidator } from './request-validator';
import { RequestFormHelper } from './request-form-helper';
import { CanComponentDeactivate } from '../../../can-deactivate.guard';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';

@Component({
    selector: 'app-edit-request',
    templateUrl: './edit-request.component.html',
    styleUrls: ['./edit-request.component.scss']
})
export class EditRequestComponent implements OnInit, OnDestroy, CanComponentDeactivate {
    @Input() requestId: number;
    @Input() editMode: boolean;
    @Output() formsStatusChange: EventEmitter<boolean> = new EventEmitter();
    @Output() requestFormDataConfirmedBySpecialist: EventEmitter<any> = new EventEmitter();
    @Output() changesDiscarded: EventEmitter<any> = new EventEmitter();

    initialized: boolean;
    formSubmitAttempt: boolean;
    containerCreation: boolean;
    characteristicCreation: boolean;
    requestForm: FormGroup;
    filteredLocations: Array<LocationDto> = [];
    filteredContainers: Array<ContainerComboBoxValue> = [];
    filteredCharacteristics: Array<CharacteristicComboBoxValue> = [];
    units: Array<any> = [];
    selectedData: SelectedRequestData = <SelectedRequestData>{};
    requestContainerCharacteristics: RequestContainerCharacteristics;
    sdsFile: Blob | File;
    sdsFileToAdd: Blob | File;
    data: DataForRequest;
    editProducerOpen: boolean;
    editArticleOpen: boolean;
    updatedArticle: EditRequestedArticleDto;
    requestData: RequestDetailsDto;
    producerName: string;
    isArticleModalChanged: boolean;
    showConfirmationDialog: boolean;
    articlesForSelect: ArticleDto[] = [];
    canEditArticle: boolean;
    canEditProducer: boolean;
    changeSdsFile: boolean;
    selectedProducer: ProducerBase;

    statesOfMatter: StateOfMatterDto[];
    selectedArticleDetails: ArticleDetailsDto;

    private entityId: string;
    private dataSubscription$: Subscription;
    private downloadSds: Subscription;

    public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'startsWith'
    };

    canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
        if (!this.requestForm.dirty) {
            return true;
        }

        return this.confirmationDialogService.showDialog();
    }

    get articleData(): ArticleDto {
        return this.requestForm.get('Article') ? this.requestForm.get('Article').value : null;
    }

    get isLitersSelected() {
        return this.validator.isLitersSelected(this.containerCreation);
    }

    get familyName(): string {
        const family = this.requestForm.get('Family').value as Family;

        return family ? family.Name : '';
    }

    constructor(
        private requestService: RequestService,
        private sharedService: SharedService,
        private spinner: SpinnerService,
        private editRequestService: EditRequestService,
        private validator: RequestValidator,
        private router: Router,
        private formHelper: RequestFormHelper,
        private fileMangerService: FileManagerService,
        private notificationService: TranslatedNotificationService,
        private confirmationDialogService: ConfirmationDialogService) {
    }

    ngOnInit() {
        this.createForm();
        this.requestForm.valueChanges.subscribe(() => {
            this.formsStatusChange.emit(this.requestForm.dirty);
        });
        this.units = Object.keys(Unit).filter(u => !isNaN(Number(u))).map(unit => Unit[unit].toString());
        this.validator.initializeFields(this.requestForm, this.selectedData);

        let id = this.sharedService.entityId;
            
        if (id && id.length > 0 && this.entityId !== id) {
            this.editRequestService.fetchDataForRequest(id, this.fillFormData.bind(this));
            this.fetchData();
            this.entityId = id;
        }
        
        this.requestService.getStatesOfMatter()
            .subscribe(
                (states: StateOfMatterDto[]) => this.statesOfMatter = states,
                () => this.notificationService.showDefaultMsgError()
        );
    }

    ngOnDestroy() {
        this.dataSubscription$.unsubscribe();
        this.unsubscribeSdsDownload();
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView({ behavior: 'smooth' });
    }

    // gets the "dirtiness" status of the child form from Producer modal
    onProducerFormStateChanged(producerFormDirty: boolean) {
        this.formsStatusChange.emit(this.requestForm.dirty || producerFormDirty);
    }

    // gets the "dirtiness" status of the child form from Article modal
    onArticleFormStateChanged(articleFormDirty: boolean) {
        this.formsStatusChange.emit(this.requestForm.dirty || articleFormDirty);
    }

    public downloadSdsFile() {
        const sdsFilePath = this.articleData?.SdsPath;
        const sdsFile: FileToDownload = {
            FileName: sdsFilePath,
            FileType: FileTypeEnum.SdsFilePath
        };

        if (sdsFilePath) {
            this.unsubscribeSdsDownload();

            this.downloadSds = this.fileMangerService.fetchFile(sdsFile).subscribe(
                (data: DownloadedFile) => {
                    this.sdsFile = data.File;
                    this.fileMangerService.downloadFile(this.sdsFile, this.articleData?.SdsDisplayName);
                },
                () => {
                    this.notificationService.showMsgError(
                        'error.cannot_load_sds_file',
                        'Cannot find the SDS file for the article. Please upload it again.'
                    );
                }
            );
        }
    }

    fillFormData() {
        if (this.requestId) {
            this.requestService.getRequestDetails(this.requestId, this.sharedService.currentLanguage.LanguageCode)
                .subscribe(data => {
                    this.requestData = data;
                    this.editRequestService.fetchArticleDetails(data.ArticleId);
                    this.fillRequestForm();
                    this.addRequestedLocationToAvailableOnes();
                    this.addRequestedSupplierToAvailableOnes();
                    this.storeRequestContainerAndCharacteristics();
                    this.requestForm.get('Producer')
                        .patchValue(this.data.Producers.find(p => p.Id === this.articleData.ProducerId));
                    this.toggleCanEditArticleAndProducer();
                    this.selectedProducer = this.requestForm.get('Producer').value;
                });
        } else {
            this.selectedData.SdsDate = new Date();
            this.selectedData.UserId = this.sharedService.loggedInUser.UserId;

            if (this.requestingNewArticle()) {
                this.createNewCharacteristic();
                this.createNewContainer();
            }
        }
        this.selectedData.RequestForm = this.requestForm;
        this.filterArticleBasedOnProducer();
    }

    fillRequestForm() {
        const container = this.editRequestService.createContainer(this.requestData.ArticleData.Container.Id);
        const characteristics = this.editRequestService.createCharacteristic(this.requestData.ArticleData.Characteristics.Id);
        const site = <SiteDto>{
            Id: this.requestData.SiteId,
            Name: this.requestData.SiteName
        };

        const patchData = <RequestFormPatchData>{
            Site: site,
            PurposeOfUse: this.requestData.PurposeOfUse,
            Quantity: this.requestData.Quantity,
            Container: container,
            Characteristics: characteristics,
            StateOfMatter: this.requestData.ArticleData.StateOfMatter
        };

        this.formHelper.patchRequestFormData(patchData);
        this.editRequestService.fillSelectedData(this.selectedData, this.requestData);

        this.createFilteredCharacteristics();
        this.createFilteredContainers();
    }

    filterLocations(selectedSite: SiteDto) {
        const selectedLocation = this.requestForm.get('Location').value as LocationDto;

        if (selectedLocation && selectedSite && selectedLocation.SiteId !== selectedSite.Id) {
            this.requestForm.get('Location').setValue(null);
        }

        if (selectedSite?.Id) {
            this.filteredLocations = this.data.Locations.filter(l => l.SiteId === selectedSite.Id);
        } else {
            this.filteredLocations = [...this.data.Locations];
        }
    }

    createNewContainer() {
        this.containerCreation = true;
        this.requestForm.get('Container').clearValidators();
        this.requestForm.get('Container').setValue(null);

        this.requestForm.get('NewContainer.ContainerTypeId').setValidators([Validators.required]);
        this.requestForm.get('NewContainer.Capacity').setValidators([Validators.required]);
        this.requestForm.get('NewContainer.Unit').setValidators([Validators.required]);
    }

    discardContainerCreation() {
        this.containerCreation = false;

        const initialContainer = this.requestContainerCharacteristics ? this.requestContainerCharacteristics.Container : null;

        this.requestForm.get('Container').setValue(initialContainer);
        this.requestForm.get('Container').setValidators([Validators.required]);

        this.clearControl('NewContainer.ContainerTypeId');
        this.clearControl('NewContainer.Capacity');
        this.clearControl('NewContainer.Unit');
    }

    createNewCharacteristic() {
        this.characteristicCreation = true;
        this.requestForm.get('Characteristics').setValue(null);
    }

    discardCharacteristicCreation() {
        this.characteristicCreation = false;

        const initialCharacteristics = this.requestContainerCharacteristics ? this.requestContainerCharacteristics.Characteristics : null;

        this.requestForm.get('Characteristics').setValue(initialCharacteristics);

        this.clearControl('NewCharacteristics.Density');
        this.clearControl('NewCharacteristics.Subdivision');
    }

    clearControl(name: string) {
        this.requestForm.get(name).setValue(null);
        this.requestForm.get(name).clearValidators();
        this.requestForm.get(name).updateValueAndValidity();
    }

    requestingNewArticle(): boolean {
        return !this.articleData?.Id;
    }

    creatingNewProducer() {
        return !this.requestForm.get('Producer')?.value?.Id;
    }

    isSiteEmpty(): boolean {
        const site = this.requestForm.get('Site');

        return !site?.value;
    }

    newContainerSelected(): boolean {
        return this.requestForm.get('Container').value === undefined;
    }

    toggleSdSFile() {
        this.changeSdsFile = !this.changeSdsFile;
        this.updateValidatorForSdsInput();
    }

    discardSdsFile() {
        this.requestForm.get('SdsFile').patchValue(this.requestData.ArticleData.SdsPath);
        this.selectedData.SdsDate = new Date(this.requestData.ArticleData.SdsDate);
        this.sdsFileToAdd = null;
        this.changeSdsFile = false;
        this.updateValidatorForSdsInput();
    }

    selectableContainerAvailable(): boolean {
        return this.articleData?.Containers?.length > 0;
    }

    getSubdivisions(): Array<string> {
        return this.articleData?.Characteristics
            ? this.articleData.Characteristics.map(s => s.Subdivision) : [];
    }

    onProducerChanged(selectedProducer: ProducerBase) {
        if (!this.requestId || selectedProducer?.Id) {
            this.requestForm.get('Producer').patchValue(selectedProducer);
        }

        if (this.editMode && !selectedProducer?.Id) {
            this.requestForm.get('Producer').patchValue(this.data.Producers.find(p => p.Id === this.articleData.ProducerId));
        }

        if (this.creatingNewProducer()) {
            if (!this.editMode) {
                this.articlesForSelect = this.data.Articles;
            }

            if (!this.requestingNewArticle()) {
                this.resetArticleWithFamily();
            }

            this.createNewCharacteristic();
            this.createNewContainer();
        } else if (!this.editMode){
            this.resetArticleAfterProducerSwitch(selectedProducer);
        }

        this.canEditProducer = this.requestForm.get('Producer').value != null && !this.requestForm.get('Producer').value.IsVerified;
        this.filterArticleBasedOnProducer();
        this.selectedProducer = this.requestForm.get('Producer').value;
    }

    onArticleChanged(selectedArticle: ArticleDto) {
        if (!this.requestId || selectedArticle?.Id) {
            // the same as [(ngModel)] while creating a new request
            // or in edition mode on selecting article from dropdown.
            this.sdsFileSelected(null);
            this.clearCharacteristicsAndContainers();
            this.updateValidatorForSdsInput();
        }

        if (this.editMode && !selectedArticle?.Id) {
            this.requestForm.get('Article').setValue(null);
        }

        if (!this.requestingNewArticle()) {
            if (selectedArticle.IsForbidden) {
                this.requestForm.get('Article').setErrors({ 'invalid': true });
                this.showForbiddenError(selectedArticle.ForbiddenNote);
            }

            this.selectedData.SdsDate = new Date(this.articleData.SdsDate);

            const stateOfMatter = selectedArticle.StateOfMatter ?
                this.data.StatesOfMatter.find(s => s.Id === selectedArticle.StateOfMatter.Id) : null;

            this.requestForm.patchValue({
                Producer: this.data.Producers.find(p => p.Id === this.articleData.ProducerId),
                Family: this.data.Families.find(p => p.Id === this.articleData.FamilyId),
                StateOfMatter: stateOfMatter
            });

            this.discardCharacteristicCreation();
            this.discardContainerCreation();
            if (selectedArticle?.Id) {
                this.clearCharacteristicsAndContainers();
                this.createFilteredCharacteristics();
                this.createFilteredContainers();
            }
        } else {
            this.createNewCharacteristic();
            this.createNewContainer();
            this.requestForm.get('Family').setValue(null);
            this.requestForm.get('StateOfMatter').setValue(null);
        }
        this.toggleCanEditArticleAndProducer();
    }

    createFilteredCharacteristics() {
        if (this.requestingNewArticle()) {
            this.clearCharacteristicsAndContainers();
        } else {
            const article = this.articleData;

            this.filteredCharacteristics = article ? this.editRequestService.filterCharacteristics(article.Id) : [];
        }
    }

    onCharacteristicChange() {
        this.createFilteredContainers();
    }

    createFilteredContainers() {
        const selectedCharacteristics = this.requestForm.get('Characteristics').value as CharacteristicComboBoxValue;

        if (selectedCharacteristics != null) {
            this.filteredContainers = this.editRequestService
                .filterContainersFromCharacteristics(selectedCharacteristics.Id);
        } else {
            const article = this.articleData;

            this.filteredContainers = article ? this.editRequestService.filterContainersFromArticle(article.Id) : [];
        }
        this.updateValidatorForSdsInput();
    }

    onContainerChange() {
        const container = this.requestForm.get('Container').value as ContainerComboBoxValue;
        const characteristics = this.requestForm.get('Characteristics').value as CharacteristicComboBoxValue;

        if (!this.characteristicCreation
            && characteristics && characteristics.Density == null
            && container && container.Unit === Unit.l) {
            this.requestForm.get('Characteristics').setValue(null);
        }
    }

    clearCharacteristicsAndContainers() {
        this.filteredCharacteristics = [];
        this.filteredContainers = [];
        this.requestForm.get('Characteristics').setValue(null);
        this.requestForm.get('Container').setValue(null);
    }

    selectSiteOfLocation(location: LocationDto) {
        if (this.isSiteEmpty && location && location.Id) {
            const site = this.data.Sites.find(s => s.Id === location.SiteId);
            this.requestForm.get('Site').setValue(site);
            this.filterLocations(site);
        }
    }

    openEditProducerModal() {
        this.editProducerOpen = true;
    }

    editProducerModalClose() {
        this.editProducerOpen = false;
    }

    saveProducerChanges(editedProducer: ProducerBase) {
        if (editedProducer) {
            const producer = this.data.Producers.find(p => p.Id === editedProducer.Id);

            if (producer) {
                producer.Name = editedProducer.Name;
                this.requestForm.get('Producer').patchValue(producer);
            } else {
                this.requestForm.get('Producer').patchValue(null);
            }
        } else {
            this.requestForm.get('Producer').patchValue(this.data.Producers.find(p => p.Id === this.articleData.ProducerId));
        }
    }

    openEditArticleModal() {
        this.editArticleOpen = true;
    }

    editArticleModalClose() {
        this.editArticleOpen = false;
    }

    updateArticle(event: EditRequestedArticleDto) {
        this.isArticleModalChanged = true;
        this.updatedArticle = event;

        const updatedArticleFromArticlesCollection = this.data.Articles.find(a => a.Id === event.ArticleId);

        if (updatedArticleFromArticlesCollection) {
            updatedArticleFromArticlesCollection.Name = event.Name;
        }

        const article = this.requestForm.get('Article').value;

        this.requestForm.patchValue({
            Article: {
                ...article,
                Name: event.Name
            }
        });
    }

    discardChanges() {
        if (!this.editMode) {
            const excludedProperties = ['UserId', 'RequestForm'];
            const resetProperties = Object.keys(this.selectedData).filter(prop => !excludedProperties.find(excluded => excluded === prop));

            resetProperties.forEach(prop => {
                this.selectedData[prop] = null;
            });

            this.clearCharacteristicsAndContainers();
            this.createNewCharacteristic();
            this.createNewContainer();
            this.filterLocations(this.requestForm.get('Site').value);
            this.formSubmitAttempt = false;
            this.requestForm.reset();
            this.requestForm.markAsPristine();
            this.formsStatusChange.emit(false);
        } else {
            this.changesDiscarded.emit();
        }
    }

    submitForm() {
        this.requestForm.markAllAsTouched();

        if (this.isFormValid()) {
            this.spinner.show('spinner');
            this.editMode ? this.submitEditedForm() : this.submitNewForm();
        } else {
            this.notificationService.showMsgError('error.request_form_invalid', 'Request form is invalid. Please review the data.');
        }
    }

    changeArticleName(name: string) {
        const article = this.requestForm.get('Article').value as ArticleDto;

        article.Name = name;
    }

    normalizer = (text: Observable<string>) => text.pipe(map((name: string) => {
        return {
            Id: undefined,
            Name: name
        };
    }))

    sdsFileSelected(file: File) {
        this.sdsFileToAdd = file;
        this.requestForm.get('SdsFile').setValue(file ? file.name : null);
        this.selectedData.SdsDate = null;
    }

    submitNewForm() {
        const characteristic = this.editRequestService.getCharacteristicsData(this.selectedData);
        const container = this.editRequestService.getContainerData(this.selectedData);
        const location = this.getLocation();
        const request = this.editRequestService.getRequestDataFromForm(this.selectedData, characteristic, container, location);
        const formData = new FormData();

        if (this.sdsFileToAdd) {
            formData.append('sdsFile', this.sdsFileToAdd, this.sdsFileToAdd['name']);
        }

        formData.append('requestDto', JSON.stringify(request));

        this.requestService.addRequest(formData)
            .pipe(
                finalize(() => this.spinner.hide('spinner'))
            )
            .subscribe(data => {
                this.formsStatusChange.emit(false);
                this.router.navigate(['/request-created/' + data]);
                this.notificationService.showMsgSuccess('success.add_request', 'Successfully created new request');
            },
            (err) => {
                this.notificationService.showExceptionError(err.error);
            });
    }

    validateCharacteristic(): boolean {
        return this.isLitersSelected
            ? this.validator.validateCharacteristic(this.containerCreation, this.characteristicCreation)
            : true;
    }

    submitEditedForm() {
        const requestWithId = this.getRequestDetailsWithId();
        const formData = new FormData();

        if (!requestWithId.ArticleData.SdsDate) {
            requestWithId.ArticleData.SdsDate = this.requestData.ArticleData.SdsDate;
        }

        if (this.canEditArticle && this.sdsFileToAdd) {
            formData.append('sdsFile', this.sdsFileToAdd, this.sdsFileToAdd['name']);
        }

        formData.append('RequestDetailsWithId', JSON.stringify(requestWithId));

        this.requestService.isRequestFinalStatusSet(requestWithId.RequestId)
            .pipe(
                finalize(() => this.spinner.hide('spinner'))
            ).switchMap((isRequestFinalStatusSet) => {
                if (isRequestFinalStatusSet) {
                    this.notificationService.showMsgInfo(
                        'info.request_final_status_set',
                        'Sorry, it is not possible to modify the request as it has been approved already.');

                    return EMPTY;
                } else {
                    return this.requestService.updateRequest(formData);
                }
            }).subscribe(() => {
                this.notificationService.showMsgSuccess('success.update_request', 'Request details have been saved.');
                this.requestFormDataConfirmedBySpecialist.emit();
                this.formsStatusChange.emit(false);
            },
                (err) => {
                    this.notificationService.showMsgError('error.update_request', 'Error occured while updating a request');
                });
    }

    validateContainer() {
        return this.validator.validateContainer(this.containerCreation);
    }

    isFormValid(): boolean {
        this.scrollToRequired();
        this.formSubmitAttempt = true;
        this.openCharacteristicsCreation(!this.validateCharacteristic());

        return this.validator.validateForm(this.containerCreation, this.characteristicCreation);
    }

    private unsubscribeSdsDownload() {
        if (this.downloadSds) {
            this.downloadSds.unsubscribe();
        }
    }

    private openCharacteristicsCreation(characteristicsInvalid: boolean) {
        if (characteristicsInvalid) {
            this.characteristicCreation = true;
            const chosenCharacteristics = this.requestForm.get('Characteristics');

            if (chosenCharacteristics.value) {
                this.requestForm.get('NewCharacteristics.Subdivision').setValue(chosenCharacteristics.value['Subdivision']);
                chosenCharacteristics.setValue(null);
            }
        }
    }

    private scrollToRequired() {
        if ((!this.requestForm.get('Location').value || !this.requestForm.get('Producer').value) && document.getElementById('siteRow')) {
            this.scroll(document.getElementById('siteRow'));
        } else if ((!this.requestForm.get('Article').value || this.requestForm.get('Article').invalid) && document.getElementById('producerRow')) {
            this.scroll(document.getElementById('producerRow'));
        } else if ((!this.requestForm.get('Family').value || !this.selectedData.RequestForm.get('PurposeOfUse').value)
            && document.getElementById('articleRow')) {
            this.scroll(document.getElementById('articleRow'));
        } else if (this.requestForm.get('StateOfMatter').invalid) {
            this.scroll(document.getElementById('articleRow'));
        } else if (this.requestForm.get('SdsFile').invalid || this.requestForm.get('SdsDate').invalid
            && document.getElementById('typeRow')) {
            this.scroll(document.getElementById('typeRow'));
        } else if (!this.requestForm.get('Characteristics').value && document.getElementById('sdsRow')) {
            this.scroll(document.getElementById('sdsRow'));
        } else if (!this.requestForm.get('Container').value && document.getElementById('characteristicRow')) {
            this.scroll(document.getElementById('characteristicRow'));
        } else if (!this.requestForm.get('Supplier').value && document.getElementById('totalAmountRow')) {
            this.scroll(document.getElementById('totalAmountRow'));
        }
    }
    // TODO: try to simplify and make the solution more generic i.e. using a custom directive.

    private getRequestDetailsWithId(): RequestDetailsWithId {
        const characteristic = this.checkCharacteristic();
        const container = this.checkContainer();
        const location = this.getLocation();
        const request = this.editRequestService.getRequestDataFromForm(this.selectedData, characteristic, container, location);

        return <RequestDetailsWithId>{
            ...request,
            RequestId: this.requestId
        };
    }

    private checkCharacteristic(): CharacteristicsDto {
        const characteristic = this.editRequestService.getCharacteristicsData(this.selectedData);

        return this.validator.checkCharacteriscticDuplicate(characteristic, this.filteredCharacteristics);
    }

    private checkContainer(): ContainerDto {
        const container = this.editRequestService.getContainerData(this.selectedData);

        return this.validator.checkContainerDuplicate(container, this.filteredContainers);
    }

    private toggleCanEditArticleAndProducer() {
        this.canEditArticle = this.articleData ? !this.articleData.IsVerified : false;
        this.canEditProducer = this.requestForm.get('Producer').value ? !this.requestForm.get('Producer').value.IsVerified : false;
    }

    private filterArticleBasedOnProducer() {
        this.articlesForSelect = this.requestForm.get('Producer').value && this.requestForm.get('Producer').value.Name !== ''
            ? this.data.Articles.filter(x => x.ProducerId === this.requestForm.get('Producer').value.Id) : this.data.Articles;
    }

    private createForm() {
        this.requestForm = this.formHelper.createRequestForm(this.editMode);
        this.updateValidatorForSdsInput();
    }

    private storeRequestContainerAndCharacteristics() {
        this.requestContainerCharacteristics = <RequestContainerCharacteristics>{
            Container: this.requestForm.get('Container').value,
            Characteristics: this.requestForm.get('Characteristics').value
        };
    }

    private updateValidatorForSdsInput() {
        const sdsFileInput = this.requestForm.get('SdsFile');
        const sdsDateInput = this.requestForm.get('SdsDate');

        const shouldSetValidators = this.requestingNewArticle() || this.changeSdsFile;

        this.updateValidator(sdsFileInput, shouldSetValidators);
        this.updateValidator(sdsDateInput, shouldSetValidators);
    }

    private updateValidator(input: AbstractControl, setValidator: boolean) {
        setValidator ? input.setValidators([Validators.required]) : input.clearValidators();
        input.updateValueAndValidity();
    }

    private fetchData() {
        this.spinner.show('spinner');
        this.dataSubscription$ = this.editRequestService.getDataForRequestForm()
            .subscribe(
                data => {
                    if (data) {
                        this.data = data;
                        this.filteredLocations = [...this.data.Locations];
                        this.spinner.hide('spinner');
                        this.initialized = true;
                    }
                },
                () => {
                    this.spinner.hide('spinner');
                    this.notificationService.showDefaultMsgError();
                }
            );
    }

    /**
    * Adds a requested location (a new location to create that has not been yet approved) to the available ones (fetched from backend)
    * in order to display it in combobox with locations.
    */
    private addRequestedLocationToAvailableOnes() {
        if (!this.data.Locations.find(l => l.Id === this.requestData.LocationId)) {
            const newLocation = <LocationDto>{
                Name: this.requestData.LocationName,
                Id: this.requestData.LocationId,
                SiteId: this.requestData.SiteId
            };

            this.data.Locations.push(newLocation);
            this.filteredLocations = this.data.Locations;
            this.requestForm.get('Location').setValue(newLocation);
        }
    }

    /**
    * Adds a requested suplier (a new supplier to create that has not been yet approved) to the available ones (fetched from backend)
    * in order to display it in combobox with supliers.
    */
    private addRequestedSupplierToAvailableOnes() {
        if (!this.data.Suppliers.find(s => s.Id === this.requestData.Supplier.Id)) {
            const newSupplier = <SupplierDto>this.requestData.Supplier;
            this.data.Suppliers.push(newSupplier);
            this.requestForm.get('Supplier').setValue(newSupplier);
        }
    }

    private resetArticleAfterProducerSwitch(newProducer: ProducerBase): void {
        if (!this.requestingNewArticle() && newProducer && this.articleData.ProducerId !== newProducer.Id) {
            this.resetArticleWithFamily();
        }
    }

    private resetArticleWithFamily(): void {
        this.requestForm.get('Article').setValue(null);
        this.requestForm.get('Family').setValue(null);
        this.requestForm.get('StateOfMatter').setValue(null);
    }

    private getLocation(): LocationDto {
        let location = this.requestForm.get('Location').value;
        if (this.editMode) {
            const existingLocation = this.data.Locations.filter(x => x.SiteId === this.requestForm.get('Site').value.Id)
                .find(x => x.Name === location.Name.trim());
            if (existingLocation) {
                location = existingLocation;
            }
        }
        return location;
    }

    private showForbiddenError(forbiddenNote: string) {
        this.notificationService.showMsgError('info.article_is_forbidden_general',
            'This product has been forbidden. Reason:', ' ' + forbiddenNote);
    }
}
