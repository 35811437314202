import { Component } from '@angular/core';

import { SharedService } from 'app/shared/shared.service';
import { UserDetailsDto } from 'app/shared/model';

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  public user: UserDetailsDto;

  constructor(private sharedService: SharedService) {
    this.user = this.sharedService.loggedInUser;
  }

  get userFullName(): string {
    return (this.user.FirstName || '') + ' ' + (this.user.LastName || '');
  }
}
