import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';

import { PphraseDto } from '../../../manage-articles.model';
import { PphraseKind } from '../../../../manage-pphrases/manage-pphrases.model';
import { CustomPphraseDto } from '../../../../../risk-analysis/risk-analysis.model';

@Component({
  selector: 'app-manage-article-p-phrases',
  templateUrl: './manage-article-p-phrases.component.html',
  styleUrls: ['./manage-article-p-phrases.component.scss']
})
export class ManageArticlePphrasesComponent implements OnInit, OnChanges {
  @Input() multiselectData: PphraseDto[];
  @Input() kinds: PphraseKind[];
  @Input() additionalPphrases: CustomPphraseDto[];

  @Output() pPhrasesUpdated = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.additionalPphrases = changes.additionalPphrases.currentValue;
    }
  }

  updatePphrases(pPhrases: CustomPphraseDto[]) {
    this.additionalPphrases = pPhrases;
    this.pPhrasesUpdated.emit(pPhrases);
  }
}
