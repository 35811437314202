import { Directive, OnInit, TemplateRef, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@Directive({
    selector: '[appConfirmationOnLeave]'
  })
export class ConfirmationDialogDirective implements OnInit {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) { }

    ngOnInit() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConfirmationDialogComponent);
        const componentRef =   this.viewContainer.createComponent(componentFactory);

        // Pass the template of the host component to the dynamic component (this with confirmation dialog)
        componentRef.instance.elementTemplateRef = this.templateRef;
    }
}
