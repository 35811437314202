import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { DataManagementService } from 'app/data-management/data-management.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { ContainerTypeDto } from 'app/shared/model';

@Component({
  selector: 'app-container-type-modal',
  templateUrl: './container-type-modal.component.html',
  styleUrls: ['./container-type-modal.component.scss']
})
export class ContainerTypeModalComponent implements OnInit {

  @Input() editedItem: ContainerTypeDto;
  @Input() containerTypes: Array<ContainerTypeDto>;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @Output() containerTypeUpdated: EventEmitter<any> = new EventEmitter();

  form: FormGroup;
  formSubmitAttempt = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataManagementService: DataManagementService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService) { }

  ngOnInit() {
    this.createForm();
  }

  closeDialog() {
    this.dialogClosed.emit();
  }

  checkNameValid() {
    this.form.markAllAsTouched();
    this.formSubmitAttempt = true;
    const name = this.form.get('Name').value;
    const existingContainerType = this.containerTypes.find(x => x.Name === name);
    if (existingContainerType) {
      if (!this.editedItem || (this.editedItem && existingContainerType.Id !== this.editedItem.Id)) {
        this.notificationService.showMsgError('error.containerType_name_used', 'Container type with this name already exist');
        return false;
      }
    }

    if (this.form.invalid) {
      this.notificationService.showMsgError('error.containerType_name_required', 'Container type name is required');
      return false;
    }

    return true;
  }

  submitForm() {
    if (this.checkNameValid()) {
      this.spinner.show('spinner');

      const containerType = {
        Id: this.editedItem ? this.editedItem.Id : null,
        Name: this.form.get('Name').value
      };
      const requestedMethod = this.getRequestedMethod(containerType);

      requestedMethod.pipe(
        finalize(() => this.spinner.hide('spinner'))
      )
      .subscribe(updatedItem => {
        this.editedItem = updatedItem;
        this.containerTypeUpdated.emit(updatedItem);
        this.notificationService.showMsgSuccess('success.containerType_updated', 'Container types collection modified');
      },
      () => {
        this.notificationService.showMsgError('error.containerType_update_error', 'Error occur during updating container types');
      });
    }
  }

  private getRequestedMethod(containerType) {
     return this.editedItem ?
      this.dataManagementService.updateContainerType(containerType) : this.dataManagementService.addContainerType(containerType);
  }

  private createForm() {
    this.form = this.formBuilder.group({
      Name: [this.editedItem === null ? null : this.editedItem.Name, Validators.required]
    });
  }
}
