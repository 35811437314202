import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { SignalWordDto } from '../../../catalogue-view/shared/catalogue-view.model';
import { DangerClassDto, CategoryDto, ClassDto, TypeDto } from 'app/catalogue-view/shared/catalogue-view.model';
import { GhsEnum, GhsDto } from '../../../shared/model';

import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { DataManagementService } from '../../data-management.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { ActionType } from '../../shared/helper';

@Component({
  selector: 'app-danger-class-modal',
  templateUrl: './danger-class-modal.component.html',
  styleUrls: ['./danger-class-modal.component.scss']
})
export class DangerClassModalComponent implements OnInit {
  @Input() editedDangerClass: DangerClassDto;
  @Input() actionType: ActionType;
  @Input() dangerClassesDialogOpen: boolean;
  @Input() categories: CategoryDto[];
  @Input() classes: ClassDto[];
  @Input() types: TypeDto[];
  @Input() signalWords: SignalWordDto[];
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();

  dangerClassesForm: FormGroup;
  dangerClass: DangerClassDto = <DangerClassDto>{};
  ghsCodes: { Code: string, Value: GhsEnum }[];
  ghsNames: string[];
  GhsEnum = GhsEnum;
  choosenGhsCodes: string[];
  formSubmitAttempt = false;

  constructor(
    private notificationService: TranslatedNotificationService,
    private formBuilder: FormBuilder,
    private dataManagementService: DataManagementService,
    private spinner: SpinnerService,
  ) { }

  ngOnInit() {
    this.ghsNames = Object.keys(GhsEnum).filter(key => isNaN(Number(key)));
    this.ghsCodes = this.ghsNames.map(code => ({ Code: code, Value: GhsEnum[code] }));
    this.choosenGhsCodes = this.actionType === ActionType.EDIT ? this.editedDangerClass.GhsList.map(c => GhsEnum[c.Code]) : [];

    this.createForm();
    this.fillForm();
  }

  discard() {
    this.dangerClassesForm.reset();
    this.closeDangerClassesDialog();
  }

  processForm() {
    this.dangerClassesForm.markAllAsTouched();
    this.formSubmitAttempt = true;
    if (this.dangerClassesForm.valid) {
      this.updateOrCreateNewDangerClass();
    } else {
      this.notificationService.showMsgError(
        'error.invalid_form',
        'Please fill all the required fileds.');
    }
  }

  closeDangerClassesDialog() {
    this.dialogClosed.emit();
  }

  fillForm() {
    if (this.actionType === ActionType.EDIT) {
      this.dangerClassesForm.get('Type').setValue(this.editedDangerClass.Type);
      this.dangerClassesForm.get('Class').setValue(this.editedDangerClass.Class);
      this.dangerClassesForm.get('Category').setValue(this.editedDangerClass.Category);
      this.dangerClassesForm.get('SignalWord').setValue(this.editedDangerClass.SignalWord);
      this.dangerClassesForm.get('Notes').setValue(this.editedDangerClass.Notes);
      this.dangerClassesForm.get('RiskScore').setValue(this.editedDangerClass.RiskScore);
    }
  }

  private assignCodes(): GhsDto[] {
    const codes = [];

    for (const element of this.choosenGhsCodes) {
      const id = this.ghsCodes.find(c => c.Code === element).Value;
      const dto = <GhsDto>{
        Id: id,
        Code: id
      };
      codes.push(dto);
    }

    return codes;
  }

  private setValuesForDangerClass() {
    const id = (this.editedDangerClass === undefined) ? 0 : this.editedDangerClass.Id;

    this.dangerClass.Id = id;
    this.dangerClass.Category = this.dangerClassesForm.get('Category').value;
    this.dangerClass.Type = this.dangerClassesForm.get('Type').value;
    this.dangerClass.Class = this.dangerClassesForm.get('Class').value;
    this.dangerClass.SignalWord = this.dangerClassesForm.get('SignalWord').value;
    this.dangerClass.Notes = this.dangerClassesForm.get('Notes').value;
    this.dangerClass.GhsList = this.assignCodes();
    this.dangerClass.RiskScore = this.dangerClassesForm.get('RiskScore').value;
  }

  private createForm() {
    this.dangerClassesForm = this.formBuilder.group({
      Type: [null, Validators.required],
      Class: [null],
      Category: [null],
      SignalWord: [null],
      Notes: [''],
      GHSCode: [''],
      RiskScore: ['', Validators.required]
    });
  }

  private updateOrCreateNewDangerClass() {
    this.spinner.show('spinner');
    this.setValuesForDangerClass();

    if (this.actionType === ActionType.NEW) {
      this.addNewDangerClass();
    } else {
      this.editDangerClass();
    }
  }

  private addNewDangerClass() {
    this.dataManagementService.addNewDangerClass(this.dangerClass)
      .pipe(
        finalize(() => this.spinner.hide('spinner'))
      )
      .subscribe(() => this.closeDangerClassesDialog());
  }

  private editDangerClass() {
    this.dataManagementService.updateExistingDangerClass(this.dangerClass)
      .pipe(
        finalize(() => this.spinner.hide('spinner'))
      )
      .subscribe(() => this.closeDangerClassesDialog());
  }
}
