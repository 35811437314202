import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
    CharacteristicsDto, ContainerDto, CharacteristicsAndContainersDto, ContainerGridDto
} from '../manage-articles.model';
import { CustomPphraseDto } from '../../../risk-analysis/risk-analysis.model';
import { Unit } from '../../../shared/model';

import { DataManagementService } from '../../data-management.service';
import { ArticleHphraseDto } from 'app/requests/new-request/new-request.model';
import { RequestService } from '../../../requests/request.service';

@Injectable({
    providedIn: 'root'
})
export class ManageArticleDataManagerService {
    articleDetails: FormData;
    characteristicsAndContainers: CharacteristicsAndContainersDto[] = [];
    characteristics: CharacteristicsDto[] = [];
    containers: ContainerDto[] = [];
    pPhrases: CustomPphraseDto[] = [];
    hPhrases: ArticleHphraseDto[] = [];

    Unit = Unit;
    dataQueries: Observable<any>[];

    constructor(
        private dataManagementService: DataManagementService,
        private requestService: RequestService
    ) {
        this.dataQueries = this.assignDataQueries();
    }

    assignDataQueries(): Observable<any>[] {
        return [
            this.dataManagementService.getSevesoNominatives(),
            this.dataManagementService.getStatesOfMatter(),
            this.dataManagementService.getProducers(),
            this.dataManagementService.getFamilies(),
            this.requestService.getContainerTypes(),
            this.dataManagementService.getPphraseKinds(),
            this.dataManagementService.getAllPphrases(),
            this.dataManagementService.getAllHphrases(),
        ];
    }

    resetData() {
        this.characteristicsAndContainers = [];
        this.characteristics = [];
        this.containers = [];
        this.pPhrases = [];
        this.hPhrases = [];
    }

    putNewArticleCharacteristic(characteristic: CharacteristicsDto) {
        const newCharacteristics = <CharacteristicsAndContainersDto>{
            ...characteristic,
            Containers: []
        };
        const index = this.characteristicsAndContainers.findIndex(c => c.TempId === characteristic.TempId);

        if (index === -1) {
            this.characteristicsAndContainers.push(newCharacteristics);
        } else {
            this.characteristicsAndContainers[index] = newCharacteristics;
        }

        this.characteristics = <CharacteristicsDto[]>this.characteristicsAndContainers;
    }

    putNewArticleContainer(container: ContainerDto) {
        const characteristics = this.characteristics.find(c => c.TempId === container.CharacteristicsId);

        let unit: Unit;
        container.Unit.toString() === Unit[Unit.kg] ? unit = Unit.kg : unit = Unit.l;

        const newContainerGrid = <ContainerGridDto>{
            ...container,
            Unit: unit,
            Subdivision: characteristics.Subdivision,
            Density: characteristics.Density
        };

        this.removeContainer(container.TempId);
        this.characteristicsAndContainers.find(cAc => cAc.TempId === container.CharacteristicsId).Containers.push(newContainerGrid);
    }

    removeContainer(containerId: number) {
        this.characteristicsAndContainers.forEach(cAc => {
            cAc.Containers = cAc.Containers.filter(c => c.TempId !== containerId);
        });
    }

    collectNewArticleData(): FormData {
        this.articleDetails.append('characteristicsAndContainers', JSON.stringify(this.characteristicsAndContainers));
        this.articleDetails.append('hPhrases', JSON.stringify(this.hPhrases));
        this.articleDetails.append('pPhrases', JSON.stringify(this.pPhrases));

        return this.articleDetails;
    }
}
