import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { DataManagementService } from 'app/data-management/data-management.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { PphraseHelperService } from '../p-phrase-helper.service';

import { PphraseGridDto, PphraseType, PphraseKind, PictogramDto, Pictogram} from '../manage-pphrases.model';

@Component({
  selector: 'app-pphrases-modal',
  templateUrl: './pphrases-modal.component.html',
  styleUrls: ['./pphrases-modal.component.scss']
})
export class PphrasesModalComponent implements OnInit {
  @Input() editedItem: PphraseGridDto;
  @Input() codeList: string[];
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @Output() pPhraseUpdated: EventEmitter<any> = new EventEmitter();
  @Output() addPphrase: EventEmitter<any> = new EventEmitter();

  form: FormGroup;
  types: PphraseType[] = [];
  kinds: PphraseKind[] = [];
  pictograms: PictogramDto[] = [];
  selectedValue: PictogramDto;
  formSubmitAttempt = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataManagementService: DataManagementService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService,
    private pPhraseService: PphraseHelperService
  ) {}

  get editMode(): boolean {
    return this.editedItem !== null && this.editedItem.Id !== 0;
  }

  ngOnInit() {
    this.pictograms = this.pPhraseService.mapPictograms();
    this.selectedValue = this.pictograms.find(x => x.EnumValue === this.editedItem.Pictogram)
      ? this.pictograms.find(x => x.EnumValue === this.editedItem.Pictogram) : <PictogramDto>{ EnumValue: Pictogram.Pic0, Value: '' };
    this.fetchData();
    this.createForm();
  }

  closeDialog() {
    this.dialogClosed.emit();
  }

  submitForm() {
    this.spinner.show('spinner');
    this.form.markAllAsTouched();
    this.formSubmitAttempt = true;

    const isCodeNew = this.codeList.find(x => x == this.form.get('Code').value);
    if (isCodeNew && this.form.get('Code').value != this.editedItem.Code) {
      this.notificationService.showMsgError('error.existing_code', 'A P-phrase with this Code already exists, please enter a new Code.');
    } else {
      const pPhraseGridDto = this.preparePphraseGridDto();

      if (this.form.valid) {
        this.getRequestedMethod(pPhraseGridDto)
          .pipe(
            finalize(() => {
              this.spinner.hide('spinner');
            })
          )
          .subscribe(
            updatedItem => {
              if (this.editedItem.Id === 0) {
                this.addPphrase.emit(updatedItem);
              } else {
                this.pPhraseUpdated.emit(updatedItem);
              }
              this.notificationService.showMsgSuccess('success.pPhrase_updated', 'P-phrase entry updated');
            },
            () => {
              this.notificationService.showMsgError(
                'error.pPhrase_update_error',
                'Sorry, we couldn\'t update P-Phrase entry. Try again later.');
            }
          );
      } else {
        this.notificationService.showMsgError('error.invalid_form', 'Please fill all the required fields.');
      }
    }
  }

  changeSelectedItem() {
    this.selectedValue = this.form.get('Pictogram').value;
  }

  hasPictogram() {
    return this.selectedValue === undefined ? false : this.selectedValue.EnumValue !== Pictogram.Pic0;
  }

  private fetchData() {
    this.spinner.show('spinner');
    forkJoin([
      this.dataManagementService.getPphraseTypes(),
      this.dataManagementService.getPphraseKinds()
    ])
      .pipe(
        finalize(() => {
          this.spinner.hide('spinner');
        })
      )
      .subscribe(([types, kinds]) => {
          this.types = types;
          this.kinds = kinds;
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }

  private createForm() {
    this.form = this.formBuilder.group({
      Code: [this.editedItem === null ? null : this.editedItem.Code, Validators.required],
      Description: [this.editedItem === null ? null : this.editedItem.Description, Validators.required],
      Type: [this.editedItem === null ? null : this.editedItem.Type, Validators.required],
      Kind: [this.editedItem === null ? null : this.editedItem.Kind, Validators.required],
      Pictogram: [this.editedItem === null ? null : this.selectedValue]
    });
  }

  private getRequestedMethod(pHhraseGridDto: PphraseGridDto) {
    return this.editedItem.Id === 0
      ? this.dataManagementService.addPphrase(pHhraseGridDto)
      : this.dataManagementService.updatePphrase(pHhraseGridDto);
  }

  private mapPictogramToEnum() {
    return this.form.get('Pictogram').value ? this.form.get('Pictogram').value.EnumValue : 0;
  }

  private preparePphraseGridDto() {
    return <PphraseGridDto>{
      Id: this.editedItem.Id,
      Code: this.form.get('Code').value,
      Description: this.form.get('Description').value,
      Kind: this.form.get('Kind').value,
      Type: this.form.get('Type').value,
      Pictogram: this.mapPictogramToEnum()
    };
  }
}
