import { UserDetailsDto } from 'app/shared/model';
import { LanguageCode } from './../../shared/model';

export interface RequestGridDto {
    RequestId: number;
    CreatedOn: string;
    RequesterGroupId: string;
    ArticleName: string;
    Site: string;
    MedicApproval: ApprovalStatusEnum;
    MedicApprovalTranslation: string;
    EnvironmentalApproval: ApprovalStatusEnum;
    EnvironmentalApprovalTranslation: string;
    SafetyApproval: ApprovalStatusEnum;
    SafetyApprovalTranslation: string;
    FinalApproval: ApprovalStatusEnum;
    FinalApprovalTranslation: string;
}

export interface Location {
    Id: number;
    Name: string;
    SiteId: number;
}

export enum ApprovalType {
    Medic = 1,
    Environment = 2,
    Safety = 3,
    Final = 4
}

export enum ApprovalStatusEnum {
    Approved = 1,
    ApprovedUnderConditions = 2,
    Rejected = 3,
    NotApprovedYet = 4
}

export enum FunctionEnum {
    Storage = 1,
    Process = 2
}

export interface ApprovalStatus {
    Id: number;
    EnumValue: ApprovalStatusEnum;
    StatusTranslations: StatusTranslation[];
    Translation: string;
}

export interface StatusTranslation {
    Language: LanguageCode;
    Text: string;
}

export interface RequestApprovalStatusDto {
    RequestId: number;
    ApprovalTypeId: number;
    ApprovalStatusId: number;
    Date?: Date;
    Approver: UserDetailsDto;
    ApproverId: string;
    Comment: string;
    Function?: FunctionEnum;
    Replaceability?: boolean;
}

export interface ApprovalReplaceAndFunctionDto {
    Function?: FunctionEnum;
    Replaceability?: boolean;
}

