import { NgModule } from '@angular/core';
import { MobilePagerComponent } from './mobile-pager.component';
import { CommonModule } from '@angular/common';
import { TranslationModule } from '../translation/translation.module';

@NgModule({
    imports: [CommonModule, TranslationModule],
    exports: [MobilePagerComponent],
    declarations: [MobilePagerComponent],
    providers: [],
})
export class MobilePagerModule { }
