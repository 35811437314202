import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslationService } from './translation.service';
import { TranslatedNotificationService } from './translated-notification.service';
import { TranslatePipe } from './translate.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        TranslatePipe
    ],
    declarations: [
        TranslatePipe
    ],
    providers: [
        TranslationService,
        TranslatedNotificationService
    ]
})
export class TranslationModule {}
