import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { TranslationModule } from 'app/shared/translation/translation.module';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    TranslationModule
  ],
  exports: [
    DisclaimerComponent
  ],
  declarations: [
    DisclaimerComponent
  ]
})
export class DisclaimerModule { }
