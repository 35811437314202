import { Injectable } from '@angular/core';

import { NotificationService } from '@progress/kendo-angular-notification';

import { TranslationService } from './translation.service';
import { NotificationBodyComponent } from '../notification-body/notification-body.component';

@Injectable()
export class TranslatedNotificationService {

    constructor(
        private translateService: TranslationService,
        private notificationService: NotificationService,
        private notificationBody: NotificationBodyComponent
    ) { }

    public showMsgError(template: string, originalText: string, additionalText?: string) {
        const additionalInfo = additionalText ? additionalText : '';

        this.translateService.translate(template, originalText)
            .then(msg => {
                if (msg) {
                    this.notificationService.show(this.notificationBody.error(msg + additionalInfo));
                } else {
                    this.notificationService.show(this.notificationBody.error(originalText + additionalInfo));
                }
            });
    }

    public showDefaultMsgError() {
        this.showMsgError('error.error_ocurred', 'Sorry, we couldn\'t upload the website. Please try again later.');
    }

    public showMsgWarning(template: string, originalText: string, additionalText?: string) {
        const additionalInfo = additionalText ? additionalText : '';

        this.translateService.translate(template, originalText)
            .then(msg => {
                if (msg) {
                    this.notificationService.show(this.notificationBody.warning(msg + additionalInfo));
                } else {
                    this.notificationService.show(this.notificationBody.warning(originalText + additionalInfo));
                }
            });
    }

    public showMsgSuccess(template: string, originalText: string) {
        this.translateService.translate(template, originalText)
            .then(msg => {
                if (msg) {
                    this.notificationService.show(this.notificationBody.success(msg));
                } else {
                    this.notificationService.show(this.notificationBody.success(originalText));
                }
            });
    }

    public showMsgInfo(template: string, originalText: string) {
        this.translateService.translate(template, originalText)
            .then(msg => {
                if (msg) {
                    this.notificationService.show(this.notificationBody.info(msg));
                } else {
                    this.notificationService.show(this.notificationBody.info(originalText));
                }
            });
    }

    public showExceptionError(error: any): any {
        if (error.Data) {
            const template = Object.keys(error.Data)[0];
            const originalText = error.Data[template];

            if (template && originalText) {
                this.translateService.translate(template, originalText)
                    .then(msg => {
                        if (msg) {
                            this.notificationService.show(this.notificationBody.error(msg));
                        } else {
                            this.notificationService.show(this.notificationBody.error(originalText));
                        }
                    });
            } else {
                this.showDefaultMsgError();
            }
        } else {
            this.showDefaultMsgError();
        }
    }
}
