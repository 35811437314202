import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';

import { GridStateHelper } from '../../../data-management/shared/grid-state';
import { EsdsReportDto } from '../../reports.model';
import { ReportsService } from '../../reports.service';
import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { SharedService } from '../../../shared/shared.service';

@Component({
  selector: 'app-esds-report',
  templateUrl: './esds-report.component.html',
  styleUrls: ['./esds-report.component.scss']
})
export class EsdsReportComponent implements OnInit {

  items: EsdsReportDto[] = [];
  gridData: GridDataResult;
  state: State;

  constructor(
    private gridState: GridStateHelper,
    private reportService: ReportsService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService,
    private sharedService: SharedService) {
    this.state = this.gridState.getBasicState();
  }

  ngOnInit() {
    this.spinner.show('spinner');
    this.reportService.getApprovalsForEsdsReport(this.sharedService.entityId)
    .pipe(
      finalize(() => this.spinner.hide('spinner'))
    )
    .subscribe(
      (items) => {
        this.items = items.map( i => {
          i.SdsDate = new Date(i.SdsDate);
          return i;
        });
        this.gridData = process(this.items, this.state);
      }, 
      () => this.notificationService.showDefaultMsgError()
    );
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  sapLinkExists(link: string) {
    return !!link && link.trim().length > 0;
  }

  openSapLink(link: string) {
    if (this.sapLinkExists(link)) {
      if (!link.match(/^http?:\/\//i) || !link.match(/^https?:\/\//i)) {
        link = 'http://' + link;
      }
      window.open(link, '_blank');
    }
  }
}
