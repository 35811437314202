import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';

import { finalize } from 'rxjs/operators';

import { ModalsService } from '../../../shared/modal/modals.service';
import { PrintingModuleService } from '../../printing-module.service';
import { FileManagerService } from '../../../shared/file-manager/file-manager.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { LabelDto, LabelSize } from '../../print.model';
import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';

@Component({
  selector: 'app-print-labels-modal',
  templateUrl: './print-labels-modal.component.html',
  styleUrls: ['./print-labels-modal.component.scss']
})
export class PrintLabelsModalComponent implements OnInit {
  @Input() requestId: number;
  @Input() set dialogOpen(isOpen: boolean) {
    if (isOpen && !this._modalShown) {
      setTimeout(() => {
        this.modalsService.showModal('warningModal', this.template, this.closeDialog.bind(this));
        this._modalShown = true;
      }, 0);
    } else if (!isOpen && this._modalShown) {
      this.modalsService.closeModal('warningModal');
      this._modalShown = false;
    }
  }
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @ViewChild(TemplateRef, { static: false }) template: TemplateRef<any>;

  private _modalShown: boolean;

  selectedLabelType = 'small';

  constructor(
    private modalsService: ModalsService,
    private spinnerService: SpinnerService,
    private printingService: PrintingModuleService,
    private fileManager: FileManagerService,
    private notificationService: TranslatedNotificationService) {
    this._modalShown = false;
  }

  ngOnInit() {
  }

  printLabel() {
    const labelDto = <LabelDto> {
      RequestId: this.requestId,
      LabelSize: this.getLabelSizeEnum(this.selectedLabelType)
    };

    if (this.selectedLabelType) {
      this.spinnerService.show('spinner');
      this.closeDialog();
      this.printingService.downloadLabels(labelDto)
      .pipe(
        finalize(() => this.spinnerService.hide('spinner'))
      )
      .subscribe(
        (data: Blob) => {
          data['name'] = 'labels.docx';
          this.fileManager.downloadFile(data);
        },
        () => {
          this.notificationService.showMsgError(
            'error.cannot_generate_labels',
            'There was an error while generating labels for the article. Please try again.'
          );
        }
      );
    }
  }

  closeDialog() {
    this.dialogClosed.emit();
  }

  private getLabelSizeEnum(optionName: string) {
    switch (optionName) {
      case 'small':
        return LabelSize.Small;
      case 'medium':
        return LabelSize.Medium;
      case 'large':
        return LabelSize.Large;
    }
  }
}
