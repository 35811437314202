export const environmentBase = {
  emailMaxLength: 100,
  phoneMaxLength: 30,
  contactNoteMaxLength: 150,
  maxMobileDeviceWidth: 1200,
  redirectUri: `${window.location.origin}/oauth2redirect`,
  scope: 'openid email profile',
  responseType: 'id_token',
  callbackUrl: 'oauth2redirect',
  baseApiUrl: 'api/'
};
