import { Injectable } from '@angular/core';

import { BehaviorSubject, forkJoin } from 'rxjs';

import { DataForRequest, SelectedRequestData, CharacteristicComboBoxValue, ContainerComboBoxValue } from './edit-request.model';
import { RequestDetailsDto } from '../../request-view/request-view.model';
import {
  NewRequestDto, RequestedArticleDto, ArticleDetailsDto,
  CharacteristicsDto, ContainerDto, SupplierDto
} from '../../new-request/new-request.model';
import { ContainerType, LanguageCode, StateOfMatterDto } from '../../../shared/model';

import { RequestService } from '../../request.service';
import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';
import { Family, LocationDto, SiteDto, ArticleDto } from '../../../shared/model';
import { SharedService } from 'app/shared/shared.service';

@Injectable()
export class EditRequestService {

  private dataForRequestForm: BehaviorSubject<DataForRequest>;
  private data: DataForRequest;
  private articleDetails: ArticleDetailsDto;

  constructor(
    private notificationService: TranslatedNotificationService,
    private requestService: RequestService,
    private sharedService: SharedService) {
    this.dataForRequestForm = new BehaviorSubject<DataForRequest>(undefined);
    this.data = <DataForRequest>{};
  }

  getDataForRequestForm(): BehaviorSubject<DataForRequest> {
    return this.dataForRequestForm;
  }

  /**
    * Method fetches data from backend, then emits fetched data by Subject.
    * At the end method emits 'undefined' by Subject to return Subject to the initial state.
    * Please take returned 'undefined' under consideration when using this data.
    */
  fetchDataForRequest(entityId: string, fillFormData: Function) {
    return forkJoin([
      this.requestService.getSites(entityId),
      this.requestService.getLocations(entityId),
      this.requestService.getProducers(),
      this.requestService.getContainerTypes(),
      this.requestService.getArticles(entityId),
      this.requestService.getStatesOfMatter(),
      this.requestService.getFamilies(),
      this.requestService.getSuppliers(),
      this.requestService.getContainers(),
      this.requestService.getCharacteristics(),
      this.requestService.getUsersEmails()]).subscribe(
        ([sites, locations, producers, containerTypes, articles, statesOfMatter, families, suppliers, containers, characteristics, emails]) => {
          this.data.Sites = sites;
          this.data.Families = families;
          this.data.Locations = locations;
          this.data.Producers = producers.sort((a, b) => a.Name.localeCompare(b.Name));
          this.data.ContainerTypes = containerTypes;
          this.data.Articles = articles.sort((a, b) => a.Name.localeCompare(b.Name));
          this.data.StatesOfMatter = statesOfMatter;
          this.data.Suppliers = suppliers.sort((a, b) => a.Name.localeCompare(b.Name));
          this.data.Containers = containers;
          this.data.Characteristics = characteristics;
          this.data.Emails = emails;
          this.dataForRequestForm.next(this.data);
          fillFormData();
          this.dataForRequestForm.next(undefined);
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }

  fetchArticleDetails(articleId: number): void {
    this.requestService.getArticleDetails(articleId, this.sharedService.currentLanguage.LanguageCode)
      .subscribe(details => {
        this.articleDetails = details;
      },
        () => {
          this.notificationService.showMsgError('error.fetch_article_details', 'Could not load article details');
        });
  }

  getRequestDataFromForm(
    selectedData: SelectedRequestData,
    characteristic: CharacteristicsDto,
    container: ContainerDto,
    location: LocationDto): NewRequestDto {
      const supplier = selectedData.RequestForm.get('Supplier').value as SupplierDto;
      const site = selectedData.RequestForm.get('Site').value as SiteDto;
      const article = selectedData.RequestForm.get('Article').value as ArticleDto;

      return <NewRequestDto>{
        SiteId: site ? site.Id : null,
        LocationId: location ? location.Id : null,
        LocationName: location ? location.Name : null,
        ArticleId: article ? article.Id : null,
        ArticleData: this.getRequestedArticleData(selectedData, characteristic, container),
        Quantity: selectedData.RequestForm.get('Quantity').value,
        Supplier: supplier,
        RequesterId: selectedData.UserId,
        OnBehalfOfEmail: selectedData.RequestForm.get('OnBehalfOfEmail').value,
        PurposeOfUse: selectedData.RequestForm.get('PurposeOfUse').value,
        SdsDate: selectedData.SdsDate
      };
  }

  getRequestedArticleData(
    selectedData: SelectedRequestData, characteristic: CharacteristicsDto, container: ContainerDto): RequestedArticleDto {
      const family = selectedData.RequestForm.get('Family').value as Family;
      const article = selectedData.RequestForm.get('Article').value as ArticleDto;
      const stateOfMatter = selectedData.RequestForm.get('StateOfMatter').value as StateOfMatterDto;

    return <RequestedArticleDto>{
      Name: article ? article.Name : null,
      FamilyId: family ? family.Id : null,
      SdsPath: selectedData.RequestForm.get('SdsPath').value,
      SdsDate: selectedData.SdsDate,
      ProducerId: selectedData.RequestForm.get('Producer').value.Id,
      ProducerName: selectedData.RequestForm.get('Producer').value.Name,
      Characteristics: characteristic,
      Container: container,
      StateOfMatter: stateOfMatter
    };
  }

  getArticleDetails(): ArticleDetailsDto {
    return this.articleDetails;
  }

  fillSelectedData(selectedData: SelectedRequestData, requestData: RequestDetailsDto) {
    const articleData = requestData.ArticleData;

    const patchData = {
      Producer: this.data.Producers.find(p => p.Id === requestData.ArticleData.ProducerId),
      Article: this.data.Articles.find(a => a.Id === requestData.ArticleId),
      StateOfMatter: articleData.StateOfMatter ? this.data.StatesOfMatter.find(s => s.Id === articleData.StateOfMatter.Id) : null,
      Location: this.data.Locations.find(l => l.Id === requestData.LocationId),
      Family: this.data.Families.find(f => f.Id === requestData.ArticleData.FamilyId),
      Supplier: this.data.Suppliers.find(s => s.Id === requestData.Supplier.Id),
      OnBehalfOfEmail: this.assignOnBehalfOf(requestData)
    };

    selectedData.RequestForm.patchValue(patchData);
    selectedData.UserId = requestData.RequesterId;
    selectedData.SdsDate = new Date(requestData.ArticleData.SdsDate);
  }

  private assignOnBehalfOf(requestData: RequestDetailsDto): string {
    const emailExists = this.data.Emails.find(s => s === requestData.OnBehalfOfEmail);
    if (!emailExists) {
      this.data.Emails.push(requestData.OnBehalfOfEmail);
       return requestData.OnBehalfOfEmail;
    }
    return emailExists;
  }

  createCharacteristic(articleId: number): CharacteristicComboBoxValue {
    const characteristic = this.data.Characteristics.find(c => c.Id === articleId);
    return <CharacteristicComboBoxValue>{
      ...characteristic,
      Name: this.prepareCharacteristicName(characteristic)
    };
  }

  createContainer(containerId: number): ContainerComboBoxValue {
    const container = this.data.Containers.find(c => c.Id === containerId);
    return <ContainerComboBoxValue>{
      ...container,
      Name: this.prepareContainerName(container)
    };
  }

  filterCharacteristics(articleId: number): Array<CharacteristicComboBoxValue> {
    return this.data.Characteristics
      .filter(c => c.ArticleId === articleId)
      .map(characteristic => ({
        ...characteristic,
        Name: this.prepareCharacteristicName(characteristic)
      }));
  }

  filterContainersFromCharacteristics(selectedCharacteristicId: number): Array<ContainerComboBoxValue> {
    return this.data.Containers
      .filter(container => container.CharacteristicsId === selectedCharacteristicId)
      .map(container => ({
        ...container,
        Name: this.prepareContainerName(container)
      }));
  }

  filterContainersFromArticle(articleId: number): Array<ContainerComboBoxValue> {
    const characteristics = this.filterCharacteristics(articleId);
    const containers = [];
    characteristics.forEach(char => containers.push(...this.filterContainersFromCharacteristics(char.Id)));

    return containers;
  }

  prepareCharacteristicName(characteristic: CharacteristicsDto): string {
    const subdivision = characteristic.Subdivision ? characteristic.Subdivision : ' - ';
    const density = characteristic.Density ? characteristic.Density : ' - ';

    return `${subdivision}, ${density}`;
  }

  prepareContainerName(container: ContainerDto): string {
    return `${container.Type.Name} ${container.Capacity} ${(container.Unit === 2 ? 'l' : 'kg')}`;
  }

  getContainerData(selectedData: SelectedRequestData): ContainerDto {
    const selectedContainer = selectedData.RequestForm.get('Container').value as ContainerComboBoxValue;
    const selectedCharacteristics = selectedData.RequestForm.get('Characteristics').value as CharacteristicComboBoxValue;

    return selectedContainer ? selectedContainer : <ContainerDto>{
      Id: null,
      Type: <ContainerType>{ Id: selectedData.RequestForm.get('NewContainer.ContainerTypeId').value },
      Capacity: selectedData.RequestForm.get('NewContainer.Capacity').value,
      Unit: selectedData.RequestForm.get('NewContainer.Unit').value,
      CharacteristicsId: selectedCharacteristics ? selectedCharacteristics.Id : null,
    };
  }

  getCharacteristicsData(selectedData: SelectedRequestData): CharacteristicsDto {
    const selectedCharacteristics = selectedData.RequestForm.get('Characteristics').value as CharacteristicComboBoxValue;

    return selectedCharacteristics ? selectedCharacteristics : <CharacteristicsDto>{
      Subdivision: selectedData.RequestForm.get('NewCharacteristics.Subdivision').value,
      Density: selectedData.RequestForm.get('NewCharacteristics.Density').value
    };
  }
}
