import { Component, OnInit } from '@angular/core';

import { finalize } from 'rxjs/operators';

import { SpinnerService } from '../../shared/spinner/spinner.service';
import { DataManagementService } from '../data-management.service';
import { TranslatedNotificationService } from '../../shared/translation/translated-notification.service';

import { GhsGridDto } from './manage-ghs.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { GridStateHelper } from '../shared/grid-state';
import { GhsEnum } from '../../shared/model';

@Component({
  selector: 'app-manage-ghs',
  templateUrl: './manage-ghs.component.html',
  styleUrls: ['./manage-ghs.component.scss', '../shared/data-grid.scss']
})
export class ManageGhsComponent implements OnInit {

  items: GhsGridDto[] = [];
  gridData: GridDataResult;
  state: State;
  GhsEnum = GhsEnum;
  showModal = false;
  editedGhs: GhsGridDto;

  constructor(
    private dataService: DataManagementService,
    private gridState: GridStateHelper,
    private spinner: SpinnerService,
    private notification: TranslatedNotificationService
  ) {
    this.state = this.gridState.getBasicState();
  }

  ngOnInit() {
    this.fetchData();
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  getGhsCode(ghs: GhsGridDto): string {
    return GhsEnum[ghs.Code];
  }

  closeDialog() {
    this.showModal = false;
  }

  openDialog() {
    this.showModal = true;
  }

  editGhs(ghs: GhsGridDto) {
    this.editedGhs = ghs;

    this.openDialog();
  }

  updateItems(item: GhsGridDto) {
    this.closeDialog();

    const updatedGhs = this.items.find(g => g.Id === item.Id);
    updatedGhs.Description = item.Description;

    this.gridData = process(this.items, this.state);
  }

  private fetchData(): void {
    this.spinner.show('spinner');

    this.dataService.getAllGhs()
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe(ghsList => {
        this.items = ghsList;
        this.gridData = process(this.items, this.state);
      },
      () => this.notification.showDefaultMsgError()
    );
  }

}
