import { Input, OnInit, AfterViewInit, Component, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';

import { SharedService } from '../shared.service';
import { TranslatedNotificationService } from '../translation/translated-notification.service';
import { PphraseGridDto, PphraseKind } from 'app/data-management/manage-pphrases/manage-pphrases.model';
import { CustomPphraseDto } from 'app/risk-analysis/risk-analysis.model';

@Component({
  selector: 'app-p-phrase-attaching',
  templateUrl: './p-phrase-attaching.component.html',
  styleUrls: ['./p-phrase-attaching.component.scss']
})
export class PphraseAttachingComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() pPhrases: CustomPphraseDto[];
  @Input() additionalPphrases: CustomPphraseDto[];
  @Input() availablePphrases: PphraseGridDto[];
  @Input() kinds: PphraseKind[];
  @Input() creatingArticlePphrase: boolean;

  @Output() additionalPphrasesListChange: EventEmitter<CustomPphraseDto[]> = new EventEmitter();

  @ViewChild('availablePphrasesMultiselect', { static: false }) multiselect: MultiSelectComponent;

  selectedAdditionalPphrases: PphraseGridDto[] = [];
  selectedAdditionalPphrasesKind: PphraseKind;
  multiselectData: PphraseGridDto[];
  formSubmitAttempt: boolean;
  form: FormGroup;
  combinedForm: FormGroup;
  isMultiPartPphrase: boolean;
  temporalId: number;

  private filter: Subscription;

  get pPhrasesNotEmpty(): boolean {
    return this.pPhrases && this.pPhrases.length > 0 ||
      this.additionalPphrases && this.additionalPphrases.length > 0;
  }

  get showPphrasesDescriptionAndKind(): boolean {
    return this.selectedAdditionalPphrases.length > 0 && this.isCombined();
  }

  constructor(
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private notificationService: TranslatedNotificationService) {
  }

  ngOnInit() {
    this.multiselectData = this.availablePphrases;
    this.getPhrasesForSelect();
    this.createForms();
    this.assignIds();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.additionalPphrases = changes.additionalPphrases.currentValue;
      this.assignIds();
    }
  }

  ngAfterViewInit() {
    this.filterSelectData();
  }

  isItemSelected(code: string): boolean {
    return this.selectedAdditionalPphrases.some(item => item.Code === code);
  }

  manageItems() {
    this.isMultiPartPphrase = this.selectedAdditionalPphrases.length > 1 && this.isCombined();
    this.setValidators();
    this.form.get('Description').patchValue(this.selectedAdditionalPphrases.map(p => p.Description).join(' ').trim());
    this.getPhrasesForSelect();
    this.filterSelectData();
  }

  addPPhrases() {
    this.isCombined() ? this.addCombinedPPhrases() : this.addSinglePPhrases();
  }

  removePphrase(tempId: number) {
    this.additionalPphrases = [...this.additionalPphrases.filter(p => p.TempId !== tempId)];
    this.additionalPphrasesListChange.emit(this.additionalPphrases);
    this.getPhrasesForSelect();
    this.filterSelectData();
  }

  private createForms() {
    this.combinedForm = this.formBuilder.group({
      Combined: ['false']
    });

    this.form = this.formBuilder.group({
      Description: [null, Validators.required],
      Kind: [null]
    });
  }

  private assignIds() {
    this.temporalId = 0;
    if (this.additionalPphrases) {
      const tempIds = this.additionalPphrases
        .map(p => {
          if (p.Id) {
            p.TempId = p.Id;
          }

          return p.TempId;
        });
      this.temporalId = Math.max(...tempIds, 0);
    }
  }

  private resetPhraseForm() {
    this.selectedAdditionalPphrases = [];
    this.form.reset();
    this.formSubmitAttempt = false;
    this.getPhrasesForSelect();
    this.filterSelectData();
  }

  private setValidators() {
    if (this.isMultiPartPphrase) {
      this.form.controls['Kind'].setValidators(Validators.required);
      this.form.controls['Kind'].updateValueAndValidity();
    } else {
      this.form.controls['Kind'].clearValidators();
      this.form.controls['Kind'].updateValueAndValidity();
    }
  }

  private findPphrasesIds(): number[] {
    return this.selectedAdditionalPphrases.map(x => x.Id);
  }

  private createNewPphrase(pPhraseName: string) {
    this.temporalId = this.temporalId + 1;
    const pPhrase = <CustomPphraseDto>{
      Code: pPhraseName,
      Text: this.form.get('Description').value,
      Kind: this.form.get('Kind').value,
      UsedPphrasesIds: this.findPphrasesIds(),
      TempId: this.temporalId
    };
    if (!this.isMultiPartPphrase) {
      let pPhraseSelected = this.selectedAdditionalPphrases.find(p => p.Code == pPhraseName);
      pPhrase.Kind = pPhraseSelected.Kind;
      pPhrase.Text = pPhraseSelected.Description;
    }
    return pPhrase;
  }

  private getPhrasesForSelect() {
    let artilcePphrasesCodes = this.additionalPphrases.map(x => x.Code);
    if (this.pPhrases) {
      artilcePphrasesCodes = [...this.pPhrases.map(x => x.Code), ...this.additionalPphrases.map(x => x.Code)];
    }
    this.multiselectData = this.availablePphrases.filter(x => artilcePphrasesCodes.find(id => id === x.Code) == null);
  }

  private filterSelectData() {
    if (!!this.filter) {
      this.filter.unsubscribe();
    }

    this.filter = this.sharedService.filterMultiselect<PphraseGridDto>(this.multiselect, this.multiselectData, ['Code', 'Description'])
      .subscribe((pPhrases: PphraseGridDto[]) => {
        this.multiselectData = pPhrases;
      });
  }

  private addSinglePPhrases() {
    if (this.selectedAdditionalPphrases.length !== 0) {
      const pPhrasesToAdd = this.selectedAdditionalPphrases.map(p => this.createNewPphrase(p.Code));
      this.additionalPphrases.push(...pPhrasesToAdd);
      this.additionalPphrasesListChange.emit(this.additionalPphrases);
      this.resetPhraseForm();
    }
  }

  private addCombinedPPhrases() {
    this.form.markAllAsTouched();
    this.formSubmitAttempt = true;
    const pPhraseName = this.selectedAdditionalPphrases.map(p => p.Code).join('+');
    if (this.selectedAdditionalPphrases.length !== 0) {
      if (this.form.valid) {
        const pPhrase = this.createNewPphrase(pPhraseName);
        this.additionalPphrases.push(pPhrase);
        this.additionalPphrasesListChange.emit(this.additionalPphrases);
        this.resetPhraseForm();
      } else {
        this.notificationService.showMsgError('error.invalid_form', 'Please fill all the required fields.');
      }
    }
  }

  private isCombined(): boolean {
    return JSON.parse(this.combinedForm.get('Combined').value);
  }
}
