import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SupplierDto } from 'app/catalogue-view/shared/catalogue-view.model';
import { DataManagementService } from 'app/data-management/data-management.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';

@Component({
  selector: 'app-supplier-modal',
  templateUrl: './supplier-modal.component.html',
  styleUrls: ['./supplier-modal.component.scss']
})
export class SupplierModalComponent implements OnInit {

  @Input() editedSupplier: SupplierDto;
  @Input() supplierModalOpen: boolean;
  @Input() suppliers: Array<SupplierDto>;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @Output() supplierUpdated: EventEmitter<any> = new EventEmitter();

  supplierForm: FormGroup;
  formSubmitAttempt = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataManagementService: DataManagementService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService) { }

  ngOnInit() {
    this.createForm();
  }

  closeDialog() {
    this.dialogClosed.emit();
  }

  checkNameValid() {
    const name = this.supplierForm.get('Name').value;
    const existingSupplier = this.suppliers.find(x => x.Name === name);
    if (existingSupplier) {
      if (!this.editedSupplier || (this.editedSupplier && existingSupplier.Id !== this.editedSupplier.Id)) {
        this.notificationService.showMsgError('error.supplier_name_used', 'Supplier with this name already exist');
        return false;
      }
    }

    if (this.supplierForm.invalid) {
      this.notificationService.showMsgError('error.supplier_name_required', 'Supplier name is required');
      return false;
    }

    return true;
  }

  submitForm() {
    this.supplierForm.markAllAsTouched();
    this.formSubmitAttempt = true;
    if (this.checkNameValid()) {
      this.spinner.show('spinner');
      const supplier = {
        Id: this.editedSupplier ? this.editedSupplier.Id : 0,
        Name: this.supplierForm.get('Name').value
      };

      const requestedMethod = this.getRequestedMethod(supplier);

      requestedMethod.pipe(
        finalize(() => this.spinner.hide('spinner'))
      )
      .subscribe(updatedSupplier => {
        this.editedSupplier = updatedSupplier;
        this.supplierUpdated.emit(updatedSupplier);
        this.notificationService.showMsgSuccess('success.supplier_updated', 'Suppliers modified');
      },
      () => {
        this.notificationService.showMsgError('error.supplier_update_error', 'Error occur during updating suppliers');
      });
    }
  }

  private getRequestedMethod(supplier) {
     return this.editedSupplier ?
       this.dataManagementService.updateSupplier(supplier) : this.dataManagementService.addSupplier(supplier);
  }

  private createForm() {
    this.supplierForm = this.formBuilder.group({
      Name: [this.editedSupplier === null ? null : this.editedSupplier.Name, Validators.required]
    });
  }

}
