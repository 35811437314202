import { Component, OnInit } from '@angular/core';

import { process, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';

import { finalize } from 'rxjs/operators';
import { ActionType } from '../shared/helper';
import { GridStateHelper } from '../shared/grid-state';

import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { RequestService } from '../../requests/request.service';

import { ContainerTypeDto } from 'app/shared/model';

@Component({
  selector: 'app-manage-container-types',
  templateUrl: './manage-container-types.component.html',
  styleUrls: ['./manage-container-types.component.scss']
})
export class ManageContainerTypesComponent implements OnInit {
  closeDialog = true;
  actionType: ActionType;
  editedContainerType: ContainerTypeDto;
  gridData: GridDataResult;
  items: ContainerTypeDto[];
  state: State;

  constructor(private gridState: GridStateHelper,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService,
    private requestService: RequestService) {
      this.state = this.gridState.getBasicState();
    }

  ngOnInit() {
    this.fetchData();
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  closeContainerTypeDialog() {
    this.closeDialog = true;
  }

  openContainerTypeDialog(containerType: ContainerTypeDto) {
    this.editedContainerType = containerType;
    this.closeDialog = false;
  }

  applyChange(containerType: ContainerTypeDto) {
    this.closeDialog = true;
    const existingContainerType = this.items.find(x => x.Id === containerType.Id);

    existingContainerType ? existingContainerType.Name = containerType.Name : this.items.push(containerType);

    this.gridData = process(this.items, this.state);
  }

  private fetchData() {
    this.spinner.show('spinner');
    this.requestService.getContainerTypes()
    .pipe(
      finalize(() => this.spinner.hide('spinner'))
    )
    .subscribe(
      (containerTypes) => {
        this.items = containerTypes;
        this.gridData = process(this.items, this.state);
      },
      () => {
        this.notificationService.showDefaultMsgError();
      });
  }
}
