import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ConfirmationDeactivateService } from './shared/ConfirmationDeactivateModule/confirmationdeactivate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(private vRef: ViewContainerRef,
      private confirmationDeactivateService: ConfirmationDeactivateService) {
      this.confirmationDeactivateService.setRootViewContainerRef(this.vRef);
     }

   ngOnInit() {
  }
}
