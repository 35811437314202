import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { finalize } from 'rxjs/operators';

import { ArticleSevesoHazardCategoryDto } from '../../reports.model';

import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';
import { ReportsService } from '../../reports.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';

@Component({
  selector: 'app-seveso-categories-assignment-modal',
  templateUrl: './seveso-categories-assignment-modal.component.html',
  styleUrls: ['./seveso-categories-assignment-modal.component.scss']
})
export class SevesoCategoriesAssignmentModalComponent implements OnInit {
  @Input() articlesSevesoHazardCategories: ArticleSevesoHazardCategoryDto[];

  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @Output() sevesoHazardsAssigned: EventEmitter<any> = new EventEmitter();

  sevesoHazardP3A: ArticleSevesoHazardCategoryDto[];
  sevesoHazardP3B: ArticleSevesoHazardCategoryDto[];
  unassignedSevesoHazard: ArticleSevesoHazardCategoryDto[];
  categories: any;

  constructor(
    private spinner: SpinnerService,
    private notificationService: TranslatedNotificationService,
    private reportsService: ReportsService) { }

  ngOnInit() {
    this.createLists();
    this.categories = {
      'P3A': this.sevesoHazardP3A,
      'P3B': this.sevesoHazardP3B,
      'Unassigned': this.unassignedSevesoHazard
    };
  }

  closeDialog() {
    this.dialogClosed.emit();
  }

  submitForm() {
    if (this.categories['Unassigned'].length !== 0) {
      this.notificationService.showMsgError('error.articles_unassigned', 'Each article has to be assigned to the hazard category');
    } else {
      const hazardCategories = this.collectData();
      this.assignSevesoHazardCategories(hazardCategories);
    }
  }

  private createLists() {
    this.sevesoHazardP3A = this.articlesSevesoHazardCategories.filter(a => a.SevesoHazardCategory === 'P3a');
    this.sevesoHazardP3B = this.articlesSevesoHazardCategories.filter(a => a.SevesoHazardCategory === 'P3b');
    this.unassignedSevesoHazard = this.articlesSevesoHazardCategories.filter(a => !a.SevesoHazardCategory);
  }

  private collectData(): ArticleSevesoHazardCategoryDto[] {
    const assignedSevesoHazardCategories = [];
    const p3a = <ArticleSevesoHazardCategoryDto[]>this.categories['P3A'];
    const p3b = <ArticleSevesoHazardCategoryDto[]>this.categories['P3B'];

    p3a.forEach(c => {
      c.SevesoHazardCategory = 'P3a';
      assignedSevesoHazardCategories.push(c);
    });

    p3b.forEach(c => {
      c.SevesoHazardCategory = 'P3b';
      assignedSevesoHazardCategories.push(c);
    });

    return assignedSevesoHazardCategories;
  }

  private assignSevesoHazardCategories(hazardCategories: ArticleSevesoHazardCategoryDto[]) {
    this.spinner.show('spinner');
    this.reportsService.putArticlesSevesoHazardCategories(hazardCategories)
      .pipe(
        finalize(() => {
          this.spinner.hide('spinner');
        })).subscribe(
          () => {
            this.notificationService.showMsgSuccess('success.assign_article', 'Successfully assigned articles to the hazard categories');
            this.sevesoHazardsAssigned.emit();
          },
          () => this.notificationService.showDefaultMsgError()
        );
  }
}
