import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { DataManagementService } from '../../data-management.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { SharedService } from '../../../shared/shared.service';

import { ActionType } from '../../shared/helper';
import { finalize } from 'rxjs/operators';
import { SevesoNominativeDto } from 'app/shared/model';

@Component({
  selector: 'app-seveso-nominative-modal',
  templateUrl: './seveso-nominative-modal.component.html',
  styleUrls: ['./seveso-nominative-modal.component.scss']
})

export class SevesoNominativeModalComponent implements OnInit {
  @Input() editedSevesoNominative: SevesoNominativeDto;
  @Input() action: ActionType;
  @Input() sevesoNominativeDialogOpen: boolean;
  @Input() allItems: SevesoNominativeDto[];
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();

  ActionType = ActionType;
  sevesoNominativeForm: FormGroup;
  sevesoNominative: SevesoNominativeDto = <SevesoNominativeDto>{};

  constructor(
    private notificationService: TranslatedNotificationService,
    private formBuilder: FormBuilder,
    private dataManagementService: DataManagementService,
    private spinner: SpinnerService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.fillForm();
  }

  discard(): void {
    this.closeSevesoNominativeDialog();
  }

  processForm(): void {
    this.sevesoNominativeForm.markAllAsTouched();

    if (this.dataIsValid()) {
      this.saveChanges();
    }
  }

  closeSevesoNominativeDialog(): void {
    this.dialogClosed.emit();
  }

  fillForm(): void {
    if (this.action === ActionType.EDIT) {
      this.sevesoNominativeForm.patchValue(this.editedSevesoNominative);
    }
  }

  private setValuesForSevesoNominative(): void {
    this.sevesoNominative.Id = (this.editedSevesoNominative === undefined) ? 0 : this.editedSevesoNominative.Id;
    this.sevesoNominative.Name = this.sevesoNominativeForm.get('Name').value;
    this.sevesoNominative.Number = this.sevesoNominativeForm.get('Number').value;
    this.sevesoNominative.CasNumber = this.sevesoNominativeForm.get('CasNumber').value;
    this.sevesoNominative.LowThreshold = this.sevesoNominativeForm.get('LowThreshold').value;
    this.sevesoNominative.HighThreshold = this.sevesoNominativeForm.get('HighThreshold').value;
    this.sevesoNominative.Notes = this.sevesoNominativeForm.get('Notes').value;
  }

  private createForm(): void {
    this.sevesoNominativeForm = this.formBuilder.group({
      Id: [''],
      Name: ['', [Validators.maxLength(1000), Validators.required]],
      Number: ['', [Validators.maxLength(1000), Validators.required]],
      CasNumber: ['', [Validators.maxLength(1000)]],
      LowThreshold: [null, this.sharedService.validateNumberGreaterThanZero],
      HighThreshold: [null, [this.sharedService.validateNumberGreaterThanZero, Validators.required]],
      Notes: ['', [Validators.maxLength(4000)]]
    });
  }

  private saveChanges(): void {
    this.spinner.show('spinner');
    this.setValuesForSevesoNominative();

    if (this.action === ActionType.NEW) {
      this.addNewNominative();
    } else {
      this.updateExistingNominative();
    }
  }

  private addNewNominative(): void {
    this.dataManagementService.addNewSevesoNominative(this.sevesoNominative)
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe(
        () => this.closeSevesoNominativeDialog(),
        () => this.notificationService.showMsgError(
          'dataManagement_error_updateOrCreateNewSevesoNominative', 'Error while creating new Seveso Nominative')
      );
  }

  private updateExistingNominative(): void {
    this.dataManagementService.updateExistingSevesoNominative(this.sevesoNominative)
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe(
        () => this.closeSevesoNominativeDialog(),
        () => this.notificationService.showMsgError('error.add_seveso_nominative', 'Error while updating Seveso Nominative')
      );
  }

  private isThresholdValid(): boolean {
    const lowThreshold = this.sevesoNominativeForm.get('LowThreshold').value;
    const highThreshold = this.sevesoNominativeForm.get('HighThreshold').value;

    return !!lowThreshold ? lowThreshold <= highThreshold : true;
  }

  private dataIsValid(): boolean {
    if (this.sevesoNominativeForm.invalid) {
      this.notificationService.showMsgError('error.invalid_form', 'Please fill all the required fields.');

      return false;
    }

    if (!this.isThresholdValid()) {
      this.notificationService.showMsgError('dataManagement.error_threshold_invalid', 'Threshold values are invalid.');

      return false;
    }

    if (this.nameRepeats()) {
      this.notificationService.showMsgError('dataManagement.error_name_not_unique', 'Provided name already exists.');
      return false;
    }

    return true;
  }

  private nameRepeats(): boolean {
    const name = this.sevesoNominativeForm.get('Name').value;

    return this.allItems.some(s => s.Name.toLowerCase().trim() === name);
  }
}
