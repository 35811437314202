import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { SpinnerService } from './spinner.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    animations: [
        trigger(
            'showAnimation', [
                transition(':leave', [
                    style({ opacity: 1}),
                    animate('500ms ease-out', style({ opacity: 0}))
                ])
            ]
        )
    ]
})
export class SpinnerComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() group: string;

    isShowing = false;

    @Input()
    get show(): boolean {
        return this.isShowing;
    }

    @Output() showChange = new EventEmitter();

    constructor(private spinnerService: SpinnerService) { }

    set show(val: boolean) {
      this.isShowing = val;
      this.showChange.emit(this.isShowing);
    }

    ngOnInit(): void {
        if (!this.name) {
            throw new Error('Spinner must have a "name" attribute.');
        }

        this.spinnerService._register(this);
    }

    ngOnDestroy(): void {
        this.spinnerService._unregister(this);
    }
}
