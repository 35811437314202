import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';

import { EmailTemplatesComponent } from './email-templates.component';
import { TemplateEditorComponent } from './template-editor/template-editor.component';
import { EmailTemplatesService } from './shared/email-templates.service';
import { SpinnerModule } from '../shared/spinner/spinner.module';


@NgModule({
  declarations: [
    TemplateEditorComponent,
    EmailTemplatesComponent
  ],
  imports: [
    CommonModule,
    DropDownsModule,
    EditorModule,
    ToolBarModule,
    SpinnerModule,
    RouterModule
  ],
  exports: [
    EmailTemplatesComponent
  ],
  providers: [
    EmailTemplatesService
  ]
})
export class EmailTemplatesModule { }
