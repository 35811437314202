import { CharacteristicsDto } from '../requests/new-request/new-request.model';

export enum UserRole {
    User = 10,
    Requester = 20,
    Specialist = 30,
    Admin = 40
}

export enum Unit {
    kg = 1,
    l = 2
}

export enum Specialization {
    Medic = 1,
    Environment = 2,
    Safety = 3
}

export interface Language {
    Id: string;
    Name: string;
    LanguageCode: LanguageCode;
}

export enum LanguageCode {
    en = 1,
    fr = 2,
    de = 3,
    es = 4,
    nl = 5,
    it = 6
}

export enum StateOfMatter {
    Solid = 1,
    Liquid = 2,
    Gas = 3,
    Aerosol = 4
}

export interface StateOfMatterDto {
    Id: number;
    EnumValue: StateOfMatter;
    Name: string;
}

export enum FlashPointSign {
    Below = 1,
    BelowOrEqual = 2,
    More = 3,
    MoreOrEqual = 4,
    Equal = 5
}

export interface FlashPointSignDto {
    EnumValue: FlashPointSign;
    Name: string;
}

export enum LimitValueUnit {
    mg = 1,
    ppm = 2
}

export interface LimitValueUnitDto {
    EnumValue: LimitValueUnit;
    Name: string;
}

export interface GDPREntry {
    HtmlText: string;
    LanguageCode: string;
    LanguageName: string;
}

export class TranslationCollection {
    public LanguageCode: LanguageCode;
    public Collection: TranslationSet;
}

export class TranslationSet {
    public LanguageCode: LanguageCode;
    public Values: { [key: string]: string } = {};
}

export class TemplateDto {
    TemplateString: string;
    Translations: { [key: string]: string } = {};
}

export interface UserEntityDto {
  UserId: string;
  EntityId: string;
  ForMedical: boolean;
  ForEnvironment: boolean;
  ForSafety: boolean;
}

export interface OktaUser extends UserEntityDto {
    Email: string;
}

export interface UserDetailsDto extends OktaUser {
    UserRole: number;
    FirstName: string;
    LastName: string;
    GroupId: string;
    LanguageCode: string;
}

export interface UserLanguage {
    UserId: string;
    LanguageCode: LanguageCode;
}

export interface EntityDto {
    Id: string;
    Name: string;
}

export interface SiteDto {
    Id: number;
    Name: string;
}

export interface LocationDto {
    Id: number;
    Name: string;
    SiteId: number;
}

export interface ProducerBase {
    Id: number;
    Name: string;
    IsVerified: boolean;
}

export interface ArticleDto {
    Id: number;
    FamilyId: number;
    FamilyName: string;
    Name: string;
    ProducerId: number;
    ProducerName: string;
    SdsPath: string;
    SdsDisplayName: string;
    SdsDate: Date;
    Synonyms: Array<string>;
    Containers: Array<ContainerDto>;
    Characteristics: Array<CharacteristicsDto>;
    IsVerified: boolean;
    StateOfMatter: StateOfMatterDto;
    IsForbidden: boolean;
    ForbiddenNote: string;
}

export enum ContactType {
    Phone = 1,
    Email = 2,
    Emergency = 3
}

export interface ContactDto {
    ProducerId: number;
    ContactType: ContactType;
    Value: string;
    Notes: string;
}

export interface ProducerDto extends ProducerBase {
    Address: string;
    Website: string;
    Articles: ArticleDto[];
    Contacts: ContactDto[];
}

export interface SevesoNominativeDto {
    Id: number;
    Name: string;
    Number: string;
    CasNumber: string;
    LowThreshold?: number;
    HighThreshold: number;
    Notes: string;
}

export interface UserCompleteDto extends UserDetailsDto {
    EntityName: string;
    IsRemoved: boolean;
    Specializations: number[];
    RelatedEntities: UserEntityDto[];
    IsExternalUser: boolean;
}

export class AppProps {
    Languages: Language[];
    UserClaims: string;
    Translations: TranslationSet[];
    Entities: EntityDto[];
}

export interface Family {
    Id: number;
    Name: string;
}

export interface ContainerDto {
    Type: ContainerType;
    Unit: Unit;
    Capacity: number;
    Quantity: number;
}

export interface ContainerType {
    Id: number;
    Name: string;
}

export interface ContainerTypeDto {
    Id: number;
    Name: string;
}

export enum GhsEnum {
    GHS01 = 1,
    GHS02 = 2,
    GHS03 = 3,
    GHS04 = 4,
    GHS05 = 5,
    GHS06 = 6,
    GHS07 = 7,
    GHS08 = 8,
    GHS09 = 9
}

export interface GhsDto {
    Id: number;
    Code: GhsEnum;
}

export interface SiteGridDto extends SiteDto {
    EntityId: string;
    EntityName: string;
}

export interface LocationGridDto extends LocationDto {
    SiteName: string;
}

export interface EncodedClaimsToken {
    customClaimsToken: string;
  }