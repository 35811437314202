import { Injectable } from '@angular/core';
import { EntityDto, Language, TranslationSet } from 'app/shared/model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Configuration {
  public Languages: Language[];
  public Translations: TranslationSet[];
  public Entities: EntityDto[];
  public apiHost: string;

  constructor() {
    this.apiHost = environment.baseApiUrl;
  }
}
