import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { GridDataResult, DataStateChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

import { TranslatedNotificationService } from '../../shared/translation/translated-notification.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { DataManagementService } from '../data-management.service';

import { ArticleBasicDataDto } from './manage-articles.model';
import { GhsEnum } from '../../shared/model';
import { GridStateHelper } from '../shared/grid-state';
import { Subscription } from 'rxjs';
import { SharedService } from 'app/shared/shared.service';

@Component({
  selector: 'app-manage-articles',
  templateUrl: './manage-articles.component.html',
  styleUrls: ['./manage-articles.component.scss']
})
export class ManageArticlesComponent implements OnInit {
  gridData: GridDataResult;
  allItems: ArticleBasicDataDto[];
  forbiddenItems: ArticleBasicDataDto[];
  displayItems: ArticleBasicDataDto[];
  forbiddenChecked: boolean;
  GhsEnum = GhsEnum;
  state: State;
  private entityIdSubscription$: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private gridState: GridStateHelper,
    private dataManagementService: DataManagementService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.state = this.gridState.getBasicState();

    this.entityIdSubscription$ = this.sharedService.getCurrentEntityId()
      .subscribe(id => {
        this.fetchData();
      });
  }

  ngOnDestroy(): void {
    this.entityIdSubscription$.unsubscribe();
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.displayItems, this.state);
  }

  forbiddenCheck(context: RowClassArgs): object {
    return { forbidden: context.dataItem.IsForbidden };
  }

  toggleForbidden() {
    this.forbiddenChecked ? this.displayItems = this.allItems : this.displayItems = this.forbiddenItems;
    this.state = this.gridState.getBasicState();
    this.gridData = process(this.displayItems, this.state);
    this.forbiddenChecked = !this.forbiddenChecked;
  }

  goToAddNewArticle() {
    this.router.navigate(['addNew'], { relativeTo: this.route });
  }

  editArticle(articleId: number) {
    this.router.navigate([articleId], { relativeTo: this.route });
  }

  private fetchData() {
    this.spinner.show('spinner');
    this.dataManagementService.getArticlesBasicData(this.sharedService.entityId)
      .pipe(
        finalize(() => this.spinner.hide('spinner'))
      )
      .subscribe(
        (articles) => {
          this.allItems = articles;
          this.forbiddenItems = this.allItems.filter(x => x.IsForbidden);
          this.displayItems = articles;
          this.gridData = process(this.displayItems, this.state);
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }
}
