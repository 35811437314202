import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { WindowModule, DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { SafePipe } from '../shared/safe-pipe/safe-pipe';

import { CatalogueViewComponent } from './desktop/catalogue-view.component';
import { ArticleDetailsViewComponent } from './desktop/article-details-view/article-details-view.component';
import { HazardsModalComponent } from './desktop/hazards-modal/hazards-modal.component';
import { CatalogueViewMobileComponent } from './mobile/catalogue-view.component.mobile';
import { SdsFileComponent } from './article-details-view/sds-file/sds-file.component';
import { ArticleDetailsComponent } from './article-details-view/article-details/article-details.component';
import { ArticleDetailsSidebarComponent } from './article-details-view/article-details-sidebar/article-details-sidebar.component';
import { WarningsModalComponent } from './desktop/warnings-modal/warnings-modal.component';
import { FiltersFormComponent } from './mobile/filters-form/filters-form.component';
import { MobileInputsModule } from '../shared/mobile-inputs/mobile-inputs.module';
import { TranslationModule } from './../shared/translation/translation.module';
import { MobilePagerModule } from '../shared/mobile-pager/mobile-pager.module';
import { ModalsModule } from '../shared/modal/modals.module';
import { SharedModule } from '../shared/shared.module';
import { CatalogueDataManager } from './shared/catalogueDataManager';
import { CatalogueFiltersFormHelper } from './shared/catalogueFiltersFormHelper';
import { ApprovalEditModalComponent } from './article-details-view/article-details/approval-edit-modal/approval-edit-modal.component';
import { ConfirmationDialogModule } from 'app/shared/confirmation-dialog/confirmation-dialog.module';
import { PrintingModuleModule } from '../printing-module/printing-module.module';
import { DisableAutocompleteModule } from '../shared/disable-autocomplete/disable-autocomplete.module';
import { FavouriteQueriesComponent } from './favourite-queries/favourite-queries.component';

@NgModule({
  declarations: [
    CatalogueViewComponent,
    CatalogueViewMobileComponent,
    ArticleDetailsViewComponent,
    SafePipe,
    HazardsModalComponent,
    WarningsModalComponent,
    SdsFileComponent,
    ArticleDetailsComponent,
    ArticleDetailsSidebarComponent,
    FiltersFormComponent,
    ApprovalEditModalComponent,
    FavouriteQueriesComponent
  ],
  imports: [
    CommonModule,
    ConfirmationDialogModule,
    InputsModule,
    DropDownsModule,
    ButtonsModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    WindowModule,
    RouterModule,
    DialogModule,
    MobileInputsModule,
    BrowserAnimationsModule,
    MobilePagerModule,
    PdfViewerModule,
    PrintingModuleModule,
    ModalsModule,
    SharedModule,
    TranslationModule,
    DisableAutocompleteModule
  ],
  exports: [
    CatalogueViewComponent,
    CatalogueViewMobileComponent
  ],
  providers: [
    CatalogueDataManager,
    CatalogueFiltersFormHelper
  ]
})
class CatalogueViewModule { }

export {
  CatalogueViewModule,
  CatalogueViewComponent,
  CatalogueViewMobileComponent
};
