import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { NumericTextBoxModule } from '@progress/kendo-angular-inputs';

import { RiskAnalysisComponent } from './risk-analysis.component';
import { RiskAnalysisHphrasesComponent } from './components/risk-analysis-hphrases/risk-analysis-h-phrases.component';
import { RiskCalculationComponent } from './components/risk-calculation/risk-calculation.component';
import { RiskAnalysisService } from './risk-analysis.service';
import { SharedModule } from '../shared/shared.module';
import { ConfirmationDialogModule } from '../shared/confirmation-dialog/confirmation-dialog.module';
import { DisableAutocompleteModule } from '../shared/disable-autocomplete/disable-autocomplete.module';

@NgModule({
  declarations: [
    RiskAnalysisComponent,
    RiskAnalysisHphrasesComponent,
    RiskCalculationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownsModule,
    InputsModule,
    NumericTextBoxModule,
    SharedModule,
    ConfirmationDialogModule,
    RouterModule,
    DisableAutocompleteModule
  ],
  providers: [RiskAnalysisService]
})
export class RiskAnalysisModule { }
