import { Component, Input } from '@angular/core';
import { ApprovalStatusEnum } from '../requests-view/requests-view.model';

@Component({
  selector: 'app-approval-icons',
  templateUrl: './approval-icons.component.html',
  styleUrls: ['./approval-icons.component.scss'],
})
export class ApprovalIconsComponent {
  @Input() status: ApprovalStatusEnum;
  @Input() displayName: boolean;
  @Input() generateNotApprovedYetIcon: boolean;

  ApprovalStatus = ApprovalStatusEnum;

  constructor() { }
}
