import { Component, OnInit, Input } from '@angular/core';

import { ArticleHphraseDto } from '../../../requests/new-request/new-request.model';

@Component({
  selector: 'app-risk-analysis-h-phrases',
  templateUrl: './risk-analysis-h-phrases.component.html',
  styleUrls: ['./risk-analysis-h-phrases.component.scss']
})
export class RiskAnalysisHphrasesComponent implements OnInit {
  @Input() title: string;
  @Input() hPhrases: ArticleHphraseDto[];

  togglePanel: any = {};

  get maxScore(): number {
    const riskScores = this.hPhrases.map(hphrase => hphrase.DangerClass.RiskScore);

    return riskScores ? Math.max(...riskScores) : 0;
  }

  constructor() { }

  ngOnInit() {
  }

}
