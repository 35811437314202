import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { ApprovalDetailsDto, HPhrasesDetailsDto, ApprovalEditDetailsDto, ApprovalActionEnum } from '../../shared/catalogue-view.model';
import { GhsEnum, Unit } from 'app/shared/model';

import { CatalogueViewService } from '../../shared/catalogue-view.service';
import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { ArticleCommunicationService } from 'app/catalogue-view/shared/article-communication.service';

@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.scss']
})
export class ArticleDetailsComponent implements OnInit, AfterViewInit {
  openEditModal = false;
  openCopyModal = false;
  isApprovalCopied = false;
  approval: ApprovalDetailsDto;
  togglePanel: any = {};
  hPhrases: HPhrasesDetailsDto[];
  nameFirstWord: string;
  nameTheRest: string;
  synonyms: string;
  GhsEnum = GhsEnum;
  Unit = Unit;
  displayWarning: boolean;
  displayHphrases: boolean;
  displayPphrases: boolean;
  ApprovalActionEnum = ApprovalActionEnum;

  constructor(
    private catalogueService: CatalogueViewService,
    private communicationService: ArticleCommunicationService,
    private spinner: SpinnerService,
    private notificationService: TranslatedNotificationService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.confirmationDialogSubscriptions();
    this.catalogueService.currentApproval
      .pipe(
        finalize(() => this.spinner.hide('spinner'))
      )
      .subscribe(
        (approval) => {
          this.approval = approval;
          this.nameFirstWord = this.catalogueService.getNameFirstWord(this.approval.Article.Name);
          this.nameTheRest = this.catalogueService.getRemainingNamePart(this.approval.Article.Name, this.nameFirstWord) ?
            this.catalogueService.getRemainingNamePart(this.approval.Article.Name, this.nameFirstWord) : '';
          this.prepareSynonymsString();
          this.prepareHphrases();
          this.approval.GhsCodes = this.catalogueService.getGhsCodes(this.approval);
          this.checkHphrasesPphrasesWarningExists();
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }

  ngAfterViewInit(): void {
    const actionType = this.route.snapshot.queryParams['actionType'];
    this.approvalAction(actionType);
  }

  get subdivisionValue(): string {
    return !!this.approval.Subdivision ? this.approval.Subdivision : '-';
  }

  updateApprovalData(approvalData: ApprovalEditDetailsDto) {
    this.approval.Supplier = approvalData.Supplier;
    this.approval.Location = approvalData.Location.Name;
    this.approval.PurposeOfUse = approvalData.PurposeOfUse;
    this.approval.SapLink = approvalData.SapLink;
    this.openEditModal = false;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  private checkHphrasesPphrasesWarningExists() {
    this.displayWarning = !!this.approval.AdditionalWarning || !!this.approval.Warning.ContainsIsocyanates;
    this.displayHphrases = !!this.approval.Hphrases.length;
    this.displayPphrases = !!this.approval.Pphrases.length;
  }

  private prepareSynonymsString() {
    this.synonyms = this.approval.Article.Synonyms.join(', ');
  }

  private prepareHphrases() {
    this.hPhrases = this.approval.Hphrases.map(phrase => <HPhrasesDetailsDto>{
      Code: phrase.Code,
      Description: phrase.Description,
      HasDerogation: phrase.HasDerogation,
      SignalWord: phrase.DangerClass && phrase.DangerClass.SignalWord ? phrase.DangerClass.SignalWord.Name : '-',
      Category: phrase.DangerClass && phrase.DangerClass.Category ? phrase.DangerClass.Category.Name : '-',
      Type: phrase.DangerClass && phrase.DangerClass.Type.Name ? phrase.DangerClass.Type.Name : '-',
      Class: phrase.DangerClass && phrase.DangerClass.Class ? phrase.DangerClass.Class.Name : '-'
    });
  }

  private confirmationDialogSubscriptions() {
    this.communicationService.openEditApproval$.subscribe(openModal => {
      this.openEditModal = openModal;
    });
    this.communicationService.openCopyApproval$.subscribe(openModal => {
      this.openCopyModal = openModal;
      this.isApprovalCopied = true;
    });
    this.communicationService.editApprovalData$.subscribe(approvalData => {
      this.updateApprovalData(approvalData);
    });
  }

  private approvalAction(actionType: ApprovalActionEnum) {
    if (actionType) {
      switch (Number(actionType)) {
        case Number(ApprovalActionEnum.EDIT):
          this.communicationService.toggleEditAprovalModal(true);
          break;
        case Number(ApprovalActionEnum.COPY):
          this.communicationService.toggleCopyApprovalModal(true);
          break;
      }
    }

    this.router.navigate([], { queryParams: { actionType: null }, queryParamsHandling: 'merge' });
  }
}
