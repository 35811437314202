import { LanguageCode } from '../../shared/model';

export enum EmailType {
    NotificationForMedicSpecialist = 1,
    NotificationForEnvironmentalSpecialist = 2,
    NotificationForSafetySpecialist = 3,
    FinalDecisionNotification = 4
}

export interface EmailTemplateTag {
    Value: string;
    Description: string;
}

export interface EmailTemplateDto {
    Id: number;
    EmailType: EmailType;
    Title: string;
    Body: string;
    Language: LanguageCode;
    AvailableTags: EmailTemplateTag[];
}
