import { LanguageCode } from 'app/shared/model';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HphraseDto } from './../catalogue-view/shared/catalogue-view.model';
import { EditRequestedArticleDto } from './new-request/new-request.model';
import { SevesoNominativeDto, StateOfMatterDto, ProducerDto } from './../shared/model';

import { RequestGridDto, ApprovalStatus, RequestApprovalStatusDto } from './requests-view/requests-view.model';
import { RequestDetailsDto } from './request-view/request-view.model';

import { DataService } from '../data.service';

@Injectable()
export class RequestService {
    constructor(private dataService: DataService) { }

    getFamilies(): Observable<any> {
        return this.dataService.get('Article/GetFamilies', {});
    }

    getSites(entityId: string): Observable<any> {
        return this.dataService.get('Entity/GetSites', { entityId: entityId });
    }

    getLocations(entityId: string): Observable<any> {
        return this.dataService.get('Entity/GetLocations', { entityId: entityId });
    }

    getProducers(): Observable<any> {
        return this.dataService.get('Producer/GetProducersBaseInfo', {});
    }

    getArticles(entityId: string): Observable<any> {
        return this.dataService.get('Article/GetArticles', { entityId});
    }

    getContainerTypes(): Observable<any> {
        return this.dataService.get('ContainerType/GetContainerTypes', {});
    }

    getContainers(): Observable<any> {
        return this.dataService.get('Article/GetContainers', {});
    }

    getSuppliers(): Observable<any> {
        return this.dataService.get('Supplier/GetSuppliers', {});
    }

    getCharacteristics(): Observable<any> {
        return this.dataService.get('Article/GetCharacteristics', {});
    }

    addRequest(formData: FormData): Observable<any> {
        return this.dataService.postMultiPartData('Request/AddRequest', formData);
    }

    fetchRequestItems(entityId: string, userEmail : string): Observable<RequestGridDto[]> {
        return this.dataService.get('Requests/GetRequests', { entityId, userEmail });
    }

    getRequestDetails(requestId: number, language: LanguageCode): Observable<RequestDetailsDto> {
        return this.dataService.get('Requests/Details', { requestId, language });
    }

    getApprovalStatuses(): Observable<ApprovalStatus[]> {
        return this.dataService.get('ApprovalStatus/GetStatuses', {});
    }

    addRequestApprovalStatus(request: RequestApprovalStatusDto): Observable<any> {
        return this.dataService.post('Request/AddRequestApprovalStatus', request);
    }

    getProducer(id: number): Observable<any> {
        return this.dataService.get('Producer/GetProducer', { id });
    }

    getSevesoNominatives(): Observable<SevesoNominativeDto[]> {
        return this.dataService.get('SevesoNominative/GetSevesoNominatives', {});
    }

    getStatesOfMatter(): Observable<StateOfMatterDto[]> {
        return this.dataService.get('StatesOfMatter/GetStatesOfMatter', {});
    }

    getArticleDetails(articleId: number, language: LanguageCode): Observable<any> {
        return this.dataService.get('Article/GetDetails', { articleId, language });
    }

    checkArticleIsGas(articleId: number): Observable<any> {
        return this.dataService.get('Article/CheckArticleIsGas', { articleId });
    }

    updateProducer(producer: ProducerDto): Observable<ProducerDto> {
        return this.dataService.patch('Producer/UpdateProducer', producer);
    }

    updateArticle(article: EditRequestedArticleDto): Observable<any> {
        return this.dataService.patch('Article/UpdateRequestedArticle', article);
    }

    addRequestFinalApprovalStatus(request: RequestApprovalStatusDto): Observable<any> {
        return this.dataService.post('Request/AddRequestFinalApprovalStatus', request);
    }

    checkRiskAnalysis(requestId: number): Observable<any> {
        return this.dataService.get('RiskAnalysis/CheckRiskAnalysis', { requestId });
    }

    getApprovalReplaceabilityAndFunction(requestId: number): Observable<any> {
        return this.dataService.get('Approvals/GetReplaceabilityAndFunction', { requestId });
    }

    getHphrases(): Observable<HphraseDto[]> {
        return this.dataService.get('Article/GetHphrases', {});
    }

    updateRequest(formData: FormData): Observable<any> {
        return this.dataService.postMultiPartData('Request/UpdateRequest', formData);
    }

    getUsersEmails(): Observable<string[]> {
        return this.dataService.get('User/GetUsersEmails', {});
    }

    isRequestFinalStatusSet(requestId: number): Observable<boolean> {
        return this.dataService.get('Request/IsRequestFinalStatusSet', { requestId });
    }
}
