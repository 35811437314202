import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { EntityDto, SiteGridDto } from 'app/shared/model';
import { DataManagementService } from 'app/data-management/data-management.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';

@Component({
  selector: 'app-site-modal',
  templateUrl: './site-modal.component.html',
  styleUrls: ['./site-modal.component.scss']
})
export class SiteModalComponent implements OnInit {

  @Input() entities: EntityDto[];
  @Input() sites: SiteGridDto[];
  @Input() selectedSite: SiteGridDto;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  
  siteForm: FormGroup;
  formSubmitAttempt = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataManagementService: DataManagementService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService) { }

  public ngOnInit(): void {
    this.createForm();
  }

  checkFormValid() {
    const name = this.siteForm.get('Name').value;
    const existingSite = this.sites.find(x => x.Name === name);
    if (existingSite) {
      if (!this.selectedSite || (this.selectedSite && existingSite.Id !== this.selectedSite.Id)) {
        this.notificationService.showMsgError('error.site_name_used', 'Site with this name already exist');
        return false;
      }
    }

    if (this.siteForm.invalid) {
      this.siteForm.get('Name').invalid ?
        this.notificationService.showMsgError('error.site_name_required', 'Site name is required') :
        this.notificationService.showMsgError('error.entity_required', 'Entity is required');
      return false;
    }

    return true;
  }

  submitForm() {
    this.siteForm.markAllAsTouched();
    this.formSubmitAttempt = true;
    if (this.checkFormValid()) {
      this.spinner.show('spinner');

      const site = {
        Id: this.selectedSite ? this.selectedSite.Id : 0,
        Name: this.siteForm.get('Name').value,
        EntityId: this.siteForm.get('Entity').value.Id,
        EntityName: this.siteForm.get('Entity').value.Name
      };

      const requestedMethod = this.getRequestedMethod(site);

      requestedMethod.pipe(
        finalize(() => this.spinner.hide('spinner'))
      ).subscribe(updatedSite => {
        updatedSite.EntityName = site.EntityName;
        this.dialogClosed.emit(updatedSite);
        this.notificationService.showMsgSuccess('success.site_updated', 'Sites modified');
      }, () => {
        this.notificationService.showMsgError('error.site_update_error', 'Error occur during updating sites');
      });
    }
  }

  private getRequestedMethod(site) {
     return this.selectedSite ?
       this.dataManagementService.updateSite(site) : this.dataManagementService.addSite(site);
  }

  closeDialog() {
    this.dialogClosed.emit(null);
  }

  private createForm() {
    this.siteForm = this.formBuilder.group({
      Name: [this.selectedSite === null ? null : this.selectedSite.Name, Validators.required],
      Entity: [this.selectedSite === null ? null : this.entities.find(x => x.Id === this.selectedSite.EntityId), Validators.required]
    });
  }

}
