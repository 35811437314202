import { Component, OnInit } from '@angular/core';

import { EMPTY } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { ApprovalDetailsDto } from '../../shared/catalogue-view.model';
import { CatalogueViewService } from '../../shared/catalogue-view.service';
import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { FileToDownload, FileTypeEnum, DownloadedFile } from '../../../shared/file-manager/file-manager.model';
import { FileManagerService } from '../../../shared/file-manager/file-manager.service';

@Component({
  selector: 'app-sds-file',
  templateUrl: './sds-file.component.html',
  styleUrls: ['./sds-file.component.scss']
})
export class SdsFileComponent implements OnInit {
  approval: ApprovalDetailsDto;
  nameFirstWord: string;
  nameTheRest: string;
  sdsFile: Blob;
  pdfUrl: Uint8Array;
  sdsPath: string;
  private sdsDisplayName: string;
  sdsToDownload: FileToDownload;
  isPdf = true;

  constructor(
    private notificationService: TranslatedNotificationService,
    private catalogueService: CatalogueViewService,
    private fileManagerService: FileManagerService,
    private spinner: SpinnerService) {
  }

  ngOnInit() {
    this.spinner.show('spinner');
    this.catalogueService.currentApproval
      .switchMap(approval => {
        this.approval = approval;
        this.nameFirstWord = this.catalogueService.getNameFirstWord(this.approval.Article.Name);
        this.nameTheRest = this.catalogueService.getRemainingNamePart(this.approval.Article.Name, this.nameFirstWord);
        this.getSdsFile();

        if (this.sdsPath) {
          return this.fileManagerService.fetchFile(this.sdsToDownload);
        } else {
          this.spinner.hide('spinner');
          this.notificationService.showMsgError('error.no_sds_file', 'No SDS file has been provided for article');

          return EMPTY;
        }
      }).subscribe((data: DownloadedFile) => {
        if (data) {
          this.sdsFile = data.File;
          const fileReader = new FileReader();
          fileReader.onload = () => {
            this.pdfUrl = new Uint8Array(fileReader.result as ArrayBuffer);
        };
        fileReader.readAsArrayBuffer(data.File); 

          if (!this.sdsPath.endsWith('.pdf') && !this.sdsPath.endsWith('.PDF')) {
            this.isPdf = false;
          }
        }
        this.spinner.hide('spinner');
      },
      () => {
        this.spinner.hide('spinner');
        this.notificationService.showMsgError(
          'error.cannot_load_sds_file',
          'Cannot find the SDS file for the article. Please upload it again.'
        );
      });
  }

  public downloadSdsFile() {
    this.fileManagerService.downloadFile(this.sdsFile, this.sdsDisplayName);
}

  private getSdsFile(): void {
    this.sdsPath = this.approval.Article.SdsPath;
    this.sdsDisplayName = this.approval.Article.SdsDisplayName;
    this.sdsToDownload = <FileToDownload>{
      FileName: this.sdsPath,
      FileType: FileTypeEnum.SdsFilePath
    };
  }
}
