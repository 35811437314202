import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Configuration } from './shared-files/configuration';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json;charset=utf-8',
    })
  };

  constructor(
      private http: HttpClient,
      private handler: HttpHandler,
      private configuration: Configuration) { }

  get(url: string, params: any): Observable<any> {
    return this.http.get(`${this.configuration.apiHost}${url}`, {params : params}).pipe(
      catchError(e => {
        return throwError(e);
      }));
  }

  getWithCustomOptions(url: string, params: any, options: any): Observable<any> {
    options.params = params;
    options.headers = this.httpOptions;

    return this.http.get(`${this.configuration.apiHost}${url}`, options).pipe(
      catchError(e => {
        return throwError(e);
      }));
  }

  downloadFile(url: string, params: any): Observable<Blob> {
    return this.http.post(`${this.configuration.apiHost}${url}`, params, { responseType: 'blob'}).pipe(
      catchError(e => {
        return throwError(e);
      }));
  }

  post(url: string, data: any): Observable<any> {
    return this.http.post(`${this.configuration.apiHost}${url}`, data, {headers : this.httpOptions.headers}).pipe(
      catchError(e => {
        return throwError(e);
      }));
  }

  /**
   * Posts multi-part data. This should be used for files upload.
   * Additional data can be sent as well (they need to be within FormData object)
   * @param url endpoint url
   * @param data form data object with files and dtos data
   */
  postMultiPartData(url: string, data: FormData): Observable<any> {
    const headers = new HttpHeaders();

    // needed for multi-part data request to endpoint without arguments; otherwise BadRequest
    headers.append('Accept', 'application/json');

    return this.http.post(`${this.configuration.apiHost}${url}`, data, {headers : headers}).pipe(
      catchError(e => {
        return throwError(e);
      }));
  }

  /**
   * Patches multi-part data. This should be used for files upload.
   * Additional data can be sent as well (they need to be within FormData object)
   * @param url endpoint url
   * @param data form data object with files and dtos data
   */
  patchMultiPartData(url: string, data: FormData): Observable<any> {
    const headers = new HttpHeaders();

    // needed for multi-part data request to endpoint without arguments; otherwise BadRequest
    headers.append('Accept', 'application/json');

    return this.http.patch(`${this.configuration.apiHost}${url}`, data, {headers : headers}).pipe(
      catchError(e => {
        return throwError(e);
      }));
  }

  postWithCustomHeaders(url: string, data: any, headers: HttpHeaders): Observable<any> {
    return this.http.post(`${this.configuration.apiHost}${url}`, data, { headers }).pipe(
      catchError(e => {
        return throwError(e);
      }));
  }

  postWithProgress(url: string, data: any): Observable<any> {
    const req = new HttpRequest('POST', `${this.configuration.apiHost}${url}`, data, {
      reportProgress: true,
      responseType: 'json',
      withCredentials: false
    });
    return this.handler.handle(req).pipe(
      catchError(e => {
        return throwError(e);
    }));
  }

  delete(url: string, data: any): Observable<any> {
    return this.http.delete(`${this.configuration.apiHost}${url}`, { params: data, headers: this.httpOptions.headers }).pipe(
      catchError(e => {
        return throwError(e);
      }));
  }

  put(url: string, data: any): Observable<any> {
    return this.http.put(`${this.configuration.apiHost}${url}`, data).pipe(
      catchError(e => {
        return throwError(e);
      }));
  }

  patch(url: string, data: any): Observable<any> {
    return this.http.patch(`${this.configuration.apiHost}${url}`, data).pipe(
      catchError(e => {
        return throwError(e);
      }));
  }
}
