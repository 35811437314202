import { ArticleHphraseDto } from 'app/requests/new-request/new-request.model';
import { HPhrasesDetailsDto, PphraseDto, RequestDto } from '../catalogue-view/shared/catalogue-view.model';
import { PphraseGridDto, PphraseKind } from 'app/data-management/manage-pphrases/manage-pphrases.model';

export enum RiskType {
    Physical = 1,
    OneOffAcute = 2,
    MultipleChronic = 3,
    Environmental = 4
}

export interface RiskTypeDto {
    Id: number;
    Name: string;
    Type: RiskType;
}

export interface RiskAnalysisHphraseDto extends HPhrasesDetailsDto {
    RiskType: RiskTypeDto;
    RiskScore: number;
}

export interface TechnicalMeasureDto {
    Id: number;
    Name: string;
    Value: number;
}

export interface RiskAnalysisBaseDto {
    Id: number;
    RiskScore: number;
    AdditionalPphrases: PphraseDto[];
    AdditionalWarning: string;
    ContainsIsocyanates: boolean;
}

export interface RiskAnalysisDto extends RiskAnalysisBaseDto {
    ArticleName: string;
    AvailablePphrases: PphraseGridDto[];
    Pphrases: CustomPphraseDto[];
    Hphrases: ArticleHphraseDto[];
    TechnicalMeasure: TechnicalMeasureDto;
    DerogationFilePath: string;
    DerogationFileDisplayName: string;
    IsRequestCopied: boolean;
    Request: RequestDto;
    AdditionalRiskAnalyses: AdditionalRiskAnalysisDto[];
}

export interface RiskAnalysisSaveDto {
    RequestId: number;
    TechnicalMeasureId: number;
    RiskScore: number;
    AdditionalPphrases: CustomPphraseDto[];
    AdditionalWarning: string;
    ContainsIsocyanates: boolean;
}

export interface CustomPphraseDto {
    TempId: number;
    Id: number;
    Text: string;
    Code: string;
    Kind: PphraseKind;
    UsedPphrasesIds: number[];
}

export interface AdditionalRiskAnalysisDto {
    RiskAnalysisId: number;
    RiskScore: number;
    TechnicalMeasure: TechnicalMeasureDto;
    AdditionalPphrases: CustomPphraseDto[];
    AdditionalWarning: string;
    Site: string;
    Location: string;
}
