import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { State, process } from '@progress/kendo-data-query';

import { SharedService } from './../../../shared/shared.service';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { ReportsService } from '../../reports.service';
import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { RiskAnalysisReportDto, EntityReportDto } from '../../reports.model';
import { GridStateHelper } from '../../../data-management/shared/grid-state';
import { FileManagerService } from '../../../shared/file-manager/file-manager.service';

@Component({
  selector: 'app-risk-analysis-report',
  templateUrl: './risk-analysis-report.component.html',
  styleUrls: ['./risk-analysis-report.component.scss']
})
export class RiskAnalysisReportComponent implements OnInit {

  items: RiskAnalysisReportDto[] = [];
  gridData: GridDataResult;
  state: State;

  constructor(private gridState: GridStateHelper,
    private reportService: ReportsService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService,
    private sharedService: SharedService,
    private fileManager: FileManagerService) {
    this.state = this.gridState.getBasicState();
    }

  ngOnInit() {
    this.spinner.show('spinner');
    this.reportService.getApprovalsForRiskAnalysisReport(this.sharedService.entityId)
    .pipe(
      finalize(() => this.spinner.hide('spinner'))
    )
    .subscribe(
      (items) => {
        this.items = items;
        this.gridData = process(this.items, this.state);
      },
      () => this.notificationService.showDefaultMsgError()
    );
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  classForValue(dataItem: number) {
    return {
      'general-class': true,
      'class-green': dataItem < 2,
      'class-yellow': dataItem >= 2 && dataItem < 5,
      'class-orange': dataItem >= 5 && dataItem < 7,
      'class-darkorange': dataItem >= 7 && dataItem < 10,
      'class-red': dataItem >= 10
    };
  }

  downloadReport() {
    this.spinner.show('spinner');
    const entity = <EntityReportDto> {
       Id: this.sharedService.entityId
    };
    this.reportService.downloadRiskAnalysisReport(entity)
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe(
        (data: Blob) => {
          data['name'] = `RISK_ANALYSIS_${Date.now()}.xlsx`;
          this.fileManager.downloadFile(data);
        },
        () => {
          this.notificationService.showMsgError(
            'error.cannot_download_risk_analysis_report',
            'There was an error on downloading Risk Analysis report.'
          );
        }
      );
  }

}
