import { Injectable } from '@angular/core';
import { PictogramDto, Pictogram } from './manage-pphrases.model';

@Injectable({
  providedIn: 'root'
})

export class PphraseHelperService {
  constructor() {}

public mapPictograms() {
    return [
      <PictogramDto>{
        EnumValue: Pictogram.Pic1,
        Value: 'boots.ico',
      },
      <PictogramDto>{
        EnumValue: Pictogram.Pic2,
        Value: 'chemicalBoots.ico',
      },
      <PictogramDto>{
        EnumValue: Pictogram.Pic3,
        Value: 'dustRespirator.ico',
      },
      <PictogramDto>{
        EnumValue: Pictogram.Pic4,
        Value: 'faceshield.ico',
      },
      <PictogramDto>{
        EnumValue: Pictogram.Pic5,
        Value: 'gloves.ico',
      },
      <PictogramDto>{
        EnumValue: Pictogram.Pic6,
        Value: 'goggles.ico',
      },
      <PictogramDto>{
        EnumValue: Pictogram.Pic7,
        Value: 'hearingProtection.ico',
      },
      <PictogramDto>{
        EnumValue: Pictogram.Pic8,
        Value: 'helmet.ico',
      },
      <PictogramDto>{
        EnumValue: Pictogram.Pic9,
        Value: 'noFire.png',
      },
      <PictogramDto>{
        EnumValue: Pictogram.Pic10,
        Value: 'protectiveClothes.ico',
      },
      <PictogramDto>{
        EnumValue: Pictogram.Pic11,
        Value: 'respiaratoryProtection.ico',
      },
      <PictogramDto>{
        EnumValue: Pictogram.Pic12,
        Value: 'safetyHarness.ico',
      }
    ];
  }
}
