import { Component, OnInit } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';

import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { DataManagementService } from '../data-management.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';

import { LocationGridDto, SiteDto } from 'app/shared/model';
import { GridStateHelper } from '../shared/grid-state';

@Component({
  selector: 'app-manage-locations',
  templateUrl: './manage-locations.component.html',
  styleUrls: ['./manage-locations.component.scss']
})
export class ManageLocationsComponent implements OnInit {

  dialogOpen = false;
  selectedItem: LocationGridDto;
  gridData: GridDataResult;
  sites: SiteDto[];
  items: LocationGridDto[];
  state: State;

  constructor(private dataManagementService: DataManagementService,
    private notificationService: TranslatedNotificationService,
    private gridStateHelper: GridStateHelper,
    private spinner: SpinnerService) {
      this.state = this.gridStateHelper.getBasicState();
  }

  ngOnInit(): void {
    this.fetchData();
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  openModal(locationDto: LocationGridDto): void {
    this.selectedItem = locationDto;
    this.dialogOpen = true;
  }

  applyChange(gridItem: LocationGridDto): void {
    this.dialogOpen = false;
    this.selectedItem = null;

    if (gridItem) {
      const existingGridItem = this.items.find(x => x.Id === gridItem.Id);

      if (existingGridItem) {
        existingGridItem.Name = gridItem.Name;
        existingGridItem.SiteId = gridItem.SiteId;
        existingGridItem.SiteName = gridItem.SiteName;
      } else {
        this.items.push(gridItem);
      }

      this.gridData = process(this.items, this.state);
    }
  }

  private fetchData(): void {
    this.spinner.show('spinner');

    forkJoin([this.dataManagementService.getGridLocations(), this.dataManagementService.getSites()])
    .pipe(
      finalize(() => this.spinner.hide('spinner'))
    ).subscribe(
      ([locations, sites]) => {
        this.sites = sites;
        this.items = locations;
        this.gridData = process(this.items, this.state);
      },
      () => this.notificationService.showDefaultMsgError()
    );
  }

}
