import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { RequestService } from '../request.service';
import { SharedService } from '../../shared/shared.service';
import { TranslatedNotificationService } from '../../shared/translation/translated-notification.service';

import { LanguageCode } from '../../shared/model';
import { RequestDetailsDto } from '../request-view/request-view.model';

@Component({
  selector: 'app-request-created',
  templateUrl: './request-created.component.html',
  styleUrls: ['./request-created.component.scss']
})
export class RequestCreatedComponent implements OnInit {
  requestId: number;
  requestData: RequestDetailsDto;
  initialized: boolean;

  constructor(
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private router: Router,
    private requestService: RequestService,
    private sharedService: SharedService,
    private notificationService: TranslatedNotificationService
  ) { }

  ngOnInit() {
    this.spinner.show('spinner');
    this.requestId = parseInt(this.route.snapshot.params.RequestId, 10);

    if (isNaN(this.requestId)) {
      this.router.navigate(['/']);
      this.spinner.hide('spinner');
    } else {
      this.fetchRequest();
    }
  }

  navigateToNewRequestForm() {
    this.router.navigate(['/new-request']);
  }

  private fetchRequest() {
    this.requestService.getRequestDetails(this.requestId, this.sharedService.currentLanguage.LanguageCode)
      .pipe(finalize(() => { this.spinner.hide('spinner'); }))
      .subscribe(
        data => {
          this.requestData = data;
          this.requestData.ArticleData.SdsDate = new Date(this.requestData.ArticleData.SdsDate);
          this.initialized = true;
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }
}
