import { ContainerDto, LocationDto, StateOfMatterDto, GhsDto, GhsEnum } from 'app/shared/model';
import { CustomPphraseDto, RiskTypeDto } from 'app/risk-analysis/risk-analysis.model';
import { ArticleHphraseDto } from '../../requests/new-request/new-request.model';

export interface WarningDto {
    Description: string;
    DerogationFileDisplayName: string;
    DerogationFilePath: string;
    ContainsIsocyanates: boolean;
}

export interface DangerClassDto {
    Id: number;
    SignalWord: SignalWordDto;
    Type: TypeDto;
    Class: ClassDto;
    Notes: string;
    Category: CategoryDto;
    GhsList: GhsDto[];
    RiskScore: number;
    DisplayName: string;
}

export interface TypeDto {
    Id: number;
    Name: string;
}

export interface CategoryDto {
    Id: number;
    Name: string;
}

export interface SignalWordDto {
    Id: number;
    Name: string;
}

export interface ClassDto {
    Id: number;
    Name: string;
}

export interface HphraseDto {
    Id: number;
    Code: string;
    Description: string;
    HasDerogation: boolean;
    RiskType: RiskTypeDto;
    DangerClasses: DangerClassDto[];
}

export interface ArticleDto {
    Id: number;
    FamilyId: number;
    FamilyName: string;
    Name: string;
    Synonyms: string[];
    ProducerName: string;
    SdsPath: string;
    SdsDisplayName: string;
    Hphrases: HphraseDto[];
}

export interface ApprovalDto {
    Id: number;
    Article: ArticleDto;
    Subdivision: string;
    Site: string;
    Location: string;
    Container: ContainerDto;
    Hphrases: ArticleHphraseDto[];
    Warning?: WarningDto;
    GhsCodes: GhsEnum[];
    Quantity: number;
}

export class HazardStatementsDetails {
    ApprovalId: number;
    ArticleName: string;
    Warning: WarningDto;
    HPhrases: HPhrasesDetailsDto[];
}

export interface HPhrasesDetailsDto {
    GhsList: GhsEnum[];
    Code: string;
    Description: string;
    HasDerogation: boolean;
    SignalWord: string;
    Category: string;
    Type: string;
    Class: string;
}

export interface SupplierDto {
    Id: number;
    Name: string;
}

export interface CharacteristicsDto {
    Id: string;
    Subdivision: string;
    Site: string;
    Density: string;
}

export interface PphraseDto {
    Id: number;
    Code: string;
    Description: string;
}

export interface RequesterDto {
    Id: string;
    Email: string;
}

export interface ApprovalDetailsDto extends ApprovalDto {
    SdsDate: Date;
    SapLink: string;
    Supplier: SupplierDto;
    PurposeOfUse: string;
    Pphrases: CustomPphraseDto[];
    AdditionalWarning: string;
    Request: RequestDto;
    FlashPoint: string;
}

export interface RequestDto {
    Id: string;
    Requester: RequesterDto;
    CreatedOn: Date;
}


export interface ApprovalEditDetailsDto {
    Id: string;
    Quantity: number;
    Location: LocationDto;
    Supplier: SupplierDto;
    StateOfMatter: StateOfMatterDto;
    PurposeOfUse: string;
    IsReplaceable?: boolean;
    SapNumber?: string;
    SapLink?: string;
    DerogationFilePath?: string;
    DerogationFileDisplayName?: string;
    Function?: number;
    ApproverEmail: string;
}

export interface CatalogueExportDto {
    ApprovalsIds: number[];
}

export enum ApprovalActionEnum {
    EDIT = 1,
    COPY = 2,
    REMOVE = 3
}
