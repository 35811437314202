import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslatedNotificationService } from '../translation/translated-notification.service';

/**
 * An HTTP Interceptor for displaying error messages coming from the server.a
 * The response error property should have property:
 * Data: {
 *      exception-translation-tag: 'default-exception-message'
 * }.
 */
@Injectable()
export class ErrorRequestsInterceptor implements HttpInterceptor {
    constructor(private notificationService: TranslatedNotificationService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                catchError((response: any) => {
                    if (response instanceof HttpErrorResponse) {
                        if (response.error && response.error.hasOwnProperty('Data')) {
                            this.notificationService.showExceptionError(response.error);

                            return EMPTY;
                        } else if (response.error && !(response.error instanceof Blob)) {
                            // other types of unhandled exceptions displayed with a general message
                            // Blob error messages are excluded because they concern file downloading
                            // which is handled in components in a custom way
                            const unhandledError = {
                                Data: {
                                    'exception.unhandled-error': 'Unexpected error occured.'
                                }
                            };

                            this.notificationService.showExceptionError(unhandledError);
                        }
                    }

                    return throwError(response); // if the error response is of any other type, rethrow it further
                })
            );
    }
}
