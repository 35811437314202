import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InputsModule, NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule, ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { WindowModule, DialogModule } from '@progress/kendo-angular-dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ModalsModule } from '../shared/modal/modals.module';
import { AppRoutingModule } from 'app/app-routing.module';
import { RequestsModule } from './../requests/requests.module';
import { TranslationModule } from 'app/shared/translation/translation.module';
import { SharedModule } from '../shared/shared.module';
import { ConfirmationDialogModule } from '../shared/confirmation-dialog/confirmation-dialog.module';

import { ManageUsersComponent } from './manage-users/manage-users.component';
import { UserModalComponent } from './manage-users/user-modal/user-modal.component';
import { ManageDangerClassComponent } from './manage-danger-classes/manage-danger-class.component';
import { ManageSevesoNominativesComponent } from './manage-seveso-nominatives/manage-seveso-nominatives.component';
import { SevesoNominativeModalComponent } from './manage-seveso-nominatives/seveso-nominative-modal/seveso-nominative-modal.component';
import { DangerClassModalComponent } from './manage-danger-classes/danger-class-modal/danger-class-modal.component';
import { ManageArticlesComponent } from './manage-articles/manage-articles.component';
import { ManageEntitiesComponent } from './manage-entities/manage-entities.component';
import { EntityModalComponent } from './manage-entities/entity-modal/entity-modal.component';
import { ManageSuppliersComponent } from './manage-suppliers/manage-suppliers.component';
import { SupplierModalComponent } from './manage-suppliers/supplier-modal/supplier-modal.component';
import { ManageProducersComponent } from './manage-producers/manage-producers.component';
import { ManageContainerTypesComponent } from './manage-container-types/manage-container-types.component';
import { ContainerTypeModalComponent } from './manage-container-types/container-type-modal/container-type-modal.component';
import { ManageArticleEditComponent } from './manage-articles/manage-article-edit/manage-article-edit.component';
import {
    ManageArticleDetailsComponent
} from './manage-articles/manage-article-edit/manage-article-details/manage-article-details.component';
import { ManageSitesComponent } from './manage-sites/manage-sites.component';
import { SiteModalComponent } from './manage-sites/site-modal/site-modal.component';
import { ManageGhsComponent } from './manage-ghs/manage-ghs.component';
import {
    ManageCharacteristicsContainersComponent
} from './manage-articles/manage-article-edit/manage-characteristics-containers/manage-characteristics-containers.component';
import {
    ManageCharacteristicsComponent
} from './manage-articles/manage-article-edit/manage-characteristics-containers/manage-characteristics/manage-characteristics.component';
import {
    ManageContainersComponent
} from './manage-articles/manage-article-edit/manage-characteristics-containers/manage-containers/manage-containers.component';

import { DataManagementService } from './data-management.service';
import { GridStateHelper } from './shared/grid-state';
import { ManagePphrasesComponent } from './manage-pphrases/manage-pphrases.component';
import { GhsModalComponent } from './manage-ghs/ghs-modal/ghs-modal.component';
import { PphrasesModalComponent } from './manage-pphrases/pphrases-modal/pphrases-modal.component';
import {
    ManageArticleHpPhrasesComponent
} from './manage-articles/manage-article-edit/manage-article-h-p-phrases/manage-article-h-p-phrases.component';
import { ManageLocationsComponent } from './manage-locations/manage-locations.component';
import { LocationModalComponent } from './manage-locations/location-modal/location-modal.component';
import { ManageTechnicalMeasuresComponent } from './manage-technical-measures/manage-technical-measures.component';
import { TechnicalMeasureModalComponent } from './manage-technical-measures/technical-measure-modal/technical-measure-modal.component';
import {
    ContainerFormComponent
} from './manage-articles/manage-article-edit/manage-characteristics-containers/manage-containers/container-form/container-form.component';
import { ManageHphrasesComponent } from './manage-hphrases/manage-hphrases.component';
import { HphrasesModalComponent } from './manage-hphrases/hphrases-modal/hphrases-modal.component';
import {
    ManageArticlePphrasesComponent
} from './manage-articles/manage-article-edit/manage-article-h-p-phrases/manage-article-p-phrases/manage-article-p-phrases.component';
import {
    ManageArticleHphrasesComponent
} from './manage-articles/manage-article-edit/manage-article-h-p-phrases/manage-article-h-phrases/manage-article-h-phrases.component';
import { DisableAutocompleteModule } from '../shared/disable-autocomplete/disable-autocomplete.module';

@NgModule({
    declarations: [
        ManageUsersComponent,
        UserModalComponent,
        ManageDangerClassComponent,
        ManageSevesoNominativesComponent,
        SevesoNominativeModalComponent,
        DangerClassModalComponent,
        ManageArticlesComponent,
        ManageEntitiesComponent,
        EntityModalComponent,
        ManageSuppliersComponent,
        SupplierModalComponent,
        ManageProducersComponent,
        ManageSitesComponent,
        SiteModalComponent,
        ManageContainerTypesComponent,
        ContainerTypeModalComponent,
        ManageGhsComponent,
        ManageArticleDetailsComponent,
        ManageArticleEditComponent,
        ManageCharacteristicsContainersComponent,
        ManageCharacteristicsComponent,
        ManageContainersComponent,
        ContainerFormComponent,
        GhsModalComponent,
        ManagePphrasesComponent,
        GhsModalComponent,
        PphrasesModalComponent,
        ManageLocationsComponent,
        LocationModalComponent,
        ManageArticleHpPhrasesComponent,
        ManageTechnicalMeasuresComponent,
        TechnicalMeasureModalComponent,
        ManageHphrasesComponent,
        HphrasesModalComponent,
        ManageArticlePphrasesComponent,
        ManageArticleHphrasesComponent
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        InputsModule,
        ButtonsModule,
        GridModule,
        FormsModule,
        ReactiveFormsModule,
        DropDownsModule,
        NumericTextBoxModule,
        TranslationModule,
        WindowModule,
        ModalsModule,
        DialogModule,
        ComboBoxModule,
        RequestsModule,
        SharedModule,
        DateInputsModule,
        ConfirmationDialogModule,
        DisableAutocompleteModule
    ],
    providers: [
        DataManagementService,
        GridStateHelper
    ]
})
export class DataManagementModule { }
