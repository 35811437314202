import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestFormPatchData } from './edit-request.model';

@Injectable()
export class RequestFormHelper {
  private form: FormGroup;

  constructor(
    private formBuilder: FormBuilder) { }

  createRequestForm(editMode: boolean): FormGroup {
    this.form = this.formBuilder.group({
      Site: [null, Validators.required],
      Location: [null, Validators.required],
      Producer: [null, Validators.required],
      Article: [null, Validators.required],
      StateOfMatter: [null],
      SdsPath: '',
      SdsFile: '',
      SdsDate: null,
      Container: [null, Validators.required],
      NewContainer: this.formBuilder.group({
        ContainerTypeId: [null],
        Capacity: [''],
        Unit: ['']
      }),
      Characteristics: null,
      NewCharacteristics: this.formBuilder.group({
        Subdivision: '',
        Density: null,
      }),
      Quantity: ['', Validators.required],
      Supplier: [null, Validators.required],
      OnBehalfOfEmail: ['', Validators.email],
      Family: null,
      PurposeOfUse: ['', Validators.required]
    });

    if (editMode) {
      this.form.get('StateOfMatter').setValidators(Validators.required);
      this.form.updateValueAndValidity();
    }

    return this.form;
  }

  patchRequestFormData(data: RequestFormPatchData) {
    this.form.patchValue(data);
  }
}
