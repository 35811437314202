import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiteDto, LocationDto, ProducerBase } from 'app/shared/model';
import { CatalogueViewService } from '../../shared/catalogue-view.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { SharedService } from '../../../shared/shared.service';

@Component({
    selector: 'app-filters-form',
    styleUrls: ['filters-form.component.scss'],
    templateUrl: 'filters-form.component.html'
})
export class FiltersFormComponent implements OnInit {
    @Input() show: boolean;
    @Input() set form(form: FormGroup) {
        this._form = form;
        this._form.get('Site').valueChanges.subscribe(val => {
            const locationControl = this._form.get('Location');

            locationControl.disable();
            locationControl.setValue(null);

            if (val && val !== '') {
                this.locations = this.service.getLocations(val.Id).pipe(
                    tap(() => locationControl.enable())
                );
            }
        });
    }

    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() reset: EventEmitter<void> = new EventEmitter();
    @Output() applyFilters: EventEmitter<void> = new EventEmitter();

    _form: FormGroup;

    sites: Observable<SiteDto[]>;
    locations: Observable<LocationDto[]>;
    producers: Observable<ProducerBase[]>;


    constructor(private service: CatalogueViewService, private shared: SharedService) {
        this.sites = this.service.getSites(this.shared.entityId);
        this.producers = this.service.getProducers();
    }

    ngOnInit() { }

    get dialogWidth(): number {
        return window.innerWidth - 32;
    }

    onClose() {
        this.close.emit();
    }

    onReset() {
        this.reset.emit();
    }

    onApplyFilters() {
        this.applyFilters.emit();
    }

    filtersFormSubmit(event) {
        event.preventDefault();
        this.onApplyFilters();
    }
}
