import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { DataManagementService } from 'app/data-management/data-management.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { EntityDto } from 'app/shared/model';

@Component({
  selector: 'app-entity-modal',
  templateUrl: './entity-modal.component.html',
  styleUrls: ['./entity-modal.component.scss']
})
export class EntityModalComponent implements OnInit {

  @Input() editedEntity: EntityDto;
  @Input() entityModalOpen: boolean;
  @Input() entities: Array<EntityDto>;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @Output() entityUpdated: EventEmitter<any> = new EventEmitter();

  entityForm: FormGroup;
  formSubmitAttempt = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataManagementService: DataManagementService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService) { }

  ngOnInit() {
    this.createForm();
  }

  closeDialog() {
    this.dialogClosed.emit();
  }

  checkNameValid() {
    this.entityForm.markAllAsTouched();
    this.formSubmitAttempt = true;
    const name = this.entityForm.get('Name').value;
    const existingEntity = this.entities.find(x => x.Name === name);
    if (existingEntity) {
      if (!this.editedEntity || (this.editedEntity && existingEntity.Id !== this.editedEntity.Id)) {
        this.notificationService.showMsgError('error.entity_name_used', 'entity with this name already exist');
        return false;
      }
    }

    if (this.entityForm.invalid) {
      this.notificationService.showMsgError('error.entity_name_required', 'entity name is required');
      return false;
    }

    return true;
  }

  submitForm() {
    if (this.checkNameValid()) {
      this.spinner.show('spinner');

      const entity = {
        Id: this.editedEntity ? this.editedEntity.Id : null,
        Name: this.entityForm.get('Name').value
      };
      const requestedMethod = this.getRequestedMethod(entity);

      requestedMethod.pipe(
        finalize(() => this.spinner.hide('spinner'))
      )
      .subscribe(updatedEntity => {
        this.editedEntity = updatedEntity;
        this.entityUpdated.emit(updatedEntity);
        this.notificationService.showMsgSuccess('success.entity_updated', 'Entities modified');
      });
    }
  }

  private getRequestedMethod(entity) {
     return this.editedEntity ? this.dataManagementService.updateEntity(entity) : this.dataManagementService.addEntity(entity);
  }

  private createForm() {
    this.entityForm = this.formBuilder.group({
      Name: [this.editedEntity === null ? null : this.editedEntity.Name, Validators.required]
    });
  }

}
