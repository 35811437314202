import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GridModule } from '@progress/kendo-angular-grid';

import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { SortableModule } from '@progress/kendo-angular-sortable';

import { ReportsHubComponent } from './reports-hub/reports-hub.component';
import {
  SevesoCategoriesAssignmentModalComponent
} from './reports-hub/seveso-categories-assignment-modal/seveso-categories-assignment-modal.component';

import { TranslationModule } from '../shared/translation/translation.module';
import { SpinnerModule } from '../shared/spinner/spinner.module';

import { ReportsService } from './reports.service';
import { SdsReportComponent } from './reports-hub/sds-report/sds-report.component';
import { DisableAutocompleteModule } from '../shared/disable-autocomplete/disable-autocomplete.module';
import { EsdsReportComponent } from './reports-hub/esds-report/esds-report.component';
import { RiskAnalysisReportComponent } from './reports-hub/risk-analysis-report/risk-analysis-report.component';


@NgModule({
  declarations: [
    ReportsHubComponent,
    SevesoCategoriesAssignmentModalComponent,
    SdsReportComponent,
    EsdsReportComponent,
    RiskAnalysisReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputsModule,
    TranslationModule,
    ReactiveFormsModule,
    DropDownsModule,
    SpinnerModule,
    WindowModule,
    SortableModule,
    GridModule,
    RouterModule,
    DisableAutocompleteModule
  ],
  providers: [
    ReportsService,
  ]
})
export class ReportsModule { }
