import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { State, process } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { GridStateHelper } from 'app/data-management/shared/grid-state';
import { DangerClassDto } from '../../catalogue-view/shared/catalogue-view.model';
import { HphraseDto } from 'app/catalogue-view/shared/catalogue-view.model';
import { RiskTypeDto } from 'app/risk-analysis/risk-analysis.model';
import { DataManagementService } from 'app/data-management/data-management.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';

@Component({
  selector: 'app-manage-hphrases',
  templateUrl: './manage-hphrases.component.html',
  styleUrls: ['./manage-hphrases.component.scss']
})
export class ManageHphrasesComponent implements OnInit {

  items: HphraseDto[] = [];
  gridData: GridDataResult;
  state: State;
  editedHphrase: HphraseDto;
  riskTypes: RiskTypeDto[];
  dangerClasses: DangerClassDto[];
  showModal = false;

  constructor(
    private dataService: DataManagementService,
    private gridState: GridStateHelper,
    private spinner: SpinnerService,
    private notification: TranslatedNotificationService
  ) {
    this.state = this.gridState.getBasicState();
  }

  ngOnInit() {
    this.fetchData();
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  closeDialog() {
    this.showModal = false;
  }

  openDialog() {
    this.showModal = true;
  }

  editHphrase(hPhrase: HphraseDto) {
    this.editedHphrase = hPhrase;
    this.openDialog();
  }

  updateItems (item: HphraseDto) {
    if (item == null) {
      this.notification.showMsgError('error.hPhrase_not_exists', 'This h-phrase does not exist.');
    } else {
    this.closeDialog();
    this.updateHphrase(item);
    }
  }

  addHphrase(item: HphraseDto) {
    if (item == null) {
      this.notification.showMsgError('error.hPhrase_not_exists', 'This h-phrase does not exist.');
    } else {
      this.closeDialog();
      this.items.push(item);
      this.gridData = process(this.items, this.state);
    }
  }

  createNewHphrase() {
    this.editedHphrase = <HphraseDto> {
      Code: '',
      Description: '',
      Id: 0,
      HasDerogation: false,
      DangerClasses: []
    };
    this.openDialog();
  }

  private fetchData(): void {
   this.spinner.show('spinner');

   forkJoin([this.dataService.getAllHphrases(), this.dataService.getAllRiskTypes(), this.dataService.getDangerClasses()])
      .pipe(finalize(() => {
        this.spinner.hide('spinner');
        this.gridData = process(this.items, this.state);
        }))
      .subscribe(([hPhraseList, riskTypes, dangerClasses]) => {
        this.items = hPhraseList;
        this.riskTypes = riskTypes;
        this.dangerClasses = dangerClasses;
      },
      () => this.notification.showDefaultMsgError()
    );
  }

  private updateHphrase(item: HphraseDto) {
    const updatedHphrase = this.items.find(p => p.Id === item.Id);
    updatedHphrase.Description = item.Description;
    updatedHphrase.Code = item.Code;
    updatedHphrase.RiskType = item.RiskType;
    updatedHphrase.HasDerogation = item.HasDerogation;
    updatedHphrase.DangerClasses = item.DangerClasses;
  }
}
