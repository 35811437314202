import { Component, Input, QueryList, ContentChildren } from '@angular/core';

import { SubMenuItemComponent } from '../sub-menu-item/sub-menu-item.component';

@Component({
    selector: 'app-sub-menu',
    styleUrls: ['./sub-menu.component.scss'],
    templateUrl: './sub-menu.component.html'
})
export class SubMenuComponent {
    @ContentChildren(SubMenuItemComponent) items: QueryList<SubMenuItemComponent>;
    @Input() show: boolean;
}
