import {
    ComponentRef,
    ViewContainerRef,
    ComponentFactoryResolver,
    Injectable
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfirmationDeactivateComponent } from './confirmationdeactivate.component';

@Injectable()
export class ConfirmationDeactivateService {

    private vRef: ViewContainerRef;
    private popup: ComponentRef<ConfirmationDeactivateComponent>;

    constructor(private resolver: ComponentFactoryResolver) { }

    public setRootViewContainerRef(vRef: ViewContainerRef) {
        this.vRef = vRef;
    }

    show(): Observable<boolean> {
        return this.initAndAttachPopup();
    }

    private initAndAttachPopup(): Observable<boolean> {
        this.popup = this.vRef.createComponent(this.resolver.resolveComponentFactory(ConfirmationDeactivateComponent));

        const subject = new Subject<boolean>();

        this.popup.instance.optionClicked.subscribe(value => {
            subject.next(value);
            subject.complete();
            this.destroyPopup();
        });

        return subject;
    }

    private destroyPopup() {
        this.popup.destroy();
    }
}
