import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { RiskType, TechnicalMeasureDto } from '../../risk-analysis.model';
import { LanguageCode } from '../../../shared/model';
import { ArticleHphraseDto } from 'app/requests/new-request/new-request.model';

import { RiskAnalysisService } from '../../risk-analysis.service';
import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';

@Component({
  selector: 'app-risk-calculation',
  templateUrl: './risk-calculation.component.html',
  styleUrls: ['./risk-calculation.component.scss']
})
export class RiskCalculationComponent implements OnInit, OnChanges {
  @Input() hPhrases: ArticleHphraseDto[];
  @Input() selectedTechnicalMeasure: TechnicalMeasureDto;
  @Input() language: LanguageCode;
  @Input() isSubmitted: boolean;

  @Output() riskScoreChange: EventEmitter<{
    technicalMeasureId: number;
    riskScore: number;
  }> = new EventEmitter();

  technicalMeasures: { Name: string; Value: number }[];
  formSubmitAttempt = false;
  form: FormGroup;

  RiskTypeEnum = RiskType;

  get selectedTechnicalMeasureValue(): number {
    return this.selectedTechnicalMeasure ? this.selectedTechnicalMeasure.Value : 0;
  }

  set selectedTechnicalMeasureValue(value: number) {
    this.selectedTechnicalMeasure.Value = value;
  }

  get healthRiskHphrases(): ArticleHphraseDto[] {
    return this.hPhrases.filter(hphrase => hphrase.RiskType.Type === this.RiskTypeEnum.Physical);
  }

  get environmentalRiskHphrases(): ArticleHphraseDto[] {
    return this.hPhrases.filter(hphrase => hphrase.RiskType.Type !== this.RiskTypeEnum.Physical);
  }

  get hasHealthRiskHphrases(): boolean {
    return this.healthRiskHphrases && this.healthRiskHphrases.length > 0;
  }

  get hasEnvironmentalRiskHphrases(): boolean {
    return (this.environmentalRiskHphrases && this.environmentalRiskHphrases.length > 0);
  }

  get riskScore(): number {
    const scores = this.hPhrases.map(hphrase => hphrase.DangerClass.RiskScore);
    const maxScore = scores && scores.length > 0 ? Math.max(...scores) : 0;
    const riskScore = maxScore * this.selectedTechnicalMeasureValue;

    return riskScore;
  }

  get riskClassForValue() {
    return {
      'risk-score-green': this.riskScore < 2,
      'risk-score-yellow': this.riskScore >= 2 && this.riskScore < 5,
      'risk-score-orange': this.riskScore >= 5 && this.riskScore < 7,
      'risk-score-darkorange': this.riskScore >= 7 && this.riskScore < 10,
      'risk-score-red': this.riskScore >= 10
    };
  }

  constructor(
    private riskAnalysisService: RiskAnalysisService,
    private notificationService: TranslatedNotificationService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.riskAnalysisService
      .getAvailableTechnicalMeasures(this.language)
      .subscribe(
        availableMeasures => (this.technicalMeasures = availableMeasures),
        () => this.notificationService.showDefaultMsgError()
      );
    this.prepareForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSubmitted && this.form) {
      this.form.markAllAsTouched();
      this.formSubmitAttempt = true;
    }
  }

  updateScore(selectedMeasure: TechnicalMeasureDto) {
    this.checkSelectedMeasure(selectedMeasure);
    this.form.markAllAsTouched();
    this.formSubmitAttempt = true;

    this.riskScoreChange.emit({
      technicalMeasureId: selectedMeasure !== undefined ? selectedMeasure.Id : undefined,
      riskScore: this.riskScore
    });
  }

  private prepareForm() {
    this.form = this.formBuilder.group({
      TechnicalMeasure: [null, Validators.required]
    });
    if (this.selectedTechnicalMeasure) {
      this.form.get('TechnicalMeasure').patchValue(this.selectedTechnicalMeasureValue);
    }
  }

  private checkSelectedMeasure(selectedMeasure: TechnicalMeasureDto) {
    if (selectedMeasure === undefined) {
      this.form.get('TechnicalMeasure').patchValue(null);
    } else {
      this.selectedTechnicalMeasure = selectedMeasure;
    }
  }
}
