import { Component, OnInit, ViewChild } from '@angular/core';

import { GridComponent, GridDataResult, DataStateChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';

import { LanguageCode, SevesoNominativeDto } from 'app/shared/model';
import { ActionType } from '../shared/helper';
import { GridStateHelper } from '../shared/grid-state';
import { finalize } from 'rxjs/operators';

import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SharedService } from 'app/shared/shared.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { RequestService } from 'app/requests/request.service';

@Component({
  selector: 'app-manage-seveso-nominatives',
  templateUrl: './manage-seveso-nominatives.component.html',
  styleUrls: ['./manage-seveso-nominatives.component.scss']
})

export class ManageSevesoNominativesComponent implements OnInit {
   @ViewChild(GridComponent, {static: false}) grid: GridComponent;

  gridData: GridDataResult;
  sevesoNominativeDialogOpen = false;
  actionType: ActionType;
  items: SevesoNominativeDto[];
  editedSevesoNominative: SevesoNominativeDto;
  expandedRows: number[] = [];
  state: State;
  ActionType = ActionType;

  private languages = {
    'en': LanguageCode.en,
    'nl': LanguageCode.nl,
    'it': LanguageCode.it,
    'fr': LanguageCode.fr,
    'de': LanguageCode.de,
    'es': LanguageCode.es
  };

  constructor(private requestService: RequestService,
    private gridState: GridStateHelper,
    private notificationService: TranslatedNotificationService,
    private sharedService: SharedService,
    private spinner: SpinnerService) {
      this.state = this.gridState.getBasicState();
     }

  public ngOnInit() {
    this.fetchData();
  }

  public dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  private fetchData() {
    this.spinner.show('spinner');
    this.requestService.getSevesoNominatives()
    .pipe(finalize(() => this.spinner.hide('spinner')))
    .subscribe(
      (sevesoNominative) => {
        this.items = sevesoNominative;
        this.gridData = process(this.items, this.state);
      },
      () => this.notificationService.showDefaultMsgError()
    );
  }

  public isOpened(id: number): boolean {
    return this.expandedRows.findIndex(rowId => rowId === id) > -1;
  }

  public toggleExpandedClass = (context: RowClassArgs) => {
    const isExpanded = this.expandedRows.indexOf(context.index) !== -1;

    return {
      expanded: isExpanded
    };
  }

  public detailExpand({ dataItem, index }) {
    this.expandedRows = [...this.expandedRows, index];
    setTimeout(() => {
      const elements = document.getElementsByClassName('k-detail-cell');
      for (let i = 0; i < elements.length; ++i) {
        elements.item(i).setAttribute('colspan', '7');
      }
    }, 0);
  }

  public detailCollapse({ dataItem, index }) {
    this.expandedRows = this.expandedRows.filter(x => x !== index);
  }

  openSevesoNominativeDialog(actionType: ActionType) {
    this.actionType = actionType;
    this.sevesoNominativeDialogOpen = true;
  }

  editSevesoNominative(sevesoNominative: SevesoNominativeDto) {
    this.editedSevesoNominative = sevesoNominative;
    this.openSevesoNominativeDialog(ActionType.EDIT);
  }

  closeSevesoNominativeDialog() {
    this.sevesoNominativeDialogOpen = false;
    this.fetchData();
  }

  replaceEmptyTextWithDash(text: string): string {
    return !!text ? text : '-';
  }
}
