import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, from } from 'rxjs';

import { OktaAuthService } from '@okta/okta-angular';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly oktaAuth: OktaAuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next));
  }

  private async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {

    const idToken = await this.oktaAuth.getIdToken();
    const accessToken = await this.oktaAuth.getAccessToken();
    const customClaimsToken = localStorage.getItem('myToken');

    const headers: any = {
      Authorization: 'Bearer ' + accessToken + ' Token ' + idToken
    };
    if (customClaimsToken) {
      headers.CustomClaimsToken = customClaimsToken;
    }

    request = request.clone({
      setHeaders: headers
    });
    return next.handle(request).toPromise();
  }
}
