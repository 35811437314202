import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { DataService } from '../data.service';
import { ReportDto, ArticleSevesoHazardCategoryDto, SdsReportDto, EsdsReportDto, RiskAnalysisReportDto, EntityReportDto } from './reports.model';

@Injectable()
export class ReportsService {

  constructor(private dataService: DataService) { }

  downloadVlaremReport(dto: ReportDto): Observable<any> {
    return this.dataService.downloadFile('Printing/GetVlaremReport', dto);
  }

  downloadSevesoReport(dto: ReportDto): Observable<any> {
    return this.dataService.downloadFile('Printing/GetSevesoReport', dto);
  }

  getArticlesSevesoHazardCategories(): Observable<ArticleSevesoHazardCategoryDto[]> {
    return this.dataService.get('Article/GetArticlesSevesoHazardCategories', {});
  }

  putArticlesSevesoHazardCategories(articlesSevesoHazardCategoriesDtos: ArticleSevesoHazardCategoryDto[]): Observable<any> {
    return this.dataService.put('Article/PutArticlesSevesoHazardCategories', articlesSevesoHazardCategoriesDtos);
  }

  getArticlesforSdsReport(): Observable<SdsReportDto[]> {
    return this.dataService.get('Report/GetArticlesForSdsReport', {});
  }

  getApprovalsForEsdsReport(entityId: string): Observable<EsdsReportDto[]> {
    return this.dataService.get('Report/GetApprovalsForEsdsReport', { entityId: entityId });
  }

  getApprovalsForRiskAnalysisReport(entityId: string): Observable<RiskAnalysisReportDto[]> {
    return this.dataService.get('Report/GetApprovalsForRiskAnalysisReport', { entityId: entityId });
  }

  downloadSdsReport(): Observable<any> {
    return this.dataService.downloadFile('Printing/GetSdsReport', {} );
  }

  downloadRiskAnalysisReport(entity: EntityReportDto): Observable<any> {
    return this.dataService.downloadFile('Printing/GetRiskAnalysisReport', entity );
  }
}
