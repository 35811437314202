import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { process, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';

import { RequestService } from '../request.service';
import { SharedService } from '../../shared/shared.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { TranslatedNotificationService } from '../../shared/translation/translated-notification.service';

import { RequestGridDto, ApprovalType, ApprovalStatusEnum, ApprovalStatus } from './requests-view.model';
import { LanguageCode } from '../../shared/model';

@Component({
  selector: 'app-requests-view',
  templateUrl: './requests-view.component.html',
  styleUrls: ['./requests-view.component.scss', '../../shared/styles/top-view.scss']
})
export class RequestsViewComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent, {static: false}) grid: GridComponent;

  approvalStatusList: ApprovalStatus[];
  gridData: GridDataResult;
  ApprovalTypeEnum = ApprovalType;
  ApprovalStatusEnum = ApprovalStatusEnum;
  StatusListTranslations: any[];
  items: RequestGridDto[];
  state: State = {
    skip: 0,
    take: 10,

    filter: {
      logic: 'and',
      filters: []
    }
  };

  private languages = {
    'en': LanguageCode.en,
    'nl': LanguageCode.nl,
    'it': LanguageCode.it,
    'fr': LanguageCode.fr,
    'de': LanguageCode.de,
    'es': LanguageCode.es
  };
  private entityId: string;
  private userEmail: string;
  private entityIdSubscription$: Subscription = new Subscription();

  constructor(
    private requestService: RequestService,
    private notificationService: TranslatedNotificationService,
    private sharedService: SharedService,
    private spinner: SpinnerService) {
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  getFormattedDate(time: any): Date {
    return new Date(time + 'Z');
  }

  ngOnInit(): void {
    this.userEmail = this.sharedService.loggedInUser.Email;
    this.entityIdSubscription$ = this.sharedService.getCurrentEntityId()
      .subscribe(id => {
        this.entityId = id;
        this.fetchData();
      });
  }

  ngOnDestroy(): void {
    this.entityIdSubscription$.unsubscribe();
  }

  private fetchData() {
    this.spinner.show('spinner');
    forkJoin(
      [this.requestService.getApprovalStatuses(),
      this.requestService.fetchRequestItems(this.entityId, this.userEmail)])
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe(([types, data]) => {
        this.approvalStatusList = this.mapApprovals(types);
        this.items = this.mapRequestItems(data);
        this.gridData = process(this.items, this.state);
      },
        () => {
          this.notificationService.showMsgError(
            'error.create_approval_status_list_or_data',
            'Error while creating approval status list or data');
        });
  }

  private mapApprovals(types: ApprovalStatus[]): ApprovalStatus[] {
    return types.map(type => {
      return {
        Id: type.Id,
        EnumValue: type.EnumValue,
        StatusTranslations: this.getStatusTranslations(type),
        Translation: this.getTranslation(type)
      };
    });
  }

  private getStatusTranslations(type: ApprovalStatus): any[] {
    return [type.StatusTranslations.find(l => l.Language === this.languages[this.sharedService.currentLanguage.LanguageCode])];
  }

  private getTranslation(type: ApprovalStatus): string {
    return type.StatusTranslations.find(l => l.Language === this.languages[this.sharedService.currentLanguage.LanguageCode]).Text;
  }

  private mapRequestItems(data: RequestGridDto[]): RequestGridDto[] {
    return data.map(d => {
      return {
        ...d,
        MedicApprovalTranslation: this.approvalStatusList.find(e => e.EnumValue === d.MedicApproval).Translation,
        EnvironmentalApprovalTranslation: this.approvalStatusList.find(e => e.EnumValue === d.EnvironmentalApproval).Translation,
        SafetyApprovalTranslation: this.approvalStatusList.find(e => e.EnumValue === d.SafetyApproval).Translation,
        FinalApprovalTranslation: this.approvalStatusList.find(e => e.EnumValue === d.FinalApproval).Translation
      };
    });
  }
}
