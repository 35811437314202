import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { LocationGridDto, SiteDto } from 'app/shared/model';

import { DataManagementService } from 'app/data-management/data-management.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';

@Component({
  selector: 'app-location-modal',
  templateUrl: './location-modal.component.html',
  styleUrls: ['./location-modal.component.scss']
})
export class LocationModalComponent implements OnInit {
  @Input() sites: SiteDto[];
  @Input() locations: LocationGridDto[];
  @Input() selectedLocation: LocationGridDto;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();

  locationForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dataManagementService: DataManagementService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService) { }

  public ngOnInit(): void {
    this.createForm();
  }

  checkFormValid(): boolean {
    if (this.locationForm.invalid) {
      this.notificationService.showMsgError('error.data_required', 'Required data must be filled in');
      return false;
    }

    const name = this.locationForm.get('Name').value;
    const site = this.locationForm.get('Site').value;

    const existingLocation = this.locations.find(x => x.Name.toLowerCase().trim() === name.toLowerCase().trim() && x.SiteId === site.Id);

    if (existingLocation) {
      if (!this.selectedLocation || (this.selectedLocation && existingLocation.Id !== this.selectedLocation.Id)) {
        this.notificationService.showMsgError('error.location_name_used', 'Location with this name already exist');
        return false;
      }
    }

    return true;
  }

  submitForm(): void {
    if (this.checkFormValid()) {
      this.spinner.show('spinner');

      const location = this.getLocationFormData();
      const requestedMethod = this.getRequestedMethod(location);

      requestedMethod
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe(updatedLocation => {
        updatedLocation.SiteName = location.SiteName;
        this.dialogClosed.emit(updatedLocation);
        this.notificationService.showMsgSuccess('success.location_updated', 'Locations modified');
      }, () => {
        this.notificationService.showMsgError('error.location_update_error', 'Error occur during updating locations');
      });
    }
  }

  closeDialog(): void {
    this.dialogClosed.emit(null);
  }

  private getRequestedMethod(location: LocationGridDto): any {
     return this.selectedLocation ?
       this.dataManagementService.updateLocation(location) : this.dataManagementService.addLocation(location);
  }

  private createForm(): void {
    this.locationForm = this.formBuilder.group({
      Name: [this.selectedLocation === null ? null : this.selectedLocation.Name, Validators.required],
      Site: [this.selectedLocation === null ? null : this.sites.find(x => x.Id === this.selectedLocation.SiteId), Validators.required]
    });
  }

  private getLocationFormData(): LocationGridDto {
    return <LocationGridDto> {
      Id: this.selectedLocation ? this.selectedLocation.Id : 0,
      Name: this.locationForm.get('Name').value,
      SiteId: this.locationForm.get('Site').value.Id,
      SiteName: this.locationForm.get('Site').value.Name
    };
  }
}
