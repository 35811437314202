import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { RequestService } from '../request.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { SharedService } from '../../shared/shared.service';
import { TranslatedNotificationService } from '../../shared/translation/translated-notification.service';

import { UserCompleteDto, Specialization, UserRole } from './../../shared/model';
import { LanguageCode } from '../../shared/model';
import { ApprovalStatus, ApprovalStatusEnum } from '../requests-view/requests-view.model';
import { RequestDetailsDto } from './request-view.model';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';
import { CanComponentDeactivate } from '../../can-deactivate.guard';

@Component({
  selector: 'app-request-view',
  templateUrl: './request-view.component.html',
  styleUrls: ['./request-view.component.scss', '../../shared/styles/top-view.scss']
})
export class RequestViewComponent implements OnInit, CanComponentDeactivate {
  requestData: RequestDetailsDto;
  approvalStatusList: ApprovalStatus[];
  requestId: number;
  medicStatus: ApprovalStatus;
  environmentalStatus: ApprovalStatus;
  safetyStatus: ApprovalStatus;
  finalStatus: ApprovalStatus;
  enableEditingDetails: boolean;
  user: UserCompleteDto;
  userSpecializations: number[];
  createdOn: Date;
  hideCorrectDataButton: boolean;

  private articleFormDirty: boolean;
  private producerFormDirty: boolean;
  private requestFormDirty: boolean;
  private approvalsFormDirty: boolean;

  ApprovalStatus = ApprovalStatusEnum;

  private languages = {
    'en': LanguageCode.en,
    'nl': LanguageCode.nl,
    'it': LanguageCode.it,
    'fr': LanguageCode.fr,
    'de': LanguageCode.de,
    'es': LanguageCode.es
  };

  constructor(
    private route: ActivatedRoute,
    private requestService: RequestService,
    private spinner: SpinnerService,
    private notification: TranslatedNotificationService,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.spinner.show('spinner');
    this.requestId = parseInt(this.route.snapshot.params.RequestId, 10);

    if (isNaN(this.requestId)) {
      this.router.navigate(['/']);
    }

    forkJoin([
      this.requestService.getApprovalStatuses(),
      this.requestService.getRequestDetails(this.requestId, this.sharedService.currentLanguage.LanguageCode),
      this.sharedService.getUserCompleteDetails()
    ]).pipe(
      finalize(() => this.spinner.hide('spinner'))
    ).subscribe(
      ([types, data, user]) => {
        this.approvalStatusList = this.mapApprovals(types);
        data.MedicApproval = this.approvalStatusList.find(e => e.EnumValue === data.MedicApprovalEnum);
        this.medicStatus = data.MedicApproval;

        data.EnvironmentalApproval = this.approvalStatusList.find(e => e.EnumValue === data.EnvironmentalApprovalEnum);
        this.environmentalStatus = data.EnvironmentalApproval;

        data.SafetyApproval = this.approvalStatusList.find(e => e.EnumValue === data.SafetyApprovalEnum);
        this.safetyStatus = data.SafetyApproval;

        data.FinalApproval = this.approvalStatusList.find(e => e.EnumValue === data.FinalApprovalEnum);
        this.finalStatus = data.FinalApproval;

        this.user = user;
        this.userSpecializations = user.Specializations;

        this.requestData = { ...data };
        this.createdOn = new Date(this.requestData.CreatedOn + 'Z');
        this.canEditDetails(!data.IsMarkedAsCorrectBySpecialist);
      },
      () => this.notification.showDefaultMsgError()
    );
  }

  onArticleFormStateChanged(dirty: boolean) {
    this.articleFormDirty = dirty;
  }

  onProducerFormStateChanged(dirty: boolean) {
    this.producerFormDirty = dirty;
  }

  onFormStatusChanged(dirty: boolean) {
    this.requestFormDirty = dirty;
  }

  onApprovalStatusChange(dirty: boolean) {
    this.approvalsFormDirty = dirty;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.requestFormDirty || this.articleFormDirty || this.producerFormDirty || this.approvalsFormDirty) {
      return this.confirmationDialogService.showDialog();
    }

    return true;
  }

  get riskAnalysisEnabled(): boolean {
    const approval = this.safetyStatus;

    return approval && approval.EnumValue !== this.ApprovalStatus.NotApprovedYet;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  isUndefined(element: Object) {
    if (element === null) {
      return '-';
    } else {
      return element;
    }
  }

  goToRiskAnalysis() {
    this.router.navigate(['/risk-analysis', this.requestId]);
  }

  updateMedicStatus(event) {
    this.medicStatus = event;
    this.canEditDetails(true);
    this.scroll(document.getElementById('details'));
  }

  updateEnvironmentalStatus(event) {
    this.environmentalStatus = event;
    this.scroll(document.getElementById('details'));
  }

  updateSafetyStatus(event) {
    this.safetyStatus = event;
    this.scroll(document.getElementById('details'));
  }

  updateFinalStatus(event) {
    this.finalStatus = event;
    this.hideCorrectDataButton = true;
    this.updateRequest();
    this.scroll(document.getElementById('details'));
  }

  canEditDetails(event) {
    
    this.enableEditingDetails = event && ( this.user.UserRole > UserRole.Requester && ((this.requestData.MedicApproval.EnumValue !== ApprovalStatusEnum.NotApprovedYet
      || this.medicStatus.EnumValue !== ApprovalStatusEnum.NotApprovedYet)
      && this.user.Specializations.indexOf(Specialization.Environment) !== -1)
      && this.requestData.FinalApproval.EnumValue === ApprovalStatusEnum.NotApprovedYet);
  }

  toggleEditingDetails() {
    this.enableEditingDetails = !this.enableEditingDetails;

    if (!this.enableEditingDetails) {
      this.requestFormDirty = false;
      this.articleFormDirty = false;
      this.producerFormDirty = false;
      this.approvalsFormDirty = false;
    }
  }

  onRequestFormDataConfirmed() {
    this.updateRequest();
  }

  private updateRequest() {
    this.spinner.show('spinner');
    this.requestService.getRequestDetails(this.requestId, this.sharedService.currentLanguage.LanguageCode).pipe(
      finalize(() => this.spinner.hide('spinner'))
    ).subscribe(
      data => {
        this.requestData = { ...data };
        this.enableEditingDetails = false;
        this.requestData.IsMarkedAsCorrectBySpecialist = true;
      },
      () => this.notification.showDefaultMsgError()
    );
  }

  private mapApprovals(types: ApprovalStatus[]): ApprovalStatus[] {
    return types.map(type => {
      return {
        Id: type.Id,
        EnumValue: type.EnumValue,
        StatusTranslations: this.getStatusTranslations(type),
        Translation: this.getTranslation(type)
      };
    });
  }

  private getStatusTranslations(type: ApprovalStatus): any[] {
    return [type.StatusTranslations.find(l => l.Language === this.languages[this.sharedService.currentLanguage.LanguageCode])];
  }

  private getTranslation(type: ApprovalStatus): string {
    return type.StatusTranslations.find(l => l.Language === this.languages[this.sharedService.currentLanguage.LanguageCode]).Text;
  }
}
