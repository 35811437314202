import { environmentBase } from './environment-base';

const environmentDevelopment = {
  production: false,
  mobile: false,
  hmr: false,
  issuer: 'https://thermal.oktapreview.com/oauth2/aus4nrrrgvKKQc8Nv0x7',
  clientId: '0oa4s3abdmf80XDMn0x7'
};

export const environment = { ...environmentDevelopment, ...environmentBase };
