import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

@Injectable({providedIn: 'root'})
export class CatalogueFiltersFormHelper {
    private _filtersForm: FormGroup;

    public get filtersForm(): FormGroup { return this._filtersForm; }

    constructor(private formBuilder: FormBuilder) {
        this._filtersForm = this.createFiltersForm();
    }

    private createFiltersForm(): FormGroup {
        const form = this.formBuilder.group({
            ArticleName: [''],
            Site: [''],
            Location: [{value: '', disabled: true}],
            Characteristics: [''],
            Producer: ['']
        });

        return form;
    }

    public get filterDescriptor(): CompositeFilterDescriptor {
        const filters: FilterDescriptor[] = [];

        const articleName = this._filtersForm.get('ArticleName').value;
        if (articleName && articleName !== '') {
            filters.push({field: 'Article.Name', value: articleName, operator: 'contains'});
        }

        const site = this._filtersForm.get('Site').value;
        if (site && site !== '') {
            filters.push({field: 'Site', value: site.Name, operator: 'eq'});
        }

        const location = this._filtersForm.get('Location').value;
        if (location && location !== '') {
            filters.push({field: 'Location', value: location.Name, operator: 'eq'});
        }

        const characteristics = this._filtersForm.get('Characteristics').value;
        if (characteristics && characteristics !== '') {
            filters.push({field: 'Subdivision', value: characteristics, operator: 'contains'});
        }

        const producer = this._filtersForm.get('Producer').value;
        if (producer && producer !== '') {
            filters.push({field: 'Article.ProducerName', value: producer.Name, operator: 'eq'});
        }

        return {
            logic: 'and',
            filters: filters
        };
    }

    public resetFilters() {
        this._filtersForm.setValue({
            ArticleName: '',
            Site: '',
            Location: '',
            Characteristics: '',
            Producer: ''
        });
        this._filtersForm.get('Location').disable();
    }
}
