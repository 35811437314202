import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { GhsGridDto } from '../manage-ghs.model';

import { DataManagementService } from '../../data-management.service';
import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { SharedService } from '../../../shared/shared.service';
import { GhsEnum } from '../../../shared/model';

@Component({
  selector: 'app-ghs-modal',
  templateUrl: './ghs-modal.component.html',
  styleUrls: ['./ghs-modal.component.scss']
})
export class GhsModalComponent implements OnInit {

  @Input() editedItem: GhsGridDto;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @Output() ghsUpdated: EventEmitter<any> = new EventEmitter();

  form: FormGroup;
  GhsEnum = GhsEnum;

  constructor(
    private formBuilder: FormBuilder,
    private dataManagementService: DataManagementService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService) { }

  ngOnInit() {
    this.createForm();
  }

  get ghsCode(): string {
    return GhsEnum[this.editedItem.Code];
  }

  closeDialog() {
    this.dialogClosed.emit();
  }

  submitForm() {
    this.spinner.show('spinner');

      const ghsDto = <GhsGridDto> {
        Id: this.editedItem.Id,
        Code: this.editedItem.Code,
        Description: this.form.get('Description').value
      };

      this.dataManagementService.updateGhs(ghsDto)
      .pipe(
        finalize(() => this.spinner.hide('spinner'))
      )
      .subscribe(updatedItem => {
        this.ghsUpdated.emit(updatedItem);
        this.notificationService.showMsgSuccess('success.ghs_updated', 'GHS entry updated');
      },
      () => {
        this.notificationService.showMsgError('error.ghs_update_error', 'Sorry, we couldn\'t update GHS entry. Try again later.');
      });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      Description: [this.editedItem === null ? null : this.editedItem.Description, Validators.required]
    });
  }
}
