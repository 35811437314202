import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormGroup, Validators, AbstractControl } from '@angular/forms';

import { forkJoin, Subscription } from 'rxjs';

import { TranslatedNotificationService } from '../../../../shared/translation/translated-notification.service';
import { SharedService } from '../../../../shared/shared.service';
import { FileManagerService } from '../../../../shared/file-manager/file-manager.service';
import { ManageArticleFormManagerService } from '../manage-article-form-manager.service';

import {
  SevesoNominativeDto, FlashPointSignDto, FlashPointSign, StateOfMatterDto, ProducerBase, Family, LimitValueUnit, LimitValueUnitDto, EntityDto
} from '../../../../shared/model';
import { EditArticleDetailsDto } from '../../manage-articles.model';
import { FileToDownload, FileTypeEnum, DownloadedFile } from '../../../../shared/file-manager/file-manager.model';
import { NavigationService } from 'app/navigation/shared/navigation.service';

@Component({
  selector: 'app-manage-article-details',
  templateUrl: './manage-article-details.component.html',
  styleUrls: ['./manage-article-details.component.scss']
})
export class ManageArticleDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() details: EditArticleDetailsDto;
  @Input() allNominatives: SevesoNominativeDto[];
  @Input() statesOfMatter: StateOfMatterDto[];
  @Input() producers: ProducerBase[];
  @Input() families: Family[];
  @Input() editOperation: boolean;
  @Input() downloadFileEnabled: boolean;

  initialized: boolean;
  articleForm: FormGroup;
  flashPointSigns: FlashPointSignDto[];
  flashPointSignEnum = FlashPointSign;
  limitValueUnits: LimitValueUnitDto[];
  limitValueUnitEnum = LimitValueUnit;
  originalSdsPath: string;
  sdsFile: Blob | File;
  sdsFileToAdd: Blob | File;
  formSubmitAttempt: boolean;
  changeSdsFile: boolean;
  collectedData: EditArticleDetailsDto;
  forbidden: boolean;

  private downloadSds: Subscription;
  public allEntities: Array<EntityDto> = [];

  constructor(
    private notificationService: TranslatedNotificationService,
    private formManagerService: ManageArticleFormManagerService,
    private sharedService: SharedService,
    private fileManagerService: FileManagerService,
    private navigationService: NavigationService
  ) { }

  public ngOnInit() : void {
    this.loadEntities();
    this.fetchData(); 
  }

  private loadEntities() : void {    
    forkJoin([
      this.navigationService.getEntities(),
      this.sharedService.getUserCompleteDetails()
    ]).subscribe(
      ([entities, user]) => {
        this.allEntities = entities.filter(x => user.RelatedEntities.map(userentity => userentity.EntityId).indexOf(x.Id) > -1);        
      },
      () => this.notificationService.showDefaultMsgError()
    );
  }

  public ngOnChanges(changes: SimpleChanges) : void {
    if (this.initialized && changes.details) {
      this.details = changes.details.currentValue;
      this.sdsFile = this.sdsFileToAdd;
      this.revertArticleDetails(this.details);
    }
  }

  public ngOnDestroy() : void {
    this.unsubscribeSdsDownload();
  }

  private scroll(el: HTMLElement) : void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  public sdsFileSelected(file: File) : void {
    this.sdsFileToAdd = file;
    this.articleForm.get('SdsFile').setValue(file ? file.name : null);
    this.articleForm.get('SdsDate').setValue(null);
  }

  public clearForm() : void {
    this.articleForm.reset();
    this.formManagerService.fillBasicData(this.articleForm);
    this.articleForm.markAsPristine();
    this.formSubmitAttempt = false;
  }

  public submitForm(): FormData {
    this.articleForm.get('SdsFile').markAsTouched(); // this is needed, otherwise the control is always untouched
    this.articleForm.get('ForbiddenNote').markAsTouched();
    this.articleForm.get('ForbiddenEntities').markAsTouched();

    if (this.isFormValid()) {
      this.changeSdsFile = false;
      const formData = new FormData();

      if (this.sdsFileToAdd) {
        formData.append('sdsFile', this.sdsFileToAdd, this.sdsFileToAdd['name']);
      }

      this.collectedData = this.formManagerService.collectData(this.articleForm, this.details, this.assignSdsFileName());

      formData.append('editArticleDetailsDto', JSON.stringify(this.collectedData));

      return formData;
    }

    this.scrollToRequired();

    return null;
  }

  public revertArticleDetails(details: EditArticleDetailsDto) : void {
    this.discardSdsFile();
    this.clearForm();
    this.fillForm(details);
    this.details.SdsPath = details.SdsPath;
    this.details.SdsDate = details.SdsDate;
  }

  public downloadSdsFile() : void {
    const sdsFilePath = this.details.SdsPath;
    const sdsFile: FileToDownload = {
      FileName: sdsFilePath,
      FileType: FileTypeEnum.SdsFilePath
    };

    if (sdsFilePath) {
      this.unsubscribeSdsDownload();

      this.downloadSds = this.fileManagerService.fetchFile(sdsFile).subscribe(
        (data: DownloadedFile) => {
          if (!this.sdsFile) {
            this.sdsFile = data.File;
          }

          this.fileManagerService.downloadFile(this.sdsFile, this.details?.SdsDisplayName);
        },
        () => {
          this.notificationService.showMsgError(
            'error.cannot_load_sds_file',
            'Cannot find the SDS file for the article. Please upload it again.'
            );
        }
      );
    }
  }

  public toggleSdSFile() : void {
    this.articleForm.get('SdsFile').patchValue(null);
    this.articleForm.get('SdsDate').patchValue(new Date);
    this.setSdsValidators(true);
    this.changeSdsFile = true;
  }

  public discardSdsFile() : void {
    const sdsFileInput = this.articleForm.get('SdsFile');
    const sdsDateInput = this.articleForm.get('SdsDate');

    sdsFileInput.reset();
    sdsFileInput.patchValue(this.details.SdsPath);

    sdsDateInput.reset();
    sdsDateInput.patchValue(new Date(this.details.SdsDate));

    this.setSdsValidators(false);
    this.changeSdsFile = false;
    this.sdsFileToAdd = null;
  }

  private fillForm(details: EditArticleDetailsDto) : void {
    this.formManagerService.fillForm(this.articleForm, details);    
  }

  private fetchData() : void {
    this.mapFlashPointSign();
    this.mapFinalValueUnit();
    this.articleForm = this.formManagerService.createForm();
    this.fillForm(this.details);

    this.setValidators();
    this.initialized = true;    
  }

  private isFormValid(): boolean {
    this.formSubmitAttempt = true;

    return this.articleForm.valid;
  }

  private scrollToRequired() : void {
    const firstElementWithError = document.querySelector('.ng-invalid:not(form)').parentElement;
    this.scroll(firstElementWithError);
  }

  private mapFlashPointSign() : void {
    this.flashPointSigns = this.sharedService.mapFlashPointSign();
  }

  private mapFinalValueUnit() : void {
    this.limitValueUnits = this.sharedService.mapFinalValueUnit();
  }

  private setValidators() : void {
    this.setSdsValidators(!this.details);
    this.setLimitValueValidators();
    this.setFlashPointValueValidators();
    this.setForbiddenValidator();
  }

  private setSdsValidators(setValidators: boolean) : void {
    const sdsFileInput = this.articleForm.get('SdsFile');
    const sdsDateInput = this.articleForm.get('SdsDate');

    this.updateValidator(sdsFileInput, setValidators);
    this.updateValidator(sdsDateInput, setValidators);
  }

  private updateValidator(input: AbstractControl, setValidator: boolean) : void {
    setValidator ? input.setValidators([Validators.required]) : input.clearValidators();
    input.updateValueAndValidity();
  }

  private setLimitValueValidators() : void {
    const limitValue = this.articleForm.get('LimitValue');
    const limitValueUnit = this.articleForm.get('LimitValueUnit');

    this.sharedService.setConditionalValidation(limitValue, limitValueUnit);
    this.sharedService.setPositiveNumberValidation(limitValue, limitValueUnit);
  }

  private setFlashPointValueValidators() : void {
    const flashPointValue = this.articleForm.get('FlashPointValue');
    const flashPointSign = this.articleForm.get('FlashPointSign');

    this.sharedService.setConditionalValidation(flashPointValue, flashPointSign);
    this.sharedService.setConditionalValidation(flashPointSign, flashPointValue);
  }

  private setForbiddenValidator() : void {
    const forbiddenInput = this.articleForm.get('Forbidden');
    const forbiddenNote = this.articleForm.get('ForbiddenNote');
    const forbiddenEntities = this.articleForm.get('ForbiddenEntities');

    this.forbidden = JSON.parse(forbiddenInput.value);
    this.updateValidator(forbiddenNote, this.forbidden);
    this.updateValidator(forbiddenEntities, this.forbidden);

    forbiddenInput.valueChanges.subscribe(
      () => {
        this.forbidden = JSON.parse(forbiddenInput.value);
        if(this.forbidden) {
           forbiddenNote.setValidators([Validators.required])
           forbiddenEntities.setValidators([Validators.required])
         } else {
          forbiddenNote.clearValidators();
          forbiddenEntities.clearValidators();
         }
        forbiddenNote.updateValueAndValidity({ emitEvent: false });
        forbiddenEntities.updateValueAndValidity({ emitEvent: false });
      }
    );
  }

  private assignSdsFileName(): string {
    return this.sdsFileToAdd ? this.sdsFileToAdd['name'].toString() : this.details.SdsPath;
  }

  private unsubscribeSdsDownload() : void {
    if (this.downloadSds) {
      this.downloadSds.unsubscribe();
    }
  }
}
