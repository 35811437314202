import { Injectable } from '@angular/core';

import { Observable, throwError, of } from 'rxjs';

import { saveAs as importedSaveAs } from 'file-saver';

import { DataService } from '../../data.service';
import { FileToDownload, DownloadedFile } from './file-manager.model';
import { TranslatedNotificationService } from '../translation/translated-notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
    'providedIn': 'root'
})
export class FileManagerService {
    constructor(
        private dataService: DataService,
        private notificationService: TranslatedNotificationService) { }

    private readonly _bytesPerKb = 1024;

    private fetchFileFromServer(file: FileToDownload): Observable<any> {
        return this.dataService.downloadFile(`Files/GetFile`, file);
    }

    fetchFile(file: FileToDownload): Observable<DownloadedFile> {
        if (file.FileName) {
            return this.fetchFileFromServer(file)
                .pipe(
                    switchMap(
                        (blob: Blob) => {
                            blob['name'] = file.FileName;

                            return of({
                                File: blob,
                                Size: (blob.size / this._bytesPerKb).toFixed()
                            });
                        }),
                    catchError(
                        (err: HttpErrorResponse) => {
                            const error = {
                                ...err,
                                error: {
                                    'Data': {'error.cannot_fetch_file': 'Cannot load the file from server.'}
                                }};

                            return throwError(error);
                        }
                    )
                );
        }

        return of(null);
    }

    downloadFile(file: Blob | File, fileDisplayName?: string): void {
        const fileName = fileDisplayName || file['name'];
        if (file && fileName) {
            importedSaveAs(file, fileName);
        } else {
            this.notificationService.showMsgError(
                'error.cannot_save_file',
                'Cannot save the file. Either file object or its name is invalid or null');
        }
    }
}
