import { Component, OnInit, ViewChild } from '@angular/core';

import { finalize } from 'rxjs/operators';

import { GridComponent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

import { ActionType } from '../shared/helper';
import { GridStateHelper } from '../shared/grid-state';

import { DataManagementService } from 'app/data-management/data-management.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';

import { UserRole, UserCompleteDto, Specialization } from 'app/shared/model';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit {
  @ViewChild(GridComponent, { static: false }) grid: GridComponent;

  UserRole = UserRole;
  Specialization = Specialization;
  userDialogOpen = false;
  actionType: ActionType;
  gridData: GridDataResult;
  editedUser: UserCompleteDto;
  items: UserCompleteDto[];
  state: State;
  deleteUserOpened = false;
  userIdToRemove: string;

  constructor(private dataManagementService: DataManagementService,
    private notificationService: TranslatedNotificationService,
    private gridState: GridStateHelper,
    private spinner: SpinnerService) {
    this.state = this.gridState.getBasicState();
  }

  ngOnInit() {
    this.fetchData();
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  openUserDialog(actionType: ActionType) {
    this.actionType = actionType;
    this.userDialogOpen = true;
  }

  editUser(user: UserCompleteDto, actionType: ActionType) {
    this.editedUser = user;
    this.openUserDialog(actionType);
  }

  openDeleteUserModal(userId: string) {
    this.userIdToRemove = userId;
    this.deleteUserOpened = true;
  }

  closeDeleteUserModal() {
    this.deleteUserOpened = false;
  }

  deleteUser() {
    this.spinner.show('spinner');

    this.dataManagementService.deleteUser(this.userIdToRemove)
    .pipe(
      finalize(() => {
        this.spinner.hide('spinner');
        this.closeDeleteUserModal();
      }))
    .subscribe(
      () => {
        this.removeUserFromItems();
        this.gridData = process(this.items, this.state);
      },
      () => {
        this.notificationService.showMsgError('error.delete_user', 'Error occurred while deleting user');
      });
  }

  closeUserDialog() {
    this.userDialogOpen = false;
    this.fetchData();
  }

  isMedicalSpecialist(user: UserCompleteDto): boolean {
    return user.Specializations.indexOf(1) > -1;
  }

  isEnvironmentSpecialist(user: UserCompleteDto): boolean {
    return user.Specializations.indexOf(2) > -1;
  }

  isSafetySpecialist(user: UserCompleteDto): boolean {
    return user.Specializations.indexOf(3) > -1;
  }

  private fetchData() {
    this.dataManagementService.getUsers()
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe((users) => {
        this.items = users;
        this.gridData = process(this.items, this.state);
      });
  }

  private removeUserFromItems(): void {
    const index = this.items.findIndex(u => u.UserId === this.userIdToRemove);

    if (index !== -1) {
      this.items.splice(index, 1);
    }
  }
}
