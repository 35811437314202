import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { DataService } from '../data.service';
import { LabelDto, UserDataSheetDto } from './print.model';

@Injectable({
  providedIn: 'root'
})
export class PrintingModuleService {

  constructor(private dataService: DataService) { }

  downloadLabels(dto: LabelDto): Observable<any> {
      return this.dataService.downloadFile('Printing/GetLabels', dto);
  }

  downloadUserDataSheet(dto: UserDataSheetDto): Observable<any> {
    return this.dataService.downloadFile('Printing/GetUserDataSheetReport', dto);
  }
}
