import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfirmationDialogService {
    public dialogConfirmationSubject: Subject<boolean> = new Subject();
    public showConfirmationDialog: Subject<boolean> = new Subject();

    showDialog(): Observable<boolean> {
        this.showConfirmationDialog.next(true);

        return this.dialogConfirmationSubject.asObservable();
    }

    leftButtonClicked(): void {
        this.showConfirmationDialog.next(false);
        this.dialogConfirmationSubject.next(true);
    }

    rightButtonClicked(): void {
        this.showConfirmationDialog.next(false);
        this.dialogConfirmationSubject.next(false);
    }
}
