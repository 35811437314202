import { ArticleHphraseDto } from './../../requests/new-request/new-request.model';
import { Component, OnInit, OnChanges, SimpleChanges, EventEmitter, Input, Output } from '@angular/core';
import { DangerClassDto } from '../../catalogue-view/shared/catalogue-view.model';

@Component({
  selector: 'app-h-phrase-table',
  templateUrl: './h-phrase-table.component.html',
  styleUrls: ['./h-phrase-table.component.scss']
})
export class HphraseTableComponent implements OnInit, OnChanges {
  @Input() articleHphrases: ArticleHphraseDto[];
  @Input() isHphraseRemovable: boolean;
  @Input() hasLessData: boolean;

  @Output() hPhraseRemoved = new EventEmitter();

  togglePanel: any = {};

  constructor() { }

  ngOnInit() {
    this.fillDisplayName();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.articleHphrases) {
      this.articleHphrases = changes.articleHphrases.currentValue;
      this.fillDisplayName();
    }
  }

  removeHphrase(id: number) {
    this.articleHphrases = [...this.articleHphrases.filter(p => p.Id !== id)];
    this.hPhraseRemoved.emit(this.articleHphrases);
  }

   hasDangerClass(hPhrase: ArticleHphraseDto) {
    return hPhrase.DangerClass && hPhrase.DangerClass.Type;
  }

  getCategory(dataItem: DangerClassDto) {
    return dataItem.Category ? dataItem.Category.Name : '';
  }

  getClass(dataItem: DangerClassDto) {
    return dataItem.Class ? dataItem.Class.Name : '';
  }

  getSignalWord(dataItem: DangerClassDto) {
    return dataItem.SignalWord ? dataItem.SignalWord.Name : '';
  }

  private fillDisplayName() {
    if (this.articleHphrases) {
      this.articleHphrases.forEach(phrase => {
        if (phrase.DangerClass && phrase.DangerClass.Id) {
          phrase.DangerClass.DisplayName = phrase.DangerClass.Type.Name + ', ' + this.getClass(phrase.DangerClass) + ', ' +
          this.getCategory(phrase.DangerClass);
        }
      });
    }
  }
}
