import { LanguageCode } from '../shared/model';

export enum LabelSize {
    Small,
    Medium,
    Large
}

export interface LabelDto {
    RequestId: number;
    LabelSize: LabelSize;
}

export interface UserDataSheetDto {
    ApprovalId: number;
    LanguageCode: LanguageCode;
}
