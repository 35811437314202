import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HelpService {
    private helpNotice = new Subject<boolean>();
    helpNotice$ = this.helpNotice.asObservable();

    public showHelpNotice() {
        return this.helpNotice.next();
    }
}
