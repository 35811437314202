import {
  Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';

import { CharacteristicsAndContainersDto, ContainerType, CharacteristicsDto } from '../../manage-articles.model';

@Component({
  selector: 'app-manage-characteristics-containers',
  templateUrl: './manage-characteristics-containers.component.html'
})
export class ManageCharacteristicsContainersComponent implements OnInit, OnChanges {
  @Input() characteristicsAndContainers: CharacteristicsAndContainersDto[];
  @Input() containerTypes: ContainerType[];

  @Output() characteristicPut = new EventEmitter();
  @Output() containerPut = new EventEmitter();
  @Output() characteristicDeleted = new EventEmitter();
  @Output() containerDeleted = new EventEmitter();

  characteristics: CharacteristicsDto[];
  initialized: boolean;

  constructor() { }

  ngOnInit() {
    this.characteristics = <CharacteristicsDto[]>this.characteristicsAndContainers;
    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.initialized) {
      this.characteristicsAndContainers = changes.characteristicsAndContainers.currentValue;
      this.characteristics = <CharacteristicsDto[]>this.characteristicsAndContainers;
    }
  }

  putCharacteristic(event) {
    this.characteristicPut.emit(event);
  }

  putContainer(event) {
    this.containerPut.emit(event);
  }

  deleteCharacteristic(event) {
    this.characteristicDeleted.emit(event);
  }

  deleteContainer(event) {
    this.containerDeleted.emit(event);
  }
}
