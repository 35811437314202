import { Component, Input, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';

import { HazardStatementsDetails } from '../../shared/catalogue-view.model';
import { ModalsService } from 'app/shared/modal/modals.service';
import { FileTypeEnum, FileToDownload, DownloadedFile } from '../../../shared/file-manager/file-manager.model';
import { FileManagerService } from '../../../shared/file-manager/file-manager.service';
import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';

@Component({
  selector: 'app-warnings-modal',
  templateUrl: './warnings-modal.component.html',
  styleUrls: ['./warnings-modal.component.scss', '../../../shared/modal/modal-content.scss']
})
export class WarningsModalComponent {
  @Input() isMobile = false;
  @Input() data: HazardStatementsDetails;
  @Input() set dialogOpen(isOpen: boolean) {
    if (isOpen && !this._modalShown) {
      setTimeout(() => {
        this.modalsService.showModal('warningModal', this.template, this.warningsDialogClosed.bind(this));
        this._modalShown = true;
        this.fetchFile();
      }, 0);
    } else if (!isOpen && this._modalShown) {
      this.modalsService.closeModal('warningModal');
      this._modalShown = false;
    }
  }
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @ViewChild(TemplateRef, { static: false }) template: TemplateRef<any>;

  get needsDerogation() {
    return this.data.HPhrases.find(x => x.HasDerogation === true);
  }

  private _modalShown: boolean;
  private derogationFile: Blob | File;
  private derogationFileDisplayName: string;
  public derogationFileSize: string;

  constructor(
    private fileManagerService: FileManagerService,
    private modalsService: ModalsService,
    private notificationService: TranslatedNotificationService) {
      this._modalShown = false;
    }

  downloadFile() {
    this.fileManagerService.downloadFile(this.derogationFile, this.derogationFileDisplayName);
  }

  warningsDialogClosed() {
    this.dialogClosed.emit();
  }

  private fetchFile() {
    const derogationFilePath = this.data?.Warning?.DerogationFilePath;
    this.derogationFileDisplayName = this.data?.Warning?.DerogationFileDisplayName;

    const file = <FileToDownload> {
      FileName: derogationFilePath,
      FileType: FileTypeEnum.DerogationFilePath
    };

    this.fileManagerService.fetchFile(file)
      .subscribe(
        (data: DownloadedFile) => {
          if (data) {
            this.derogationFile = data?.File;
            this.derogationFileSize = data?.Size;
          }
        },
        () => {
          this.notificationService.showMsgError(
            'error.cannot_download_derogration_file',
            'There was an error while downloading derogation file for the article. Please try again.'
          );
        }
      );
  }
}
