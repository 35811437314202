import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { EMPTY } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { ApprovalDetailsDto } from '../../shared/catalogue-view.model';
import { FileToDownload, FileTypeEnum } from '../../../shared/file-manager/file-manager.model';

import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { CatalogueViewService } from '../../shared/catalogue-view.service';
import { FileManagerService } from '../../../shared/file-manager/file-manager.service';
import { SharedService } from 'app/shared/shared.service';

@Component({
  selector: 'app-article-details-view',
  templateUrl: './article-details-view.component.html',
  styleUrls: ['./article-details-view.component.scss']
})
export class ArticleDetailsViewComponent implements OnInit {
  approval: ApprovalDetailsDto;
  private sdsFile: Blob;
  private sdsPath: string;
  private sdsDisplayName: string;
  private sdsToDownload: FileToDownload;
  deleteApprovalOpened: boolean;

  constructor(
    private catalogueService: CatalogueViewService,
    private fileManagerService: FileManagerService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService) {
  }

  ngOnInit() {
    this.spinner.show('spinner');
    const approvalId = this.route.snapshot.paramMap.get('approvalId');

    if (!approvalId || approvalId === '0') {
      this.router.navigate(['']);
    } else {
      this.catalogueService.fetchApprovalWithGivenId(approvalId, this.sharedService.currentLanguage.LanguageCode)
        .pipe(
          finalize(() => this.spinner.hide('spinner'))
        )
        .subscribe(
          data => {
            this.approval = data;
            this.catalogueService.changeApproval(data);
            this.getSdsFile();
          },
          () => this.notificationService.showDefaultMsgError()
        );
    }
  }

  downloadFile() {
    this.fileManagerService.fetchFile(this.sdsToDownload)
      .pipe(
        map((data) => {
          this.sdsFile = data?.File;

          if (this.sdsPath) {
            return this.fileManagerService.downloadFile(this.sdsFile, this.sdsDisplayName);
          } else {
            this.notificationService.showMsgError('error.no_sds_file', 'No SDS file has been provided for article');

            return EMPTY;
          }
        })).subscribe();
  }

  openDeleteApprovalModal() {
    this.deleteApprovalOpened = true;
  }

  closeDeleteApprovalModal() {
    this.deleteApprovalOpened = false;
  }

  removeApproval() {
    this.spinner.show('spinner');

    this.catalogueService.removeApproval(this.approval.Id.toString()).pipe(
      finalize(() => {
        this.spinner.hide('spinner');
        this.closeDeleteApprovalModal();
      })
    ).subscribe(
      () => {
        this.notificationService.showMsgSuccess('success.delete_approval', 'Successfully deleted approval');
        this.router.navigate(['']);
      },
      () => this.notificationService.showMsgError(
        'error.delete_aproval_failed',
        'Removing approval is not possible now, please try again later.')
    );
  }

  private getSdsFile(): void {
    this.sdsPath = this.approval.Article.SdsPath;
    this.sdsDisplayName = this.approval.Article.SdsDisplayName;
    this.sdsToDownload = <FileToDownload>{
      FileName: this.sdsPath,
      FileType: FileTypeEnum.SdsFilePath
    };
  }
}
