import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ManageArticleDetailsComponent } from './manage-article-details/manage-article-details.component';

import {
  ViewCard, CharacteristicsDto, ContainerDto,
  ManageArticleData, UpdatePphrasesDto, UpdateHphrasesDto, EditArticleDetailsDto
} from '../manage-articles.model';
import { CustomPphraseDto } from '../../../risk-analysis/risk-analysis.model';
import { ArticleHphraseDto } from 'app/requests/new-request/new-request.model';

import { ManageArticleDataManagerService } from './manage-article-data-manager.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';
import { DataManagementService } from '../../data-management.service';
import { SharedService } from '../../../shared/shared.service';
import { ConfirmationDialogService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-manage-article-edit',
  templateUrl: './manage-article-edit.component.html',
  styleUrls: ['./manage-article-edit.component.scss']
})
export class ManageArticleEditComponent implements OnInit, OnDestroy {
  @ViewChild('detailsManager', { static: false }) detailsManager: ManageArticleDetailsComponent;

  data: ManageArticleData;
  editOperation: boolean;
  ViewCard = ViewCard;
  activeCard: ViewCard;
  initializated: boolean;
  deleteCharacteristicsOpened: boolean;
  deleteContainerOpened: boolean;
  downloadFileEnabled: boolean;

  private idToDelete: number;
  private articleId: number;
  private dirty: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataManagerService: ManageArticleDataManagerService,
    private dataManagementService: DataManagementService,
    private spinner: SpinnerService,
    private notificationService: TranslatedNotificationService,
    private confirmationDialogService: ConfirmationDialogService,
    private sharedService: SharedService) {
  }

  ngOnInit() {
    this.articleId = Number(this.route.snapshot.paramMap.get('articleId'));
    this.toggleCard(ViewCard.Details);

    if (this.articleId) {
      this.editOperation = true;
      this.dataManagerService.dataQueries.push(this.dataManagementService.getArticle(this.articleId, this.sharedService.currentLanguage.LanguageCode));
    }

    this.fetchData();    
  }

  ngOnDestroy() {
    this.dataManagerService.resetData();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.isDetails() && this.detailsManager && this.detailsManager.articleForm.dirty || this.dirty) {
      return this.confirmationDialogService.showDialog();
    }

    return true;

  }

  scroll(el: HTMLElement) {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  toggleCard(viewCard: ViewCard) {
    if (this.isDetails()) {
      this.submitForm(viewCard);
    } else {
      this.activeCard = viewCard;
      this.scroll(document.getElementById('topPage'));
    }
  }

  isDetails(): boolean {
    return this.activeCard === this.ViewCard.Details;
  }

  isContainersCharacteristics(): boolean {
    return this.activeCard === this.ViewCard.ContainersCharacteristics;
  }

  isHpPhrases(): boolean {
    return this.activeCard === this.ViewCard.HpPhrases;
  }

  revertArticleDetails() {
    this.detailsManager.revertArticleDetails(this.data.InitialDetails);
    this.downloadFileEnabled = true;
    this.dirty = false;
    this.scroll(document.getElementById('topPage'));
  }

  discard() {
    this.router.navigate(['management/articles']);
  }

  toggleNext() {
    if (this.isDetails()) {
      this.submitForm(this.ViewCard.ContainersCharacteristics);
    } else {
      this.activeCard = ViewCard.HpPhrases;
      this.scroll(document.getElementById('topPage'));
    }
  }

  saveArticle() {
    const formData = this.dataManagerService.collectNewArticleData();

    if (formData) {
      this.spinner.show('spinner');
      this.dataManagementService.addNewArticle(formData)
        .pipe(
          finalize(() => {
            this.spinner.hide('spinner');
            this.scroll(document.getElementById('topPage'));
          })).subscribe(
            (details) => {
              this.dirty = false;
              this.notificationService.showMsgSuccess('success.add_article', 'Successfully added a new article');
              this.router.navigate(['management/articles']);
            },
            () => {
              this.notificationService.showMsgError('error.update_article_with_given_id', 'Error while saving article');
            }
          );
    }
  }

  saveArticleDetails() {
    const formData = this.detailsManager.submitForm();

    if (formData) {
      this.spinner.show('spinner');
      this.dataManagementService.updateArticleDetails(formData)
        .pipe(
          finalize(() => {
            this.spinner.hide('spinner');
            this.scroll(document.getElementById('topPage'));
          })).subscribe(
            (details) => {
              this.data.Article.Details = details;
              this.data.Details = this.data.Article.Details;
              this.data.InitialDetails = this.data.Details;
              this.downloadFileEnabled = true;
              this.dirty = false;
              this.notificationService.showMsgSuccess('success.edit_article', 'Successfully edited article');
            },
            () => {
              this.notificationService.showMsgError('error.update_article_with_given_id', 'Error while saving article');
            }
          );
    }
  }

  putCharacteristic(characteristic: CharacteristicsDto) {
    characteristic.ArticleId = this.articleId;
    if (this.editOperation) {
      this.saveCharacteristic(characteristic);
    } else {
      this.dataManagerService.putNewArticleCharacteristic(characteristic);
      this.data.CharacteristicsAndContainers = [...this.dataManagerService.characteristicsAndContainers];
    }
  }

  putContainer(container: ContainerDto) {
    if (this.editOperation) {
      this.saveContainer(container);
    } else {
      this.dataManagerService.putNewArticleContainer(container);
      this.data.CharacteristicsAndContainers = [...this.dataManagerService.characteristicsAndContainers];
    }
  }

  deleteCharacteristic(characteristicsId: number) {
    if (this.editOperation) {
      this.deleteExistingCharacteristic(characteristicsId);
    } else {
      this.deleteNewArticleCharacteristic(characteristicsId);
    }
  }

  deleteContainer(containerId: number) {
    if (this.editOperation) {
      this.deleteExistingContainer(containerId);
    } else {
      this.deleteNewArticleContainer(containerId);
    }
  }

  updatePphrases(pPhrases: CustomPphraseDto[]) {
    if (this.editOperation) {
      this.updateExistingPphrases(pPhrases);
    } else {
      this.dataManagerService.pPhrases = pPhrases;
      this.data.Pphrases = this.dataManagerService.pPhrases;
    }
  }

  updateHphrases(hPhrases: ArticleHphraseDto[]) {
    if (this.editOperation) {
      this.updateExistingHphrases(hPhrases);
    } else {
      this.dataManagerService.hPhrases = hPhrases;
      this.data.Hphrases = this.dataManagerService.hPhrases;
    }
  }

  confirmDelete() {
    this.spinner.show('spinner');

    if (this.deleteCharacteristicsOpened) {
      this.modifyElementAndUpdateData(this.dataManagementService.deleteCharacteristic(this.idToDelete));
      this.deleteCharacteristicsOpened = false;
    } else {
      this.modifyElementAndUpdateData(this.dataManagementService.deleteContainer(this.idToDelete));
      this.deleteContainerOpened = false;
    }
  }

  closeDelete() {
    this.deleteCharacteristicsOpened = false;
    this.deleteContainerOpened = false;
  }

  private fetchData() {
    this.spinner.show('spinner');
    forkJoin(this.dataManagerService.dataQueries)
      .pipe(
        finalize(() => this.spinner.hide('spinner'))
      ).subscribe(
        ([seveso, states, producers, families, containerTypes, kinds, availablePphrases, hPhrases, article]) => {
          this.data = <ManageArticleData>{};
          this.data.AllNominatives = seveso;
          this.data.StatesOfMatter = states;
          this.data.Producers = producers.sort((a, b) => a.Name.localeCompare(b.Name));
          this.data.Families = families;
          this.data.ContainerTypes = containerTypes;
          this.data.Kinds = kinds;
          this.data.AvailablePphrases = availablePphrases;
          this.data.Hphrases = hPhrases;
          this.data.CharacteristicsAndContainers = [];
          this.data.ArticleHphrases = [];
          this.data.Pphrases = [];
          
          if (article) {
            this.data.Article = article;
            this.data.Details = this.data.Article.Details;
            this.data.InitialDetails = this.data.Details;
            this.data.CharacteristicsAndContainers = this.data.Article.CharacteristicsAndContainers;
            this.data.ArticleHphrases = this.data.Article.Hphrases;
            this.data.Pphrases = this.data.Article.Pphrases;
            this.showForbiddenWarning(this.data.Details);
            this.dataManagerService.dataQueries = this.dataManagerService.assignDataQueries();
            this.downloadFileEnabled = true;
          }

          this.initializated = true;
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }

  private showForbiddenWarning(detailsDto: EditArticleDetailsDto) {
    if (detailsDto.IsForbidden && detailsDto.ForbiddenEntities.indexOf(this.sharedService.currentEntity) > -1) {
      this.notificationService.showMsgWarning('info.article_is_forbidden_general', 'This product has been forbidden. Reason:',
        ' ' +this.data.Details.ForbiddenNote);
    }
  }

  private submitForm(nextCard: ViewCard) {
    const formData = this.detailsManager.submitForm();

    if (formData) {
      this.dataManagerService.articleDetails = formData;
      this.data.Details = this.detailsManager.collectedData;
      this.activeCard = nextCard;
      this.scroll(document.getElementById('topPage'));

      if (this.data.InitialDetails && this.data.InitialDetails.SdsPath !== this.data.Details.SdsPath) {
        this.downloadFileEnabled = false;
      }

      if (this.detailsManager.articleForm.dirty) {
        this.dirty = true;
      }
    }
  }

  private saveCharacteristic(characteristics: CharacteristicsDto) {
    if (characteristics) {
      this.spinner.show('spinner');
      this.modifyElementAndUpdateData(this.dataManagementService.putCharacteristic(characteristics));
    }
  }

  private saveContainer(container: ContainerDto) {
    if (container) {
      this.spinner.show('spinner');
      this.modifyElementAndUpdateData(this.dataManagementService.putContainer(container));
    }
  }

  private deleteExistingCharacteristic(characteristicsId: number) {
    this.dataManagementService.areApprovalsAssignedToCharacteristics(characteristicsId)
      .pipe(
        finalize(() => {
          this.spinner.hide('spinner');
        }))
      .subscribe((areApprovalsAssigned) => {
        if (areApprovalsAssigned || this.areContainersAssignedToCharacteristics(characteristicsId)) {
          this.notificationService.showMsgInfo(
            'info.characteristic_remove_impossible',
            'Sorry, it is not possible to remove the characteristic as it has been approved already.');
        } else {
          this.idToDelete = characteristicsId;
          this.deleteCharacteristicsOpened = true;
        }
      },
        () => this.notificationService.showDefaultMsgError()
      );
  }

  private areContainersAssignedToCharacteristics(characteristicsId: number): boolean {
    return !!this.data.CharacteristicsAndContainers
      .find(characteristics => characteristics.Containers
        .find(container => container.CharacteristicsId === characteristicsId));
  }

  private deleteExistingContainer(containerId: number) {
    this.dataManagementService.areApprovalsAssignedToContainer(containerId)
      .pipe(
        finalize(() => {
          this.spinner.hide('spinner');
        }))
      .subscribe((areApprovalsAssigned) => {
        if (areApprovalsAssigned) {
          this.notificationService.showMsgInfo(
            'info.container_remove_impossible',
            'To delete this container, You have to delete connected approvals at first.');
        } else {
          this.idToDelete = containerId;
          this.deleteContainerOpened = true;
        }
      },
        () => this.notificationService.showDefaultMsgError()
      );
  }

  private deleteNewArticleCharacteristic(characteristicsId: number) {
    if (this.areContainersAssignedToCharacteristics(characteristicsId)) {
      this.notificationService.showMsgInfo(
        'info.characteristic_remove_impossible',
        'Sorry, it is not possible to remove the characteristic as it has been approved already.');
    } else {
      this.dataManagerService.characteristicsAndContainers
        = this.dataManagerService.characteristicsAndContainers.filter(c => c.TempId !== characteristicsId);

      this.data.CharacteristicsAndContainers = [...this.dataManagerService.characteristicsAndContainers];
    }
  }

  private deleteNewArticleContainer(containerId: number) {
    this.dataManagerService.removeContainer(containerId);
    this.data.CharacteristicsAndContainers = [...this.dataManagerService.characteristicsAndContainers];
  }

  private updateExistingPphrases(pPhrases: CustomPphraseDto[]) {
    if (pPhrases) {
      this.spinner.show('spinner');
      const updatePphrasesDto = <UpdatePphrasesDto>{
        ArticleId: this.articleId,
        ArticlePphrases: pPhrases
      };

      this.dataManagementService.updatePphrases(updatePphrasesDto)
        .switchMap((data) => {
          this.notificationService.showMsgSuccess('success.edit_article', 'Successfully edited article');

          return this.dataManagementService.getArticle(this.articleId, this.sharedService.currentLanguage.LanguageCode);
        }
        ).pipe(
          finalize(() => {
            this.spinner.hide('spinner');
            this.scroll(document.getElementById('topPage'));
          })).subscribe(
            (article) => {
              this.data.Article.Pphrases = article.Pphrases;
            },
            () => this.notificationService.showDefaultMsgError()
          );
    }
  }

  private updateExistingHphrases(hPhrases: ArticleHphraseDto[]) {
    if (hPhrases) {
      this.spinner.show('spinner');
      const updateHphrasesDto = <UpdateHphrasesDto>{
        ArticleId: this.articleId,
        ArticleHphrases: hPhrases
      };

      this.dataManagementService.updateHphrases(updateHphrasesDto)
        .switchMap((data) => {
          this.notificationService.showMsgSuccess('success.edit_article', 'Successfully edited article');

          return this.dataManagementService.getArticle(this.articleId, this.sharedService.currentLanguage.LanguageCode);
        }
        ).pipe(
          finalize(() => {
            this.spinner.hide('spinner');
            this.scroll(document.getElementById('topPage'));
          })).subscribe(
            (article) => {
              this.data.Article.Hphrases = article.Hphrases;
            },
            () => this.notificationService.showDefaultMsgError()
          );
    }
  }

  private modifyElementAndUpdateData(putMethod: Observable<any>) {
    putMethod.switchMap((data) => {
      this.notificationService.showMsgSuccess('success.edit_article', 'Successfully edited article');

      return this.dataManagementService.getCharacteristicsAndContainersByArticle(this.articleId);
    }
    ).pipe(
      finalize(() => {
        this.spinner.hide('spinner');
        this.scroll(document.getElementById('topPage'));
      })).subscribe(
        (updatedCharacteristicsAndContainers) => {
          this.data.Article.CharacteristicsAndContainers = updatedCharacteristicsAndContainers;
          this.data.CharacteristicsAndContainers = this.data.Article.CharacteristicsAndContainers;
        },
        () => {
          this.notificationService.showDefaultMsgError();
        }
      );
  }
}
