import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { TechnicalMeasureDto } from '../../../risk-analysis/risk-analysis.model';
import { LanguageCode } from '../../../shared/model';
import { TechnicalMeasureEditDto } from '../manage-technical-measures.model';

import { SharedService } from '../../../shared/shared.service';
import { DataManagementService } from '../../data-management.service';
import { TranslatedNotificationService } from '../../../shared/translation/translated-notification.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';

@Component({
  selector: 'app-technical-measure-modal',
  templateUrl: './technical-measure-modal.component.html',
  styleUrls: ['./technical-measure-modal.component.scss']
})
export class TechnicalMeasureModalComponent implements OnInit {

  @Input() editedItem: TechnicalMeasureDto;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @Output() measureUpdated: EventEmitter<any> = new EventEmitter();

  form: FormGroup;

  constructor(
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private dataManagementService: DataManagementService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService) {
  }

  ngOnInit() {
    this.createForm();
  }

  closeDialog() {
    this.dialogClosed.emit();
  }

  submitForm() {
    if (this.formValid()) {
      this.spinner.show('spinner');

      const measureDto = <TechnicalMeasureEditDto> {
        Id: this.editedItem.Id,
        Name: this.form.get('Name').value,
        Value: this.form.get('Value').value,
        Language: this.sharedService.currentLanguage.LanguageCode
      };

      this.dataManagementService.updateTechnicalMeasure(measureDto)
        .pipe(
          finalize(() => this.spinner.hide('spinner'))
        ).subscribe(() => {
          this.measureUpdated.emit(measureDto);
          this.notificationService.showMsgSuccess('success.measure_updated', 'Technical measure entry updated');
        },
        () => {
          this.notificationService.showMsgError(
            'error.measure_update_error',
            'Sorry, we couldn\'t update technical measure entry. Try again later.');
        });
    }
  }

  private createForm() {
    this.form = this.formBuilder.group({
      Name: [this.editedItem === null ? null : this.editedItem.Name, Validators.required],
      Value: [this.editedItem === null ? null : this.editedItem.Value, Validators.required]
    });
  }

  private formValid(): boolean {
    if (this.form.invalid) {
      this.notificationService.showMsgError('error.measure_invalid_data', 'Please fill in required data');
      return false;
    }

    if (this.valueInvalid()) {
      this.notificationService.showMsgError('error.measure_invalid_value', 'Value cannot be less or equal to 0');
      return false;
    }

    return true;
  }

  private valueInvalid(): boolean {
    return this.form.get('Value').value <= 0;
  }

}
