import { Component } from '@angular/core';
import { NotificationSettings } from '@progress/kendo-angular-notification';

@Component({
    template: ``
})
export class NotificationBodyComponent {
    base: NotificationSettings = {
        content: '',
        hideAfter: 6000,
        position: { horizontal: 'right', vertical: 'top' },
    };

    error(message): NotificationSettings {
        this.base.content = message;
        this.base.type = { style: 'error', icon: true };

        return this.base;
    }

    warning(message): NotificationSettings {
        this.base.content = message;
        this.base.type = { style: 'warning', icon: true };

        return this.base;
    }

    success(message): NotificationSettings {
        this.base.content = message;
        this.base.type = { style: 'success', icon: true };

        return this.base;
    }

    info(message): NotificationSettings {
        this.base.content = message;
        this.base.type = { style: 'info', icon: true };

        return this.base;
    }
}
