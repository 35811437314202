import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';

import { GDPREntry } from '../../shared/model';

import { DisclaimerService } from '../disclaimer.service';
import { TranslatedNotificationService } from '../../shared/translation/translated-notification.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit, OnDestroy {
  gdprDefinitions: GDPREntry[];
  disclaimerOpened = false;
  languageChosen: string;
  subscription: Subscription;

  private gdprContainer: ElementRef;
    @ViewChild('gdprContainer', {static: false}) set controlElRef(gdprContainer: ElementRef) {
      this.gdprContainer = gdprContainer;
    }

  constructor(
    private service: DisclaimerService,
    private notificationService: TranslatedNotificationService) {
    this.languageChosen = 'EN';
    this.gdprDefinitions = [];
    this.subscription = new Subscription();
  }

  ngOnInit() {
    forkJoin(
      this.service.GetGdprDefinitionsForAllLanguages(),
      this.service.GetUserSeenGdpr())
      .subscribe(([definitions, hasSeen]) => {
        this.gdprDefinitions = definitions;
        if (hasSeen === false) {
          this.openDisclaimer();
        }
      },
      (err) => {
        this.notificationService.showMsgError(
          'error.loading_gdpr',
          'Sorry, loading GDPR data is not possible now. Please try again later.');
      });


    this.subscription.add(
      this.service.privacyNotice$.subscribe(() => {
          this.openDisclaimer();
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  closeDisclaimer() {
    this.disclaimerOpened = false;
    this.subscription.add(this.service.UpdateUserSeenGdpr().subscribe());
  }

  openDisclaimer() {
    this.disclaimerOpened = true;
    this.gdprContainer.nativeElement.innerHTML = this.selectedGdprDefinition;
  }

  changeLanguage(languageCode: string) {
    this.languageChosen = languageCode;
    this.gdprContainer.nativeElement.innerHTML = this.selectedGdprDefinition;
  }

  get selectedGdprDefinition(): string {
    const selectedHtml = this.gdprDefinitions.filter(x => x.LanguageCode.toLowerCase() === this.languageChosen.toLowerCase())[0];

    if (selectedHtml !== undefined) {
      return selectedHtml.HtmlText;
    }

    return '';
  }
}
