import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'mobile-pager',
    styleUrls: ['./mobile-pager.component.scss'],
    templateUrl: './mobile-pager.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobilePagerComponent implements OnInit {
    @Input() currentPage: number;
    @Input() pagesCount: number;
    @Input() paginationRange: number;
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

    constructor() { }

    ngOnInit() { }

    get pagesArray() {
        return this.createPageArray(this.currentPage, this.pagesCount, this.paginationRange);
    }

    private createPageArray(currentPage: number, pagesCount: number, paginationRange: number): Page[] {
        // paginationRange could be a string if passed from attribute, so cast to number.
        paginationRange = +paginationRange;
        const pages = [];
        const halfWay = Math.ceil(paginationRange / 2);

        const firstHalf = currentPage <= halfWay;
        const seconfHalf = pagesCount - halfWay < currentPage;
        const isMiddle = !firstHalf && !seconfHalf;

        const ellipsesNeeded = paginationRange < pagesCount;

        for (let i = 1; i <= pagesCount && i <= paginationRange; ++i) {
            let label;
            const pageNumber = this.calculatePageNumber(i, currentPage, paginationRange, pagesCount);
            const openingEllipsesNeeded = (i === 2 && (isMiddle || seconfHalf));
            const closingEllipsesNeeded = (i === paginationRange - 1 && (isMiddle || firstHalf));
            if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
                label = '...';
            } else {
                label = pageNumber;
            }
            pages.push({
                label: label,
                value: pageNumber
            });
        }
        return pages;
    }

    private calculatePageNumber(i: number, currentPage: number, paginationRange: number, totalPages: number) {
        const halfWay = Math.ceil(paginationRange / 2);

        if (i === paginationRange) {
            return totalPages;
        } else if (i === 1) {
            return i;
        } else if (paginationRange < totalPages) {
            if (totalPages - halfWay < currentPage) {
                return totalPages - paginationRange + i;
            } else if (halfWay < currentPage) {
                return currentPage - halfWay + i;
            } else {
                return i;
            }
        } else {
            return i;
        }
    }

    selectPage(page: number) {
        this.pageChange.next(page);
    }

    nextPage() {
        this.selectPage(this.currentPage + 1);
    }

    previousPage() {
        this.selectPage(this.currentPage - 1);
    }
}

export interface Page {
    label: string;
    value: any;
}