import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { AppRoutingModule } from '../app-routing.module';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

import { RequestService } from './request.service';
import { EditRequestService } from './shared/edit-request/edit-request.service';
import { RequestValidator } from './shared/edit-request/request-validator';
import { RequestFormHelper } from './shared/edit-request/request-form-helper';

import { ApprovalIconsComponent } from './approval-icons/approval-icons.component';
import { NewRequestComponent } from './new-request/new-request.component';
import { RequestsViewComponent } from './requests-view/requests-view.component';
import { RequestViewComponent } from './request-view/request-view.component';
import { DropdownFilterComponent } from './dropdown-filter/dropdown-filter.component';
import { RequestDetailsApprovalsComponent } from './request-details-approvals/request-details-approvals.component';
import { ChangeApprovalComponent } from './request-details-approvals/change-approval/change-approval.component';
import { ChangeApprovalHeaderComponent } from './request-details-approvals/change-approval-header/change-approval-header.component';
import { EditArticleModalComponent } from './request-view/edit-article-modal/edit-article-modal.component';
import { EditRequestComponent } from './shared/edit-request/edit-request.component';
import { FileManagerModule } from '../shared/file-manager/file-manager.module';
import { ConfirmationDialogModule } from '../shared/confirmation-dialog/confirmation-dialog.module';
import { RequestDetailsReadOnlyComponent } from './request-details-read-only/request-details-read-only.component';
import { RequestCreatedComponent } from './request-created/request-created.component';
import { EditProducerModalComponent } from './request-view/edit-producer-modal/edit-producer-modal.component';
import { ModalsModule } from '../shared/modal/modals.module';
import { TranslationModule } from '../shared/translation/translation.module';
import { RiskAnalysisModule } from 'app/risk-analysis/risk-analysis.module';
import { SharedModule } from 'app/shared/shared.module';
import { DisableAutocompleteModule } from '../shared/disable-autocomplete/disable-autocomplete.module';

@NgModule({
    declarations: [
        NewRequestComponent,
        RequestsViewComponent,
        ApprovalIconsComponent,
        RequestViewComponent,
        DropdownFilterComponent,
        RequestDetailsApprovalsComponent,
        ChangeApprovalComponent,
        ChangeApprovalHeaderComponent,
        EditProducerModalComponent,
        EditArticleModalComponent,
        EditRequestComponent,
        RequestDetailsReadOnlyComponent,
        RequestCreatedComponent
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        InputsModule,
        ButtonsModule,
        GridModule,
        FormsModule,
        ReactiveFormsModule,
        DropDownsModule,
        NumericTextBoxModule,
        WindowModule,
        DateInputsModule,
        FileManagerModule,
        ConfirmationDialogModule,
        ModalsModule,
        TranslationModule,
        RiskAnalysisModule,
        SharedModule,
        DisableAutocompleteModule
    ],
    exports: [EditProducerModalComponent],
    providers: [
        RequestService,
        EditRequestService,
        RequestValidator,
        RequestFormHelper
    ]
})
export class RequestsModule {}
