import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { GridComponent, GridDataResult, DataStateChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

import { DataManagementService } from '../data-management.service';
import { TranslatedNotificationService } from '../../shared/translation/translated-notification.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';

import { GhsEnum } from '../../shared/model';
import { DangerClassDto, CategoryDto, ClassDto, TypeDto, SignalWordDto } from '../../catalogue-view/shared/catalogue-view.model';
import { DangerClassGridDto } from './manage-danger-class.model';

import { ActionType } from '../shared/helper';
import { GridStateHelper } from '../shared/grid-state';

@Component({
  selector: 'app-manage-danger-class',
  templateUrl: './manage-danger-class.component.html',
  styleUrls: ['./manage-danger-class.component.scss']
})
export class ManageDangerClassComponent implements OnInit {
  @ViewChild(GridComponent, { static: false }) grid: GridComponent;

  expandedRows: number[] = [];
  dangerClassesDialogOpen = false;
  actionType: ActionType;
  editedDangerClass: DangerClassDto;
  gridData: GridDataResult;
  items: DangerClassGridDto[];
  GhsEnum = GhsEnum;
  state: State;
  categories: CategoryDto[];
  classes: ClassDto[];
  types: TypeDto[];
  signalWords: SignalWordDto[];

  constructor(private dataManagementService: DataManagementService,
    private gridState: GridStateHelper,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService) {
    this.state = this.gridState.getBasicState();
  }

  ngOnInit() {
    this.fetchData();
  }

  toggleExpandedClass = (context: RowClassArgs) => {
    const isExpanded = this.expandedRows.indexOf(context.index) !== -1;

    return {
      expanded: isExpanded
    };
  }

  detailExpand({ dataItem, index }) {
    this.expandedRows = [...this.expandedRows, index];
    setTimeout(() => {
      const elements = document.getElementsByClassName('k-detail-cell');
      for (let i = 0; i < elements.length; ++i) {
        elements.item(i).setAttribute('colspan', '7');
      }
    }, 0);
  }

  detailCollapse({ dataItem, index }) {
    this.expandedRows = this.expandedRows.filter(x => x !== index);
  }

  openDangerClassesDialog(actionType: ActionType) {
    this.actionType = actionType;
    this.dangerClassesDialogOpen = true;
  }

  closeDangerClassesDialog() {
    this.dangerClassesDialogOpen = false;
    this.fetchData();
  }

  editDangerClass(dangerClass: DangerClassGridDto, actionType: ActionType) {
    this.editedDangerClass = dangerClass;
    this.openDangerClassesDialog(actionType);
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  getCategory(dataItem: DangerClassGridDto) {
    return dataItem.Category ? dataItem.Category.Name : '';
  }

  getClass(dataItem: DangerClassGridDto) {
    return dataItem.Class ? dataItem.Class.Name : '';
  }

  getSignalWord(dataItem: DangerClassGridDto) {
    return dataItem.SignalWord ? dataItem.SignalWord.Name : '';
  }

  getGhsCodes(dataItem: DangerClassGridDto) {
    return dataItem.GhsCodes ? dataItem.GhsCodes : '-';
  }

  private createGhsList(dangerClasses: DangerClassGridDto[]) {
    dangerClasses.forEach(dc => dc.GhsCodes = dc.GhsList
      .map(g => GhsEnum[g.Code]).join(', ').toString());
  }

  private fetchData() {
    this.spinner.show('spinner');

    forkJoin([this.dataManagementService.getDangerClasses(),
    this.dataManagementService.getCategories(),
    this.dataManagementService.getDangerClassClasses(),
    this.dataManagementService.getDangerClassTypes(),
    this.dataManagementService.getDangerClassSignalWords()
    ])
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe(
        ([dangerClasses, categories, classes, types, signalWords]) => {
          this.items = dangerClasses;
          this.createGhsList(this.items);
          this.categories = categories;
          this.classes = classes;
          this.types = types;
          this.signalWords = signalWords;
          this.gridData = process(this.items, this.state);
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }
}
