import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { EditArticleDetailsDto } from '../manage-articles.model';
import { LimitValueUnitDto, LimitValueUnit } from '../../../shared/model';

import { SharedService } from '../../../shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class ManageArticleFormManagerService {
    constructor(
        private sharedService: SharedService,
        private formBuilder: FormBuilder) { }

    createForm(): FormGroup {
        return this.formBuilder.group({
            Forbidden: ['false'],
            ForbiddenNote: [''],
            Name: ['', Validators.required],
            Producer: [null, Validators.required],
            Synonyms: [''],
            Cas: [''],
            FlashPointValue: [''],
            FlashPointSign: [null],
            LimitValue: [null, this.sharedService.validateNumberGreaterThanZero],
            LimitValueUnit: [null],
            SdsNumber: [''],
            ReachNumber: [''],
            Biocide: ['false'],
            Esds: ['false'],
            Family: [null, Validators.required],
            StateOfMatter: [null, Validators.required],
            SevesoNominative: [null],
            SdsFile: [null, Validators.required],
            SdsDate: [new Date, Validators.required],
            ForbiddenEntities: [null]
        });
    }

    fillBasicData(form: FormGroup) {
        form.patchValue({
            Forbidden: 'false',
            Biocide: 'false',
            Esds: 'false',
            SdsDate: new Date
        });
    }

    fillForm(form: FormGroup, details: EditArticleDetailsDto) {
        if (details) {
            form.patchValue({
                Forbidden: details.IsForbidden.toString(),
                ForbiddenNote: details.ForbiddenNote,      
                Name: details.Name,
                Producer: details.Producer,
                Synonyms: details.Synonyms.join(', '),
                Cas: details.Cas,
                FlashPointValue: details.FlashPointValue,
                FlashPointSign: details.FlashPointSign,
                LimitValue: details.LimitValue,
                LimitValueUnit: this.mapSelectedLimitValueUnit(details.LimitValueUnit),
                SdsNumber: details.SdsNumber,
                ReachNumber: details.ReachNumber,
                Biocide: details.IsBiocide.toString(),
                Esds: details.IsEsds.toString(),
                Family: details.Family,
                StateOfMatter: details.StateOfMatter,
                SevesoNominative: details.SevesoNominative,
                SdsDate: new Date(details.SdsDate),
                ForbiddenEntities: details.ForbiddenEntities
            });
        }
    }

    collectData(form: FormGroup, details: EditArticleDetailsDto, sdsFileToAddName: string): EditArticleDetailsDto {
        return <EditArticleDetailsDto>{
            Id: details ? details.Id : null,
            IsForbidden: form.get('Forbidden').value,
            ForbiddenNote : JSON.parse(form.get('Forbidden').value) ? form.get('ForbiddenNote').value : null,
            Name: form.get('Name').value,
            Producer: form.get('Producer').value,
            Synonyms: this.splitSynonyms(form.get('Synonyms').value),
            Cas: form.get('Cas').value,
            FlashPointValue: form.get('FlashPointValue').value,
            FlashPointSign: form.get('FlashPointSign').value,
            LimitValue: form.get('LimitValue').value,
            LimitValueUnit: this.getLimitValueUnit(form.get('LimitValueUnit').value),
            SdsNumber: form.get('SdsNumber').value,
            ReachNumber: form.get('ReachNumber').value,
            IsBiocide: form.get('Biocide').value,
            IsEsds: form.get('Esds').value,
            Family: form.get('Family').value,
            StateOfMatter: form.get('StateOfMatter').value,
            SevesoNominative: form.get('SevesoNominative').value,
            SdsPath: sdsFileToAddName ? sdsFileToAddName : details.SdsPath,
            SdsDate: form.get('SdsDate').value ? form.get('SdsDate').value : details.SdsDate,
            ForbiddenEntities : JSON.parse(form.get('Forbidden').value) ? form.get('ForbiddenEntities').value : []
        };
    }

    private mapSelectedLimitValueUnit(limitValueUnit: LimitValueUnit): LimitValueUnitDto {
        if (limitValueUnit === null) {
            return null;
        }

        return <LimitValueUnitDto>{
            EnumValue: limitValueUnit,
            Name: this.findLimitValueUnitName(limitValueUnit)
        };
    }

    private findLimitValueUnitName(limitValueUnitToFind: LimitValueUnit): string {
        const limitValueUnit = this.sharedService.mapFinalValueUnit().find(x => x.EnumValue === limitValueUnitToFind);

        return (limitValueUnit && limitValueUnit.Name) ? limitValueUnit.Name : '';
    }

    private splitSynonyms(synonyms: string) {
        return synonyms ? synonyms.toString().split(';') : Array<string>();
    }

    private getLimitValueUnit(limitValueUnit: LimitValueUnitDto) {
        return limitValueUnit ? limitValueUnit.EnumValue : null;
    }
}
