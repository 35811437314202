import { Injectable, OnDestroy } from '@angular/core';

import { Subscription, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, take, finalize, first } from 'rxjs/operators';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

import { ApprovalDto, CatalogueExportDto } from './catalogue-view.model';
import { DownloadedFile, FileToDownload, FileTypeEnum } from '../../shared/file-manager/file-manager.model';

import { CatalogueViewService } from './catalogue-view.service';
import { SharedService } from 'app/shared/shared.service';
import { TranslatedNotificationService } from '../../shared/translation/translated-notification.service';
import { FileManagerService } from '../../shared/file-manager/file-manager.service';

@Injectable({
    providedIn: 'root',
})
export class CatalogueDataManager implements OnDestroy {
    currentState: State;
    private _data: any[];
    private _filteredDataForExport: ApprovalDto[];
    private _searchString: string;
    private _searchInFileContent: boolean;
    private entityIdSubscription: Subscription;
    private pendingDataSubscription: Subscription;
    private dataSubscription: Subscription;

    /**
     * Indicates whether loading of catalogue data or downloading a file is in progress
     */
    public dataIsLoading: BehaviorSubject<boolean>;
    public dataInitialized: BehaviorSubject<boolean>;
    public gridData: BehaviorSubject<GridDataResult>;
    public page: BehaviorSubject<number>;
    public pagesCount: BehaviorSubject<number>;
    public get state() { return this.currentState; }
    private get _page() { return Math.floor(this.currentState.skip / this.currentState.take) + 1; }
    private get _pagesCount() { return Math.ceil(this.gridData.getValue().total / this.currentState.take) || 1; }

    constructor(
        private sharedService: SharedService,
        private fileManagerService: FileManagerService,
        private catalogueService: CatalogueViewService,
        private fileManager: FileManagerService,
        private notificationService: TranslatedNotificationService) {
        this.currentState = {
            skip: 0,
            take: 10,

            filter: {
                logic: 'and',
                filters: []
            }
        };

        this._searchString = '';
        this._searchInFileContent = false;

        this.gridData = new BehaviorSubject({ data: [], filteredData: [], total: 0 });
        this.page = new BehaviorSubject(this._page);
        this.pagesCount = new BehaviorSubject(this._pagesCount);

        this.dataInitialized = new BehaviorSubject(false);
        this.dataIsLoading = new BehaviorSubject(false);
    }

    public search(searchString: string, searchInFileContent : boolean = false ) {
        if (!searchString) {
            this._searchString = ''; 
            this._searchInFileContent = false;
        } else {
            this._searchString = searchString;
            this._searchInFileContent = searchInFileContent;
        }

        this.searchData();
    }

    public reloadData() {
        this.dataIsLoading.next(true);
        this.cancelActiveSubscribtions();

        this.entityIdSubscription = this.sharedService.getCurrentEntityId().subscribe(entityId => {
            this.pendingDataSubscription = this.fetchApprovals(entityId, this._searchString)
                .pipe(take(1))
                .subscribe(data => {
                    this.assignData(data);
                });
        });
    }

    public updateState(newState: Partial<State>) {
        this.currentState = { ...this.currentState, ...newState };
        this.assignData(this._data);
    }

    public fetchFile(filepath: string, fileDisplayName?: string) {
        const file = <FileToDownload>{
            FileName: filepath,
            FileType: FileTypeEnum.SdsFilePath
        };

        this.dataIsLoading.next(true);
        this.fileManagerService.fetchFile(file)
            .pipe(
                first(), // need to take the first emitted value, otherwise there will be more and more downloaded files accumulated
                finalize(() => this.dataIsLoading.next(false))
            )
            .subscribe((data: DownloadedFile) => {
                this.fileManagerService.downloadFile(data.File, fileDisplayName);
            });
    }

    public searchData() {
        this.cancelActiveSubscribtions();

        this.dataSubscription = this.sharedService.getCurrentEntityId().switchMap((entityId) => {
            this.dataIsLoading.next(true);

            return this.catalogueService.fetchApprovals(entityId, this._searchString, this._searchInFileContent);
        }).pipe(
            map(approvals => approvals.map(app => ({ ...app, GhsCodes: this.catalogueService.getGhsCodes(app) })))
        ).subscribe((data) => {
            this._data = data;

            // resetting state so that the 1st page will be shown after filtering
            this.currentState = {
                ...this.currentState,
                skip: 0,
                take: 10
            };

            this.assignData(data);
        },
            () => {
                this.notificationService.showDefaultMsgError();
            });
    }

    public exportToExcel() {
        const dto = <CatalogueExportDto> {
            ApprovalsIds: this._filteredDataForExport.map(x => x.Id)
        };

        this.dataIsLoading.next(true);
        this.catalogueService.exportToExcel(dto)
            .pipe(
                finalize(() => this.dataIsLoading.next(false))
            )
            .subscribe(
                (data: Blob) => {
                    data['name'] = `Catalogue_Export_${Date.now()}.xlsx`;
                    this.fileManager.downloadFile(data);
                },
                () => this.notificationService.showMsgError('error.catalogue_generate_excel', 'Error on exporting to Excel.')
            );
    }

    private assignData(data: any) {
        // this filtered data should have only filters state applied and not take/skip so that it will all be exported to excel -
        // otherwise only visible items would be exported depending on take/skip state
        this._filteredDataForExport = process(data, { filter: this.currentState.filter }).data as ApprovalDto[];
        this.gridData.next(process(data, this.currentState));

        this._data = data;
        this.dataInitialized.next(true);
        this.dataInitialized.next(false);
        this.page.next(this._page);
        this.pagesCount.next(this._pagesCount);
        this.dataIsLoading.next(false);
    }

    private fetchApprovals(entityId: string, searchString: string) {
        const subject = new ReplaySubject<ApprovalDto[]>(1);
        this.catalogueService.fetchApprovals(entityId, searchString)
            .pipe(
                map(approvals => approvals.map(app => ({ ...app, GhsCodes: this.catalogueService.getGhsCodes(app) })))
            ).subscribe(
                (data) => {
                    subject.next(data);
                },
                () => {
                    this.notificationService.showDefaultMsgError();
                });

        return subject;
    }

    private cancelActiveSubscribtions() {
        if (this.entityIdSubscription && !this.entityIdSubscription.closed) {
            this.entityIdSubscription.unsubscribe();
        }

        if (this.pendingDataSubscription) {
            this.pendingDataSubscription.unsubscribe();
        }

        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    ngOnDestroy(): void {
        if (this.entityIdSubscription) {
            this.entityIdSubscription.unsubscribe();
        }
    }
}
