import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisclaimerService {
  private privacyNotice = new Subject<boolean>();
  privacyNotice$ = this.privacyNotice.asObservable();
  constructor(private _service: DataService) {}

  public UpdateUserSeenGdpr() {
    return this._service.put('Gdpr/Seen', {});
  }

  public GetGdprDefinitionsForAllLanguages() {
    return this._service.get('GDPR', {});
  }

  public GetUserSeenGdpr() {
    return this._service.get('Gdpr/HasSeen', {});
  }

  public ShowPrivacyNotice() {
    return this.privacyNotice.next();
  }
}
