import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import {
  CharacteristicsAndContainersDto, CharacteristicsComboBoxDto,
  ContainerType, ContainerGridDto, ContainerDto
} from '../../../manage-articles.model';
import { Unit } from '../../../../../shared/model';

@Component({
  selector: 'app-manage-containers',
  templateUrl: './manage-containers.component.html',
  styleUrls: ['./manage-containers.component.scss']
})
export class ManageContainersComponent implements OnInit, OnChanges {
  @Input() characteristicsAndContainers: CharacteristicsAndContainersDto[];
  @Input() containerTypes: ContainerType[];

  @Output() containerPut = new EventEmitter();
  @Output() containerDeleted = new EventEmitter();

  Unit = Unit;
  characteristics: CharacteristicsComboBoxDto[];
  containers: ContainerGridDto[];
  initialized: boolean;
  addContainerForm: FormGroup;
  editContainerForm: FormGroup;
  editedContainerIndex: number;

  private temporalId: number;

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.assignIds();
    this.createNamesForComboBox(this.characteristicsAndContainers);
    this.createContainerList(this.characteristicsAndContainers);
    this.addContainerForm = this.createForm();
    this.editContainerForm = this.createForm();
    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.initialized) {
      this.characteristicsAndContainers = changes.characteristicsAndContainers.currentValue;
      this.assignIds();
      this.createNamesForComboBox(this.characteristicsAndContainers);
      this.createContainerList(this.characteristicsAndContainers);
    }
  }

  getDensity(container: ContainerGridDto) {
    return container.Density ? container.Density : '-';
  }

  discardEditForm() {
    this.editedContainerIndex = -1;
    this.clearForm(this.editContainerForm);
  }

  clearForm(form: FormGroup) {
    form.reset();
    form.markAsPristine();
  }

  submitForm(form: FormGroup, id: number) {
    const isEdit = !!id;
    const container = this.collectData(form);

    if (isEdit) {
      container.TempId = id;
      container.Id = id;
    }

    if (form.valid && this.isNewContainer(container) && this.isProperCharacteristic(container)) {
      this.containerPut.emit(container);
      isEdit ? this.discardEditForm() : this.clearForm(form);
      this.temporalId++;
    }
  }

  editContainer(id: number, index: number) {
    this.fillEditForm(id);
    this.editedContainerIndex = index;
  }

  deleteContainer(id: number) {
    this.containerDeleted.emit(id);
  }

  private assignIds() {
    this.temporalId = 1;
    if (this.characteristicsAndContainers) {
      this.characteristicsAndContainers.forEach(c => {
        if (c.Id) {
          c.TempId = c.Id;
        }
      });

      this.characteristicsAndContainers.forEach(cAc =>
        cAc.Containers.map(c => {
          if (c.Id) {
            c.TempId = c.Id;
          }
          if (c.TempId >= this.temporalId) {
            this.temporalId = c.TempId + 1;
          }
        }));
    }
  }

  private createNamesForComboBox(characteristicsAndContainers: CharacteristicsAndContainersDto[]) {
    if (characteristicsAndContainers) {
      this.characteristics = [];
      characteristicsAndContainers.forEach(c => {
        if (c.Subdivision || c.Density) {
          this.characteristics.push({ ...c, Name: this.prepareCharacteristicsName(c) });
        }
      });
    }
  }

  private prepareCharacteristicsName(characteristics: CharacteristicsAndContainersDto): string {
    const subdivision = characteristics.Subdivision ? characteristics.Subdivision : ' - ';
    const density = characteristics.Density ? characteristics.Density : ' - ';

    return `${subdivision}, ${density}`;
  }

  private createContainerList(characteristicsAndContainers: CharacteristicsAndContainersDto[]) {
    if (characteristicsAndContainers) {
      this.containers = [];
      characteristicsAndContainers.forEach(cAc => {
        this.containers.push(...cAc.Containers.map(container => {
          return <ContainerGridDto>{
            TempId: container.TempId,
            CharacteristicsId: cAc.TempId,
            Type: container.Type,
            Unit: container.Unit,
            Capacity: container.Capacity,
            Subdivision: cAc.Subdivision,
            Density: cAc.Density
          };
        }));
      });
    }
  }

  private fillEditForm(id: number) {
    const containerToEdit = this.containers.find(c => c.TempId === id);

    if (containerToEdit) {
      this.editContainerForm.patchValue({
        Capacity: containerToEdit.Capacity,
        Type: containerToEdit.Type.Id,
        Unit: Unit[containerToEdit.Unit],
        Characteristics: containerToEdit.CharacteristicsId
      });
    }
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      Type: ['', Validators.required],
      Capacity: ['', Validators.required],
      Unit: ['', Validators.required],
      Characteristics: ['', Validators.required]
    });
  }

  private isNewContainer(container: ContainerDto): boolean {
    return !this.containers
      .find(c =>
        c.CharacteristicsId === container.CharacteristicsId
        && c.Type.Id === container.Type.Id
        && c.Capacity === container.Capacity
        && Unit[c.Unit] === container.Unit.toString());
  }

  private isProperCharacteristic(container: ContainerDto): boolean {
    const characteristicsId = container.CharacteristicsId;
    const unit = container.Unit;

    return unit !== Unit.l || !!this.characteristicsAndContainers.find(c => c.TempId === characteristicsId).Density;
  }

  private collectData(form: FormGroup): ContainerDto {
    return <ContainerDto>{
      Capacity: form.get('Capacity').value,
      Type: <ContainerType>{
        Id: form.get('Type').value,
        Name: this.findContainerTypeName(form.get('Type').value)
      },
      Unit: form.get('Unit').value,
      CharacteristicsId: form.get('Characteristics').value,
      TempId: this.temporalId
    };
  }

  private findContainerTypeName(id: number) {
    return this.containerTypes.find(t => t.Id === id).Name;
  }
}
