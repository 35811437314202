import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HelpService } from './help.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, OnDestroy {

  helpOpened = false;
  helpSubscription$: Subscription;

  constructor(private service: HelpService) {
    this.helpSubscription$ = new Subscription();
  }

  ngOnInit() {
    this.helpSubscription$.add(
      this.service.helpNotice$.subscribe(() => {
          this.openHelp();
        }
      )
    );
  }

  ngOnDestroy() {
    if (this.helpSubscription$) {
      this.helpSubscription$.unsubscribe();
    }
  }

  closeHelp() {
    this.helpOpened = false;
  }

  openHelp() {
    this.helpOpened = true;
  }
}
