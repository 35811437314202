import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { EntityDto } from 'app/shared/model';
import { SharedService } from 'app/shared/shared.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-entity-select-dropdown',
  templateUrl: './entity-select-dropdown.component.html',
  styleUrls: ['./entity-select-dropdown.component.scss']
})
export class EntitySelectDropdownComponent implements OnInit {
  @Output() change: EventEmitter<string> = new EventEmitter();

  public entities: EntityDto[];
  public currentEntity: EntityDto;

  constructor(public sharedService: SharedService, 
    private notificationService: TranslatedNotificationService) { }

    public ngOnInit(): void {
      this.entities = this.sharedService.entities;
      this.currentEntity = this.sharedService.currentEntity;
    }

  public entityChange(entityId: string) {
    this.sharedService.setCurrentEntity(entityId)
      .pipe(take(1))
      .subscribe(() => {
        this.change.emit(entityId);
      }, () => {
        this.notificationService.showMsgError('error.assign_entity_to_user', 'Error while assigning entity to user');
      });
  }
}
