import { Component, OnInit } from '@angular/core';
import { Language } from '../shared/model';
import { EmailTemplateDto, EmailType } from './shared/email-templates.model';
import { Router } from '@angular/router';
import { EmailTemplatesService } from './shared/email-templates.service';
import { TranslatedNotificationService } from '../shared/translation/translated-notification.service';
import { SharedService } from '../shared/shared.service';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss']
})
export class EmailTemplatesComponent implements OnInit {
  languages: Language[];
  selectedLanguage: Language;

  templates: EmailTemplateDto[];

  medicTemplate: EmailTemplateDto;
  environmentalTemplate: EmailTemplateDto;
  safetyTemplate: EmailTemplateDto;
  finalTemplate: EmailTemplateDto;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private spinnerService: SpinnerService,
    private notificationService: TranslatedNotificationService,
    private emailTemplatesService: EmailTemplatesService) { }

  ngOnInit() {
    this.spinnerService.show('spinner');
    this.languages = this.sharedService.languages;
    this.selectedLanguage = this.languages.find(x => x.Name === 'English');

    this.fetchTemplatesForLanguage();
  }

  fetchTemplatesForLanguage() {
    this.emailTemplatesService.getAvailableTemplates(this.selectedLanguage.LanguageCode)
      .pipe(
        finalize(() => {
          this.spinnerService.hide('spinner');
        })
      )
      .subscribe(
        data => {
          this.templates = data;
          this.medicTemplate = this.templates.find(t => t.EmailType === EmailType.NotificationForMedicSpecialist);
          this.environmentalTemplate = this.templates.find(t => t.EmailType === EmailType.NotificationForEnvironmentalSpecialist);
          this.safetyTemplate = this.templates.find(t => t.EmailType === EmailType.NotificationForSafetySpecialist);
          this.finalTemplate = this.templates.find(t => t.EmailType === EmailType.FinalDecisionNotification);
      },
      err => {
        this.notificationService.showMsgError('error.fetch_templates', 'Error on loading templates.');
      });
  }

  languageChange(language: Language) {
    this.selectedLanguage = <Language>{
      Id: language.Id,
      Name: language.Name,
      LanguageCode: language.LanguageCode
    };

    this.fetchTemplatesForLanguage();
  }

  updateMedicTemplate(template: EmailTemplateDto) {
    this.medicTemplate = template;
  }

  updateEnvironmentalTemplate(template: EmailTemplateDto) {
    this.environmentalTemplate = template;
  }

  updateSafetyTemplate(template: EmailTemplateDto) {
    this.safetyTemplate = template;
  }

  updateFinalTemplate(template: EmailTemplateDto) {
    this.finalTemplate = template;
  }

  saveChanges() {
    const updatedTemplates = this.getUpdatedTemplates();

    this.emailTemplatesService.updateTemplates(updatedTemplates).subscribe(
      () => this.notificationService.showMsgSuccess('success.update_templates', 'Templates updates successfully.'),
      err => this.notificationService.showMsgError('error.update_templates', 'Error on updating templates.')
    );
  }

  goBack() {
    this.router.navigate(['../']);
  }

  private getUpdatedTemplates() {
    const allTemplates = [this.medicTemplate, this.environmentalTemplate, this.safetyTemplate, this.finalTemplate];

    return allTemplates.filter(template => template != null);
  }

}
