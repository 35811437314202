import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { ConfirmationDialogService } from './confirmation-dialog.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  @Input() elementTemplateRef: TemplateRef<any>;

  @Output() leavePage: EventEmitter<any> = new EventEmitter();
  @Output() stayOnPage: EventEmitter<any> = new EventEmitter();

  dialogOpen: boolean;

  constructor(private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
    this.confirmationDialogService.showConfirmationDialog.subscribe(
      (showDialog: boolean) => this.dialogOpen = showDialog
    );
  }

  leftButtonClicked() {
    this.leavePage.emit();
    this.confirmationDialogService.leftButtonClicked();
  }

  rightButtonClicked() {
    this.stayOnPage.emit();
    this.confirmationDialogService.rightButtonClicked();
  }

}
