import { Component, Input } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-dropdown-filter',
  templateUrl: './dropdown-filter.component.html'
})
export class DropdownFilterComponent extends BaseFilterCellComponent {
  @Input() filter: CompositeFilterDescriptor;
  @Input() data: any[];
  @Input() textField: string;
  @Input() valueField: string;
  @Input() filterField: string;

  get selectedValue(): any {
    const filter = this.filterByField(this.valueField);
    return filter ? filter.value : null;
  }

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public onChange(value: any): void {
    this.applyFilter(
      value === undefined ?
        this.removeFilter(this.filterField) :
        this.updateFilter({
            field: this.filterField,
            operator: 'eq',
            value: value
        })
    );
  }

}
