import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileManagerService } from './file-manager.service';
import { TranslationModule } from '../translation/translation.module';

@NgModule({
  declarations: [ FileUploadComponent ],
  imports: [
    CommonModule,
    TranslationModule
  ],
  providers: [
    FileManagerService
  ],
  exports: [
    FileUploadComponent
  ]
})
export class FileManagerModule { }
