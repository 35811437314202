import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Observable, Subject, from, BehaviorSubject } from 'rxjs';
import { switchMap, delay, map, tap, finalize } from 'rxjs/operators';

import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';

import {
  UserDetailsDto,
  UserLanguage,
  Language,
  AppProps,
  EntityDto,
  UserCompleteDto,
  FlashPointSignDto,
  FlashPointSign,
  LimitValueUnitDto,
  LimitValueUnit,
  TranslationSet,
  LanguageCode,
} from '../shared/model';

import { DataService } from '../data.service';
import { CookieService } from 'ngx-cookie-service';
import { SpinnerService } from './spinner/spinner.service';
import { Configuration } from 'app/shared-files/configuration';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private flashPointSignEnum = FlashPointSign;
  private limitValueUnitEnum = LimitValueUnit;

  private flagSrc = [
    {
      'name': 'English',
      'code': 'en',
      'src': 'assets/icons/flags/en.svg'
    }, {
      'name': 'French',
      'code': 'fr',
      'src': 'assets/icons/flags/fr.svg'
    }, {
      'name': 'German',
      'code': 'de',
      'src': 'assets/icons/flags/de.svg'
    }, {
      'name': 'Spanish',
      'code': 'es',
      'src': 'assets/icons/flags/es.svg'
    }, {
      'name': 'Dutch',
      'code': 'nl',
      'src': 'assets/icons/flags/nl.svg'
    }, {
      'name': 'Italian',
      'code': 'it',
      'src': 'assets/icons/flags/it.svg'
    }];

    private observableEntityId: Subject<string>;

  constructor(
    private readonly dataService: DataService,
    private readonly cookieService: CookieService,
    private readonly spinner: SpinnerService,
    private configuration: Configuration,
    private readonly jwtHelperService: JwtHelperService) {
      this.observableEntityId = new BehaviorSubject<string>("");
  }

  public get loggedInUser(): UserDetailsDto {
    const token = localStorage.getItem('myToken');
    if (token != null && token != "undefined") {
      return this.jwtHelperService.decodeToken(token);
    }
  }

  public get currentLanguage(): Language {
    let langCode = 'en';

    let language : Language = {
      Id : langCode,
      LanguageCode : LanguageCode.en,
      Name : 'English'
    };

    let userData = this.loggedInUser; 

    if (!!userData && !!userData.LanguageCode && userData.LanguageCode !== '') {
      langCode = userData.LanguageCode;
    }

    if (this.cookieService.get('language') && this.cookieService.get('language') !== langCode) {
      this.updateLanguageCookie(langCode);
    }

    if(this.configuration.Languages) {
      language = this.configuration.Languages.find(x => x.LanguageCode.toString().toLowerCase() === langCode.toLowerCase());
    }

    return language;
  }

  public get entityId(): string {
    return this.loggedInUser.EntityId;
  }

  public get currentEntity(): EntityDto {
    return this.configuration.Entities?.find(x => x.Id === this.loggedInUser.EntityId);
  }

  public get languages(): Language[] {
    return this.configuration.Languages || [];
  }

  public get entities(): EntityDto[] {
    return this.configuration.Entities || [];
  }

  public get translations(): TranslationSet[] {
    return this.configuration.Translations || [];
  }

  public get userRole(): number {
    return this.loggedInUser.UserRole;
  }

  public setConfiguration(userData: AppProps) : void {
    if(userData.UserClaims) {
      localStorage.setItem('myToken', userData.UserClaims);
    }

    this.configuration.Entities = userData.Entities;
    this.configuration.Languages = userData.Languages;
    this.configuration.Translations = userData.Translations;
    
    this.observableEntityId.next(this.loggedInUser.EntityId);
  }

  setCurrentLanguage(language: Language) {
    this.spinner.show('spinner');

    const userLanguage = <UserLanguage>{
      UserId: this.loggedInUser.UserId,
      LanguageCode: language.LanguageCode
    };

    this.setLanguageForUser(userLanguage)
    .switchMap(() => {
      return this.resetCacheAfterLanguageChange()
        .pipe(finalize(() => window.location.reload()));
    }).subscribe(() => {
        this.updateLanguageCookie(language.LanguageCode.toString());
    });
  }

  setCurrentEntity(entityId: string): Observable<any> {
    const observable = new Subject<any>();

    const dto = {
      UserId: this.loggedInUser.UserId,
      EntityId: entityId
    };

    this.dataService.post('User/SaveUserEntity', dto).subscribe(
      () => {
        this.fetchUserConfigurationData()
            .subscribe(userData => this.setConfiguration(userData));

        observable.next();
      },
      (error) => {
        observable.error(error);
      });

    return observable;
  }

  public fetchUserConfigurationData(): Observable<AppProps> {
    return this.dataService.get('User/GetUserConfigurationData', {});
  }

  public getCurrentEntityId(): Observable<string> {
    return this.observableEntityId;
  }

  public getUserCompleteDetails(): Observable<UserCompleteDto> {
    return this.dataService.get('User/GetCompleteDetails', {});
  }

  public getPermissionsLevel(): Observable<number> {
    return Observable.create((observer) => {
      this.emitPermissionsLevel(this.loggedInUser, observer);
    });
  }

  public mapFlashPointSign() : FlashPointSignDto[] {
    return [
      <FlashPointSignDto>{
        EnumValue: this.flashPointSignEnum.Below,
        Name: '<'
      },
      <FlashPointSignDto>{
        EnumValue: this.flashPointSignEnum.BelowOrEqual,
        Name: '<='
      },
      <FlashPointSignDto>{
        EnumValue: this.flashPointSignEnum.More,
        Name: '>'
      },
      <FlashPointSignDto>{
        EnumValue: this.flashPointSignEnum.MoreOrEqual,
        Name: '>='
      },
      <FlashPointSignDto>{
        EnumValue: this.flashPointSignEnum.Equal,
        Name: '='
      }
    ];
  }

  public mapFinalValueUnit(): LimitValueUnitDto[] {
    return [
      <LimitValueUnitDto>{
        EnumValue: this.limitValueUnitEnum.mg,
        Name: 'mg/m³'
      },
      <LimitValueUnitDto>{
        EnumValue: this.limitValueUnitEnum.ppm,
        Name: 'ppm'
      },
    ];
  }

  validateNumberGreaterThanZero(numberInput: AbstractControl): object {
    if ((numberInput.value !== null) && (numberInput.value <= 0)) {
      return {
        validateNumber: {
          invalidNumber: true
        }
      };
    }

    return null;
  }

  setPositiveNumberValidation(numericInput: AbstractControl, enumInput: AbstractControl) {
    this.positiveNumberValidation(numericInput, enumInput);

    enumInput.valueChanges.subscribe(
      () => {
        this.positiveNumberValidation(numericInput, enumInput);
      });
  }

  setConditionalValidation(conditionInput: AbstractControl, inputToValidation: AbstractControl) {
    this.conditionalValidation(conditionInput, inputToValidation);

    conditionInput.valueChanges.subscribe(
      () => {
        this.conditionalValidation(conditionInput, inputToValidation);
      });
  }

  updateEntities(entities: EntityDto[]) {
    this.configuration.Entities = entities;
  }

  filterMultiselect<T>(multiselect: MultiSelectComponent, filteredList: T[], filteredProperties: string[]): Observable<T[]> {
    const contains = (propertyValue: string) => (filteredElement: T) =>
      filteredProperties.find(fp => filteredElement[fp].toLowerCase().indexOf(propertyValue.toLowerCase()) !== -1);

    return multiselect.filterChange.asObservable().pipe(
      switchMap(value => from([filteredList]).pipe(
        tap(() => multiselect.loading = true),
        delay(500),
        map((data) => data.filter(contains(value)))
      ))
    );
  }

  resetCacheAfterLanguageChange() {
    return this.dataService.get('Languages/ResetCacheAfterLanguageChange', {});
  }

  setLanguageForUser(userLanguage: UserLanguage) {
    return this.dataService.post('User/SaveLanguage', userLanguage);
  }

  private updateLanguageCookie(languageCode: string) {
    this.cookieService.delete('language');

    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 100);
    this.cookieService.set('language', languageCode.toLowerCase(), expires, '/', null, null, 'Strict');
  }

  private emitPermissionsLevel(user: UserDetailsDto, observer: any) {
    if (user === undefined) {
      observer.next(0);
    } else {
      observer.next(user.UserRole);
    }
    observer.complete();
  }

  private positiveNumberValidation(numericInput: AbstractControl, enumInput: AbstractControl) {
    enumInput.value
      ? numericInput.setValidators([this.validateNumberGreaterThanZero, Validators.required])
      : numericInput.setValidators([this.validateNumberGreaterThanZero]);

    numericInput.updateValueAndValidity({ emitEvent: false });
  }

  private conditionalValidation(conditionInput: AbstractControl, inputToValidation: AbstractControl) {
    !!conditionInput.value ? inputToValidation.setValidators([Validators.required]) : inputToValidation.clearValidators();
    inputToValidation.updateValueAndValidity({ emitEvent: false });
  }
}
