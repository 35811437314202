import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'mobile-text',
    styleUrls: ['./mobile-text-input.component.scss'],
    templateUrl: './mobile-text-input.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MobileTextInputComponent),
        multi: true
    }]
})

export class MobileTextInputComponent implements ControlValueAccessor {
    @Input() placeholder: string;
    @Input() label: string;
    @Input() type: 'text'|'search';

    isDisabled: boolean;
    _value: string;

    onChange: (value: any) => void;
    onTouched: () => void;

    writeValue(value: any) { this._value = value; }
    registerOnChange(fn: any) { this.onChange = fn; }
    registerOnTouched(fn: any) { this.onTouched = fn; }
    setDisabledState?(isDisabled: boolean) { this.isDisabled = isDisabled; }

    constructor() {
        this._value = '';
        this.isDisabled = false;
    }

    change() {
        this.onChange(this._value);
    }

    clearValue(event) {
        event.preventDefault();
        this._value = null;
        this.onChange(null);
    }

    get isEmpty(): boolean {
        return !this._value || this._value === '';
    }
}
