import {
    Family, StateOfMatterDto, FlashPointSignDto, ProducerBase,
    SevesoNominativeDto, LimitValueUnit, Unit, GhsDto, GhsEnum, EntityDto
} from '../../shared/model';
import { CustomPphraseDto } from '../../risk-analysis/risk-analysis.model';
import { PphraseKind, PphraseGridDto } from '../manage-pphrases/manage-pphrases.model';
import { CategoryDto, HphraseDto } from 'app/catalogue-view/shared/catalogue-view.model';
import { ArticleHphraseDto } from 'app/requests/new-request/new-request.model';

export enum ViewCard {
    Details,
    ContainersCharacteristics,
    HpPhrases
}

export interface ContainerType {
    Id: number;
    Name: string;
}

export interface ArticleBasicDataDto {
    Id: number;
    Name: string;
    ProducerName: string;
    IsForbidden: boolean;
    Hphrases: string;
    ForbiddenEntities: EntityDto[];   
}

export interface ContainerDto {
    Id: number;
    TempId: number;
    Type: ContainerType;
    Unit: Unit;
    Capacity: number;
    CharacteristicsId: number;
}

export interface ContainerGridDto extends ContainerDto {
    Subdivision: string;
    Density: number;
}

export interface CharacteristicsDto {
    Id: number;
    TempId: number;
    ArticleId: number;
    Subdivision: string;
    Density: number;
}

export interface CharacteristicsComboBoxDto extends CharacteristicsDto {
    Name: string;
}

export interface CharacteristicsAndContainersDto extends CharacteristicsDto {
    Containers: ContainerGridDto[];
}

export interface DangerClassDto {
    Id: number;
    SignalWord: string;
    Subdivision: string;
    Type: string;
    Class: string;
    Notes: string;
    Category: CategoryDto;
    GhsList: GhsDto[];
}

export interface HphraseDetailsDto {
    Id: number;
    TempId: number;
    GhsList: GhsEnum[];
    Code: string;
    Description: string;
    HasDerogation: boolean;
    SignalWord: string;
    Subdivision: string;
    Type: string;
    Class: string;
}

export interface PphraseDto {
    Id: number;
    Code: string;
    Description: string;
}

export interface EditArticleDetailsDto {
    Id: number;
    Name: string;
    Producer: ProducerBase;
    SdsNumber: string;
    Family: Family;
    Cas: string;
    ReachNumber: string;
    SdsDate: Date;
    SdsPath: string;
    SdsDisplayName: string;
    IsEsds: boolean;
    StateOfMatter: StateOfMatterDto;
    LimitValue: number;
    LimitValueUnit: LimitValueUnit;
    FlashPointSign: FlashPointSignDto;
    FlashPointValue: number;
    SevesoNominative: SevesoNominativeDto;
    Synonyms: Array<string>;
    IsForbidden: boolean;
    ForbiddenNote: string;
    IsBiocide: boolean;
    ForbiddenEntities: EntityDto[];     
}

export interface EditArticleDto {
    Details: EditArticleDetailsDto;
    CharacteristicsAndContainers: CharacteristicsAndContainersDto[];
    Hphrases: ArticleHphraseDto[];
    Pphrases: CustomPphraseDto[];
}

export interface ManageArticleData {
    AllNominatives: SevesoNominativeDto[];
    StatesOfMatter: StateOfMatterDto[];
    Producers: ProducerBase[];
    Families: Family[];
    ContainerTypes: ContainerType[];
    Article: EditArticleDto;
    Details: EditArticleDetailsDto;
    InitialDetails: EditArticleDetailsDto;
    CharacteristicsAndContainers: CharacteristicsAndContainersDto[];
    AvailablePphrases: PphraseGridDto[];
    Kinds: PphraseKind[];
    Hphrases: HphraseDto[];
    ArticleHphrases: ArticleHphraseDto[];
    Pphrases: CustomPphraseDto[];
}

export interface UpdatePphrasesDto {
   ArticleId: number;
   ArticlePphrases: CustomPphraseDto[];
}

export interface UpdateHphrasesDto {
    ArticleId: number;
    ArticleHphrases: ArticleHphraseDto[];
 }
