import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DialogModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { RippleModule } from '@progress/kendo-angular-ripple';

import { ConfirmationDeactivateComponent } from './confirmationdeactivate.component';
import { ConfirmationDeactivateService } from './confirmationdeactivate.service';
import { TranslationModule } from '../translation/translation.module';

@NgModule({
  imports: [
    DialogModule,
    ButtonsModule,
    CommonModule,
    RippleModule,
    TranslationModule
  ],
  exports: [],
  declarations: [
    ConfirmationDeactivateComponent
  ],
  providers: [],
  entryComponents: [
    ConfirmationDeactivateComponent
  ]
})
export class ConfirmationDeactivateModule {
  static forRoot(): ModuleWithProviders<ConfirmationDeactivateModule> {
    return {
      ngModule: ConfirmationDeactivateModule,
      providers: [ConfirmationDeactivateService]
    };
  }
}
