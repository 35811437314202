import { Injectable } from '@angular/core';
import { DataService } from '../../data.service';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { ApprovalDto, ApprovalDetailsDto, ApprovalEditDetailsDto, CatalogueExportDto } from './catalogue-view.model';
import { SiteDto, LocationDto, ProducerBase, GhsEnum, LanguageCode } from '../../shared/model';

@Injectable({
  providedIn: 'root',
})
export class CatalogueViewService {
  private _sitesSubject: ReplaySubject<SiteDto[]>;
  private _producersSubject: ReplaySubject<ProducerBase[]>;
  private _locationsSubjects: { [siteId: number]: ReplaySubject<LocationDto[]> };
  public currentApproval: Observable<ApprovalDetailsDto>;
  private approvalSource: BehaviorSubject<ApprovalDetailsDto>;

  constructor(private dataService: DataService) {
    this._sitesSubject = null;
    this._producersSubject = null;
    this._locationsSubjects = {};
    const approval = <ApprovalDetailsDto>{};
    this.approvalSource = new BehaviorSubject(approval);
    this.currentApproval = this.approvalSource.asObservable();
  }

  changeApproval(approval: ApprovalDetailsDto) {
    this.approvalSource.next(approval);
  }

  updateApproval(formData: FormData): Observable<any> {
    return this.dataService.postMultiPartData('Approvals/Update', formData);
  }

  addApproval(formData: FormData): Observable<any> {
    return this.dataService.postMultiPartData('Approvals/Add', formData);
  }

  removeApproval(approvalId: string): Observable<any> {
    return this.dataService.delete('Approvals/Delete/' + approvalId, null);
  }

  removeApprovalByRequestId(requestId: string): Observable<any> {
    return this.dataService.delete('Approval/Delete/' + requestId, null);
  }

  fetchApprovals(entityId: string, searchString: string, searchInFileContent:boolean = false): Observable<ApprovalDto[]> {
    const requestData = {
      entityId: entityId
    };

    if (searchString) {
      requestData['stringToFind'] = searchString;
      requestData['searchInFileContent'] = searchInFileContent;
    }

    return this.dataService.get('Approvals/GetApprovals', requestData);
  }

  fetchApprovalWithGivenId(approvalId: string, language: LanguageCode): Observable<ApprovalDetailsDto> {
    return this.dataService.get('Approvals/GetApprovalDetails', { approvalId, language });
  }

  getApprovalById(approvalId: string): Observable<ApprovalDetailsDto> {
    return this.dataService.get('Approvals/GetApproval', { approvalId });
  }

  getEditApprovalDetails(approvalId: string): Observable<ApprovalEditDetailsDto> {
    return this.dataService.get('Approvals/GetEditApprovalDetails', { approvalId });
  }

  getSuppliers(): Observable<any> {
    return this.dataService.get('Supplier/GetSuppliers', {});
  }

  getGhsCodes(approval: ApprovalDto): GhsEnum[] {
    const ghsCodes = approval.Hphrases
      .filter(hPhrase => hPhrase.DangerClass != null)
      .map(hPhrase => hPhrase.DangerClass.GhsList
          .map(ghs => ghs.Code));
    return this.getUniqueFlattenArray(ghsCodes);
  }

  getSites(entityId: string): Observable<SiteDto[]> {
    if (!this._sitesSubject) {
      this._sitesSubject = new ReplaySubject(1);
    }

    this.dataService.get('SitesByEntity', { entityId: entityId })
      .subscribe(data => { this._sitesSubject.next(data); });

    return this._sitesSubject;
  }

  getLocations(siteId: number): Observable<LocationDto[]> {
    if (!this._locationsSubjects[siteId]) {
      this._locationsSubjects[siteId] = new ReplaySubject(1);
      this.dataService
        .get(`Sites/${siteId}/Locations`, {})
        .subscribe(data => { this._locationsSubjects[siteId].next(data); });
    }
    return this._locationsSubjects[siteId];
  }

  getProducers(): Observable<ProducerBase[]> {
    if (!this._producersSubject) {
      this._producersSubject = new ReplaySubject(1);
      this.dataService
        .get('Producers', {})
        .subscribe(data => { this._producersSubject.next(data); });
    }
    return this._producersSubject;
  }

  exportToExcel(exportDto: CatalogueExportDto): Observable<any> {
    return this.dataService.downloadFile('Approval/ExcelExport', exportDto);
  }

  // **** HELPER METHODS *************

  public getUniqueFlattenArray(array: GhsEnum[][] | GhsEnum[][][]): GhsEnum[] {
    return this.getUniqueArray(this.flattenArray(array));
  }

  private flattenArray<T extends any[]>(array: T): T[] {
    return array.reduce((acc, val) => Array.isArray(val) ? acc.concat(this.flattenArray(val)) : acc.concat(val), []);
  }

  public getUniqueArray(array: any[]) {
    return array.filter((value, elementIndex, thisArray) => thisArray.indexOf(value) === elementIndex);
  }

  /**
   * Transforms array of strings into a string joined by given separator. If input collection is empty, the default text is returned.
   * @param array string array.
   * @param separator separator (";" by default).
   * @param defaultText text returned when the array is empty ("-" by default).
   */
  arrayToString(array: string[], separator: string = '; ', defaultText: string = '-') {
    const joinedString = this.getUniqueArray(array).join(separator);

    return joinedString ? joinedString : defaultText;
  }

  getNameFirstWord(name: string) {
    return name.split(' ')[0];
  }

  getRemainingNamePart(name: string, nameFirstWord: string) {
    if (name === nameFirstWord) {
      return '';
    }
    return name.replace(nameFirstWord.concat(' '), '');
  }
}
