
export enum Report {
    Seveso = 1,
    Vlarem = 2
}

export interface ReportDto {
    Report: Report;
    SiteId: number;
}

export interface ArticleSevesoHazardCategoryDto {
    Id: number;
    ArticleId: number;
    ArticleName: string;
    SevesoHazardCategory: string;
}

export interface SdsReportDto {
    ArticleId: number;
    ArticleName: string;
    SdsDate: Date;
    SdsFileName: string;
}

export interface EsdsReportDto extends SdsReportDto {
    ApprovalId: number;
    Site: string;
    Location: string;
    PurposeOfUse: string;
    ReachNumber: string;
    IsEsds: boolean;
    SapLink: string;
    SapNumber: string;
}

export interface RiskAnalysisReportDto {
    Entity: string;
    ApprovalId: number;
    ArticleName: string;
    Subdivision: string;
    Density: number;
    ContainerType: string;
    Capacity: number;
    Unit: string;
    Site: string;
    Location: string;
    MaxPhysicalRiskScore: number;
    MaxOtherRiskScore: number;
    TechnicalMeasure: number;
    RiskScore: number;
}

export interface EntityReportDto {
    Id: string;
}
