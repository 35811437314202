import { Component, OnInit } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs/operators';

import { DataManagementService } from '../data-management.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { SharedService } from '../../shared/shared.service';

import { ActionType } from '../shared/helper';
import { EntityDto } from 'app/shared/model';
import { GridStateHelper } from '../shared/grid-state';

@Component({
  selector: 'app-manage-entities',
  templateUrl: './manage-entities.component.html',
  styleUrls: ['./manage-entities.component.scss']
})
export class ManageEntitiesComponent implements OnInit {
  closeDialog = true;
  actionType: ActionType;
  editedEntity: EntityDto;
  gridData: GridDataResult;
  items: EntityDto[];
  state: State;

  constructor(private dataManagementService: DataManagementService,
    private gridState: GridStateHelper,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService,
    private sharedService: SharedService) {
      this.state = this.gridState.getBasicState();
    }

  ngOnInit() {
    this.fetchData();
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  closeEntityDialog() {
    this.closeDialog = true;
  }

  openEntityDialog(entity: EntityDto) {
    this.editedEntity = entity;
    this.closeDialog = false;
  }

  applyEntityChange(entity: EntityDto) {
    this.closeDialog = true;
    const existingEntity = this.items.find(x => x.Id === entity.Id);

    if (existingEntity) {
      existingEntity.Name = entity.Name;
    } else {
      this.items.push(entity);
    }

    this.gridData = process(this.items, this.state);
    this.sharedService.updateEntities(this.items);
  }

  private fetchData() {
    this.spinner.show('spinner');
    this.dataManagementService.getEntities()
    .pipe(
      finalize(() => this.spinner.hide('spinner'))
    )
    .subscribe(
      (entities) => {
        this.items = entities;
        this.gridData = process(this.items, this.state);
      },
      () => this.notificationService.showDefaultMsgError()
    );
  }
}
