import { Component, Input } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'sub-menu-item',
    styleUrls: ['./sub-menu-item.component.scss'],
    templateUrl: './sub-menu-item.component.html'
})
export class SubMenuItemComponent {
    @Input() route: string | any[];
    @Input() label: string;

    constructor() {}
}
