import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';

import { HphraseDto, DangerClassDto } from './../../../catalogue-view/shared/catalogue-view.model';
import { RiskTypeDto } from 'app/risk-analysis/risk-analysis.model';
import { DangerClassMultiselectDto } from './hphrases-modal.model';

import { DataManagementService } from 'app/data-management/data-management.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hphrases-modal',
  templateUrl: './hphrases-modal.component.html',
  styleUrls: ['./hphrases-modal.component.scss']
})
export class HphrasesModalComponent implements OnInit, AfterViewInit {
  @Input() editedItem: HphraseDto;
  @Input() riskTypes: RiskTypeDto[];
  @Input() dangerClasses: DangerClassDto[];

  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @Output() hPhraseUpdated: EventEmitter<any> = new EventEmitter();
  @Output() addHphrase: EventEmitter<any> = new EventEmitter();

  @ViewChild('availableDangerClassesMultiselect', { static: false }) multiselect: MultiSelectComponent;

  form: FormGroup;
  formSubmitAttempt = false;
  dangerClassesForSelect: DangerClassDto[];
  selectedDangerClasses: DangerClassDto[] = [];
  dangerClassesTemp: DangerClassDto[] = [];

  private filter: Subscription;

  constructor(
    private dataManagementService: DataManagementService,
    private spinner: SpinnerService,
    private notificationService: TranslatedNotificationService,
    private formBuilder: FormBuilder,
    private sharedService: SharedService
  ) { }

  get editMode(): boolean {
    return this.editedItem !== null && this.editedItem.Id !== 0;
  }

  get dangerClassNotEmpty(): boolean {
    return this.dangerClassesTemp.length !== 0;
  }

  ngOnInit() {
    this.createForm();
    this.fillForm();
  }

  ngAfterViewInit() {
    this.filterSelectData();
  }

  closeDialog() {
    this.dangerClassesTemp = [];
    this.dialogClosed.emit();
  }

  isHphraseSelected(id: number): boolean {
    return this.selectedDangerClasses ? this.selectedDangerClasses.some(item => item.Id === id) : false;
  }

  addDangerClasses() {
    if (this.selectedDangerClasses.length !== 0) {
      this.dangerClassesTemp.push(...this.selectedDangerClasses);
      this.selectedDangerClasses = [];
      this.dangerClassesForSelect = this.getDangerClassesForSelect();
      this.filterSelectData();
    }
  }

  removeDangerClass(id: number) {
    this.dangerClassesTemp = this.dangerClassesTemp.filter(item => item.Id !== id);
    this.dangerClassesForSelect = this.getDangerClassesForSelect();
    this.filterSelectData();
  }

  submitForm() {
    this.spinner.show('spinner');
    this.form.markAllAsTouched();
    this.formSubmitAttempt = true;

    if (this.form.valid) {
      const hPhrase = this.prepareHphrase();
      this.getRequestedMethod(hPhrase)
        .pipe(
          finalize(() => {
            this.spinner.hide('spinner');
          })
        )
        .subscribe(
          updatedItem => {
            if (this.editedItem.Id === 0) {
              this.addHphrase.emit(updatedItem);
            } else {
              this.hPhraseUpdated.emit(updatedItem);
            }
            this.notificationService.showMsgSuccess('success.hPhrase_updated', 'H-phrase entry updated');
          },
          () => {
            this.notificationService.showMsgError(
              'error.hPhrase_update_error',
              'Sorry, we couldn\'t update H-Phrase entry. Try again later.');
          }
        );
    } else {
      this.notificationService.showMsgError('error.invalid_form', 'Please fill all the required fields.');
      this.spinner.hide('spinner');
    }
  }

  getCategory(dataItem: DangerClassDto) {
    return dataItem.Category ? dataItem.Category.Name : '';
  }

  getClass(dataItem: DangerClassDto) {
    return dataItem.Class ? dataItem.Class.Name : '';
  }

  getSignalWord(dataItem: DangerClassDto) {
    return dataItem.SignalWord ? dataItem.SignalWord.Name : '';
  }

  private createForm() {
    this.form = this.formBuilder.group({
      Code: ['', Validators.required],
      Description: ['', Validators.required],
      RiskType: [null, Validators.required],
      HasDerogation: [false, Validators.required]
    });
  }

  private fillForm() {
    this.form.patchValue({
      Code: this.editedItem.Code,
      Description: this.editedItem.Description,
      RiskType: this.editedItem.RiskType,
      HasDerogation: this.editedItem.HasDerogation.toString()
    });
    this.dangerClassesTemp.push(...this.editedItem.DangerClasses);
    this.dangerClassesForSelect = this.getDangerClassesForSelect();
  }

  private getRequestedMethod(hPhrase: HphraseDto) {
    return this.editedItem.Id === 0
      ? this.dataManagementService.addHphrase(hPhrase)
      : this.dataManagementService.updateHphrase(hPhrase);
  }

  private prepareHphrase() {
    return <HphraseDto>{
      Id: this.editedItem.Id,
      Code: this.form.get('Code').value,
      Description: this.form.get('Description').value,
      RiskType: this.form.get('RiskType').value,
      HasDerogation: this.form.get('HasDerogation').value,
      DangerClasses: this.dangerClassesTemp
    };
  }

  private getDangerClassesForSelect(): DangerClassDto[] {
    const editedItemDangerClassesIds = this.dangerClassesTemp.map(x => x.Id);

    return this.dangerClasses.filter(x => editedItemDangerClassesIds.find(id => id === x.Id) == null);
  }

  private filterSelectData() {
    if (!!this.filter) {
      this.filter.unsubscribe();
    }

    const propertiesToCheck = ['TypeName', 'ClassName', 'CategoryName', 'RiskScoreValue'];
    const dangerClassesMultiselect = this.dangerClassesForSelect.map(dc => <DangerClassMultiselectDto>{
      ...dc,
      TypeName: dc.Type ? dc.Type.Name : '',
      ClassName: dc.Class ? dc.Class.Name : '',
      CategoryName: dc.Category ? dc.Category.Name : '',
      RiskScoreValue: dc.RiskScore ? dc.RiskScore.toString() : ''
    });

    this.filter = this.sharedService.filterMultiselect<DangerClassMultiselectDto>(this.multiselect, dangerClassesMultiselect, propertiesToCheck)
      .subscribe((dangerClasses: DangerClassDto[]) => {
        this.dangerClassesForSelect = dangerClasses;
      });
  }
}
