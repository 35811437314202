import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Language, EntityDto } from 'app/shared/model';

import { DisclaimerService } from '../../../disclaimer/disclaimer.service';
import { HelpService } from '../../help/help.service';

@Component({
    selector: 'app-drawer',
    styleUrls: ['drawer.component.scss'],
    templateUrl: 'drawer.component.html'
})

export class DrawerComponent {
    _currentLanguage: Language;
    _currentEntity: EntityDto;

    @Input() show: boolean;
    @Input() userEmail: string;

    @Input() allLanguages: Language[];
    @Input() set currentLanguage(value: Language) { this._currentLanguage = value; }
    @Output() languageChanged: EventEmitter<Language> = new EventEmitter();

    @Input() allEntities: EntityDto[];
    @Input() set currentEntity(value: EntityDto) { this._currentEntity = value; }
    @Output() entityChanged: EventEmitter<EntityDto> = new EventEmitter();

    @Output() linkClicked: EventEmitter<string> = new EventEmitter();

    constructor(
        private disclaimerService: DisclaimerService,
        private helpService: HelpService) { }

    showPrivacyNotice() {
      this.disclaimerService.ShowPrivacyNotice();
    }

    showHelpNotice() {
        this.helpService.showHelpNotice();
    }

    onLinkClicked(route: string) {
        this.linkClicked.emit(route);
    }

    onEntityChanged() {
        this.entityChanged.emit(this._currentEntity);
    }

    onLanguageChanged() {
        this.languageChanged.emit(this._currentLanguage);
    }
}
