import { CharacteristicsDto, ContainerDto } from 'app/requests/new-request/new-request.model';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectedRequestData, CharacteristicComboBoxValue, ContainerComboBoxValue } from './edit-request.model';
import { Unit } from 'app/shared/model';

@Injectable()
export class RequestValidator {
    private requestForm: FormGroup;
    private selectedData: SelectedRequestData;

    initializeFields(requestForm: FormGroup, selectedData: SelectedRequestData) {
        this.requestForm = requestForm;
        this.selectedData = selectedData;
    }

    validateForm(customContainer: boolean, customCharacteristic: boolean): boolean {
        return this.requestForm.valid
            && this.validateCharacteristic(customContainer, customCharacteristic)
            && this.validateContainer(customContainer);
    }

    isLitersSelected(customContainer: boolean) {
        let unit: Unit;
        const container = this.requestForm.get('Container');

        if (customContainer) {
            unit = Unit[this.requestForm.get('NewContainer.Unit').value as string];

            return unit === Unit.l;
        } else if (container && container.value) {
            return (container.value as ContainerComboBoxValue).Unit === Unit.l;
        }
        return false;
    }

    validateCharacteristic(customContainer: boolean, customCharacteristic: boolean) {
        if (this.isLitersSelected(customContainer)) {
            const characteristics = this.requestForm.get('Characteristics').value as CharacteristicComboBoxValue;

            return customCharacteristic ?
                this.requestForm.get('NewCharacteristics.Density') && this.requestForm.get('NewCharacteristics.Density').value != null :
                characteristics != null && characteristics.Density != null;
        }
        return true;
    }

    validateContainer(customContainer: boolean) {
        if (customContainer) {
            return this.requestForm.get('NewContainer').valid;
        }

        return this.requestForm.get('Container') != null;
    }

    checkCharacteriscticDuplicate (characteristic: CharacteristicsDto, filteredCharacteristics: CharacteristicsDto[]) {
        if (filteredCharacteristics) {
            filteredCharacteristics.forEach(element => {
                if (this.doesCharacteriscticExist(element, characteristic)) {
                    characteristic = element;
                }
            });
        }
        return characteristic;
    }

    checkContainerDuplicate (container: ContainerDto, filteredContainers: ContainerDto[]) {
        if (filteredContainers) {
            filteredContainers.forEach(element => {
                if (this.doesContainerExist(element, container)) {
                    container = element;
                }
            });
        }
        return container;
    }

    private doesContainerExist(element: ContainerDto, container: ContainerDto) {
        return element.Type === container.Type
            && element.Capacity === container.Capacity
            && element.Unit === container.Unit
            && element.CharacteristicsId === container.CharacteristicsId;
    }

    private doesCharacteriscticExist(element: CharacteristicsDto, characteristic: CharacteristicsDto) {
        return element.Subdivision
            && element.Density === characteristic.Density
            && element.Subdivision === characteristic.Subdivision;
    }
}
