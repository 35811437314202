import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PrintLabelsModalComponent } from './components/print-labels-modal/print-labels-modal.component';
import { TranslationModule } from '../shared/translation/translation.module';

@NgModule({
  declarations: [PrintLabelsModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslationModule
  ],
  exports: [PrintLabelsModalComponent]
})
export class PrintingModuleModule { }
