import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidationErrors, AbstractControl } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { UserRole, UserCompleteDto, Specialization, EntityDto, UserEntityDto } from 'app/shared/model';

import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { DataManagementService } from 'app/data-management/data-management.service';
import { NavigationService } from 'app/navigation/shared/navigation.service';
import { ActionType } from '../../shared/helper';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {
  @Input() editedUser: UserCompleteDto;
  @Input() actionType: ActionType;
  @Input() userDialogOpen: boolean;
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();

  public userForm: FormGroup;
  public userRoles: { Name: string, Value: UserRole }[];
  public userRolesNames: string[];
  public allEntities: Array<EntityDto> = [];
  public selectedEntity: EntityDto = <EntityDto>{};
  public formSubmitAttempt = false;
  public selectedEntities: Array<EntityDto>=[];  
  public selectedEntitiesForMedical: Array<EntityDto>=[];
  public selectedEntitiesForEnvironment: Array<EntityDto>=[];
  public selectedEntitiesForSafety: Array<EntityDto>=[];
  
  private user: UserCompleteDto = <UserCompleteDto>{};

  constructor(
    private notificationService: TranslatedNotificationService,
    private formBuilder: FormBuilder,
    private dataManagementService: DataManagementService,
    private spinner: SpinnerService,
    private navigationService: NavigationService
  ) { }

  public ngOnInit() {
    this.createForm();
    this.loadEntities();

    this.userRolesNames = Object.keys(UserRole).filter(key => isNaN(Number(key)));
    this.userRoles = this.userRolesNames.map(name => ({ Name: name, Value: UserRole[name] }));
  }

  public discard() {
    this.userForm.reset();
    this.closeUserDialog();
  }

  private loadEntities() {
    this.navigationService.getEntities().subscribe(
      (entities) => {
        this.allEntities = entities;
        this.mapSelectedEntity();
        this.fillForm();
      },
      () => this.notificationService.showDefaultMsgError()
    );
  }

  public assignEntityToUser(entityName: string) {
    this.user.EntityName = entityName;
  }

  public refreshSelectedEntities() :void {
    this.selectedEntitiesForMedical = this.selectedEntitiesForMedical.filter(e=> this.selectedEntities.indexOf(e)>-1);
    this.selectedEntitiesForEnvironment = this.selectedEntitiesForEnvironment.filter(e=> this.selectedEntities.indexOf(e)>-1);
    this.selectedEntitiesForSafety = this.selectedEntitiesForSafety.filter(e=> this.selectedEntities.indexOf(e)>-1);
  }

  public processForm() {
    this.userForm.markAllAsTouched();
    this.formSubmitAttempt = true;
    if (this.userForm.valid) {
      this.updateOrCreateNewUser();
    } 
    else if(this.getFirstNameControl().hasError('whitespace')){
      this.notificationService.showMsgError('error.firstName_is_whitespace', 'First name field cannot be filled with whitespaces.');
    }
    else if(this.getLastNameControl().hasError('whitespace')){
      this.notificationService.showMsgError('error.lastName_is_whitespace', 'Last name field cannot be filled with whitespaces.');
    }
    else {
      this.notificationService.showMsgError('error.invalid_form', 'Please fill all the required fields.');
    }
  }

  private closeUserDialog() {
    this.dialogClosed.emit();
  }

  private fillForm() {
    if (this.actionType === ActionType.EDIT) {
      this.getRoleControl().setValue(UserRole[this.editedUser.UserRole]);
      this.getEmailControl().setValue(this.editedUser.Email);
      this.selectedEntity = this.allEntities.find(x => x.Name === this.editedUser.EntityName);
      this.getFirstNameControl().setValue(this.editedUser.FirstName);
      this.getLastNameControl().setValue(this.editedUser.LastName);
      this.getIsMedicalSpecialistUserControl().setValue(this.editedUser.Specializations.indexOf(1) > -1);
      this.getEnvironmentSpecialistUserControl().setValue(this.editedUser.Specializations.indexOf(2) > -1);
      this.getIsSafetySpecialistUserControl().setValue(this.editedUser.Specializations.indexOf(3) > -1);
      this.getIsExternalUserControl().setValue(this.editedUser.IsExternalUser);
    }
    this.setFormControlActivity(this.getMedicalEntityControl(), this.getIsMedicalSpecialistUserControl().value || false);
    this.setFormControlActivity(this.getEnvironmentEntityControl(), this.getEnvironmentSpecialistUserControl().value || false);
    this.setFormControlActivity(this.getSafetyEntityControl(), this.getIsSafetySpecialistUserControl().value || false);
  }

  public assignRole(selectedUserRole: string): number {
    const roleEnum = this.userRoles.find(role => role.Name === selectedUserRole).Value;

    return roleEnum;
  }

  private setFormControlActivity(form:AbstractControl, enabled : boolean) :void {
    if(enabled) {
      form.enable();
    } else {
      form.disable();
    }
  }

  public markUserAsMedicalSpecialist(isMarkedAsMedicalpecialist: boolean) {
    this.setFormControlActivity(this.getMedicalEntityControl(), isMarkedAsMedicalpecialist);
  }

  public markUserAsEnvironmentSpecialist(isMarkedAsEnvironmentSpecialst: boolean) {
    this.setFormControlActivity(this.getEnvironmentEntityControl(), isMarkedAsEnvironmentSpecialst);
  }

  public markUserAsSafetySpecialist(isMarkedAsSafetySpecialst: boolean) {
    this.setFormControlActivity(this.getSafetyEntityControl(), isMarkedAsSafetySpecialst);
  }

  private mapSelectedEntity() {
    if (this.actionType === ActionType.EDIT) {
      this.selectedEntity = this.allEntities.filter(x => x.Name === this.editedUser.EntityName)[0];
      this.selectedEntities = this.allEntities.filter(x => this.editedUser.RelatedEntities.map(m=>m.EntityId).indexOf(x.Id) > -1);

      this.selectedEntitiesForMedical = this.allEntities.filter(x => this.editedUser.RelatedEntities.filter(s=>s.ForMedical).map(m=>m.EntityId).indexOf(x.Id) > -1);
      this.selectedEntitiesForEnvironment = this.allEntities.filter(x => this.editedUser.RelatedEntities.filter(s=>s.ForEnvironment).map(m=>m.EntityId).indexOf(x.Id) > -1);
      this.selectedEntitiesForSafety = this.allEntities.filter(x => this.editedUser.RelatedEntities.filter(s=>s.ForSafety).map(m=>m.EntityId).indexOf(x.Id) > -1);
    } else {
      this.selectedEntity = this.allEntities[0];
    }
  }

  private createForm() {
    this.userForm = this.formBuilder.group({
      Role: ['', Validators.required],
      Email: ['', [Validators.maxLength(100), Validators.required, Validators.email]],
      EntityName: [{}, Validators.required],
      EntityNames: [null, Validators.required],
      FirstName: ['', [Validators.required, this.noWhitespaceValidator]],
      LastName: ['', [Validators.required, this.noWhitespaceValidator]],
      IsMedicalSpecialist: [false],
      IsEnvironmentSpecialist: [false],
      IsSafetySpecialist: [false],
      IsExternalUser: [false],
      EntityNamesForMedical:[null, Validators.required],
      EntityNamesForEnvironment:[null, Validators.required],
      EntityNamesForSafety:[null, Validators.required]
    });
  }

  private setValuesForUser() {
    this.user.UserId = (this.editedUser === undefined) ? null : this.editedUser.UserId;
    this.user.UserRole = this.assignRole(this.getRoleControl().value);
    this.user.Email = this.getEmailControl().value;
    this.user.FirstName = this.getFirstNameControl().value;
    this.user.LastName = this.getLastNameControl().value;
    this.user.EntityName = this.getEntityNameControl().value.Name;
    this.user.IsExternalUser = this.getIsExternalUserControl().value;
    this.user.RelatedEntities =  Array.from(this.getSelectedRelatedEntities().values());
    this.setSpecializationsForUser();
  }

  private getSelectedRelatedEntities() : Map<String,UserEntityDto> {
    let userEntitiesDictionary = new Map<string, UserEntityDto>();

    this.selectedEntities.forEach(
      entity => userEntitiesDictionary.set(
        entity.Id, {
          UserId: this.user.UserId,
          EntityId: entity.Id,
          ForMedical: false,
          ForEnvironment: false,
          ForSafety: false
        }));

    if (this.getIsMedicalSpecialistUserControl().value) {
      this.selectedEntitiesForMedical.forEach(m => userEntitiesDictionary.get(m.Id).ForMedical = true);
    }
    else {
      this.selectedEntitiesForMedical = [];
    }
    if (this.getEnvironmentSpecialistUserControl().value) {
      this.selectedEntitiesForEnvironment.forEach(m => userEntitiesDictionary.get(m.Id).ForEnvironment = true);
    }
    else {
      this.selectedEntitiesForEnvironment = [];
    } 
    if (this.getIsSafetySpecialistUserControl().value) {
     this.selectedEntitiesForSafety.forEach(m => userEntitiesDictionary.get(m.Id).ForSafety = true); 
    }
    else {
      this.selectedEntitiesForSafety = [];
    } 
    return userEntitiesDictionary;
  }

  private setSpecializationsForUser() {
    this.user.Specializations = [];
    if (this.getIsMedicalSpecialistUserControl().value) {
      this.user.Specializations.push(Specialization.Medic);
    }
    if (this.getEnvironmentSpecialistUserControl().value) {
      this.user.Specializations.push(Specialization.Environment);
    }
    if (this.getIsSafetySpecialistUserControl().value) {
      this.user.Specializations.push(Specialization.Safety);
    }
  }

  private getRoleControl() : AbstractControl {
    return this.userForm.get('Role');
  }

  private getEmailControl() : AbstractControl {
    return this.userForm.get('Email');
  }

  private getFirstNameControl() : AbstractControl {
    return this.userForm.get('FirstName');
  }

  private getLastNameControl() : AbstractControl {
    return this.userForm.get('LastName');
  }

  private getEntityNameControl() : AbstractControl {
    return this.userForm.get('EntityName');
  }

  private getIsExternalUserControl() : AbstractControl {
    return this.userForm.get('IsExternalUser');
  }

  private getIsMedicalSpecialistUserControl() : AbstractControl {
    return this.userForm.get('IsMedicalSpecialist');
  }

  private getEnvironmentSpecialistUserControl() : AbstractControl {
    return this.userForm.get('IsEnvironmentSpecialist');
  }

  private getIsSafetySpecialistUserControl() : AbstractControl {
    return this.userForm.get('IsSafetySpecialist');
  }

  private getMedicalEntityControl() : AbstractControl {
    return this.userForm.get('EntityNamesForMedical');
  }

  private getEnvironmentEntityControl() : AbstractControl {
    return this.userForm.get('EntityNamesForEnvironment'); 
  }

  private getSafetyEntityControl() : AbstractControl {
    return this.userForm.get('EntityNamesForSafety');
  }

  private updateOrCreateNewUser() {
    this.spinner.show('spinner');
    this.setValuesForUser();

    if (this.actionType === ActionType.NEW) {
      this.createNewUser();
    } else {
      this.updateUser();
    }
  }

  private createNewUser() {
    this.dataManagementService.addNewUser(this.user)
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe(
        () => this.closeUserDialog(),
        () => this.notificationService.showMsgError('error.creating_user', 'Error while creating new user'));
  }

  private updateUser() {
    this.dataManagementService.updateExistingUser(this.user)
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe(
        () => this.closeUserDialog(),
        () => this.notificationService.showMsgError('error.updating_user', 'Error while updating user')
      );
  }

  private noWhitespaceValidator(control: FormControl): ValidationErrors | null {
    const isWhitespace = (control.value || '').trim().length === 0;

    return isWhitespace ? { 'whitespace': true } : null;
  }
}
