import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmationDialogService } from '../../shared/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss', '../../shared/styles/top-view.scss']
})
export class NewRequestComponent {
  formDirty = false;

  constructor(private confirmationDialogService: ConfirmationDialogService) {}

  onFormStatusChanged(dirty: boolean) {
    this.formDirty = dirty;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.formDirty) {
      return this.confirmationDialogService.showDialog();
    }

    return true;
  }
}
