import { Component, OnInit } from '@angular/core';

import { finalize } from 'rxjs/operators';
import { State, process } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { DataManagementService } from '../data-management.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { GridStateHelper } from '../shared/grid-state';
import { PphraseGridDto, Pictogram } from './manage-pphrases.model';

@Component({
  selector: 'app-manage-pphrases',
  templateUrl: './manage-pphrases.component.html',
  styleUrls: ['./manage-pphrases.component.scss']
})
export class ManagePphrasesComponent implements OnInit {

  items: PphraseGridDto[] = [];
  gridData: GridDataResult;
  state: State;
  editedPphrase: PphraseGridDto;
  showModal = false;
  codeList: string[];

  constructor(
    private dataService: DataManagementService,
    private gridState: GridStateHelper,
    private spinner: SpinnerService,
    private notification: TranslatedNotificationService
  ) {
    this.state = this.gridState.getBasicState();
  }

  ngOnInit() {
    this.fetchData();
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  closeDialog() {
    this.showModal = false;
    this.spinner.hide('spinner');
  }

  openDialog() {
    this.showModal = true;
  }

  updateItems(item: PphraseGridDto) {
    if (item == null) {
      this.notification.showMsgError('error.pPhrase_not_exists', 'This p-phrase does not exist.');
    } else {
    this.closeDialog();
    this.updatePphrase(item);
    }
  }

  addPphrase(item: PphraseGridDto) {
    if (item == null) {
      this.notification.showMsgError('error.pPhrase_not_exists', 'This p-phrase does not exist.');
    } else {
      this.closeDialog();
      this.items.push(item);
      this.gridData = process(this.items, this.state);
    }
  }

  editPphrase(pPhrase: PphraseGridDto) {
    this.editedPphrase = <PphraseGridDto> {
      Id: pPhrase.Id,
      Code: pPhrase.Code,
      Description: pPhrase.Description,
      Kind: pPhrase.Kind,
      Type: pPhrase.Type,
      Pictogram: pPhrase.Pictogram
    };

    this.openDialog();
  }

  createNewPphrase() {
    const newPphrase = <PphraseGridDto>{
      Code: '',
      Id: 0,
      Pictogram: Pictogram.Pic0,
    };
    this.editPphrase(newPphrase);
  }

  private fetchData(): void {
    this.spinner.show('spinner');

    this.dataService.getAllPphrases()
      .pipe(finalize(() => {
        this.spinner.hide('spinner');
        this.codeList = this.items.map(x => x.Code);
        this.items = this.items.filter(x => x.RiskAnalysis === 0);
        this.gridData = process(this.items, this.state);
        }))
      .subscribe(pPhraseList => {
        this.items = pPhraseList;
      },
      () => this.notification.showDefaultMsgError()
    );
  }

  private updatePphrase(item: PphraseGridDto) {
    const updatedPphrase = this.items.find(p => p.Id === item.Id);
    updatedPphrase.Description = item.Description;
    updatedPphrase.Code = item.Code;
    updatedPphrase.Kind = item.Kind;
    updatedPphrase.Type = item.Type;
    updatedPphrase.Pictogram = item.Pictogram;
  }

}
