import { Component, OnInit } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { DataManagementService } from '../data-management.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { EntityDto, SiteGridDto } from 'app/shared/model';

@Component({
  selector: 'app-manage-sites',
  templateUrl: './manage-sites.component.html',
  styleUrls: ['./manage-sites.component.scss']
})
export class ManageSitesComponent implements OnInit {
  dialogOpen = false;
  selectedItem: SiteGridDto;
  gridData: GridDataResult;
  entities: EntityDto[];
  items: SiteGridDto[];
  state: State = {
    skip: 0,
    take: 10,

    filter: {
      logic: 'and',
      filters: []
    }
  };

  constructor(private dataManagementService: DataManagementService,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService) { }

  ngOnInit() {
    this.fetchData();
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  openModal(siteDto: SiteGridDto) {
    this.selectedItem = siteDto;
    this.dialogOpen = true;
  }

  applyChange(gridItem: SiteGridDto) {
    this.dialogOpen = false;
    this.selectedItem = null;

    if (gridItem) {
      const existingGridItem = this.items.find(x => x.Id === gridItem.Id);

      if (existingGridItem) {
        existingGridItem.Name = gridItem.Name;
        existingGridItem.EntityId = gridItem.EntityId;
        existingGridItem.EntityName = gridItem.EntityName;
      } else {
        this.items.push(gridItem);
      }

      this.gridData = process(this.items, this.state);
    }
  }

  private fetchData() {
    this.spinner.show('spinner');

    forkJoin([this.dataManagementService.getGridSites(), this.dataManagementService.getEntities()]).pipe(
      finalize(() => this.spinner.hide('spinner'))
    ).subscribe(
      ([sites, entities]) => {
        this.entities = entities;
        this.items = sites;
        this.gridData = process(this.items, this.state);
      },
      () => this.notificationService.showDefaultMsgError()
    );
  }
}
