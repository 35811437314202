import { Component } from '@angular/core';
import { SharedService } from 'app/shared/shared.service';

@Component({
    selector: 'app-language-select-dropdown',
    styleUrls: ['./language-select-dropdown.component.scss'],
    templateUrl: './language-select-dropdown.component.html'
})
export class LanguageSelectDropdownComponent {
    constructor(public sharedService: SharedService) { }

    languageChange(languageCode: string) {
        const language = this.sharedService.languages
            .find(x => x.LanguageCode.toString().toLowerCase() === languageCode.toLowerCase());
        this.sharedService.setCurrentLanguage(language);
    }

    getFlagSrc(languageCode: string): string {
        if (languageCode) {
            const code = languageCode.toString().toUpperCase();
            return 'assets/GDPR/' + code + '.svg';
        }

        return 'assets/GDPR/EN.svg';
    }
}
