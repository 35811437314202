import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ApprovalStatus, ApprovalType, ApprovalStatusEnum } from './../requests-view/requests-view.model';
import { UserDetailsDto, Specialization } from '../../shared/model';
import { RequestDetailsDto } from '../request-view/request-view.model';

@Component({
  selector: 'app-request-details-approvals',
  templateUrl: './request-details-approvals.component.html',
  styleUrls: ['./request-details-approvals.component.scss']
})
export class RequestDetailsApprovalsComponent implements OnInit {
  @Input() set data(data: ApprovalStatus[]) {
    this.statuses = data.slice(0, data.length - 1);
  }
  @Input() request: RequestDetailsDto;
  @Input() requestId: number;
  @Input() specializations: number[];
  @Output() newMedicStatus = new EventEmitter();
  @Output() newEnvironmentalStatus = new EventEmitter();
  @Output() newSafetyStatus = new EventEmitter();
  @Output() newFinalStatus = new EventEmitter();
  @Output() canEditDetails = new EventEmitter();
  @Output() dirtyStatusChange = new EventEmitter();

  user: UserDetailsDto;
  statuses: ApprovalStatus[];
  medicApprovalType: ApprovalType;
  environmentalApprovalType: ApprovalType;
  safetyApprovalType: ApprovalType;
  finalApprovalType: ApprovalType;
  isApprovalStateSetByMedic: boolean;
  isApprovalStateSetByEnvironmental: boolean;
  isApprovalStateSetBySafety: boolean;
  isApprovalStateSetByAll: boolean;
  isMedic: boolean;
  isEnvironmental: boolean;
  isSafety: boolean;
  isRequestApproved: boolean;

  constructor() { }

  ngOnInit() {
    this.medicApprovalType = ApprovalType.Medic;
    this.safetyApprovalType = ApprovalType.Safety;
    this.environmentalApprovalType = ApprovalType.Environment;
    this.finalApprovalType = ApprovalType.Final;
    this.isApprovalStateSetByMedic = this.checkMedicApproval();
    this.isApprovalStateSetByEnvironmental = this.checkEnvironmentalApproval();
    this.isApprovalStateSetBySafety = this.checkSafetyApproval();
    this.isApprovalStateSetByAll = this.checkAllApprovals();
    this.isMedic = this.specializations.indexOf(Specialization.Medic) !== -1;
    this.isEnvironmental = this.specializations.indexOf(Specialization.Environment) !== -1;
    this.isSafety = this.specializations.indexOf(Specialization.Safety) !== -1;
  }

  checkAllApprovals(): boolean {
    return (this.checkIfRequestApproved(this.request.MedicApproval.EnumValue)
      && this.checkIfRequestApproved(this.request.SafetyApproval.EnumValue)
      && this.checkIfRequestApproved(this.request.EnvironmentalApproval.EnumValue));
  }

  checkMedicApproval(): boolean {
    return this.request.MedicApproval.EnumValue !== ApprovalStatusEnum.NotApprovedYet;
  }

  checkEnvironmentalApproval(): boolean {
    return this.request.EnvironmentalApproval.EnumValue !== ApprovalStatusEnum.NotApprovedYet;
  }

  checkSafetyApproval(): boolean {
    return this.request.SafetyApproval.EnumValue !== ApprovalStatusEnum.NotApprovedYet;
  }

  updateMedicStatus(event) {
    this.isApprovalStateSetByMedic = true;
    this.enableEditDetails();
    this.newMedicStatus.emit(event);
    this.dirtyStatusChange.emit(false);
  }

  updateEnvironmentalStatus(event) {
    this.isApprovalStateSetByEnvironmental = true;
    this.enableEditDetails();
    this.newEnvironmentalStatus.emit(event);
    this.dirtyStatusChange.emit(false);
  }

  updateSafetyStatus(event) {
    this.isApprovalStateSetBySafety = true;
    this.enableEditDetails();
    this.newSafetyStatus.emit(event);
    this.dirtyStatusChange.emit(false);
  }

  updateFinalStatus(event) {
    this.newFinalStatus.emit(event);
    this.enableEditDetails();
    this.dirtyStatusChange.emit(false);
  }

  onChangeApprovalComponentDirtyStatusChange(dirty: boolean) {
    this.dirtyStatusChange.emit(dirty);
  }

  enableEditDetails() {
    this.canEditDetails.emit(!this.request.IsMarkedAsCorrectBySpecialist);
  }

  isCompletelyApproved($event) {
    this.isRequestApproved = $event;
    this.canEditDetails.emit(false);
  }

  private checkIfRequestApproved(status: ApprovalStatusEnum) {
    return status === ApprovalStatusEnum.Approved || status === ApprovalStatusEnum.ApprovedUnderConditions;
  }
}
