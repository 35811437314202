import { Component, OnInit } from '@angular/core';

import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';

import { TechnicalMeasureDto } from '../../risk-analysis/risk-analysis.model';
import { LanguageCode } from '../../shared/model';
import { GridStateHelper } from '../shared/grid-state';

import { DataManagementService } from '../data-management.service';
import { SharedService } from '../../shared/shared.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { TranslatedNotificationService } from '../../shared/translation/translated-notification.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-manage-technical-measures',
  templateUrl: './manage-technical-measures.component.html',
  styleUrls: ['./manage-technical-measures.component.scss']
})
export class ManageTechnicalMeasuresComponent implements OnInit {

  items: TechnicalMeasureDto[] = [];
  gridData: GridDataResult;
  state: State;
  showModal = false;
  editedTechnicalMeasure: TechnicalMeasureDto;

  constructor(
    private dataService: DataManagementService,
    private gridState: GridStateHelper,
    private sharedService: SharedService,
    private spinner: SpinnerService,
    private notification: TranslatedNotificationService
  ) {
    this.state = this.gridState.getBasicState();
  }

  ngOnInit() {
    this.fetchData();
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  closeDialog() {
    this.showModal = false;
  }

  openDialog() {
    this.showModal = true;
  }

  editMeasure(measure: TechnicalMeasureDto) {
    this.editedTechnicalMeasure = <TechnicalMeasureDto> {
      Id: measure.Id,
      Name: measure.Name,
      Value: measure.Value
    };

    this.openDialog();
  }

  updateItems(item: TechnicalMeasureDto) {
    this.closeDialog();

    const updatedMeasure = this.items.find(m => m.Id === item.Id);
    updatedMeasure.Name = item.Name;
    updatedMeasure.Value = item.Value;

    this.gridData = process(this.items, this.state);
  }

  private fetchData(): void {
    this.spinner.show('spinner');

    this.dataService.getTechnicalMeasures(this.sharedService.currentLanguage.LanguageCode)
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe(
        technicalMeasures => {
          this.items = technicalMeasures;
          this.gridData = process(this.items, this.state);
        },
        () => this.notification.showDefaultMsgError()
      );
  }

}
