import { Component, EventEmitter, Output, Input, TemplateRef, ViewChild, OnChanges } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, AbstractControl } from '@angular/forms';

import { EMPTY } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { RequestService } from './../../request.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { ModalsService } from '../../../shared/modal/modals.service';

import { ContactDto, ProducerDto, ContactType, ProducerBase } from './../../../shared/model';
import { RequestDetailsDto } from '../request-view.model';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-edit-producer-modal',
  styleUrls: ['./edit-producer-modal.component.scss', '../../../shared/modal/modal-content.scss'],
  templateUrl: './edit-producer-modal.component.html'
})
export class EditProducerModalComponent implements OnChanges {
  @Input() editProducerOpen: boolean;
  @Input() requestData: RequestDetailsDto;
  @Input() requestId: number;
  @Input() producerBase: ProducerBase;
  @Input() openedFromManagement = false;

  @Input() set dialogOpen(isOpen: boolean) {
    if (isOpen && !this._modalShown) {
      setTimeout(() => {
        this.modalsService.showModal('editProducerModal', this.template, this.editProducerDialogClosed.bind(this));
        this._modalShown = true;
      }, 0);
    } else if (!isOpen && this._modalShown) {
      this.modalsService.closeModal('editProducerModal');
      this._modalShown = false;
    }
  }

  @ViewChild(TemplateRef, { static: false }) template: TemplateRef<any>;
  @Output() formStateChange: EventEmitter<boolean> = new EventEmitter();
  @Output() saveProducerChanges: EventEmitter<ProducerDto> = new EventEmitter();
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();

  producerForm: FormGroup;
  contactList: ContactDto[] = [];
  producer: ProducerDto;
  phoneList: ContactDto[];
  emailList: ContactDto[];
  emergencyList: ContactDto[];
  ContactTypeEnum: ContactType;
  formSubmitAttempt = false;

  private _modalShown: boolean;
  private emailMaxLength = environment.emailMaxLength;
  private phoneMaxLength = environment.phoneMaxLength;
  private contactNoteMaxLength = environment.contactNoteMaxLength;

  constructor(
    private modalsService: ModalsService,
    private notificationService: TranslatedNotificationService,
    private requestService: RequestService,
    private spinner: SpinnerService,
    private formBuilder: FormBuilder) {
    this._modalShown = false;
  }

  ngOnChanges(changes) {
    const openingModal = changes.dialogOpen && changes.dialogOpen.currentValue && !changes.dialogOpen.previousValue;
    if (openingModal && this.producerBase && this.requestData) {
      this.contactList = [];
      this.loadData();
    }
  }

  loadData() {
    this.spinner.show('spinner');
    if (this.producerBase.Id === 0) {
      this.producer = <ProducerDto>{
        Id: 0,
        Name: '',
        Address: '',
        Website: '',
        Contacts: []
      };
      this.createForm();
      this.fillForm();
      this.spinner.hide('spinner');
    } else {
      this.requestService.getProducer(this.producerBase.Id)
        .pipe(finalize(() => this.spinner.hide('spinner')))
        .subscribe(producer => {
          this.producer = producer;
          this.fillForm();
        });
      this.createForm();
    }
    this.producerForm.valueChanges.subscribe(() => {
      this.formStateChange.emit(this.producerForm.dirty);
    });
  }

  editProducerDialogClosed() {
    this.dialogClosed.emit();
  }

  submitForm() {
    this.producerForm.markAllAsTouched();
    this.formSubmitAttempt = true;
    const producer = this.getProducerFromForm();

    if (this.producerForm.valid) {
      this.spinner.show('spinner');
      if (!this.openedFromManagement) {
        this.updateProducerIfRequestNotYetAccepted(producer);
      } else {
        this.updateProducer(producer);
      }
    } else {
      this.contactList = [];
      this.notificationService.showMsgError('error.invalid_form', 'Please fill all the required fields.');
    }
  }

  public discard() {
    this.producerForm.reset();
    this.contactList = [];
    this.formStateChange.emit(false);
    this.saveProducerChanges.emit(null);
    this.formSubmitAttempt = false;
    this.dialogClosed.emit();
  }

  editProducerClose(producer: ProducerDto) {
    if (producer) {
      this.saveProducerChanges.emit(<ProducerDto>{
        Id: producer.Id,
        Name: producer.Name,
        Address: producer.Address,
        Contacts: producer.Contacts,
        Website: producer.Website,
        IsVerified: producer.IsVerified
      });

      this.dialogClosed.emit();
      this.formSubmitAttempt = false;
    }
  }

  addPhoneClickButton(): void {
    (<FormArray>this.producerForm.get('Phones')).push(this.addPhoneFormGroup());
  }

  addEmailClickButton(): void {
    (<FormArray>this.producerForm.get('Emails')).push(this.addEmailFormGroup());
  }

  addEmergencyClickButton(): void {
    (<FormArray>this.producerForm.get('Emergencies')).push(this.addEmergencyFormGroup());
  }

  private createForm() {
    this.producerForm = this.formBuilder.group({
      Name: [this.producerBase.Name, Validators.compose([Validators.required, Validators.maxLength(60)])],
      Address: ['', Validators.maxLength(150)],
      Phones: this.formBuilder.array([]),
      Emails: this.formBuilder.array([]),
      Emergencies: this.formBuilder.array([]),
      Website: ['', Validators.maxLength(200)]
    });
  }

  private addPhoneFormGroup(): FormGroup {
    return this.formBuilder.group({
      PhoneValue: ['', Validators.maxLength(this.phoneMaxLength)],
      PhoneNotes: ['', Validators.maxLength(this.contactNoteMaxLength)]
    });
  }

  private addEmailFormGroup(): FormGroup {
    return this.formBuilder.group({
      EmailValue: ['', [Validators.maxLength(this.emailMaxLength), Validators.email]],
      EmailNotes: ['', Validators.maxLength(this.contactNoteMaxLength)]
    });
  }

  private addEmergencyFormGroup(): FormGroup {
    return this.formBuilder.group({
      EmergencyValue: ['', Validators.maxLength(this.phoneMaxLength)],
      EmergencyNotes: ['', Validators.maxLength(this.contactNoteMaxLength)]
    });
  }

  private getControls(type: string): FormArray {
    return <FormArray>this.producerForm.controls[type];
  }

  private fillForm() {
    this.phoneList = this.getContactList(ContactType.Phone);
    this.emailList = this.getContactList(ContactType.Email);
    this.emergencyList = this.getContactList(ContactType.Emergency);
    this.producerForm.patchValue({
      Address: this.producer.Address,
      Website: this.producer.Website,
    });
    const phoneControl = <FormArray>this.producerForm.controls.Phones;
    const emailControl = <FormArray>this.producerForm.controls.Emails;
    const emergencyControl = <FormArray>this.producerForm.controls.Emergencies;

    this.fillContacts(phoneControl, emailControl, emergencyControl);
  }

  private fillContacts(phoneControl: FormArray, emailControl: FormArray, emergencyControl: FormArray) {
    if (this.phoneList.length === 0) {
      phoneControl.push(this.formBuilder.group({
        PhoneValue: ['', Validators.maxLength(this.phoneMaxLength)],
        PhoneNotes: ['', Validators.maxLength(this.contactNoteMaxLength)]
      }));
    } else {
      this.fillExistingContactPhones(phoneControl);
    }
    if (this.emailList.length === 0) {
      emailControl.push(this.formBuilder.group({
        EmailValue: ['', [Validators.maxLength(this.emailMaxLength), Validators.email]],
        EmailNotes: ['', Validators.maxLength(this.contactNoteMaxLength)]
      }));
    } else {
      this.fillExistingContactEmails(emailControl);
    }
    if (this.emergencyList.length === 0) {
      emergencyControl.push(this.formBuilder.group({
        EmergencyValue: ['', Validators.maxLength(this.phoneMaxLength)],
        EmergencyNotes: ['', Validators.maxLength(this.contactNoteMaxLength)]
      }));
    } else {
      this.fillExistingContactEmergencies(emergencyControl);
    }
  }

  private fillExistingContactPhones(phoneControl: FormArray) {
    for (let i = 0; i < this.phoneList.length; i++) {
      phoneControl.push(this.formBuilder.group({
        PhoneValue: [this.phoneList[i].Value, Validators.maxLength(this.phoneMaxLength)],
        PhoneNotes: [this.phoneList[i].Notes, Validators.maxLength(this.contactNoteMaxLength)]
      }));
    }
  }

  private fillExistingContactEmails(emailControl: FormArray) {
    for (let i = 0; i < this.emailList.length; i++) {
      emailControl.push(this.formBuilder.group({
        EmailValue: [this.emailList[i].Value, [Validators.maxLength(this.emailMaxLength), Validators.email]],
        EmailNotes: [this.emailList[i].Notes, Validators.maxLength(this.contactNoteMaxLength)]
      }));
    }
  }

  private fillExistingContactEmergencies(emergenciesControl: FormArray) {
    for (let i = 0; i < this.emergencyList.length; i++) {
      emergenciesControl.push(this.formBuilder.group({
        EmergencyValue: [this.emergencyList[i].Value, Validators.maxLength(this.phoneMaxLength)],
        EmergencyNotes: [this.emergencyList[i].Notes, Validators.maxLength(this.contactNoteMaxLength)]
      }));
    }
  }

  private getContactList(contactType: ContactType): ContactDto[] {
    return this.producer.Contacts.filter(x => x.ContactType === contactType);
  }

  private collectContacts(): ContactDto[] {
    const phones = this.getControls('Phones').controls;
    const emails = this.getControls('Emails').controls;
    const emergencies = this.getControls('Emergencies').controls;

    this.collectSpecifiedContacts(phones, ContactType.Phone);
    this.collectSpecifiedContacts(emails, ContactType.Email);
    this.collectSpecifiedContacts(emergencies, ContactType.Emergency);

    return this.contactList;
  }

  private collectSpecifiedContacts(contacts: AbstractControl[], type: ContactType): void {
    const controlNoteName = ContactType[type] + 'Notes';
    const controlValueName = ContactType[type] + 'Value';

    contacts.forEach(contact => {
      const noteControl = contact.get(controlNoteName);
      const valueControl = contact.get(controlValueName);

      if (noteControl.value !== '' && valueControl.value === '') {
        if (type !== ContactType.Email) {
          valueControl.setValidators([Validators.maxLength(this.phoneMaxLength), Validators.required]);
        } else {
          valueControl.setValidators([Validators.maxLength(this.emailMaxLength), Validators.required, Validators.email]);
        }
        valueControl.updateValueAndValidity();
      }
      if (valueControl.value !== '') {
        if (type !== ContactType.Email) {
          valueControl.setValidators([Validators.maxLength(this.phoneMaxLength)]);
        } else {
          valueControl.setValidators([Validators.maxLength(this.emailMaxLength), Validators.email]);
        }
        valueControl.updateValueAndValidity();
        this.addSpecifiedContact(contact, type);
      }
    });
  }

  private addSpecifiedContact(control: AbstractControl, type: ContactType) {
    const controlNoteName = ContactType[type] + 'Notes';
    const controlValueName = ContactType[type] + 'Value';

    const contact = <ContactDto>{
      ProducerId: this.producer.Id,
      ContactType: type,
      Value: control.get(controlValueName).value,
      Notes: control.get(controlNoteName).value
    };

    this.contactList.push(contact);
  }

  private getProducerFromForm(): ProducerDto {
    return <ProducerDto>{
      Id: this.producer.Id,
      Name: this.producerForm.get('Name').value,
      Address: this.producerForm.get('Address').value === null ? '' : this.producerForm.get('Address').value,
      Contacts: this.collectContacts(),
      Website: this.producerForm.get('Website').value === null ? '' : this.producerForm.get('Website').value
    };
  }

  private updateProducerIfRequestNotYetAccepted(producer: ProducerDto){
    this.requestService.isRequestFinalStatusSet(this.requestId)
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .switchMap((isRequestFinalStatusSet) => {
        if (isRequestFinalStatusSet) {
          this.notificationService.showMsgInfo(
            'info.request_final_status_set',
            'Sorry, it is not possible to modify the request as it has been approved already.');

          return EMPTY;
        } else {
          return this.requestService.updateProducer(producer);
        }
      }).subscribe((newProducer) => {
        this.editProducerClose(newProducer);
        this.notificationService.showMsgSuccess('success.update_producer_', 'Producer has been saved.');
      },
      () => {
        this.contactList = [];
        this.notificationService.showMsgError('error.producer_update_failed', 'Producer update failed.');
      });
  }

  private updateProducer(producer: ProducerDto){
    this.requestService.updateProducer(producer)
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe((newProducer) => {
        this.editProducerClose(newProducer);
        this.notificationService.showMsgSuccess('success.update_producer_', 'Producer has been saved.');
      },
      () => {
        this.contactList = [];
        this.notificationService.showMsgError('error.producer_update_failed', 'Producer update failed.');
      });
  }
}

