import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ArticleHphraseDto } from './../../../../../requests/new-request/new-request.model';
import { HphraseDto } from '../../../../../catalogue-view/shared/catalogue-view.model';

@Component({
  selector: 'app-manage-article-h-phrases',
  templateUrl: './manage-article-h-phrases.component.html',
  styleUrls: ['./manage-article-h-phrases.component.scss']
})
export class ManageArticleHphrasesComponent implements OnInit, OnChanges {
  @Input() hPhrases: HphraseDto[];
  @Input() articleHphrases: ArticleHphraseDto[];

  @Output() hPhrasesUpdated = new EventEmitter();

  private allHphrases: HphraseDto[];
  private initialized: boolean;

  constructor() { }

  ngOnInit() {
    this.allHphrases = this.hPhrases;
    this.hPhrases = this.getPhrasesForSelect();
    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.articleHphrases) {
      this.articleHphrases = changes.articleHphrases.currentValue;

      if (this.initialized) {
        this.hPhrases = this.getPhrasesForSelect();
      }
    }
  }

  updateHphrases(hPhrases: ArticleHphraseDto[]) {
    if (hPhrases) {
      this.articleHphrases = this.articleHphrases.concat(hPhrases);
      this.hPhrasesUpdated.emit(this.articleHphrases);
    }
  }

  removeHphrase(hPhrases: ArticleHphraseDto[]) {
    this.hPhrasesUpdated.emit(hPhrases);
  }

  private getPhrasesForSelect(): HphraseDto[] {
    if (this.articleHphrases) {
      const artilceHphrasesIds = this.articleHphrases.map(x => x.Id);

      return this.allHphrases.filter(x => artilceHphrasesIds.find(id => id === x.Id) == null);
    }

    return this.allHphrases;
  }
}
