import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ApprovalType } from '../../requests-view/requests-view.model';

@Component({
  selector: 'app-change-approval-header',
  templateUrl: './change-approval-header.component.html',
  styleUrls: ['./change-approval-header.component.scss'],
})
export class ChangeApprovalHeaderComponent {
  @Input() approvalType: number;
  @Input() approvalStatus: number;
  @Output() toggleState: EventEmitter<boolean> = new EventEmitter();

  isExpanded: boolean;

  ApprovalType = ApprovalType;

  toggleClick() {
    this.isExpanded = !this.isExpanded;
    this.toggleState.emit(this.isExpanded);
  }
}
