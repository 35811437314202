import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { Subscription } from 'rxjs';

import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';

import { ArticleHphraseDto } from './../../requests/new-request/new-request.model';
import { DangerClassDto } from './../../catalogue-view/shared/catalogue-view.model';

import { SharedService } from '../shared.service';

@Component({
  selector: 'app-h-phrase-attaching',
  templateUrl: './h-phrase-attaching.component.html',
  styleUrls: ['./h-phrase-attaching.component.scss']
})
export class HphraseAttachingComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() hPhrases: ArticleHphraseDto[];
  @Input() title: string;

  @Output() hPhrasesUpdated = new EventEmitter();

  @ViewChild('availableHphrasesMultiselect', { static: false }) multiselect: MultiSelectComponent;

  selectedHphrases: ArticleHphraseDto[];
  multiselectData: ArticleHphraseDto[];
  dangerClassForm: FormGroup;
  formSubmitAttempt: boolean;
  temporalId: number;

  private filter: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.selectedHphrases = [];
    this.multiselectData = this.hPhrases;
    this.createForm();
    this.fillDisplayName();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.multiselect) {
      this.hPhrases = changes.hPhrases.currentValue;
      this.multiselectData = this.hPhrases;
      this.filterSelectData();
      this.fillDisplayName();
    }
  }

  ngAfterViewInit() {
    this.filterSelectData();
  }

  onSizeChange(hPhrases: ArticleHphraseDto[]) {
    this.selectedHphrases = hPhrases;
  }

  isHphraseSelected(id: number): boolean {
    return this.selectedHphrases ? this.selectedHphrases.some(item => item.Id === id) : false;
  }

  selectedDangerClass(hPhrase: ArticleHphraseDto, selectedDangerClass: DangerClassDto) {
    hPhrase.DangerClass = selectedDangerClass;
  }

  hasMultipleDangerClasses(hPhrase: ArticleHphraseDto) {
    return hPhrase.DangerClasses && hPhrase.DangerClasses.length > 1;
  }

  addHphrases() {
    if (this.selectedHphrases) {
      this.clearValidators();
      this.selectedHphrases.forEach(hPhrase => {
        if (hPhrase.DangerClasses.length === 1) {
          hPhrase.DangerClass = hPhrase.DangerClasses[0];
        }
        if (this.hasMultipleDangerClasses(hPhrase)) {
          this.setValidators();
        }
      });
      if (this.dangerClassForm.valid) {
        this.hPhrasesUpdated.emit(this.selectedHphrases);
        this.selectedHphrases = null;
        this.dangerClassForm.reset();
      }
    }
    this.formSubmitAttempt = false;
  }

  getCategory(dataItem: DangerClassDto) {
    return dataItem.Category ? dataItem.Category.Name : '';
  }

  getClass(dataItem: DangerClassDto) {
    return dataItem.Class ? dataItem.Class.Name : '';
  }

  getSignalWord(dataItem: DangerClassDto) {
    return dataItem.SignalWord ? dataItem.SignalWord.Name : '';
  }

  private clearValidators() {
    this.dangerClassForm.get('DangerClass').setValidators([]);
    this.dangerClassForm.get('DangerClass').updateValueAndValidity();
  }

  private setValidators() {
    this.dangerClassForm.get('DangerClass').setValidators([Validators.required]);
    this.dangerClassForm.get('DangerClass').updateValueAndValidity();
    this.dangerClassForm.markAllAsTouched();
    this.formSubmitAttempt = true;
  }

  private createForm() {
    this.dangerClassForm = this.formBuilder.group({
      DangerClass: [null]
    });
  }

  private fillDisplayName() {
    if (this.hPhrases) {
      this.hPhrases.forEach(phrase => {
        phrase.DangerClasses.forEach(dangerClass => {
          dangerClass.DisplayName = dangerClass.Type.Name + ', ' + this.getClass(dangerClass) + ', ' +
            this.getCategory(dangerClass);
        });
      });
    }
  }

  private filterSelectData() {
    if (!!this.filter) {
      this.filter.unsubscribe();
    }

    this.filter = this.sharedService.filterMultiselect<ArticleHphraseDto>(this.multiselect, this.hPhrases, ['Code', 'Description'])
      .subscribe((hPhrases: ArticleHphraseDto[]) => {
        this.multiselectData = hPhrases;
      });
  }
}
