import { Component, TemplateRef, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'app-modal',
    styleUrls: ['./modal.component.scss'],
    templateUrl: './modal.component.html',
    animations: [
        trigger('showModal', [
          transition(':enter', [
            style({ transform: 'translateY(-40px)', opacity: 0.5}),
            animate('0.15s cubic-bezier(.2,.6,.3,1)', style({ transform: 'translateY(0)', opacity: 1 }))
          ]),
          transition(':leave', [
            animate('0.1s', style({ transform: 'scale(0.9)', opacity: 0 }))
          ])
        ]),
        trigger('showBackdrop', [
            transition(':enter', [
                style({ opacity: 0}),
                animate('0.15s cubic-bezier(.2,.6,.3,1)', style({ opacity: 0.2 }))
              ]),
              transition(':leave', [
                animate('0.1s', style({ opacity: 0 }))
              ])
        ])
      ]
})
export class ModalComponent implements OnInit {
    template: TemplateRef<any>;
    close: () => void;
    show = false;

    ngOnInit(): void {
      // Without this timeout ":enter" transition wasn't shown in Firefox
      setTimeout(() => {
        this.show = true;
      }, 0);
    }

    destroy() {
        this.show = false;
    }

    onClose() {
        this.close();
    }
}