import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ContainerType, CharacteristicsComboBoxDto } from '../../../../manage-articles.model';
import { Unit } from '../../../../../../shared/model';

@Component({
  selector: 'app-container-form',
  templateUrl: './container-form.component.html',
  styleUrls: ['./container-form.component.scss']
})
export class ContainerFormComponent implements OnInit {
  @Input() containerTypes: ContainerType[];
  @Input() characteristics: CharacteristicsComboBoxDto[];
  @Input() containerForm: FormGroup;

  Unit = Unit;
  units: any[];
  initialized: boolean;

  constructor() { }

  ngOnInit() {
    this.units = Object.keys(Unit)
      .filter(u => !isNaN(Number(u)))
      .map(unit => Unit[unit].toString());
    this.initialized = true;
  }
}
