import { Component, OnInit, Output, EventEmitter, Input, forwardRef, Self, Optional, ViewChild } from '@angular/core';
import { FileManagerService } from '../../file-manager.service';
import { TranslatedNotificationService } from '../../../translation/translated-notification.service';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FileTypeEnum, FileToDownload, DownloadedFile } from '../../file-manager.model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {
  @Input() filePath: string;
  @Input() fileType: FileTypeEnum = FileTypeEnum.DerogationFilePath;
  @Input() disableAutomaticDownload: boolean;
  @Output() selectedFileChange: EventEmitter<Blob | File> = new EventEmitter();

  @ViewChild('fileUploadInput', { static: false }) fileInput: HTMLFormElement;

  file: Blob | File;
  fileName: string;
  fileSize: string;

  onChange: any = () => { };
  onTouch: any = () => { };
  onDisable: any = () => { };

  get invalid(): boolean {
    return this.ngControlProvider ? this.ngControlProvider.control.touched && this.ngControlProvider.invalid : false;
  }

  get filename(): string {
    return this.file && this.hasNameProperty(this.file) && this.file['name'];
  }

  constructor(
    private ngControlProvider: NgControl,
    private fileManagerService: FileManagerService,
    private notificationService: TranslatedNotificationService) {
    if (this.ngControlProvider != null) {
      this.ngControlProvider.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.filePath) {
      const file = <FileToDownload> {
        FileName: this.filePath,
        FileType: this.fileType
      };
      if (!this.disableAutomaticDownload) {
        this.fileManagerService.fetchFile(file).subscribe(
          (data: DownloadedFile) => {
            this.file = new Blob([data.File]);
            this.file['name'] = this.filePath;
            this.fileSize = data.Size;
            this.selectedFileChange.emit(this.file);
          },
          () => {
            this.notificationService.showMsgError('error.cannot_load_file', 'Cannot load the requested file.');
          }
        );
      }
    }
  }

  //#region implementation of CVA

  writeValue(obj: any): void {
    if (!obj) {
      // form in which the control is placed had been reset (Angular puts null in writeeValue by default)
      // so the control should be handled on reset as well
      this.file = null;
    }

    this.filePath = obj;
    this.onChange(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  //#endregion

  fileSelected(event: any) {
    const uploadedFiles = event.target.files;

    if (uploadedFiles.length > 0) {
      this.file = uploadedFiles[0];
      this.selectedFileChange.emit(this.file);
    }
  }

  removeFile() {
    this.file = null;
    this.selectedFileChange.emit(null);
    this.fileInput.nativeElement.value = null; // this is needed to make file input accept the same file again.
  }

  fileDropped(event: DragEvent) {
    event.preventDefault();

    const droppedItems = event.dataTransfer;

    if (droppedItems) {
      for (let i = 0; i < droppedItems.files.length; i++) {
        this.file = droppedItems.files[i];
        this.selectedFileChange.emit(this.file);
      }
    }
  }

  /**
   * This handler is needed to enable drag & drop. Otherwise file content is displayed in browser.
   */
  fileDraggedOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  private hasNameProperty(file: Blob): boolean {
    // if the 'name' property is assigned dynamically via file['name']= '...' syntax, then the 1st statement
    // is true (it is an own property of the object). However if this property comes from the File object itself
    // (i.e. while uploading the browser provides File object with already set 'name' property), then the 1st condition
    // is false because this property comes from the inherited type (File) and hence the 2nd condition.
    return file && file.hasOwnProperty('name') || file.constructor.prototype.hasOwnProperty('name');
  }
}
