import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { forkJoin, EMPTY } from 'rxjs';

import { UserDetailsDto, UserRole } from '../../../shared/model';
import {
  ApprovalType, ApprovalStatus, ApprovalStatusEnum, RequestApprovalStatusDto, FunctionEnum, ApprovalReplaceAndFunctionDto
} from '../../requests-view/requests-view.model';
import { RequestDetailsDto } from '../../request-view/request-view.model';

import { RequestService } from '../../request.service';
import { SharedService } from 'app/shared/shared.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';

@Component({
  selector: 'app-change-approval',
  templateUrl: './change-approval.component.html',
  styleUrls: ['./change-approval.component.scss']
})
export class ChangeApprovalComponent implements OnInit {
  @Input() approvalType: ApprovalType;
  @Input() statuses: ApprovalStatus[];
  @Input() requestId: number;
  @Input() previousStatusApproved: boolean;
  @Input() request: RequestDetailsDto;
  @Input() hasProperSpecialization: boolean;
  @Input() isCompletelyApproved: boolean;

  @Output() statusChange = new EventEmitter();
  @Output() dirtyStatusChange = new EventEmitter();
  @Output() completelyApproved = new EventEmitter();

  approvalForm: FormGroup;
  functionForm: FormGroup;
  replaceabilityForm: FormGroup;
  isSetWithComments = false;
  statusToUpdate: ApprovalStatus;
  isExpanded = false;
  user: UserDetailsDto;
  isStateOfGas: boolean;
  isRiskAnalysisDone: boolean;
  detailsData: ApprovalReplaceAndFunctionDto;

  functionEnum = FunctionEnum;
  ApprovalType = ApprovalType;

  private _currentRequestApprovalStatus: RequestApprovalStatusDto;

  get requestApprovalStatusId(): number {
    const statusId = this._currentRequestApprovalStatus.ApprovalStatusId;
    const status = this.statuses.find(x => x.Id === statusId);

    return status ? status.EnumValue : ApprovalStatusEnum.NotApprovedYet;
  }

  constructor(
    private sharedService: SharedService,
    private requestService: RequestService,
    private notificationService: TranslatedNotificationService,
    private formBuilder: FormBuilder) {
    this.user = this.sharedService.loggedInUser;
  }

  ngOnInit() {
    this.createApprovalForm();
    this.setCommentValidator();

    if (this.isFinal()) {
      this.createForms();

      if (this.previousStatusApproved || this.request.FinalApproval.EnumValue !== ApprovalStatusEnum.NotApprovedYet) {
        forkJoin([
          this.requestService.checkRiskAnalysis(this.requestId),
          this.requestService.checkArticleIsGas(this.request.ArticleId),
          this.requestService.getApprovalReplaceabilityAndFunction(this.requestId)
        ])
          .subscribe(
            ([isRiskAnalysisDone, isArticleGas, functionAndReplaceability]) => {
              this.isRiskAnalysisDone = isRiskAnalysisDone;
              this.isStateOfGas = isArticleGas;
              this.detailsData = functionAndReplaceability;
              this.fillInRadioButtons();
              this.toggleRadioButtons();
            },
            () => this.notificationService.showDefaultMsgError()
          );
      }
    }

    this._currentRequestApprovalStatus = this.request.RequestApprovalStatus.find(x => x.ApprovalTypeId === this.approvalType);
    this.isCompletelyApproved = this.request.FinalApprovalEnum !== ApprovalStatusEnum.NotApprovedYet;
  }

  onChange(): void {
    this.dirtyStatusChange.emit(true);

    if (this.isFinal()) {
      if (this.isRiskAnalysisDone) {
        this.patchValuesToForm();
        this.toggleRadioButtons();
      } else {
        this.notificationService
          .showMsgError(
            'error.cant_set_final_without_risk_analysis',
            'Sorry, we couldn\'t finalize the approval. Please complete risk analysis first.');
        this.reset();
      }
    } else {
      this.patchValuesToForm();
    }

    if (this.isEnvironment() && !this.request.IsMarkedAsCorrectBySpecialist) {
      this.notificationService.showMsgError(
        'error.review.request.data',
        'Sorry, we couldn\'t save the form. Please provide all the missing data.');
      this.reset();
    }
  }

  reset() {
    this.approvalForm.reset();
    this.dirtyStatusChange.emit(false);
    this.isSetWithComments = false;
  }

  isMedic(): boolean {
    return this.approvalType === ApprovalType.Medic;
  }

  isEnvironment(): boolean {
    return this.approvalType === ApprovalType.Environment;
  }

  isSafety(): boolean {
    return this.approvalType === ApprovalType.Safety;
  }

  isFinal(): boolean {
    return this.approvalType === ApprovalType.Final;
  }

  isActive(): boolean {
    if (this.isFinal()) {
      return this.previousStatusApproved && this.request.FinalApprovalEnum === ApprovalStatusEnum.NotApprovedYet;
    } else {
      return this.previousStatusApproved;
    }
  }

  toggleExpandedState(isExpanded: boolean) {
    this.isExpanded = isExpanded;
  }

  getApprovalStatus(): RequestApprovalStatusDto {
    if (this.request && this.request.RequestApprovalStatus) {
      return this.request.RequestApprovalStatus.find(x => x.ApprovalTypeId === this.approvalType);
    }

    return null;
  }

  canApproveRequest(): boolean {
    const approvalStatus = this.approvalForm.get('Status').value;

    return (approvalStatus === ApprovalStatusEnum.ApprovedUnderConditions || approvalStatus === ApprovalStatusEnum.Approved)
      && this.previousStatusApproved && this.isRiskAnalysisDone
      && this.request.FinalApprovalEnum === ApprovalStatusEnum.NotApprovedYet
      && !this.isCompletelyApproved;
  }

  submitForm() {
    this.isFinal() ? this.submitFinalForm() : this.submitRegularForm();
  }

  private submitFinalForm() {
    if (this.isFinalApprovalValid() && this.checkIfFinalFormValid() && this.isArticleNotForbidden()) {
      const request = this.generateFinalRequest();

      this.requestService.isRequestFinalStatusSet(this.requestId)
        .switchMap((isRequestFinalStatusSet) => {
          if (isRequestFinalStatusSet) {
            this.notificationService.showMsgInfo(
              'info.request_final_status_set',
              'Sorry, it is not possible to modify the request as it has been approved already.');

            return EMPTY;
          } else {
            return this.requestService.addRequestFinalApprovalStatus(request);
          }
        }).subscribe(() => {
          this.emitStatus();
          this.isCompletelyApproved = true;
          this.completelyApproved.emit(this.isCompletelyApproved);
          this.assignNewApprovalStatus(request);
          this.toggleRadioButtons();
        },
          err => {
            this.notificationService.showExceptionError(err.error);
          });
    }
  }

  private assignNewApprovalStatus(request: RequestApprovalStatusDto) {
    this._currentRequestApprovalStatus.ApprovalStatusId = request.ApprovalStatusId;
  }

  private submitRegularForm() {
    const request = this.getRequestApprovalStatusFromForm();
    if (this.isApprovalValid()) {
      this.requestService.isRequestFinalStatusSet(this.requestId)
        .switchMap((isRequestFinalStatusSet) => {
          if (isRequestFinalStatusSet) {
            this.notificationService.showMsgInfo(
              'info.request_final_status_set',
              'Sorry, it is not possible to modify the request as it has been approved already.');

            return EMPTY;
          } else {
            return this.requestService.addRequestApprovalStatus(request);
          }
        }).subscribe(() => {
          this.emitStatus();
          this.assignNewApprovalStatus(request);
        },
          () => {
            this.notificationService.showMsgError('error.approval_status_update_failed', 'Approval status update failed.');
          });
    }
  }

  private generateFinalRequest(): RequestApprovalStatusDto {
    const approvalStatus = this.approvalForm.get('Status').value;
    const request = this.getRequestApprovalStatusFromForm();

    if ((approvalStatus === ApprovalStatusEnum.ApprovedUnderConditions || approvalStatus === ApprovalStatusEnum.Approved)) {
      request.Function = this.functionForm.get('function').value;
      request.Replaceability = null;

      if (this.isStateOfGas) {
        request.Replaceability = this.replaceabilityForm.get('replaceability').value;
      }
    }

    return request;
  }

  private emitStatus() {
    const newStatus = this.statuses.find(x => x.EnumValue === this.approvalForm.get('Status').value);

    this.statusChange.emit(newStatus);
    this.notificationService.showMsgSuccess(
      'success.add_approval_status',
      'Approval status has been saved.'
    );
  }

  private checkIfFinalFormValid(): boolean {
    const approvalStatus = this.approvalForm.get('Status').value;

    return (this.approvalForm.valid && (this.functionForm.valid || approvalStatus === ApprovalStatusEnum.Rejected)
      && (!this.isStateOfGas || this.replaceabilityForm.valid || approvalStatus === ApprovalStatusEnum.Rejected));
  }

  private toggleRadioButtons() {
    if (this.canApproveRequest()) {
      this.functionForm.enable();

      if (this.isStateOfGas) {
        this.replaceabilityForm.enable();
      }
    } else {
      this.functionForm.disable();
      this.replaceabilityForm.disable();
    }
  }

  private isApprovalValid(): boolean {
    const status = this.approvalForm.get('Status');

    if (status.invalid || status.value === ApprovalStatusEnum.NotApprovedYet) {
      this.notificationService.showMsgError('error.status_must_be_chosen', 'Status must be chosen');

      return false;
    }
    if (this.approvalForm.invalid) {
      this.notificationService.showMsgError('error.comment_must_be_filled', 'Please provide a comment for this type of approval.');

      return false;
    }

    return true;
  }

  private isFinalApprovalValid(): boolean {
    if (this.functionForm.invalid) {
      this.notificationService.showMsgError('error.function_must_be_chosen', 'Function must be chosen');
      return false;
    }
    if (this.isStateOfGas && this.replaceabilityForm.invalid) {
      this.notificationService.showMsgError('error.replaceability_must_be_chosen', 'Replaceability must be chosen');
      return false;
    }
    if (!this.isApprovalValid()) {
      return false;
    }
    if (!this.isRiskAnalysisDone) {
      this.notificationService.showMsgError('error.risk_analysis_must_be_done', 'Risk Analysis must be done');
      return false;
    }

    return true;
  }

  private isArticleNotForbidden(): boolean {
    const approvalStatus = this.approvalForm.get('Status').value;

    if (this.approvalForm.valid && this.functionForm.valid
      && approvalStatus !== ApprovalStatusEnum.Rejected && this.request.ArticleData.IsForbidden) {
      this.showForbiddenError(this.request.ArticleData.ForbiddenNote);

      return false;
    }

    return true;
  }

  private setCommentValidator() {
    const commentControl = this.approvalForm.get('Comment');
    this.approvalForm.get('Status').valueChanges
      .subscribe(statusWithComment => {
        commentControl.setValidators(null);

        if (statusWithComment === ApprovalStatusEnum.ApprovedUnderConditions || statusWithComment === ApprovalStatusEnum.Rejected) {
          commentControl.setValidators([Validators.required]);
        } else {
          this.approvalForm.patchValue({
            Comment: ''
          });
        }
      });
  }

  private createForms() {
    this.functionForm = this.formBuilder.group({ function: ['', [Validators.required]] });
    this.functionForm.disable();
    this.replaceabilityForm = this.formBuilder.group({ replaceability: ['', [Validators.required]] });
    this.replaceabilityForm.disable();
  }

  private fillInRadioButtons() {
    const replaceability = this.detailsData.Replaceability;
    const functionEnum = this.detailsData.Function;

    if (replaceability !== undefined && replaceability !== null) {
      this.replaceabilityForm.patchValue({ replaceability: replaceability.toString() });
    }

    if (functionEnum) {
      this.functionForm.patchValue({ function: functionEnum.toString() });
    }
  }

  get statusTranslation() {
    const approvalStatus = this.getApprovalStatus();

    return approvalStatus &&
      (approvalStatus.ApprovalStatusId === ApprovalStatusEnum.ApprovedUnderConditions
        || approvalStatus.ApprovalStatusId === ApprovalStatusEnum.Approved
        || approvalStatus.ApprovalStatusId === ApprovalStatusEnum.Rejected)
      ? this.statuses.find(x => x.Id === approvalStatus.ApprovalStatusId).Translation : null;
  }

  get isEnabled(): boolean {
    return this.previousStatusApproved && this.hasProperSpecialization && !this.isCompletelyApproved && this.user.UserRole > UserRole.Requester;
  }

  get statusComment(): string {
    return this.approvalForm.get('Comment').value;
  }

  private createApprovalForm() {
    const approvalStatus = this.getApprovalStatus();

    if (approvalStatus) {
      this.approvalForm = this.formBuilder.group({
        UserEmail: [this.fillEmail(approvalStatus), [Validators.required]],
        UserName: [this.fillName(approvalStatus), [Validators.required]],
        Status: [this.fillStatus(approvalStatus), [Validators.required]],
        Date: [this.fillDate(approvalStatus), [Validators.required]],
        Comment: [this.fillComment(approvalStatus)]
      });

      this.isSetWithComments = this.isStatusWithComment();
    }
  }

  private fillDate(approvalStatus: RequestApprovalStatusDto): Date {
    return (approvalStatus && approvalStatus.Date) ? new Date(approvalStatus.Date + 'Z') : null;
  }

  private fillEmail(approvalStatus: RequestApprovalStatusDto): string {
    return (approvalStatus && approvalStatus.Approver && approvalStatus.Approver.Email) ? approvalStatus.Approver.Email : null;
  }

  private fillName(approvalStatus: RequestApprovalStatusDto): string {
    return (approvalStatus && approvalStatus.Approver && approvalStatus.Approver.Email)
      ? approvalStatus.Approver.FirstName + ' ' + approvalStatus.Approver.LastName : null;
  }

  private fillStatus(approvalStatus: RequestApprovalStatusDto): number {
    return approvalStatus ? approvalStatus.ApprovalStatusId : null;
  }

  private fillComment(approvalStatus: RequestApprovalStatusDto): string {
    return (approvalStatus && approvalStatus.Comment) ? approvalStatus.Comment : null;
  }

  private patchValuesToForm(): void {
    this.approvalForm.patchValue({
      UserEmail: this.user.Email,
      UserName: `${this.user.FirstName} ${this.user.LastName}`,
      Date: new Date(),
      Comment: this.approvalForm.get('Comment').value
    });
    this.isSetWithComments = this.isStatusWithComment();
  }

  private isStatusWithComment(): boolean {
    return this.approvalForm.get('Status').value === ApprovalStatusEnum.ApprovedUnderConditions
      || this.approvalForm.get('Status').value === ApprovalStatusEnum.Rejected;
  }

  private getRequestApprovalStatusFromForm(): RequestApprovalStatusDto {
    return <RequestApprovalStatusDto>{
      RequestId: this.requestId,
      ApprovalTypeId: this.approvalType,
      ApprovalStatusId: this.approvalForm.get('Status').value,
      Date: this.approvalForm.get('Date').value,
      ApproverId: this.sharedService.loggedInUser.UserId,
      Comment: this.approvalForm.get('Comment').value
    };
  }

  private showForbiddenError(forbiddenNote: string) {
    this.notificationService.showMsgError('info.article_is_forbidden_general',
      'This product has been forbidden. Reason:', ' ' + forbiddenNote);
  }
}