import { Component, Input, ContentChildren, QueryList } from '@angular/core';

import { SubMenuItemComponent } from '../sub-menu-item/sub-menu-item.component';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'nav-menu-item',
    styleUrls: ['./nav-menu-item.component.scss'],
    templateUrl: './nav-menu-item.component.html'
})
export class NavMenuItemComponent {
    @ContentChildren(SubMenuItemComponent) subItems: QueryList<SubMenuItemComponent>;
    @Input() route: string | any[];
    @Input() iconPath: string;
    @Input() label: string;

    public showSubMenu = false;
    private showSubItemsTimeout: any;
    private hideSubItemsTimeout: any;

    get hasSubItems(): boolean {
        return this.subItems && this.subItems.length > 0;
    }

    onMouseOver() {
        if (this.hideSubItemsTimeout) {
            clearTimeout(this.hideSubItemsTimeout);
            this.hideSubItemsTimeout = null;
        }
        if (this.hasSubItems) {
            this.showSubItemsTimeout = setTimeout(() => { this.showSubMenu = true; }, 100);
        }
    }

    onMouseOut() {
        if (this.showSubItemsTimeout) {
            clearTimeout(this.showSubItemsTimeout);
            this.showSubItemsTimeout = null;
        }
        this.hideSubItemsTimeout = setTimeout(() => { this.showSubMenu = false; }, 100);
    }
}
