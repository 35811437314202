import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApprovalEditDetailsDto } from './catalogue-view.model';

@Injectable({
  providedIn: 'root'
})
export class ArticleCommunicationService {

  // Observable sources
  private openEditApproval = new Subject<boolean>();
  private editApprovalData = new Subject<ApprovalEditDetailsDto>();
  private openCopyApproval = new Subject<boolean>();

  // Observable streams
  openEditApproval$ = this.openEditApproval.asObservable();
  editApprovalData$ = this.editApprovalData.asObservable();
  openCopyApproval$ = this.openCopyApproval.asObservable();

  // Service methods
  toggleEditAprovalModal(openModal: boolean) {
    this.openEditApproval.next(openModal);
  }

  approvalEdited(approvalData: ApprovalEditDetailsDto) {
    this.editApprovalData.next(approvalData);
  }

  toggleCopyApprovalModal(openModal: boolean) {
    this.openCopyApproval.next(openModal);
  }

}
