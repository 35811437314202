export enum FileTypeEnum {
    DerogationFilePath = 1,
    SdsFilePath = 2
}

export interface FileToDownload {
    FileName: string;
    FileType: FileTypeEnum;
}

export interface DownloadedFile {
    File: Blob | File;
    Size: string;
}
