import { Component, Input } from '@angular/core';
import { UserRole } from '../../../../shared/model';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent {
  @Input() userRole: number;

  UserRole = UserRole;
}
