import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, ComponentRef, EmbeddedViewRef, TemplateRef } from '@angular/core';
import { ModalComponent } from './modal.component';

@Injectable({
    providedIn: 'root'
})
export class ModalsService {
    private _modals: {[id: string]: ComponentRef<ModalComponent>}

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector) {
            this._modals = {};
        }


    public showModal(id: string, template: TemplateRef<any>, close: () => void) {
        const modalRef: ComponentRef<ModalComponent> = this.createComponentRef(ModalComponent);
        modalRef.instance.template = template;
        modalRef.instance.close = close;

        const modalElement = this.getDomElementFromComponentRef(modalRef);
        document.body.appendChild(modalElement);
        document.body.getElementsByTagName('app-root')[0].classList.add('blur-content');

        this._modals[id] = modalRef;
    }

    public closeModal(id: string) {
        const modalRef = this._modals[id];
        if (modalRef) {
            modalRef.instance.destroy();

            // timeout to allow 'leave' animation to complete
            setTimeout(() => {
                this.appRef.detachView(modalRef.hostView);
                modalRef.destroy();
            }, 150);
            document.body.getElementsByTagName('app-root')[0].classList.remove('blur-content');
        }
    }

    createComponentRef(component: any): ComponentRef<any> {
        const componentRef = this.componentFactoryResolver
            .resolveComponentFactory(component)
            .create(this.injector);
        this.appRef.attachView(componentRef.hostView);
        return componentRef;
    }

    getDomElementFromComponentRef(componentRef: ComponentRef<any>): HTMLElement {
        return (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;
    }
}
