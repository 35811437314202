import { Component, Input } from '@angular/core';

import { DisclaimerService } from 'app/disclaimer/disclaimer.service';
import { HelpService } from '../../help/help.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() userRole: number;

  public version = '1.0';

  constructor(private disclaimerService: DisclaimerService,
    private helpService: HelpService) { }

  showPrivacyNotice() {
    this.disclaimerService.ShowPrivacyNotice();
  }

  showHelpNotice() {
    this.helpService.showHelpNotice();
  }
  
  contactEmailClick(): void {
    window.location.href = 'mailto:lorin@engie.com';
  }
}
