import { Injectable } from '@angular/core';
import { DataService } from '../../data.service';
import { Observable } from 'rxjs';
import { EmailTemplateDto } from './email-templates.model';
import { LanguageCode } from '../../shared/model';

@Injectable({
    providedIn: 'root',
})
export class EmailTemplatesService {
    constructor(private dataService: DataService) { }

    getAvailableTemplates(languageCode: LanguageCode): Observable<EmailTemplateDto[]> {
        return this.dataService.get('Emails/AvailableTemplates', { languageCode });
    }

    updateTemplates(templates: EmailTemplateDto[]): Observable<any> {
        return this.dataService.patch('Emails/UpdateTemplates', { UpdatedTemplates: templates });
    }
}
