import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

import { WindowModule, DialogModule } from '@progress/kendo-angular-dialog';
import { ConfirmationDialogService } from './confirmation-dialog.service';
import { ConfirmationDialogDirective } from './confirmation-dialog.directive';
import { TranslationModule } from '../translation/translation.module';


@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    ConfirmationDialogDirective
  ],
  imports: [
    CommonModule,
    WindowModule,
    DialogModule,
    TranslationModule
  ],
  exports: [
    ConfirmationDialogComponent,
    ConfirmationDialogDirective
  ],
  providers: [ConfirmationDialogService]
})
export class ConfirmationDialogModule { }
