import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { EmailTemplateTag, EmailTemplateDto } from '../shared/email-templates.model';
import { EditorComponent } from '@progress/kendo-angular-editor';

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss']
})
export class TemplateEditorComponent {
  @Input() header: string;
  @Input() set template(value: EmailTemplateDto) {
    this._template = value;
  }

  @Output() templateEdited: EventEmitter<EmailTemplateDto> = new EventEmitter();

  @ViewChild('templateEditor', { static: false }) templateEditor: EditorComponent;

  private _template: EmailTemplateDto;

  get tags(): EmailTemplateTag[] {
    return this._template && this._template.AvailableTags;
  }

  get templateTitle(): string {
    return this._template && this._template.Title;
  }

  set templateTitle(value: string) {
    this._template.Title = value;
    this.templateEdited.emit(this._template);
  }

  get templateText(): string {
    return this._template && this._template.Body;
  }

  set templateText(value: string) {
    this._template.Body = value;
    this.templateEdited.emit(this._template);
  }

  constructor() { }

  insertTag(tag: string) {
    this.templateEditor.exec('insertText', { text: tag });
  }
}
