import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

import { SharedService } from './shared/shared.service';
import { NotificationBodyComponent } from './shared/notification-body/notification-body.component';
import { TranslationService } from './shared/translation/translation.service';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { SpinnerModule } from './shared/spinner/spinner.module';
import { NavigationModule } from './navigation/navigation.module';
import { ConfirmationDeactivateModule } from './shared/ConfirmationDeactivateModule/confirmationdeactivate.module';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';
import { TranslationModule } from './shared/translation/translation.module';
import { CatalogueViewModule } from './catalogue-view/catalogue-view.module';
import { RequestsModule } from './requests/requests.module';
import { EntitySelectViewComponent } from './entity-select-view/entity-select-view.component';
import { RiskAnalysisModule } from './risk-analysis/risk-analysis.module';
import { DataManagementModule } from './data-management/data-management.module';
import { CookieService } from 'ngx-cookie-service';
import { EmailTemplatesModule } from './email-templates/email-templates.module';
import { ConfirmationDialogModule } from './shared/confirmation-dialog/confirmation-dialog.module';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { EditProducerModalComponent } from './requests/request-view/edit-producer-modal/edit-producer-modal.component';
import { ReportsModule } from './reports/reports.module';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { ErrorRequestsInterceptor } from './shared/error-requests-interceptor/error-requests.interceptor';

import { OktaAuthModule, OktaConfig, OKTA_CONFIG } from '@okta/okta-angular';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from 'environments/environment';
import { AuthInterceptor } from './shared/auth-token/auth.interceptor';

const config: OktaConfig = {
  issuer: environment.issuer,
  redirectUri: environment.redirectUri,
  clientId: environment.clientId,
  scope: environment.scope,
  responseType: ['code', 'id_token']
};

export function tokkenGetter() {
  return localStorage.getItem('myToken');
}

@NgModule({
  declarations: [
    AppComponent,
    EntitySelectViewComponent,
    NotificationBodyComponent
  ],
  exports: [
    EditProducerModalComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    SpinnerModule,
    DropDownsModule,
    NotificationModule,
    ReactiveFormsModule,
    NavigationModule,
    ConfirmationDeactivateModule.forRoot(),
    PageNotFoundModule,
    TranslationModule,
    CatalogueViewModule,
    DataManagementModule,
    RequestsModule,
    RiskAnalysisModule,
    DataManagementModule,
    DateInputsModule,
    EmailTemplatesModule,
    ConfirmationDialogModule,
    ReportsModule,
    SortableModule,
    OktaAuthModule,
    JwtModule.forRoot({
      config: {
         tokenGetter: tokkenGetter
      }
    })
  ],
  entryComponents: [ConfirmationDialogComponent],
  providers: [
    SharedService,
    TranslationService,
    CookieService,
    NotificationBodyComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorRequestsInterceptor,
      multi: true
    },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: AuthInterceptor, 
      multi: true 
    },
    {
      provide: OKTA_CONFIG, 
      useValue: config
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

