import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageNotFoundComponent } from './page-not-found.component';
import { TranslationModule } from '../shared/translation/translation.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@NgModule({
  imports: [
    CommonModule,
    TranslationModule
  ],
  declarations: [PageNotFoundComponent, UnauthorizedComponent]
})
export class PageNotFoundModule { }
