import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'mobile-select',
    styleUrls: ['./mobile-select.component.scss'],
    templateUrl: './mobile-select.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MobileSelectComponent),
        multi: true
    }]
})

export class MobileSelectComponent implements ControlValueAccessor {
    @Input() placeholder: string;
    @Input() label: string;
    @Input() textField: string;

    @Input() set data(data: any[]) {
        if (!data || data.length === 0) {
            this._data = [];
        } else {
            if (typeof(data[0]) === 'object') {
                this._data = data.map(item => ({Text: item[this.textField], Value: item}));
            } else if (typeof(data[0]) === 'string') {
                this._data = data.map(item => ({Text: item, Value: item}));
            }
        }
    }


    _value: {};
    _data: Array<{Text: string, Value: any}>;
    isDisabled: boolean;
    isFocused: boolean;

    onChange: (value: any) => void;
    onTouched: () => void;

    get text() {
        return this._value[this.textField];
    }

    constructor() {
        this._value = null;
        this._data = [];
        this.isDisabled = false;
        this.isFocused = false;
    }

    writeValue(value: any): void {
        if (!value || value === '') {
            this._value = null;
        } else {
            this._value = value;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    change() {
        this.onChange(this._value);
    }

    focus() {
        this.isFocused = true;
    }

    blur() {
        this.isFocused = false;
    }

    get showPlaceholder(): boolean {
        return !this._value || this._value === '';
    }

    clearValue() {
        this._value = null;
        this.onChange(null);
    }
}
