import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedService } from './shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class EntityIsAssignedToUserGuard implements CanActivate {

  constructor(private sharedService: SharedService, private router: Router) {}

  public canActivate() : Observable<boolean> | boolean {
    if(!this.sharedService.loggedInUser || !this.sharedService.entityId || !this.sharedService.currentEntity)
    {
      return this.sharedService.fetchUserConfigurationData().pipe(map(userData =>{
            this.sharedService.setConfiguration(userData);

            if (this.sharedService.loggedInUser && this.sharedService.entityId && this.sharedService.currentEntity) {
              return true;
            } else {
              this.router.navigate(['entity-selection']);
              return false;
            }
        }
      ));
    }
    return true;
  }
}
