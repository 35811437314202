import { Component, Input, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';

import { HazardStatementsDetails } from '../../shared/catalogue-view.model';
import { GhsEnum } from '../../../shared/model';
import { ModalsService } from 'app/shared/modal/modals.service';

@Component({
  selector: 'app-hazards-modal',
  templateUrl: './hazards-modal.component.html',
  styleUrls: ['./hazards-modal.component.scss', '../../../shared/modal/modal-content.scss']
})
export class HazardsModalComponent {
  @Input() isMobile = false;
  @Input() data: HazardStatementsDetails;
  @Input() set dialogOpen(isOpen: boolean) {
    if (isOpen && !this._modalShown) {
      setTimeout(() => {
        this.modalsService.showModal('hazardsModal', this.template, this.hazardsDialogClosed.bind(this));
        this._modalShown = true;
      }, 0);
    } else if (!isOpen && this._modalShown) {
      this.modalsService.closeModal('hazardsModal');
      this._modalShown = false;
    }
  }
  @Output() dialogClosed: EventEmitter<any> = new EventEmitter();
  @ViewChild(TemplateRef, { static: false }) template: TemplateRef<any>;

  private _modalShown: boolean;

  GhsEnum = GhsEnum;

  constructor(private modalsService: ModalsService) {
    this._modalShown = false;
  }

  hazardsDialogClosed() {
    this.dialogClosed.emit();
  }
}
