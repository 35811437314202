import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LanguageCode } from 'app/shared/model';
import { DataService } from '../data.service';

@Injectable()
export class RiskAnalysisService {
    constructor(private dataService: DataService) { }

    getRequest(requestId: number, language: LanguageCode, entityId: string): Observable<any> {
        return this.dataService.get('RiskAnalysis/GetRequest', { requestId, language, entityId });
    }

    getAvailableTechnicalMeasures(language: LanguageCode): Observable<any> {
        return this.dataService.get('TechnicalMeasure/GetTechnicalMeasures', { language });
    }

    addAnalysis(formData: FormData): Observable<any> {
        return this.dataService.postMultiPartData('RiskAnalysis/AddRiskAnalysis', formData);
    }

    updateAnalysis(formData: FormData): Observable<any> {
        return this.dataService.patchMultiPartData('RiskAnalysis/UpdateRiskAnalysis', formData);
    }
}
