import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { State } from '@progress/kendo-data-query';

import { finalize } from 'rxjs/operators';

import { QueryDto } from './favourite-queries.model';
import { FavouriteQueriesService } from './favourite-queries.service';
import { TranslatedNotificationService } from '../../shared/translation/translated-notification.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';

@Component({
  selector: 'app-favourite-queries',
  templateUrl: './favourite-queries.component.html',
  styleUrls: ['./favourite-queries.component.scss']
})
export class FavouriteQueriesComponent implements OnInit {
  @Input() initialState: State;
  @Input() currentFiltersConfiguration: State;
  @Output() activeQueryChange: EventEmitter<QueryDto> = new EventEmitter();

  queriesForm: FormGroup;
  queries: QueryDto[];
  selectedQuery: QueryDto;
  selectedQueryToDelete: QueryDto;
  openEditModal: boolean;
  openDeleteConfirmationModal: boolean;
  editMode: boolean;

  private readonly _cacheKey = 'selectedFavouriteQuery';

  constructor(
    private fb: FormBuilder,
    private queriesService: FavouriteQueriesService,
    private notificationService: TranslatedNotificationService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.queriesForm = this.fb.group({
      Name: ['', [Validators.required, Validators.maxLength(300)]]
    });

    this.spinnerService.show('spinner');

    this.queriesService.getQueries()
      .pipe(
        finalize(() => this.spinnerService.hide('spinner'))
      )
      .subscribe(
        (data: QueryDto[]) => {
          this.queries = data;
          this.applyQueryFromCache();
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }

  applyQuery(query: QueryDto) {
    this.selectedQuery = query;
    sessionStorage.setItem(this._cacheKey, JSON.stringify(query));
    this.activeQueryChange.emit(query);
  }

  isActive(query: QueryDto) {
    return this.selectedQuery && this.selectedQuery.Id === query.Id;
  }

  closeEditModal() {
    this.queriesForm.reset();
    this.openEditModal = false;
  }

  showDeleteConfirmationModal(query: QueryDto) {
    this.openDeleteConfirmationModal = true;
    this.selectedQueryToDelete = query;
  }

  closeDeleteConfirmationModal() {
    this.openDeleteConfirmationModal = false;
  }

  createNewQuery() {
    this.editMode = false;
    this.openEditModal = true;
  }

  editQuery() {
    this.queriesForm.patchValue({
      Name: this.selectedQuery.Name
    });

    this.editMode = true;
    this.openEditModal = true;
  }

  resetState() {
    const query = this.getCurrentQueryDto({}, this.initialState);

    this.selectedQuery = null;
    this.activeQueryChange.emit(query);
    sessionStorage.removeItem(this._cacheKey);
  }

  saveQuery() {
    if (this.queriesForm.valid) {
      this.editMode ? this.updateQuery() : this.addNewQuery();
      this.closeEditModal();
    }
  }

  addNewQuery() {
    this.spinnerService.show('spinner');

    const newQuery = this.getQueryFromForm();
    const newQueryDto = this.getCurrentQueryDto(newQuery, this.currentFiltersConfiguration);

    this.queriesService.addQuery(newQueryDto)
      .pipe(
        finalize(() => this.spinnerService.hide('spinner'))
      )
      .subscribe(
        (data: QueryDto[]) => {
          this.queries = data;

          const selectedQuery = data.find(q => q.Name === newQueryDto.Name);

          this.applyQuery(selectedQuery);
          this.notificationService.showMsgSuccess(
            'success.add_query',
            'Query added successfully');
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }

  updateQuery() {
    const query = this.getQueryFromForm();
    const queryDto = this.getCurrentQueryDto(query, this.currentFiltersConfiguration);

    this.spinnerService.show('spinner');

    this.queriesService.updateQuery(queryDto)
      .pipe(
        finalize(() => this.spinnerService.hide('spinner'))
      )
      .subscribe(
        (data: QueryDto[]) => {
          this.queries = data;
          this.notificationService.showMsgSuccess(
            'success.update_query',
            'Query updated successfully');
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }

  deleteSelectedQuery() {
    this.openDeleteConfirmationModal = false;
    this.spinnerService.show('spinner');

    if (this.selectedQuery && this.selectedQueryToDelete.Id === this.selectedQuery.Id) {
      this.resetState();
    }

    this.queriesService.deleteQuery(this.selectedQueryToDelete.Id, this.selectedQueryToDelete.UserId)
      .pipe(
        finalize(() => this.spinnerService.hide('spinner'))
      )
      .subscribe(
        (data: QueryDto[]) => {
          this.queries = data;
          this.selectedQueryToDelete = null;

          this.notificationService.showMsgSuccess(
            'success.add_query',
            'Query saved successfully');
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }

  private getQueryFromForm(): QueryDto {
    const query = <QueryDto> {
      Name: this.queriesForm.get('Name').value
    };

    return query;
  }

  private getCurrentQueryDto(query: QueryDto | {}, state: State): QueryDto {
    const currentState = JSON.stringify(state);
    const updatedQuery = <QueryDto> {
      ...this.selectedQuery,
      ...query,
      Configuration: currentState
    };

    return updatedQuery;
  }

  private applyQueryFromCache() {
    const cachedQueryEntry = sessionStorage.getItem(this._cacheKey);

    if (cachedQueryEntry) {
      const query = JSON.parse(cachedQueryEntry);

      this.applyQuery(query);
    }
  }
}
