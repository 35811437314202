import { Component, OnInit } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';

import { finalize } from 'rxjs/operators';
import { GridStateHelper } from '../shared/grid-state';
import { ActionType } from '../shared/helper';
import { SupplierDto } from 'app/catalogue-view/shared/catalogue-view.model';

import { DataManagementService } from '../data-management.service';
import { TranslatedNotificationService } from 'app/shared/translation/translated-notification.service';
import { SpinnerService } from 'app/shared/spinner/spinner.service';

@Component({
  selector: 'app-manage-suppliers',
  templateUrl: './manage-suppliers.component.html',
  styleUrls: ['./manage-suppliers.component.scss']
})
export class ManageSuppliersComponent implements OnInit {
  closeDialog = true;
  actionType: ActionType;
  editedSupplier: SupplierDto;
  gridData: GridDataResult;
  items: SupplierDto[];
  state: State;

  constructor(private dataManagementService: DataManagementService,
    private gridState: GridStateHelper,
    private notificationService: TranslatedNotificationService,
    private spinner: SpinnerService) {
      this.state = this.gridState.getBasicState();
     }

  ngOnInit() {
    this.fetchData();
  }

  dataStateChange(currentState: DataStateChangeEvent): void {
    this.state = currentState;
    this.gridData = process(this.items, this.state);
  }

  closeSupplierDialog() {
    this.closeDialog = true;
  }

  openSupplierDialog(supplier: SupplierDto) {
    this.editedSupplier = supplier;
    this.closeDialog = false;
  }

  applySupplierChange(supplier: SupplierDto) {
    this.closeDialog = true;
    const existingSupplier = this.items.find(x => x.Id === supplier.Id);

    if (existingSupplier) {
      existingSupplier.Name = supplier.Name;
    } else {
      this.items.push(supplier);
    }

    this.gridData = process(this.items, this.state);
  }

  private fetchData() {
    this.spinner.show('spinner');
    this.dataManagementService.getSuppliers()
    .pipe(
      finalize(() => this.spinner.hide('spinner'))
    )
    .subscribe(
      (suppliers) => {
        this.items = suppliers;
        this.gridData = process(this.items, this.state);
      },
      () => this.notificationService.showDefaultMsgError()
    );
  }
}
