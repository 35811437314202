import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Observable, Subscription, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { PphraseKind } from './../data-management/manage-pphrases/manage-pphrases.model';
import { RiskAnalysisSaveDto, RiskAnalysisDto, CustomPphraseDto, AdditionalRiskAnalysisDto } from './risk-analysis.model';
import { RiskAnalysisService } from './risk-analysis.service';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { TranslatedNotificationService } from '../shared/translation/translated-notification.service';
import { ConfirmationDialogService } from '../shared/confirmation-dialog/confirmation-dialog.service';
import { SharedService } from './../shared/shared.service';
import { DataManagementService } from 'app/data-management/data-management.service';
import { CatalogueViewService } from 'app/catalogue-view/shared/catalogue-view.service';
import { DangerClassDto } from 'app/catalogue-view/shared/catalogue-view.model';

@Component({
  selector: 'app-risk-analysis',
  templateUrl: './risk-analysis.component.html',
  styleUrls: ['./risk-analysis.component.scss']
})
export class RiskAnalysisComponent implements OnInit, OnDestroy {
  request: RiskAnalysisDto;
  requestId: number;
  technicalMeasureId: number;
  riskScore: number;
  additionalPphrases: CustomPphraseDto[];
  derogationFile: File;
  confirmationDialogSubscription: Subscription;
  enableSubmit = false;
  kinds: PphraseKind[];
  isSubmitted = false;
  selectedAdditionalRiskAnalysis: AdditionalRiskAnalysisDto;
  additionalRiskAnalysisForm : FormGroup;
  isocyanatesCheckForm : FormGroup;

  private copiedApprovalId: number;
  private dirty = false;

  get hasPphrases(): boolean {
    return this.request.Pphrases && this.request.Pphrases.length > 0;
  }

  get derogationFileRequired(): boolean {
    return (
      this.request &&
      this.request.Hphrases.find(h => h.HasDerogation === true) != null
    );
  }

  get hasAdditionalRiskAnalyses(): boolean {
    return this.request.AdditionalRiskAnalyses.length > 0;
  }

  get riskClassForValue() {
    return {
      'risk-score-green': this.selectedAdditionalRiskAnalysis.RiskScore < 2,
      'risk-score-yellow': this.selectedAdditionalRiskAnalysis.RiskScore >= 2 && this.selectedAdditionalRiskAnalysis.RiskScore < 5,
      'risk-score-orange': this.selectedAdditionalRiskAnalysis.RiskScore >= 5 && this.selectedAdditionalRiskAnalysis.RiskScore < 7,
      'risk-score-darkorange': this.selectedAdditionalRiskAnalysis.RiskScore >= 7 && this.selectedAdditionalRiskAnalysis.RiskScore < 10,
      'risk-score-red': this.selectedAdditionalRiskAnalysis.RiskScore >= 10
    };
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: SpinnerService,
    private notificationService: TranslatedNotificationService,
    private confirmationDialogService: ConfirmationDialogService,
    private riskAnalysisService: RiskAnalysisService,
    private catalogueViewService: CatalogueViewService,
    public sharedService: SharedService,
    private dataManagementService: DataManagementService,
  ) {
  }

  ngOnInit() {
    this.copiedApprovalId = this.route.snapshot.queryParams['copiedApprovalId'];
    this.router.navigate([], { queryParams: { copiedApprovalId: null }, queryParamsHandling: 'merge' });

    this.confirmationDialogSubscription = this.confirmationDialogService.dialogConfirmationSubject.subscribe(
      leftButtonClicked => {
        if (leftButtonClicked && this.request.Id === 0 && this.request.IsRequestCopied) {
          this.removeRequest();
        }
      }
    );
    this.spinner.show('spinner');

    this.requestId = +this.route.snapshot.paramMap.get('requestId');

    if (isNaN(this.requestId)) {
      this.router.navigate(['/']);

      return;
    }

    forkJoin(
      [this.riskAnalysisService.getRequest(this.requestId, this.sharedService.currentLanguage.LanguageCode, this.sharedService.entityId),
      this.dataManagementService.getPphraseKinds()])
      .pipe(finalize(() => {
        this.spinner.hide('spinner');
        if (this.request && this.request.Hphrases) {
          this.mapDangerClass();
        }
        this.createForm();
      }))
      .subscribe(
        ([data, kinds]) => {
          this.request = data;
          this.technicalMeasureId = data.TechnicalMeasure ? data.TechnicalMeasure.Id : 0;
          this.additionalPphrases = data.AdditionalPphrases || [];
          this.kinds = kinds;
        },
        () => this.notificationService.showDefaultMsgError()
      );
  }

  ngOnDestroy(): void {
    this.confirmationDialogSubscription.unsubscribe();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.dirty || (this.request.Id === 0 && this.request.IsRequestCopied && !this.enableSubmit)) {
      return this.confirmationDialogService.showDialog();
    }

    return true;
  }

  updateRiskScore(event: { technicalMeasureId: number | undefined; riskScore: number }) {
    this.dirty = true;
    this.riskScore = event.riskScore;
    this.technicalMeasureId = event.technicalMeasureId;
  }

  updatePphrasesList(pPhrases: CustomPphraseDto[]) {
    this.dirty = true;
    this.additionalPphrases = pPhrases;
  }

  updateDerogationFile(file: File) {
    this.dirty = true;
    this.derogationFile = file;
  }

  goBack() {
    if (this.copiedApprovalId) {
      this.router.navigate(['/catalogue', this.copiedApprovalId]);
    } else {
      this.router.navigate(['/request-details', this.requestId]);
    }
  }

  saveCalculation() {
    this.isSubmitted = true;
    const entity = <RiskAnalysisSaveDto>{
      TechnicalMeasureId: this.technicalMeasureId,
      RequestId: this.requestId,
      RiskScore: this.riskScore ? this.riskScore : this.request.RiskScore,
      AdditionalWarning: this.request.AdditionalWarning,
      AdditionalPphrases: this.additionalPphrases,
      ContainsIsocyanates: this.getContainsIsocyanates()
    };

    const formData = new FormData();

    formData.append('riskAnalysisDto', JSON.stringify(entity));

    if (this.derogationFileRequired && this.derogationFile) {
      formData.append('derogationFile', this.derogationFile, this.derogationFile.name);
    }

    // simple validation:
    const technicalMeasureSelectedFulfilled = this.technicalMeasureId > 0;

    if (!technicalMeasureSelectedFulfilled) {
      this.scroll(document.getElementById('riskCalculation'));
      this.notificationService.showMsgError('error.technical_measure_not_selected', 'Technical measure was not selected.');
    } else {
      this.handleRequest(formData);
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  private handleRequest(formData: FormData) {
    const editMode = this.request.Id > 0;
    const action = editMode ? this.riskAnalysisService.updateAnalysis(formData) : this.riskAnalysisService.addAnalysis(formData);

    action.subscribe(
      () => {
        this.notificationService.showMsgSuccess('success.save_risk_analysis', 'Successfully saved risk analysis');
        this.dirty = false;
        this.enableSubmit = true;
        this.router.navigate(['/request-details', this.requestId]);
      },
      () =>
        this.notificationService.showMsgError('error.cannot_save_risk_analysis', 'There was an error on saving risk analysis')
    );
  }

  private removeRequest() {
    this.catalogueViewService
      .removeApprovalByRequestId(this.requestId.toString())
      .pipe(finalize(() => this.spinner.hide('spinner')))
      .subscribe(
        () => {
          this.notificationService.showMsgSuccess('success.delete_approval', 'Successfully deleted approval');
          this.router.navigate(['/catalogue', this.copiedApprovalId]);
        },
        () =>
          this.notificationService.showMsgError(
            'error.delete_aproval_failed',
            'Removing approval is not possible now, please try again later.')
      );
  }

  private mapDangerClass() {
    this.request.Hphrases.forEach(phrase => {
      if (!phrase.DangerClass) {
        phrase.DangerClass = <DangerClassDto>
          {
            RiskScore: 0
          };
      }
    });
  }

  private createForm() {
    if (this.request.AdditionalRiskAnalyses.length === 1) {
      this.selectedAdditionalRiskAnalysis = this.request.AdditionalRiskAnalyses.find(x => x.AdditionalPphrases);
    } else if (this.request.AdditionalRiskAnalyses.length > 1) {
      this.additionalRiskAnalysisForm = this.formBuilder.group({
      AdditionalRiskAnalysis: [null]
      });
    }
    if (this.request.ContainsIsocyanates){
      this.isocyanatesCheckForm = this.formBuilder.group({ isocyanatesCheck: ['true'] })
    }
    else {
      this.isocyanatesCheckForm = this.formBuilder.group({ isocyanatesCheck: ['false'] })
    }
  }

  private getContainsIsocyanates(): boolean {
    return JSON.parse(this.isocyanatesCheckForm.get('isocyanatesCheck').value);
  }
}
